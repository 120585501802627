import React from 'react';
import { Box } from '@chakra-ui/react';
import { UserAvatar, getAvatarTriggerElement } from '../userAvatar';
import { getActionContentStyles } from '@allergan-data-labs/alle-elements';

import {
  ActionMenu,
  ActionMenuContent,
} from '@allergan-data-labs/alle-elements';
import { UserInfo } from '../userInfo';
import { UserRoleMockData } from '../../mocks/mockRoles';
import { ProfileMenuHeader } from './profileMenuHeader';
import { ProfileMenuGroup } from './profileMenuGroup';
import { ProfileMenuItem } from './profileMenuItem';
import { ProfileRoleBasedMenuGroup } from './profileRoleBasedMenuGroup';

interface UserProps {
  id?: string;
  name: string;
  role?: UserRoleMockData;
}

export interface ProfileMenuProps {
  user: UserProps;
  onSignOut?: () => void;
}

// TODO: Currently using UserRoleMockData for temporary mock implementation.
// Need to replace with final version once the actual UserRole data structure is defined and available.
const ProfileMenu: React.FC<ProfileMenuProps> = ({
  user,
  onSignOut,
}: ProfileMenuProps) => {
  const UserAvatarTriggerElement = getAvatarTriggerElement(user.name);

  const onHelpCenter = () =>
    window.open('https://allesupporthelp.zendesk.com', '_blank');

  return (
    <ActionMenu
      data-testid="profile-menu"
      triggerElement={UserAvatarTriggerElement}
      placement="bottom-end"
    >
      <ActionMenuContent
        data-testid="profile-menu-content"
        sx={{
          ...getActionContentStyles('light', false),
          '&': {
            minWidth: '310px',
            maxHeight: 'none',
          },
        }}
      >
        <Box padding={8} paddingBottom={0}>
          <ProfileMenuHeader>
            <UserAvatar label={user.name} />
            <UserInfo title={user.name} subTitle={user?.role || ''} />
          </ProfileMenuHeader>
          {user.role ? <ProfileRoleBasedMenuGroup role={user.role} /> : null}
          <ProfileMenuGroup>
            <ProfileMenuItem onClick={onHelpCenter}>
              Help Center
            </ProfileMenuItem>
            <ProfileMenuItem onClick={onSignOut}>Sign Out</ProfileMenuItem>
          </ProfileMenuGroup>
        </Box>
      </ActionMenuContent>
    </ActionMenu>
  );
};

export { ProfileMenu };
