import jwtDecode from 'jwt-decode';

const BasicAuthClientFactory = (key: string) => ({
  validateToken: () =>
    new Promise((resolve, reject) => {
      if (!localStorage[key]) {
        reject('No token present');
      }

      try {
        interface ExpiringToken {
          exp: number;
        }

        const { exp } = jwtDecode(localStorage[key]) as ExpiringToken;

        if (!exp || new Date() > new Date(exp * 1000)) {
          reject('Token is expired or has invalid expiration');
        }
      } catch (err) {
        reject(err.message);
      }

      resolve('Token is valid');
    }),
  getAccessToken: () => {
    return localStorage[key];
  },
  storeToken: (value: string) => {
    localStorage[key] = value;
  },
  subscribeToTokenInvalidation: function (callback: () => void) {
    const getAccessTokenHandler = (event: StorageEvent) => {
      if (event.key === key && (!event.newValue || event.newValue === '{}')) {
        callback();
      }
    };

    // Logout all tabs if one tab logs out
    window.addEventListener('storage', getAccessTokenHandler);

    this.validateToken().catch(() => {
      callback();
    });

    const tokenCheckInterval = setInterval(() => {
      this.validateToken().catch(() => {
        callback();
      });
    }, 60000);

    const unsubscribe = () => {
      clearInterval(tokenCheckInterval);
      window.removeEventListener('storage', getAccessTokenHandler);
    };

    return unsubscribe;
  },
  signOut: () => {
    return localStorage.removeItem(key);
  },
});

export { BasicAuthClientFactory };
