import React, { useState } from 'react';
import { Section } from '../section';
import { SectionItem } from '../sectionItem';

import { MultiLineLabel } from '../multiLineLabel';
import { Label } from '../label';
import { TimezoneSelect } from '../timezoneSelect';
import { getTimezoneLabel } from '@allergan-data-labs/component-library/src/utils/timezone';

export interface LocationInformationProps {
  shipToId: string;
  timezone: string;
  address: string[];
  onSaveTimezone: (timezone: string) => void;
}

const LocationInformation: React.FC<LocationInformationProps> = ({
  shipToId,
  timezone,
  address,
  onSaveTimezone,
}) => {
  const [timezoneValue, setTimezoneValue] = useState(timezone);
  const [editing, setEditing] = useState(false);

  const handleToggleEdit = () => {
    setEditing((isEditing) => !isEditing);
  };

  const handleOnConfirmTimezone = (nextTimezone: string) => {
    setTimezoneValue(nextTimezone);
    onSaveTimezone(nextTimezone);
    handleToggleEdit();
  };

  const timezoneSectionAction = !editing && {
    actionName: 'Edit',
    onAction: handleToggleEdit,
  };

  return (
    <Section title="Location Information" bottomDivider={true}>
      <SectionItem title="AbbVie SAP ShipTo ID">
        <Label text={shipToId} isCopyEnabled />
      </SectionItem>
      <SectionItem title="Address">
        <MultiLineLabel texts={address} isCopyEnabled />
      </SectionItem>
      <SectionItem title="Time Zone" {...timezoneSectionAction}>
        {editing ? (
          <TimezoneSelect
            value={timezoneValue}
            confirmLabel="Save"
            onConfirm={handleOnConfirmTimezone}
            onCancel={handleToggleEdit}
          />
        ) : (
          <Label text={getTimezoneLabel(timezoneValue)} />
        )}
      </SectionItem>
    </Section>
  );
};

export { LocationInformation };
