import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const People: React.FunctionComponent = () => (
  <IconContainer>
    <SvgIcon width="22" height="22" viewBox="0 0 22 22">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M12.55542,6.55554 C12.55542,7.41467 11.85901,8.11108 10.99988,8.11108 C10.14075,8.11108 9.44434,7.41467 9.44434,6.55554 C9.44434,5.69641 10.14075,5 10.99988,5 C11.85901,5 12.55542,5.69641 12.55542,6.55554 Z M14.5554,6.55554 C14.5554,8.51929 12.96362,10.11108 10.99988,10.11108 C9.03625,10.11108 7.44434,8.51929 7.44434,6.55554 C7.44434,4.59192 9.03625,3 10.99988,3 C12.96362,3 14.5554,4.59192 14.5554,6.55554 Z M13.0481,13.5251 C14.8579,13.9429 16.2548,15.2946 16.7771,17 L5.2229,17 C5.74524,15.2946 7.14209,13.9429 8.9519,13.5251 L9.10583,13.4897 C10.35217,13.202 11.64783,13.202 12.89417,13.4897 L13.0481,13.5251 Z M8.65601,11.54089 L8.5022,11.57642 C5.28149,12.3197 3,15.1876 3,18.4929 C3,18.7729 3.227051,19 3.50708,19 L18.4929,19 C18.7729,19 19,18.7729 19,18.4929 C19,15.1876 16.7185,12.3197 13.4978,11.57642 L13.344,11.54089 C11.80164,11.18494 10.19836,11.18494 8.65601,11.54089 Z"
          id="path-1"
        ></path>
      </g>
    </SvgIcon>
  </IconContainer>
);

export { People };
