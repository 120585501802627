import React, { useId } from 'react';
import {
  Flex,
  Tbody,
  Tr as ChakraTr,
  TableContainer as ChakraTableContainer,
  TableCellProps as ChakraTableCellProps,
  Table as ChakraTable,
  Td as ChakraTd,
  TableContainerProps as ChakraTableContainerProps,
  TableRowProps as ChakraTableRowProps,
  TableProps as ChakraTableProps,
  useMediaQuery,
} from '@chakra-ui/react';
import { ColorMode, Skeleton } from '@allergan-data-labs/alle-elements';

import {
  getTableContainerStyles,
  getTableTdStyles,
  getTableTrStyles,
  getTableStyles,
  sizes,
} from './Table.theme';

type TableSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type TableColumn<T> = {
  field: keyof T;
  hidden?: boolean;
  width?: ChakraTableCellProps['width'];
  renderCell?: <U extends keyof T>(row: T, cell: T[U]) => any;
};

type TableProps<T> = {
  /** List of data to be displayed in the table
   * @default undefined
   */
  data?: T[] | null;
  /** Column definition map. (how fields are mapped on the columns)
   * @default undefined;
   */
  columns: Array<TableColumn<T>>;
  /** Is the table loading data
   * @default false
   */
  isLoading?: boolean;
  /** Default number of rows when data is not provided
   * @default 5
   */
  skeletonRows?: number;
  /** The color mode of Table
   * @default light
   */
  colorMode?: ColorMode;
  /** The size of the Table
   * @default lg
   */
  size?: TableSize;
  /** Make skeleton full size of the column
   * @default false
   */
  isLoadingFullSize?: boolean;
} & Partial<CoreTableProps>;

interface TableContainerProps extends ChakraTableContainerProps {
  /** Apply full size styles for the table container
   * @default false
   */
  isLoadingFullSize: boolean;
}

interface TableRowProps extends ChakraTableRowProps {
  /**
   * If added, the Table Row will show as highlighted
   * @default null
   */
  isActive?: boolean;
  /** Apply loading full size styles
   * @default false
   */
  isLoadingFullSize?: boolean;
}

interface TableCellProps extends ChakraTableCellProps {
  /** Apply full size of the column
   * @default false
   */
  isLoadingFullSize?: boolean;
}

type SkeletonCellProps<T> = {
  /** Apply full size styles for the table container
   * @default false
   */
  isLoadingFullSize: boolean;
  /** Column definition map. (how fields are mapped on the columns)
   * @default undefined;
   */
  columns: Array<TableColumn<T>>;
  /** Make skeleton full size of the column
   * @default undefined;
   */
  rowIndex: number;
  skeletonHeight: string;
};

interface CoreTableProps extends ChakraTableProps {
  /** The color mode of Table
   * @default light
   */
  colorMode?: ColorMode;
  /** The size of the Table
   * @default lg
   */
  size?: TableSize;
}

const TableContainer = ({
  children,
  isLoadingFullSize,
  ...rest
}: TableContainerProps) => {
  return (
    <ChakraTableContainer
      sx={getTableContainerStyles(isLoadingFullSize)}
      {...rest}
    >
      {children}
    </ChakraTableContainer>
  );
};

const CoreTable = (props: CoreTableProps) => {
  const { children, colorMode = 'light', size, ...rest } = props;
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Flex>
      <ChakraTable
        size={isMobile ? 'xs' : size}
        sx={getTableStyles()}
        colorScheme={colorMode}
        {...rest}
      >
        {children}
      </ChakraTable>
    </Flex>
  );
};

const Tr = ({
  children,
  isLoadingFullSize,
  isActive,
  ...rest
}: TableRowProps) => {
  return (
    <ChakraTr
      sx={getTableTrStyles(isLoadingFullSize)}
      className={isActive ? 'active' : undefined}
      {...rest}
    >
      {children}
    </ChakraTr>
  );
};

const Td = ({ children, isLoadingFullSize, ...rest }: TableCellProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const tdCustomStyles = isLoadingFullSize ? { padding: '0px !important' } : {};
  return (
    <ChakraTd {...rest} {...tdCustomStyles}>
      <Flex sx={getTableTdStyles(isMobile, isLoadingFullSize)}>{children}</Flex>
    </ChakraTd>
  );
};

const SkeletonCell = <T,>({
  isLoadingFullSize,
  skeletonHeight,
  rowIndex,
  columns,
}: SkeletonCellProps<T>) => {
  if (!isLoadingFullSize && columns.length) {
    return (
      <>
        {columns
          .filter((cols) => !cols.hidden)
          .map((column, columnIndex) => (
            <Td
              data-testid={`skeleton-${String(
                column.field
              )}-${rowIndex}-${columnIndex}`}
              width={column.width}
              key={column.field as string}
            >
              <Skeleton height={skeletonHeight} />
            </Td>
          ))}
      </>
    );
  }

  return (
    <Td data-testid={`skeleton-column-${rowIndex}`} isLoadingFullSize>
      <Skeleton height={skeletonHeight} />
    </Td>
  );
};

export const Table = <T,>(props: TableProps<T>) => {
  const {
    data,
    columns,
    isLoading = false,
    colorMode,
    skeletonRows = 5,
    isLoadingFullSize = false,
    size = 'lg',
    ...tableProps
  } = props;
  const rowId = useId();
  const rowsNumber = data?.length || skeletonRows;
  const skeletonHeight =
    isLoadingFullSize && size
      ? sizes[size]?.td.height
      : sizes.skeleton.td.height;
  const isLoadingAndFullSize = isLoading && isLoadingFullSize;

  return (
    <TableContainer isLoadingFullSize={isLoadingAndFullSize}>
      <CoreTable
        colorMode={colorMode}
        aria-busy={isLoading}
        size={size}
        {...tableProps}
      >
        <Tbody>
          {isLoading &&
            [...Array(rowsNumber)].map((_, rowIndex) => (
              <Tr
                key={rowId + rowIndex}
                isLoadingFullSize={isLoadingAndFullSize}
              >
                <SkeletonCell
                  isLoadingFullSize={isLoadingFullSize}
                  skeletonHeight={skeletonHeight}
                  columns={columns}
                  rowIndex={rowIndex}
                />
              </Tr>
            ))}
          {!isLoading &&
            data?.map((row, rowIndex) => (
              <Tr key={rowId + rowIndex}>
                {columns
                  .filter((column) => !column.hidden)
                  .map((column, columnIndex) => (
                    <Td
                      width={column.width}
                      key={column.field as string}
                      data-testid={`${String(
                        column.field
                      )}-${rowIndex}-${columnIndex}`}
                    >
                      {column.renderCell
                        ? column.renderCell(row, row[column.field])
                        : (row[column.field] as React.ReactElement)}
                    </Td>
                  ))}
              </Tr>
            ))}
        </Tbody>
      </CoreTable>
    </TableContainer>
  );
};

export type {
  SkeletonCellProps,
  CoreTableProps,
  TableCellProps,
  TableRowProps,
  TableColumn,
  TableProps,
  TableSize,
};
