import React from 'react';
import styled from 'styled-components';

// Core
import { AlleLogo } from '@allergan-data-labs/component-library/src/icons/alleLogo';
import { Avatar } from '@allergan-data-labs/component-library/src/core/avatar';
import { colors } from '@allergan-data-labs/component-library/src/core/colors';
import { formatInitials } from '@allergan-data-labs/component-library/src/utils/format';
import { H5 } from '@allergan-data-labs/component-library/src/core/typography/typographyDEPRECATED';
import { Link } from '@allergan-data-labs/component-library/src/core/link';
import { SimpleButton } from '@allergan-data-labs/component-library/src/core/simpleButton';
import { Help } from '@allergan-data-labs/component-library/src/icons/help';
import { Open } from '@allergan-data-labs/component-library/src/icons/open';
import { alleColors } from '@allergan-data-labs/component-library/src/colorPalette';

// Material
import Backdrop from '@material-ui/core/Backdrop';

import { useAdminUserProfile } from '../hooks/useAdminUserProfile';
import { navigationVariant } from '../application.config';

const SIDE_MENU_WIDTH_CLOSED = 100;
const SIDE_MENU_WIDTH_OPEN = 250;

const InnerContainer = styled.div`
  height: 100vh;
  width: ${SIDE_MENU_WIDTH_OPEN}px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translateX(${SIDE_MENU_WIDTH_OPEN - SIDE_MENU_WIDTH_CLOSED}px);
`;

const Header = styled.header`
  z-index: 1000;
  width: ${SIDE_MENU_WIDTH_OPEN}px;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -${SIDE_MENU_WIDTH_OPEN - SIDE_MENU_WIDTH_CLOSED}px;

  background-color: ${colors[navigationVariant]};

  .show-when-open {
    transition: opacity 110ms;
    opacity: 0;
  }

  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  &.open {
    transform: translateX(${SIDE_MENU_WIDTH_OPEN - SIDE_MENU_WIDTH_CLOSED}px);

    & ${InnerContainer} {
      transform: translateX(0);
    }

    & .show-when-open {
      opacity: 1;
    }
  }

  & .MuiSvgIcon-root,
  svg {
    path {
      fill: ${colors.white};
    }
  }
`;

const AlleLogoContainer = styled.div`
  margin-top: 40px;
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Nav = styled.nav``;

const NavLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 46px;
  padding-top: 7px;
  padding-bottom: 7px;

  :last-child {
    margin-bottom: 18px;
  }

  && .MuiSvgIcon-root,
  svg {
    path {
      fill: ${colors.accent};
    }
  }
  &:hover svg,
  &:hover .MuiSvgIcon-root {
    path {
      fill: ${colors.white};
    }
  }
  &.active svg,
  &.active .MuiSvgIcon-root {
    path {
      fill: ${alleColors.nude[500]};
    }
  }
  > span {
    color: ${colors.accent};
  }
  &:hover > span,
  &.active > span {
    color: ${colors.white};
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconContainer = styled.div`
  margin-left: 39px;
  margin-right: 30px;
  height: 22px;
`;

const FooterContainer = styled.div`
  min-height: 49px;
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;

  .MuiButton-label {
    justify-content: flex-start;
    span {
      color: ${colors.accent};
    }
  }
`;

const FooterInnerContainer = styled.div`
  min-width: 0;
  margin-right: 5px;

  span {
    color: ${colors.white};
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-left: 25px;
  margin-right: 18px;
`;

const Span = styled(H5).attrs({ as: 'span' })`
  text-transform: uppercase;
  font-weight: bold;
`;

const AdminUserName = styled(H5).attrs({ as: 'div' })`
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.white};
`;

interface RouteOptions {
  title: string;
  icon: JSX.Element;
  to: string;
}

interface SideMenuProps {
  homeRoute: string;
  routes: RouteOptions[];
  onSignOut: () => void;
}

const SideMenu: React.FunctionComponent<SideMenuProps> = ({
  homeRoute,
  routes,
  onSignOut,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const [profile, error] = useAdminUserProfile();

  React.useEffect(() => {
    if (error) {
      onSignOut();
    }
  }, [error, onSignOut]);

  return (
    <>
      <Header
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className={open ? 'open' : ''}
        data-testid="open-menu-button"
      >
        <InnerContainer>
          <AlleLogoContainer>
            <Link to={homeRoute}>
              <AlleLogo />
            </Link>
          </AlleLogoContainer>
          <Nav>
            {routes.map((route: RouteOptions, index: number) => (
              <NavLink key={index} to={route.to} activeWhenNotExact preload>
                <IconContainer>{route.icon}</IconContainer>
                <Span className="show-when-open">{route.title}</Span>
              </NavLink>
            ))}
          </Nav>
          <div />
          <FooterContainer>
            <Nav>
              <NavLink
                to={{ pathname: 'https://app.surfly.io/sso/company/allergan/' }}
                external
              >
                <IconContainer>
                  <Open />
                </IconContainer>
                <Span className="show-when-open">Open Surfly</Span>
              </NavLink>
            </Nav>
            <Nav>
              <NavLink
                to={{ pathname: 'https://allesupporthelp.zendesk.com' }}
                external
              >
                <IconContainer>
                  <Help />
                </IconContainer>
                <Span className="show-when-open">Help</Span>
              </NavLink>
            </Nav>
            {profile ? (
              <AvatarContainer>
                <StyledAvatar initials={formatInitials(profile)} />
                <FooterInnerContainer className="show-when-open">
                  <AdminUserName>{`${profile.firstName} ${profile.lastName}`}</AdminUserName>
                  <SimpleButton
                    onClick={onSignOut}
                    data-testid="menu-logout-button"
                  >
                    <Span>Log Out</Span>
                  </SimpleButton>
                </FooterInnerContainer>
              </AvatarContainer>
            ) : null}
          </FooterContainer>
        </InnerContainer>
      </Header>
      <Backdrop
        open={open}
        transitionDuration={200}
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 2 }}
      />
    </>
  );
};

export { SideMenu, SIDE_MENU_WIDTH_CLOSED };
