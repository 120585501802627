import { SystemStyleObject } from '@chakra-ui/react';

export const getResultsRowsStyles = (): SystemStyleObject => ({
  padding: '8px',
  paddingX: '20px',
  alignItems: 'center',
  gap: '24px',
  _hover: {
    background: 'light.Container/Neutral/Transparent interactive/Hover',
    cursor: 'pointer',
  },
  '> :nth-of-type(1)': {
    flexBasis: '31.7%',
  },
  '> :nth-of-type(2)': {
    flexGrow: 1,
    justifyContent: 'flex-start',
    gap: '1.5rem',
  },
  '> :nth-of-type(3)': {
    flex: 'auto',
  },
});
