import { FormEvents, FormStates } from '../stateMachines/formMachineTypes';

const validatedSuccess = (
  fieldName: string,
  validationErrors: any,
  current: any
) => {
  return (
    current.context &&
    current.context.validated &&
    current.context.validated.includes(fieldName) &&
    !validationErrors[fieldName]
  );
};

const validatedSuccesses = (
  fieldNames: string[],
  validationErrors: any,
  current: any
): { [k: string]: boolean } => {
  return fieldNames.reduce((acc: { [k: string]: boolean }, nextKey: string) => {
    return {
      ...acc,
      [nextKey]: validatedSuccess(nextKey, validationErrors, current),
    };
  }, {});
};

const defaultEventMap: { [k: string]: FormEvents } = {
  [FormStates.idle]: FormEvents.IDLE_SUBMIT,
  [FormStates.validation_error]: FormEvents.VALIDATION_ERROR_RESUBMIT,
  [FormStates.server_error]: FormEvents.REQUEST_RETRY,
};

const runValidation = <T = { [key: string]: string }>(
  {
    fields,
    additionalFields,
    currentState,
    fieldName,
    eventsMap = defaultEventMap,
  }: {
    fields: T;
    additionalFields?: T;
    currentState: string;
    fieldName: string;
    eventsMap?: { [k: string]: FormEvents };
  },
  send: (obj: any) => void
) => {
  if (eventsMap[currentState]) {
    send({
      type: eventsMap[currentState],
      fields: {
        ...fields,
        additionalFields,
      },
      currentField: fieldName,
    });
  }
};

export { validatedSuccess, validatedSuccesses, defaultEventMap, runValidation };
