import { redactSensitiveDataFromURL } from '@allergan-data-labs/component-library/src/utils/format';

export const sourceRedactionMiddleware = ({
  payload,
  next,
}: {
  payload: any;
  next: (payload: any) => void;
}) => {
  // redact all possible query string parameters for `at=`
  if (payload?.obj?.context?.page?.search) {
    payload.obj.context.page.search = redactSensitiveDataFromURL(
      payload.obj.context.page.search
    );
  }
  if (payload?.obj?.context?.page?.url) {
    payload.obj.context.page.url = redactSensitiveDataFromURL(
      payload.obj.context.page.url
    );
  }
  if (payload?.obj?.properties?.search) {
    payload.obj.properties.search = redactSensitiveDataFromURL(
      payload.obj.properties.search
    );
  }
  if (payload?.obj?.properties?.url) {
    payload.obj.properties.url = redactSensitiveDataFromURL(
      payload.obj.properties.url
    );
  }
  next(payload);
};
