import React from 'react';
import { adminUserProfile } from '@allergan-data-labs/shared-sdk/src/adminUserProfile';
import { AdminUserProfile } from '@allergan-data-labs/alle-graphql-types';

/**
 * Utility hook for fetching the currently logged in admin user profile.
 */
const useAdminUserProfile = (): [AdminUserProfile | null, string | null] => {
  const mounted = React.useRef(true);
  const [profile, setProfile] = React.useState<AdminUserProfile | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchProfile = async () => {
      try {
        const adminProfile = await adminUserProfile();
        mounted.current && setProfile(adminProfile);
      } catch (err) {
        mounted.current && setError(err.message);
      }
    };

    fetchProfile();

    return () => {
      mounted.current = false;
    };
  }, []);

  return [profile, error];
};

export { useAdminUserProfile };
