// Mocked enum for user roles, used temporarily for development and testing of the ProfileMenu component
export enum UserRoleMockData {
  OneTeam = 'One Team',
  AllerganDirect = 'Allergan Direct',
  OfferBuilder = 'Offer Builder',
  CustomerSupport = 'Customer Support',
  TPS = 'Technical Product Support',
}

// Base items for the role menu, these are common across different user roles
export const roleBaseItems = [
  { name: 'Moxie', link: '/moxie' },
  { name: 'Zendesk', link: '/zendesk' },
  { name: 'Snowflake', link: '/snowflake' },
  { name: 'Datadog', link: '/datadog' },
  { name: 'CC1', link: '/cc1' },
];

// Temporary and mocked mapping of menu items based on user roles for the ProfileMenu component
export const roleMenuItems: {
  [key in UserRoleMockData]?: { name: string; link: string }[];
} = {
  [UserRoleMockData.AllerganDirect]: [
    ...roleBaseItems.filter(
      (item) => item.name === 'Moxie' || item.name === 'CC1'
    ),
    { name: 'Allergan Advantage', link: '/allergan-advantage' },
  ],
  [UserRoleMockData.CustomerSupport]: [
    ...roleBaseItems,
    { name: 'Full Story', link: '/full-story' },
  ],
  [UserRoleMockData.TPS]: [
    ...roleBaseItems,
    { name: 'Allergan Advantage', link: '/allergan-advantage' },
    { name: 'Full Story', link: '/full-story' },
  ],
};
