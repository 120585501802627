import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

interface BuildingIconProps {
  size?: number;
  fill?: string;
}

const Svg = styled.svg`
  width: ${(props: { width?: string }) => (props.width ? props.width : '22px')};
`;

const BuildingIcon: React.FunctionComponent<BuildingIconProps> = (props) => (
  <Svg
    id={uuid()}
    data-name="Building Icon"
    viewBox="0 0 22 22"
    width={props.size?.toString()}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.5H4V17.5L5.5 17.5V13.25V11.75H7H9H10.5V13.25V17.5L16 17.5V2.5ZM5.5 19H7H9H10.5L16 19H17.5H19V17.5H17.5V2.5V1H16H4H2.5V2.5V17.5H1V19H2.5H4L5.5 19ZM9 17.5V13.25H7V17.5H9ZM14.5 4.66391H5.5V6.16391H14.5V4.66391ZM5.5 7.59974H14.5V9.09974H5.5V7.59974Z"
      fill={props?.fill ?? '#111111'}
    />
  </Svg>
);

export { BuildingIcon };
