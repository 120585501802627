import { Routes } from './constants/routes';

export type Environments = 'local' | 'dev' | 'stage' | 'demo' | 'production';
export const __DEV__ = process.env.NODE_ENV === 'development';
export const __APP_VERSION__ = JSON.stringify(
  require('../package.json').version
);

const host = window.location.origin;

export const baseUrl = window.location.origin;

export const consumerBaseUrl = host.replace('://admin.', '://');

const TWO_HOURS_IN_SECONDS = 7200;

export const customOktaSettings = {
  redirectUri: `${baseUrl}${Routes.home}`,
  // Allow users to authenticate even if their clock is not accurate for up two hours
  // This fixes an issue where some users see "JWT was issued in the future" errors
  maxClockSkew: TWO_HOURS_IN_SECONDS,
};

export const oktaTokenIds = {
  accessToken: 'admin.accessTokenId',
  idToken: 'admin.idToken',
};

export const splitAuthorizationKey =
  process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY ||
  '8s75ge7ffdhkqagfkbheqdu5ueaptcu0pqu5';

export const inactivityTimer = {
  session: 7200000, // 2 hours
};

export const allerganAdvantageStorageKey = 'admin.aa-token-storage';

export const isInMaintenanceMode = false;

export const isAlleDown = process.env.REACT_APP_ALLE_DOWN === 'true';
