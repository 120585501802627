import React from 'react';
import styled from 'styled-components';

// Material
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Utils
import DateFnsUtils from '@date-io/date-fns';
import formatDate from 'date-fns/format';

import { colors } from '../colors';
import { typographyStyles } from '@allergan-data-labs/universal-component-library/src/atoms/typography';
import { alleColors } from '../../colorPalette';
import { Input, InputProps } from '../input';
import { DownArrow } from '../../icons/downArrow';
import { LeftArrow } from '../../icons/leftArrow';
import { RightArrow } from '../../icons/rightArrow';

class CustomDateFnsUtils extends DateFnsUtils {
  getCalendarHeaderText(date: any) {
    return formatDate(date, 'MMMM yyyy');
  }
  getWeekdays() {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }
}

interface DatePickerProviderProps {
  children: React.ReactNode;
}

const DatePickerProvider: React.FC<DatePickerProviderProps> = (props) => {
  return <MuiPickersUtilsProvider {...props} utils={CustomDateFnsUtils} />;
};

const StyledKeyboardDatePicker = styled(({ className, ...forwardProps }) => (
  <KeyboardDatePicker
    {...forwardProps}
    className={className}
    PopoverProps={{ classes: { paper: className } }}
  />
))`
  & .Mui-disabled {
    path {
      fill: ${alleColors.gray[300]};
    }
  }
  & .MuiTouchRipple-root {
    display: none;
  }
  .MuiPickersBasePicker-pickerView {
    min-width: 240px;
    min-height: 236px;
  }
  .MuiPickersCalendar-transitionContainer {
    margin-top: 6px;
    min-height: 200px;
  }
  .MuiTypography-root.MuiTypography-body1 {
    ${typographyStyles('body-M-bold')}
    text-align: center;
  }
  &.MuiPaper-root {
    margin-left: 48px;
    margin-top: 6px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  }
  & .MuiPickersCalendarHeader-switchHeader {
    margin-bottom: 4px;
  }
  & .MuiPickersCalendarHeader-daysHeader,
  & .MuiPickersCalendar-week {
    justify-content: space-between;
    margin-left: 8px;
    margin-right: 8px;
  }
  & span.MuiPickersCalendarHeader-dayLabel {
    ${typographyStyles('body-S-regular')}
    color: rgba(2, 2, 2, 0.25);
    text-align: center;
  }
  & button.MuiPickersCalendarHeader-iconButton {
    background-color: transparent;

    &:hover {
      .MuiSvgIcon-root path {
        fill: ${colors.skin};
      }
    }

    &.Mui-disabled {
      .MuiSvgIcon-root path {
        fill: ${colors.accent};
      }
    }
  }
  & button.MuiPickersDay-day {
    margin: 0;
    width: 32px;
    height: 32px;
    p {
      ${typographyStyles('body-S-regular')}
      text-align: center;
    }

    background-color: transparent;

    &:hover {
      color: ${colors.skin};
    }

    &.MuiPickersDay-dayDisabled {
      p {
        color: ${colors.accent};
      }
    }
    &.MuiPickersDay-current {
      p {
        color: ${colors.skin};
      }
    }
    &.MuiPickersDay-daySelected {
      .MuiIconButton-label {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: ${colors.skin};
      }
      p {
        ${typographyStyles('body-S-bold')}
        color: ${colors.white};
      }
    }
  }
`;

const StyledInput = styled(({ textColor, ...forwardProps }) => (
  <Input {...forwardProps} />
))`
  & .MuiInputBase-root {
    width: 218px;
  }
`;

// TODO: React-18 upgrade type error
// @ts-ignore
export interface DatePickerProps extends Omit<KeyboardDatePickerProps, 'css'> {
  inputProps?: InputProps;
}

const DatePicker = ({
  format = 'MM.dd.yyyy',
  inputProps,
  ...forwardProps
}: DatePickerProps) => {
  const inputPropsError = inputProps?.error ?? false;
  const CustomTextFieldComponent = React.useCallback(
    // Pull out helperText and error - we want to handle this on our own
    ({ helperText, error, ...props }: any) => (
      <StyledInput {...props} {...inputProps} />
    ),
    // Careful, anything that makes input re-render will cause it to lose focus
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputPropsError]
  );
  return (
    <StyledKeyboardDatePicker
      autoOk
      variant="inline"
      disableToolbar
      format={format}
      keyboardIcon={<DownArrow />}
      leftArrowIcon={<LeftArrow />}
      rightArrowIcon={<RightArrow />}
      TextFieldComponent={CustomTextFieldComponent}
      {...forwardProps}
    />
  );
};

export { DatePicker, DatePickerProvider };
