import React, {
  FocusEventHandler,
  MouseEventHandler,
  useEffect,
  useId,
} from 'react';
import { Flex, Text } from '@chakra-ui/react';
import {
  useSearchFieldContext,
  useSearchFieldEvents,
} from '../searchField.hook';
import { GenericDropdownProps } from '../types';
import { getRecentSearchesAndResultsDropdownStyles } from '../searchField.theme';
import { GenericRecentSearchTerms } from '../../genericRecentSearchTerms';

interface PanelProps extends React.HTMLAttributes<any> {
  isOpen: boolean;
  initialFocus?: React.MutableRefObject<HTMLInputElement | null>;
  children?: React.ReactNode;
  dataTestId?: string;
  onFocus?: FocusEventHandler<HTMLElement>;
  onMouseOut?: MouseEventHandler<HTMLElement>;
}

const Panel: React.FC<PanelProps> = ({
  isOpen,
  children,
  dataTestId = 'search-results-dropdown',
}) => {
  return isOpen ? (
    <Flex
      sx={getRecentSearchesAndResultsDropdownStyles()}
      data-testid={dataTestId}
    >
      {children}
    </Flex>
  ) : null;
};

export const GenericRecentSearchesAndResultsDropdown: React.FC<
  Omit<GenericDropdownProps, 'initialState'>
> = ({
  placeholder = '',
  recentSearches,
  menuVisible,
  onSelectRecentSearchTerm,
  onRemoveRecentSearchTerm,
}) => {
  const { inputRef, shouldFocusInput, setShouldFocusInput } =
    useSearchFieldContext();
  const searchResultMenuId = `search-results-menu-${useId()}`;
  const { onFocus, onMouseOut } = useSearchFieldEvents();

  useEffect(() => {
    if (shouldFocusInput) {
      requestAnimationFrame(() => {
        inputRef?.current?.focus();
        // Reset the state variable after focusing to avoid unnecessary focus on future renders
        setShouldFocusInput(false);
      });
    }
  }, [shouldFocusInput, inputRef, setShouldFocusInput]);

  useEffect(() => {
    inputRef?.current?.blur();
  }, []);

  return (
    <Panel
      isOpen={menuVisible}
      id={searchResultMenuId}
      initialFocus={inputRef}
      onFocus={onFocus}
      onMouseOut={onMouseOut}
    >
      {placeholder ? (
        <Text
          color="light.Text/Neutral/Support"
          textStyle="Caption/Large/Regular"
          padding="0 16px 12px"
        >
          {placeholder}
        </Text>
      ) : null}

      <GenericRecentSearchTerms
        items={recentSearches}
        onSelect={onSelectRecentSearchTerm ?? (() => {})}
        onRemove={onRemoveRecentSearchTerm}
      />
    </Panel>
  );
};
