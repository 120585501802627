import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const LeftArrow: FC<{ fill?: string }> = ({ fill }) => (
  <IconContainer pathFill={fill}>
    <SvgIcon width="22" height="22" viewBox="0 0 22 22">
      <g transform="translate(11.000000, 11.000000) rotate(-90.000000) translate(-11.000000, -11.000000) ">
        <path
          d="M10.950413,7 L5.6395535,12.6743982 C5.27969698,13.0588876 5.27969698,13.6565732 5.6395535,14.0410626 L5.69917353,14.1047638 C6.06097194,14.491328 6.66763972,14.5114046 7.05420394,14.1496062 C7.0693879,14.135395 7.08410541,14.1206935 7.09833311,14.105525 L10.950413,9.99872952 L10.950413,9.99872952 L14.802493,14.105525 C15.1647118,14.4916953 15.7714011,14.5111118 16.1575714,14.1488929 C16.1727399,14.1346652 16.1874414,14.1199477 16.2016525,14.1047638 L16.2612726,14.0410626 C16.6211291,13.6565732 16.6211291,13.0588876 16.2612726,12.6743982 L10.950413,7 L10.950413,7 Z"
          id="path-1"
        ></path>
      </g>
    </SvgIcon>
  </IconContainer>
);

export { LeftArrow };
