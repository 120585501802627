import React from 'react';
import styled from 'styled-components';
import BaseButton from '@material-ui/core/Button';

const Button = styled(BaseButton)`
  &.MuiButton-root {
    background-color: transparent;
    text-transform: none;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

export interface SimpleButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  className?: string;
  ['data-testid']?: string;
  id?: string;
}

const SimpleButtonComponent = (
  { children, ...forwardProps }: SimpleButtonProps,
  ref: any
) => {
  return (
    <Button
      disableRipple
      disableFocusRipple
      disableTouchRipple
      ref={ref}
      {...forwardProps}
    >
      {children}
    </Button>
  );
};

/**
 * A bare-bones wrapper around HTML button removing all
 * browser specific styles.
 *
 * Useful when you just need something to be clickable but nothing extra.
 */
const SimpleButton = React.forwardRef(SimpleButtonComponent);

export { SimpleButton };
