import { ValidationError, Maybe } from '@allergan-data-labs/alle-graphql-types';

interface AlleValidationErrorOptions {
  message?: string;
  validationErrors?: Maybe<ValidationError[]>;
}

class AlleValidationError extends Error {
  public validationErrors?: Maybe<ValidationError[]>;

  constructor(options: AlleValidationErrorOptions, ...params: any[]) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AlleValidationError);
    }

    this.name = 'AlleValidationError';

    // Custom debugging information
    this.validationErrors = options.validationErrors;
    this.message = options.message || 'An internal server error has occurred';
  }
}

export { AlleValidationError };
