import { Machine } from 'xstate';

/**
 * This Auth Machine supports:
 * - Okta
 * - Allergan Advantage
 * - Alle Auth (combination of Okta tokens and Alle generated tokens)
 *
 * To be used by provider-web and admin-web.
 */

export type AuthIDP = 'OKTA' | 'ALLERGAN_ADVANTAGE' | 'ALLE_AUTH';

export interface AuthMachineContext {
  idp: AuthIDP | null;
}

export interface AuthMachineSchema {
  states: {
    checkOktaLoggedIn: {};
    checkAllerganAdvantageLoggedIn: {};
    checkAlleAuthLoggedIn: {};
    isLoggedIn: {};
    isNotLoggedIn: {};
    startLoginWithAllerganAdvantage: {};
    clearCacheBeforeLoggedIn: {};
    loggingOut: {};
  };
}

export type AuthMachineEvent = {
  type:
    | 'LOGIN_ALLERGAN_ADVANTAGE_TOKEN'
    | 'LOGIN_OKTA'
    | 'LOGIN_ALLE_AUTH'
    | 'LOGOUT'
    | 'SWITCH_WORKSPACE';
  idToken?: string;
  accessToken?: string;
  allerganAdvantageToken?: string;
  alleAccessToken?: string;
  data?: {
    allerganAdvantageToken?: string;
  };
};

export const AuthState = {
  checkOktaLoggedIn: 'checkOktaLoggedIn',
  checkAllerganAdvantageLoggedIn: 'checkAllerganAdvantageLoggedIn',
  isLoggedIn: 'isLoggedIn',
  isNotLoggedIn: 'isNotLoggedIn',
  startLoginWithAllerganAdvantage: 'startLoginWithAllerganAdvantage',
  clearCacheBeforeLoggedIn: 'clearCacheBeforeLoggedIn',
  loggingOut: 'loggingOut',
};

const authMachine = Machine<
  AuthMachineContext,
  AuthMachineSchema,
  AuthMachineEvent
>({
  id: 'auth',
  initial: 'checkOktaLoggedIn',
  context: {
    idp: null,
  },
  states: {
    checkOktaLoggedIn: {
      invoke: {
        id: 'verifyOktaLoggedIn',
        src: 'verifyOktaLoggedIn',
        onDone: {
          target: 'isLoggedIn',
          actions: ['setIDPOkta'],
        },
        onError: 'checkAllerganAdvantageLoggedIn',
      },
    },
    checkAllerganAdvantageLoggedIn: {
      invoke: {
        id: 'verifyAllerganAdvantageLoggedIn',
        src: 'verifyAllerganAdvantageLoggedIn',
        onDone: {
          target: 'isLoggedIn',
          actions: ['setIDPAllerganAdvantage'],
        },
        onError: 'checkAlleAuthLoggedIn',
      },
    },
    checkAlleAuthLoggedIn: {
      invoke: {
        id: 'verifyAlleAuthLoggedIn',
        src: 'verifyAlleAuthLoggedIn',
        onDone: {
          target: 'isLoggedIn',
          actions: ['setIDPAlleAuth'],
        },
        onError: 'isNotLoggedIn',
      },
    },
    isLoggedIn: {
      entry: ['loadPermissions'],
      activities: ['checkTokenExpiration'],
      on: {
        LOGOUT: 'loggingOut',
        SWITCH_WORKSPACE: {
          actions: ['storeAlleAuthAccessToken'],
        },
      },
    },
    isNotLoggedIn: {
      on: {
        LOGIN_ALLERGAN_ADVANTAGE_TOKEN: 'startLoginWithAllerganAdvantage',
        LOGIN_OKTA: {
          target: 'clearCacheBeforeLoggedIn',
          actions: ['storeOktaToken', 'setIDPOkta'],
        },
        LOGIN_ALLE_AUTH: {
          target: 'clearCacheBeforeLoggedIn',
          actions: [
            'storeAlleAuthOktaTokens',
            'storeAlleAuthAccessToken',
            'setIDPAlleAuth',
          ],
        },
      },
    },
    startLoginWithAllerganAdvantage: {
      invoke: {
        id: 'loginWithAllerganAdvantageToken',
        src: 'loginWithAllerganAdvantageToken',
        onDone: {
          target: 'clearCacheBeforeLoggedIn',
          actions: ['storeAllerganAdvantageToken', 'setIDPAllerganAdvantage'],
        },
        onError: {
          target: 'isNotLoggedIn',
          actions: 'redirectToLogout',
        },
      },
    },
    clearCacheBeforeLoggedIn: {
      invoke: {
        id: 'clearCache',
        src: 'clearCache',
        onDone: {
          target: 'isLoggedIn',
        },
        onError: {
          target: 'isLoggedIn',
        },
      },
    },
    loggingOut: {
      entry: ['clearIDP'],
      invoke: {
        id: 'logout',
        src: 'logout',
        onDone: {
          target: 'isNotLoggedIn',
          actions: 'redirectToLogout',
        },
        onError: {
          target: 'isNotLoggedIn',
          actions: 'redirectToLogout',
        },
      },
    },
  },
});

export { authMachine };
