import {
  Provider_Organization_Type_V2,
  Provider_User_Role,
} from '@allergan-data-labs/alle-graphql-types';

export const ProviderUserRoleTitleMap = {
  [Provider_User_Role.BusinessOwner]: 'Business Admin (Primary)',
  [Provider_User_Role.BusinessAdmin]: 'Business Admin',
  [Provider_User_Role.OfficeAdmin]: 'Location Manager',
  [Provider_User_Role.OfficeMember]: 'Staff Member',
};

export const OrganizationTypeMap = {
  [Provider_Organization_Type_V2.Business]: 'Business',
  [Provider_Organization_Type_V2.Location]: 'Location',
};
