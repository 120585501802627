import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export interface SearchResultsDropdownProps
  extends React.PropsWithChildren<unknown> {
  sectionTitle?: string;
}

const searchResultsStyles = {
  '.alle-results-cell': {
    padding: '1rem',
    _hover: {
      background: 'light.Container/Neutral/Transparent interactive/Hover',
    },
  },
};

export const SearchResultsDropdown: React.FC<SearchResultsDropdownProps> = ({
  children,
  sectionTitle,
  ...otherProps
}) => {
  return (
    <Box sx={searchResultsStyles} {...otherProps}>
      {sectionTitle ? (
        <Text
          textStyle="Body/Small/Medium"
          paddingX="16"
          paddingY="8"
          marginY="0"
          data-testid="search-dropdown-title"
        >
          {sectionTitle}
        </Text>
      ) : null}
      {(children as React.ReactNode[]).length ? <Box>{children}</Box> : null}
    </Box>
  );
};
