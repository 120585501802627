import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';

import {
  ButtonGroup,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
} from '@allergan-data-labs/alle-elements';
import { BaseComponentProps } from '../../common';

export interface DeleteOfferBuilderDialogProps extends BaseComponentProps {
  isDeleteLoading?: boolean;
  isOpen: boolean;
  name: string;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteOfferBuilderDialog: React.FC<DeleteOfferBuilderDialogProps> = ({
  isDeleteLoading = false,
  isOpen,
  name,
  onClose,
  onDelete,
  dataTestId = 'delete-offer-builder-dialog',
}) => {
  const handleOnDelete = () => {
    onDelete();
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Modal size="sm" isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent data-testid={dataTestId} width="310px">
        <ModalBody>
          <Box marginTop="1rem" marginBottom="1.5rem">
            <Text textStyle="Body/Medium/Regular">
              Are you sure you want to delete this offer?
            </Text>
            <Text textStyle="Body/Medium/Regular" marginTop="1.5rem">
              {name}
            </Text>
          </Box>

          <ModalFooter padding="1rem 0">
            <ButtonGroup size="sm" width="100%">
              <Flex
                direction="row"
                width="100%"
                gap="8"
                justifyContent="center"
              >
                <Button
                  width="100%"
                  variant="outline"
                  isDisabled={isDeleteLoading}
                  onClick={handleOnClose}
                >
                  Cancel
                </Button>
                <Button
                  width="100%"
                  variant="solid"
                  isLoading={isDeleteLoading}
                  onClick={handleOnDelete}
                >
                  Yes, Delete
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { DeleteOfferBuilderDialog };
