import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const TagOutline: React.FunctionComponent = () => (
  <IconContainer>
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 11.1764L4.5 4.5L11.1771 4.5L19.4834 12.8063L12.8063 19.4834L4.51125 11.1884C4.50683 11.1839 4.50312 11.18 4.5 11.1764ZM3 4.42293C3 4.02767 3.1385 3.69186 3.41549 3.41549C3.69186 3.1385 4.02767 3 4.42293 3H11.2055C11.3953 3 11.5812 3.03953 11.7633 3.11858C11.9448 3.19763 12.0988 3.3004 12.2253 3.42688L20.5731 11.7747C20.8577 12.0593 21 12.403 21 12.8059C21 13.2093 20.8656 13.5455 20.5969 13.8142L13.8142 20.5969C13.5455 20.8656 13.2097 21 12.8068 21C12.4033 21 12.0672 20.8656 11.7984 20.5969L3.45059 12.249C3.3083 12.1067 3.19763 11.9486 3.11858 11.7747C3.03953 11.6008 3 11.4111 3 11.2055V4.42293ZM7.31305 7.31299C7.77612 6.84992 7.77612 6.09913 7.31305 5.63605C6.84998 5.17298 6.09919 5.17298 5.63612 5.63605C5.17304 6.09913 5.17304 6.84992 5.63612 7.31299C6.09919 7.77606 6.84998 7.77606 7.31305 7.31299Z"
        fill="#090909"
      />
    </SvgIcon>
  </IconContainer>
);

export { TagOutline };
