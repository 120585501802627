import { MutableRefObject } from 'react';
import { pluckRefValues } from '../helpers/pluckRefValues';
import { defaultEventMap } from '@allergan-data-labs/universal-component-library/src/stateMachines/utils';
import { FormEvents } from '@allergan-data-labs/universal-component-library/src/stateMachines/formMachineTypes';

const runValidation = (
  {
    refs,
    additionalFields = {},
    currentState,
    fieldName,
    eventsMap = defaultEventMap,
  }: {
    refs: { [k: string]: MutableRefObject<HTMLInputElement | null> };
    additionalFields?: { [k: string]: any };
    currentState: string;
    fieldName: string;
    eventsMap?: { [k: string]: FormEvents };
  },
  send: (obj: any) => void
) => {
  const fields = Object.keys(refs).reduce(
    (acc: { [k: string]: string }, nextKey: string) => {
      const [refValue] = pluckRefValues([refs[nextKey]]);
      return {
        ...acc,
        [nextKey]: refValue,
      };
    },
    additionalFields
  );

  if (eventsMap[currentState]) {
    send({
      type: eventsMap[currentState],
      fields,
      currentField: fieldName,
    });
  }
};

export { runValidation };
