import React, { useCallback, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
} from '@allergan-data-labs/alle-elements';
import { Skeleton } from '@allergan-data-labs/alle-elements';
import * as yup from 'yup';

import { Button } from '@allergan-data-labs/alle-elements';
import { EmailInput } from '../../emailInput';
import { businessUsersInvitationDialogFormSchema } from './businessUsersInvitationDialogSchema';

export interface BusinessUsersInvitationDialogFields {
  emails: string[];
}

export interface BusinessUsersInivitationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    onSubmitArgs: BusinessUsersInvitationDialogFields
  ) => Promise<void>;
}

const BusinessUsersInivitationDialog: React.FC<
  BusinessUsersInivitationDialogProps
> = ({ isOpen, onClose, onSubmit }) => {
  const [emails, setEmails] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = useCallback(async () => {
    const data = { emails };
    try {
      // This will throw an error if the email is not valid
      const result = await businessUsersInvitationDialogFormSchema.validate(
        data
      );
      setIsDisabled(true);
      await onSubmit({ emails: result.emails.split(',') });
      setEmailError('');
      setEmails('');
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        console.error('Validation errors:', error.errors);
        setEmailError(error.errors?.[0]);
      } else {
        console.error('An unexpected error occurred:', error);
      }
    }
    setIsDisabled(false);
  }, [emails, onSubmit]);

  const resetAndClose = () => {
    setEmails('');
    setEmailError('');
    setIsDisabled(false);
    onClose();
  };

  return (
    <Modal onClose={resetAndClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" gap="8">
          <Text flex="1">Who would you like to invite?</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text flex="1" paddingBottom={24}>
            Invite new business admins via email.
          </Text>
          {isDisabled ? (
            <Box borderRadius="lg" marginTop="32px" overflow="hidden">
              <Skeleton fitContainer height="56px"></Skeleton>
            </Box>
          ) : (
            <EmailInput
              isDisabled={isDisabled}
              id={'emails'}
              error={emailError}
              label="Email(s):"
              value={emails}
              onChange={(e) => {
                setEmails(e.target.value);
                setEmailError('');
              }}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup
            orientation="horizontal"
            colorMode="light"
            variant="solid"
          >
            <Button name="close" onClick={resetAndClose} variant="outline">
              Cancel
            </Button>
            <Button name="send-invite" onClick={handleSubmit}>
              Send Invite
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { BusinessUsersInivitationDialog };
