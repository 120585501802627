import * as yup from 'yup';

const businessUsersInvitationDialogFormSchema = yup.object().shape({
  emails: yup
    .string()
    .required()
    .test(
      'is-valid-email-list',
      'One or more emails are invalid',
      (value: string) => {
        const emails = value.split(',').map((email: string) => email.trim());
        // Validate each email in the array
        const emailSchema = yup.string().email();
        return emails.every((email: string) => emailSchema.isValidSync(email));
      }
    ),
});

export { businessUsersInvitationDialogFormSchema };
