import React, { useState } from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';
import { IconButton } from '@allergan-data-labs/alle-elements';
import copy from 'copy-to-clipboard';
import { CopyOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/copyOutlineIcon';
import { CheckOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/checkOutlineIcon';

export interface MultiLineLabelProps {
  texts: string[];
  textProps?: TextProps;
  isCopyEnabled?: boolean;
}

const MultiLineLabel: React.FC<MultiLineLabelProps> = ({
  texts,
  textProps,
  isCopyEnabled = false,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (textToCopy: string[]) => {
    copy(textToCopy.toString());
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1200);
  };

  return (
    <Flex alignItems="flex-end" data-testid="multiline-label-container">
      <Flex flexDirection="column" data-testid="multiline-label-text-container">
        {texts.map((text, idx) => (
          <Text
            key={`${text}-${idx}`}
            textStyle="Body/Medium/Regular"
            {...textProps}
            data-testid="multiline-label-text"
          >
            {text}
          </Text>
        ))}
      </Flex>
      {isCopyEnabled ? (
        <IconButton
          data-testid="multiline-label-icon-button"
          onClick={() => handleCopy(texts)}
          icon={
            isCopied ? (
              <CheckOutlineIcon
                sx={{ color: 'light.Container/Status/Success bold 1' }}
              />
            ) : (
              <CopyOutlineIcon
                sx={{
                  color: 'light.Icon/Neutral/Support',
                }}
              />
            )
          }
          colorScheme="muted"
          aria-label="Copy"
          variant="ghost"
          sx={{
            backgroundColor: 'white',
            width: '24px',
            minWidth: '24px',
            height: '24px',
            _hover: {},
          }}
        />
      ) : null}
    </Flex>
  );
};

export { MultiLineLabel };
