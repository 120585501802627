import React from 'react';
import { Flex } from '@chakra-ui/react';
import { SearchResultsDropdown } from '../searchResultsDropdown';
import { BusinessUserItem } from '../../../components/common';
import { BusinessUserResultRow } from '../resultRow';
import { Provider_User_Category } from '@allergan-data-labs/alle-graphql-types';
import { SearchResultsParams } from '../../searchField';

export interface BusinessUsersResultDropdownProps {
  results: BusinessUserItem[];
  searchTerm?: string;
  onSelect: (params: SearchResultsParams) => void;
}

const BusinessUsersResultDropdown = ({
  results,
  searchTerm = '',
  onSelect,
}: BusinessUsersResultDropdownProps) => (
  <SearchResultsDropdown
    sectionTitle="Business Users"
    data-testid="business-users-results-dropdown"
  >
    {results.map((result, index) => (
      <Flex
        key={result.id}
        data-testid={`business-users-result-${index}`}
        onClick={() => {
          onSelect({
            type: result.type as Provider_User_Category.BusinessUser,
            data: result as BusinessUserItem,
          });
        }}
      >
        <BusinessUserResultRow
          result={result}
          type={result.type as Provider_User_Category.BusinessUser}
          searchTerm={searchTerm}
        />
      </Flex>
    ))}
  </SearchResultsDropdown>
);

export { BusinessUsersResultDropdown };
