import React from 'react';
import { CardBody, Text, VStack } from '@chakra-ui/react';
import { Card } from '@allergan-data-labs/alle-elements';
import { BaseComponentProps } from '../../common';

export interface EmptyResultProps extends BaseComponentProps {
  /**
   * search query entered
   * @default undefined
   */
  query?: string;
  searchCriteria?: string;
}

const EmptyResult = ({
  dataTestId,
  query,
  searchCriteria,
}: EmptyResultProps) => {
  const term = query ? ` - ${query} - ` : '';
  const testId = dataTestId || 'empty-result-container';

  return (
    <Card
      data-testid={testId}
      maxHeight={'125px'}
      border={'none'}
      height={'100%'}
      width={'100%'}
    >
      <CardBody padding={24}>
        <VStack gap={'3.2'}>
          <Text textStyle="Body/Medium/Medium">
            {`Your search ${term} did not match any ${
              searchCriteria || 'businesses, locations, or business users'
            }.`}
          </Text>
          <Text textStyle="Body/Extra small/Regular">
            Check your spelling or search for a different keyword.
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default EmptyResult;
