import React from 'react';
import { Flex, Box, Text, LinkBox } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { Link, getFontWeightToken } from '@allergan-data-labs/alle-elements';
import { AlleLogo } from '@allergan-data-labs/admin-business-component-library/src/components';

import { NavLink } from '../link/link';

interface ItemsProps {
  isSelected?: boolean;
  title: string;
  path: string;
}

interface HeaderProps {
  rightContent?: React.ReactNode;
  leftContent?: React.ReactNode;
  items?: ItemsProps[];
  homeRoute?: string;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  rightContent,
  leftContent,
  homeRoute,
  items,
}) => {
  const location = useLocation();
  return (
    <Flex
      data-testid="header-container"
      borderBottom="1px solid"
      backgroundColor="white"
      padding="0 12px 0 24px"
      justify="space-between"
      borderBottomColor="light.Border/Neutral/Subtle 2"
      align="center"
      height="55px"
      as="header"
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 99,
      }}
    >
      <Flex flex={items?.length ? 'none' : '1'}>
        {leftContent || (
          <Box width="100%" alignItems="center">
            <Link href={homeRoute} isStandalone>
              <AlleLogo />
            </Link>
          </Box>
        )}
      </Flex>

      {items?.length ? (
        <Flex
          marginX={items?.length ? 'auto' : '0'}
          datatest-id="menu-items-container"
          justifyContent="space-between"
          backgroundColor="white"
          justify="center"
          align="center"
          height="100%"
          gap="64px"
        >
          {items.map((value, index) => {
            const match = location.pathname.includes(value.path);
            return (
              <NavLink to={value.path} isStandalone key={index}>
                <LinkBox
                  borderBottom="1px solid"
                  alignItems="center"
                  borderRadius="0"
                  display="flex"
                  height="100%"
                  _hover={{
                    borderBottomColor: 'light.Border/Brand/Medium 3',
                    color: 'light.Text/Neutral/Interactive/Hover',
                  }}
                  borderBottomColor={
                    match ? 'light.Border/Brand/Medium 3' : 'transparent'
                  }
                >
                  <Text
                    textStyle="Body/Small/Regular"
                    fontWeight={
                      match ? getFontWeightToken('Semibold') : 'normal'
                    }
                    _hover={{
                      color: 'light.Text/Neutral/Interactive/Hover',
                    }}
                    color="light.Text/Neutral/Interactive/Enable"
                  >
                    {value.title}
                  </Text>
                </LinkBox>
              </NavLink>
            );
          })}
        </Flex>
      ) : null}

      <Flex align="center" height="100%">
        {rightContent}
      </Flex>
    </Flex>
  );
};

export { Header };
