import React from 'react';
import { useMediaQuery, Box, Flex, Text } from '@chakra-ui/react';

import { OrganizationAvatar } from '../organizationAvatar';
import { TextHighlighter } from '../textHighlighter';
import { BaseComponentProps, OrganizationType } from '../common';

export interface OrganizationDetailsProps extends BaseComponentProps {
  /** Organization type
   * @default undefined
   */
  type: OrganizationType;
  /** Text to display in the title
   * @default undefined
   */
  title?: string;
  /** Text to display in the subtitle when provided
   * @default undefined
   */
  subTitle?: string;
  /** Optimized for space
   * @default false
   */
  isSmall?: boolean;

  /**
   * A single or list of keywords to search and highlight in the title
   * @default undefined
   */
  searchTerm?: string;
}

const OrganizationDetails = ({
  isSmall = false,
  dataTestId,
  subTitle,
  title,
  type,
  searchTerm,
}: OrganizationDetailsProps) => {
  const testId = dataTestId || 'business-details-container';
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const avatarMargin = isSmall ? 6 : 12;
  const titleStyle = isSmall
    ? 'Body/Small/Regular'
    : isLargerThan768
    ? 'Body/Medium/Medium'
    : 'Body/Small/Medium';
  const subTitleColorStyle = isSmall
    ? 'light.Text/Neutral/Support'
    : 'light.Text/Neutral/Default';

  return (
    <Flex data-testid={testId} alignItems="center">
      <Box marginRight={avatarMargin}>
        {type && <OrganizationAvatar type={type} isBordered={!isSmall} />}
      </Box>
      <Box>
        {title && (
          <Text textStyle={titleStyle} marginY={0}>
            <TextHighlighter searchTerm={searchTerm}>{title}</TextHighlighter>
          </Text>
        )}
        {subTitle && (
          <Text
            color={subTitleColorStyle}
            textStyle="Body/Small/Regular"
            marginY={0}
          >
            {subTitle}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

OrganizationDetails.displayName = 'OrganizationDetails';

export { OrganizationDetails };
