import React from 'react';
import { ColorMode } from '@allergan-data-labs/alle-elements-core';
import {
  OrganizationType,
  OrganizationItem,
  BusinessUserItem,
} from '../common';

type OnSelectHandler = (keyword: string) => void;

export interface SearchResultsParams {
  type: OrganizationType;
  data: OrganizationItem | BusinessUserItem;
}

export interface SearchResultsProps {
  results: string[];
  onSelect: (keyword: string) => void;
  colorMode: ColorMode;
  query: string;
  isLoading?: boolean;
  focusedIndex?: number | null;
}

export interface DropdownProps {
  initialState: boolean;
  results: string[];
  recentSearches: string[];
  colorMode: ColorMode;
  menuVisible: boolean;
  isOrganizationsLoading: boolean;
  isBusinessUsersLoading: boolean;
  isTyping: boolean;
  placeholder?: string;
  query: string;
  recentVisitedSearchResults: SearchResultsParams[];
  organizationsSearchResults?: OrganizationItem[];
  businessUsersSearchResults?: BusinessUserItem[];
  onRecentVisitedSearchResultsItem?: (item: any) => JSX.Element;
  onSelect: OnSelectHandler;
  onSelectRecentSearchTerm?: (item: string) => void;
  onRemoveRecentSearchTerm?: (event: React.MouseEvent, item: string) => void;
  onSelectResults: (params: SearchResultsParams) => void;
}

export interface GenericDropdownProps
  extends Omit<
    DropdownProps,
    | 'isOrganizationsLoading'
    | 'isBusinessUsersLoading'
    | 'organizationsSearchResults'
    | 'businessUsersSearchResults'
  > {}

export interface RecentSearchesProps {
  recentSearches: string[];
  isLoading?: boolean;
  colorMode?: ColorMode;
  onSelect?: OnSelectHandler;
}

export enum DropdownVariant {
  ALLE_ELEMENTS = 'alle-elements',
  ADMIN_BUSINESS = 'admin-business',
  GENERIC = 'admin-generic',
}

export interface SearchFieldProps extends React.HTMLAttributes<any> {
  initialQuery?: string;
  /**
   * A list of recent search keywords to display to the user when they initially focus in the search field
   */
  recentSearches: string[];

  /**
   * Callback to remove recent search term from localStorage
   */
  onRemoveRecentSearchTerm?: (event: React.MouseEvent, item: string) => void;

  /**
   * Callback to execute when a search term is selected
   */
  onSelectRecentSearchTerm?: (keyword: string) => void;

  /**
   * Handler that gets triggered after the user types a query
   */
  onSearch: (query: string) => Promise<any>;

  /**
   * Handler that gets triggered when the user selects a result from the dropdown
   */
  onSelectResults: (params: SearchResultsParams) => void;

  /** Placeholder text
   * @default Search
   */
  placeholder?: string;

  /** The color mode that the progress circle will display.
   * @default light
   */
  colorMode?: ColorMode;

  /** The size of the search field
   * @default md
   */
  size?: 'sm' | 'md' | 'lg';

  /**
   * Activate the dropdown functionality, default: true
   */
  dropdownActivated?: boolean;

  /**
   * Turn search field input focus ON or OFF
   */
  disableFocus?: boolean;

  /**
   * On key down handler
   * @param e KeyboardEvent<HTMLInputElement>
   * @param query the current string of the input
   * @returns void
   */
  onKeyDownHandler?: (
    e: React.KeyboardEvent<HTMLInputElement>,
    query: string
  ) => void;

  /**
   * Component variant to use Alle Element's Search Field or Admin Business's one.
   */
  dropdownVariant?: DropdownVariant;

  /**
   * Add the recently visited values to the recent searches list
   */
  recentVisitedSearchResults: SearchResultsParams[] | any[];

  /**
   * Function that render the result searches list item
   */
  onRecentVisitedSearchResultsItem?: (item: any) => JSX.Element;

  /**
   * Add the organization results values to the searches list
   */

  organizationsSearchResults?: OrganizationItem[];

  /** loading state for organizations search
   * @default false
   */
  isOrganizationsLoading?: boolean;

  businessUsersSearchResults?: BusinessUserItem[];

  /** loading state for business users search
   * @default false
   */
  isBusinessUsersLoading?: boolean;

  /**
   * Data test id for testing purposes
   */
  dataTestId?: string;
}
