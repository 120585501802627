import React, { useCallback } from 'react';
import { LocalStorageContext, ValueType } from './localStorageContext';

const LocalStorageProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [state, setState] = React.useState<ValueType>({});

  // @ts-ignore
  const setValue = useCallback((key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <LocalStorageContext.Provider
      value={{
        values: state,
        setValue,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorageContext = () =>
  React.useContext(LocalStorageContext);

export { LocalStorageProvider };
