import React from 'react';

import typewriterDeprecated from '../segment-tracking-plan-deprecated/generated';
import typewriterAdmin from '../segment-tracking-plan/generated';

import {
  segmentPublicKey,
  __SEGMENT_DEBUGGER_ENABLED__,
} from '../application.config';
import { fullStoryMiddleware } from './fullStoryMiddleware';
import { sourceRedactionMiddleware } from './sourceRedactionMiddleware';

interface SegmentContextValue {
  page: (name?: string) => void;
  trackDeprecated: () => typeof typewriterDeprecated | undefined;
  trackAdmin: () => typeof typewriterAdmin | undefined;
}

const SegmentContext = React.createContext<SegmentContextValue>({
  page: () => {},
  trackDeprecated: () => undefined,
  trackAdmin: () => undefined,
});

const trackAdminNoop = Object.keys(typewriterAdmin).reduce((acc, key) => {
  return {
    ...acc,
    [key]: (...args: any[]) => {
      if (__SEGMENT_DEBUGGER_ENABLED__) {
        console.group('Segment Debugger');
        console.log(key, 'future track event suppressed', args);
        console.groupEnd();
      }
    },
  };
}, {});

const trackDeprecatedNoop = Object.keys(typewriterDeprecated).reduce(
  (acc, key) => {
    return {
      ...acc,
      [key]: (...args: any[]) => {
        if (__SEGMENT_DEBUGGER_ENABLED__) {
          console.group('Segment Debugger');
          console.log(key, 'Deprecated track event suppressed', args);
          console.groupEnd();
        }
      },
    };
  },
  {}
);

type SegmentProviderProps = {
  children: React.ReactNode;
  enableNewTrackingPlan?: boolean;
};

const SegmentProvider = ({
  enableNewTrackingPlan,
  children,
}: SegmentProviderProps) => {
  React.useEffect(() => {
    try {
      if (!segmentPublicKey) {
        return;
      }
      // @ts-ignore
      window?.analytics?.addSourceMiddleware?.(sourceRedactionMiddleware);
      // @ts-ignore
      window?.analytics?.addDestinationMiddleware?.('FullStory', [
        fullStoryMiddleware,
      ]);
      window?.analytics?.load?.(segmentPublicKey, {
        // https://adl-technology.atlassian.net/wiki/spaces/MT/pages/2727575722/analytics.load
        // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/persistence/
        // @ts-ignore for some reason types/segment-analytics doesn't know about this
        cookie: {
          domain: window.location.hostname,
        },
        user: {
          cookie: {
            key: 'ajs_admin_user_id',
          },
          localStorage: {
            key: 'ajs_admin_user_traits',
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const page = React.useCallback(
    (name?: string) => window?.analytics?.page(name),
    []
  );

  const trackDeprecated = React.useCallback(() => {
    if (enableNewTrackingPlan) {
      return trackDeprecatedNoop as unknown as typeof typewriterDeprecated;
    }

    return typewriterDeprecated;
  }, [enableNewTrackingPlan]);

  const trackAdmin = React.useCallback(() => {
    if (enableNewTrackingPlan) {
      return typewriterAdmin;
    }

    return trackAdminNoop as unknown as typeof typewriterAdmin;
  }, [enableNewTrackingPlan]);

  return (
    <SegmentContext.Provider
      value={{
        page,
        trackDeprecated,
        trackAdmin,
      }}
    >
      {children}
    </SegmentContext.Provider>
  );
};

const useSegment = () => React.useContext(SegmentContext);

export { SegmentProvider, useSegment };
