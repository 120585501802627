import React, { useState } from 'react';
import {
  ColumnSort,
  DataTable,
  DataTableColumn,
  TextButton,
} from '@allergan-data-labs/alle-elements';
import { BusinessLocationStatusIndicator } from '../../../components/statusIndicator';
import { Flex, Text } from '@chakra-ui/react';
import { Account_Status, Maybe } from '@allergan-data-labs/alle-graphql-types';
import { BaseComponentProps } from '../../common';

type DataViewModel = {
  id: string;
  name: string;
  address: string;
  shipToId: string;
  status: string;
  migrationDate: string;
};

export interface BusinessLocationTableProps extends BaseComponentProps {
  data: Array<DataViewModel>;
  isLoading?: boolean;
  onLocationNameClick: (locationId: string) => void;
}

const BusinessLocationsTable: React.FC<BusinessLocationTableProps> = ({
  data,
  onLocationNameClick,
  dataTestId = 'business-locations-table',
  isLoading = false,
}) => {
  const columns: Array<DataTableColumn<DataViewModel>> = [
    {
      column: 'Location',
      field: 'name',
      renderCell: (_, name) => {
        return (
          <Flex flexDirection="column" maxWidth="462px">
            <TextButton
              variant="ghost"
              colorScheme="brand"
              onClick={() => onLocationNameClick(_.id)}
              sx={{
                display: 'inline',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '100%',
                textAlign: 'left',
                paddingX: 0,
              }}
            >
              <Text textStyle="Body/Small/Regular" as="span">
                {name}
              </Text>
            </TextButton>

            <Text
              color={`light.Text/Neutral/Support`}
              textStyle="Body/Small/Regular"
              as="span"
              isTruncated
            >
              {_.address}
            </Text>
          </Flex>
        );
      },
    },
    {
      column: 'AbbVie SAP ShipTo ID',
      field: 'shipToId',
      renderCell: (_, shipToId) => {
        return (
          <Text textStyle="Body/Medium/Regular" as="span">
            {shipToId as string}
          </Text>
        );
      },
    },
    {
      column: 'Status',
      field: 'status',
      renderCell: (_, status) => {
        const statusType = status as Maybe<Account_Status>;
        return (
          <BusinessLocationStatusIndicator status={statusType} size="md" />
        );
      },
    },
    {
      column: 'Migration Date',
      field: 'migrationDate',
      renderCell: (_, migrationDate) => {
        return (
          <Text textStyle="Body/Medium/Regular" as="span">
            {migrationDate as string}
          </Text>
        );
      },
    },
  ];

  const [sortConfig, setSortConfig] = useState<ColumnSort>({
    id: null,
    direction: 'asc',
  });

  const handleSort = (sortingState: ColumnSort) => {
    setSortConfig(sortingState);
  };

  const getSortedItems = (): DataViewModel[] => {
    if (!sortConfig.id || sortConfig.direction === null) {
      return data;
    }
    const sortKey = sortConfig.id as keyof DataViewModel;
    return [...data].sort((a, b) => {
      const aValue = a[sortKey] || '';
      const bValue = b[sortKey] || '';
      if (sortConfig.direction === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      }
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    });
  };

  return (
    <DataTable
      isLoading={isLoading}
      data-testid={dataTestId}
      columns={columns}
      rowGap={8}
      data={getSortedItems()}
      onSort={handleSort}
      variant="closed"
      sx={{
        '& tbody tr': { cursor: 'initial !important' },
      }}
    />
  );
};

export { BusinessLocationsTable };
