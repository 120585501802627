import React from 'react';
import {
  NavigationDrawer,
  NavigationDrawerPanel,
  NavigationDrawerEyebrowText,
  NavigationDrawerDivider,
} from '@allergan-data-labs/alle-elements';
import { Flex } from '@chakra-ui/react';

interface ItemProps {
  title: string;
  href: string;
  isActive?: boolean;
  items?: ItemProps[];
}

interface DataProps {
  items: ItemProps[];
}

export interface OrganizationDataProps {
  title: string;
  icon?: React.ReactElement;
  data: DataProps;
}

interface OrganizationDrawerProps {
  isOpen?: boolean;
  businessData: OrganizationDataProps | null;
  locationData?: OrganizationDataProps | null;
}

const OrganizationDrawer: React.FunctionComponent<OrganizationDrawerProps> = ({
  isOpen = false,
  businessData,
  locationData,
}) => {
  return isOpen ? (
    <Flex
      gap="16px"
      margin="0"
      width="270px"
      minHeight="100vh"
      borderRight="solid 1px"
      borderRightColor="light.Border/Neutral/Subtle 2"
      data-testid="organization-drawer-container"
      alignSelf="stretch"
      sx={{
        '.chakra-stack': { height: '100%' },
      }}
    >
      <NavigationDrawer isOpen={isOpen}>
        {businessData && (
          <NavigationDrawerPanel
            data={businessData.data}
            title={businessData.title}
            icon={businessData.icon}
            disableToggle={!locationData}
            data-testid="business-navigation-drawer-panel"
          />
        )}
        {locationData && (
          <>
            <NavigationDrawerDivider />
            <NavigationDrawerEyebrowText>Location</NavigationDrawerEyebrowText>
            <NavigationDrawerPanel
              disableToggle
              title={locationData.title}
              data={locationData.data}
              data-testid="location-navigation-drawer-panel"
            />
          </>
        )}
      </NavigationDrawer>
    </Flex>
  ) : null;
};

export { OrganizationDrawer };
