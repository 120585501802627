import React from 'react';
import { Text } from '@chakra-ui/react';

interface TotalResultsProps {
  label: string;
  value: number;
  dataTestId?: string;
}

export const TotalResults = ({
  dataTestId = 'total-results-data-table',
  label,
  value,
}: TotalResultsProps) => {
  return (
    <Text
      data-testid={dataTestId}
      textStyle={'Body/Small/Regular'}
    >{`${label}: ${value}`}</Text>
  );
};

export default TotalResults;
