import React from 'react';
import styled from 'styled-components';

// Material
import { KeyboardDatePicker } from '@material-ui/pickers';

// Utils
import isValid from 'date-fns/isValid';

import { colors } from '../colors';
import { heading5Style } from '../typography/typographyDEPRECATED';
import { Input, InputProps } from '../input';
import { Calendar } from '../../icons/calendar';
import { LeftArrow } from '../../icons/leftArrow';
import { RightArrow } from '../../icons/rightArrow';

const StyledKeyboardDatePicker = styled(({ className, ...forwardProps }) => (
  <KeyboardDatePicker
    {...forwardProps}
    PopoverProps={{ classes: { paper: className } }}
  />
))`
  & .MuiTouchRipple-root {
    display: none;
  }
  .MuiTypography-root.MuiTypography-body1 {
    ${heading5Style}
    text-transform: uppercase;
    text-align: center;
    line-height: 23px;
  }
  &.MuiPaper-root {
    /* (centers calendar) Half the width of the inputAdornment button */
    margin-left: 23px;
    margin-top: 12px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
  }
  & .MuiPickersCalendarHeader-switchHeader {
    margin-bottom: 4px;
  }
  & .MuiPickersCalendarHeader-daysHeader,
  & .MuiPickersCalendar-week {
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
  }
  & span.MuiPickersCalendarHeader-dayLabel {
    ${heading5Style}
    text-align: center;
    color: ${colors.accent};
  }
  & button.MuiPickersCalendarHeader-iconButton {
    background-color: transparent;

    &:hover {
      .MuiSvgIcon-root path {
        fill: ${colors.skin};
      }
    }

    &.Mui-disabled {
      .MuiSvgIcon-root path {
        fill: ${colors.accent};
      }
    }
  }
  & button.MuiPickersDay-day {
    ${heading5Style}
    text-align: center;
    color: ${colors.black};
    background-color: transparent;

    &:hover {
      color: ${colors.skin};
    }

    &.MuiPickersDay-dayDisabled {
      p {
        color: ${colors.accent};
      }
    }
    &.MuiPickersDay-daySelected {
      p {
        color: ${colors.white};
        width: 21px;
        height: 21px;
        border-radius: 12px;
        background-color: ${colors.skin};
      }
    }
  }
`;

const StyledInput = styled(({ textColor, ...forwardProps }) => (
  <Input {...forwardProps} />
))`
  & button.MuiIconButton-root {
    background-color: transparent;
    padding: 0;
  }
  & .MuiTouchRipple-root {
    display: none;
  }
  & .MuiInputAdornment-positionEnd {
    margin-right: 0;
  }
  & .MuiFormHelperText-root {
    color: ${colors.negative};
  }
  & .MuiInput-input {
    ${(props) => (props.textColor ? `color: ${props.textColor};` : '')}
  }
`;

export interface DatePickerDEPRECATEDProps {
  onChange: (date: Date) => void;
  initialValue?: Date | null;
  placeholder?: String;
  inputProps: InputProps;
  invalidDateMessage?: string;
  maxDate?: Date;
  minDate?: Date;
  maxDateMessage?: string;
  minDateMessage?: string;
  dateFormat?: string;
  disableFuture?: boolean;
  textColor?: string;
}

const DatePickerDEPRECATED: React.FunctionComponent<
  DatePickerDEPRECATEDProps
> = ({
  onChange,
  dateFormat = 'MM.dd.yyyy',
  initialValue,
  placeholder,
  inputProps = {},
  invalidDateMessage = 'Invalid date format.',
  minDateMessage = 'Must be after start date.',
  maxDateMessage = 'Must be before end date.',
  textColor,
  ...forwardProps
}) => {
  const [value, setValue] = React.useState<Date | undefined | null>(
    initialValue
  );
  const CustomTextFieldComponent = React.useCallback(
    (props: any) => (
      <StyledInput
        data-testid="date-input"
        key={inputProps.id}
        textColor={textColor}
        {...inputProps}
        {...props}
      />
    ),
    [inputProps, textColor]
  );
  const { minDate, maxDate } = forwardProps;
  return (
    <StyledKeyboardDatePicker
      autoOk
      placeholder={placeholder}
      value={value}
      onChange={(date: Date) => {
        setValue(date);
        if (
          date &&
          isValid(date) &&
          (!minDate || minDate < date) &&
          (!maxDate || maxDate > date)
        ) {
          onChange(date);
        }
      }}
      variant="inline"
      disableToolbar
      format={dateFormat}
      invalidDateMessage={invalidDateMessage}
      minDateMessage={minDateMessage}
      maxDateMessage={maxDateMessage}
      keyboardIcon={<Calendar />}
      leftArrowIcon={<LeftArrow />}
      rightArrowIcon={<RightArrow />}
      TextFieldComponent={CustomTextFieldComponent}
      {...forwardProps}
    />
  );
};

export { DatePickerDEPRECATED };
