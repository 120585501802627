import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Link,
} from '@allergan-data-labs/alle-elements';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { BusinessLocationStatusIndicator } from '../../../components/statusIndicator';
import { Account_Status } from '@allergan-data-labs/alle-graphql-types';

export interface UserLocation {
  id: string;
  name: string;
  location: string;
  role: string;
  status: Account_Status;
}

export interface BusinessUserLocationModalProps {
  name: string;
  businessUserLocations: UserLocation[];
  onClose: () => void;
  isOpen: boolean;
}

const BusinessUserLocationModal: React.FC<BusinessUserLocationModalProps> = ({
  name,
  onClose,
  isOpen,
  businessUserLocations,
}) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="lg"
      data-testid="business-user-location-modal"
    >
      <ModalOverlay />
      <ModalContent
        minWidth="648px"
        maxHeight="300px"
        paddingX={8}
        paddingBottom={20}
        data-testid="business-user-location-modal-content"
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text textStyle="Headline/Extra small/Primary medium">
            Locations - {name}
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {businessUserLocations && businessUserLocations.length ? (
            <Box width="full" overflowY="auto">
              {/* Header row */}
              <Grid templateColumns="3fr 2fr 1.5fr" gap={8} marginBottom={8}>
                <Text textStyle="Body/Medium/Medium">Location</Text>
                <Text textStyle="Body/Medium/Medium">Role</Text>
                <Text textStyle="Body/Medium/Medium">Status</Text>
              </Grid>
              {/* Data rows */}
              {businessUserLocations.map((userLocation, key) => (
                <Grid
                  key={userLocation.id}
                  templateColumns="3fr 2fr 1.5fr"
                  gap={8}
                  rowGap={8}
                  alignItems="center"
                  marginBottom={6}
                  data-testid={`business-user-location-modal-content-${key}`}
                >
                  <GridItem sx={{ maxWidth: '260px' }}>
                    <Link
                      href="#"
                      isStandalone={true}
                      sx={{
                        _hover: { textDecoration: 'none' },
                      }}
                    >
                      <Text
                        isTruncated
                        color="light.Text/Brand/Interactive/Enable"
                        textStyle="Body/Medium/Regular"
                      >
                        {`${userLocation.location} (${userLocation.name})`}
                      </Text>
                    </Link>
                  </GridItem>
                  <GridItem>
                    <Text>{userLocation.role}</Text>
                  </GridItem>
                  <GridItem>
                    <BusinessLocationStatusIndicator
                      status={userLocation.status as Account_Status}
                      size="md"
                    />
                  </GridItem>
                </Grid>
              ))}
            </Box>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { BusinessUserLocationModal };
