import React from 'react';
import { useLocation } from 'react-router';
import { useInterval } from '@allergan-data-labs/universal-component-library/src/hooks/useInterval';

const useRouteInactivity = (callback: () => void, interval: number) => {
  const { pathname } = useLocation();
  const [status, setStatus] = React.useState<'active' | 'inactive'>('active');

  React.useEffect(() => {
    setStatus('inactive');
  }, [pathname]);

  if (status === 'inactive') {
    setStatus('active');
  }

  useInterval(
    () => {
      callback();
    },
    status === 'active' ? interval : null
  );
};

export { useRouteInactivity };
