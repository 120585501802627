import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { Query } from '@allergan-data-labs/alle-graphql-types';

const QUERY = gql`
  query ViewerMarketingOfferAccessControl {
    viewerMarketingOfferAccessControl {
      canViewOfferDashboard
    }
  }
`;

// Returns undefined while the query is loading, true/false once complete
const useCanViewOfferDashboard = () => {
  const { data } = useQuery<Query>(QUERY, {
    // Its important that we use cache-first to avoid duplicate network requests,
    // as this hook will be called from multiple components on the same page
    fetchPolicy: 'cache-first',
    onError: (err) => {
      console.error('Error calling viewerMarketingOfferAccessControl:', err);
    },
  });
  return data?.viewerMarketingOfferAccessControl?.canViewOfferDashboard;
};

export { useCanViewOfferDashboard };
