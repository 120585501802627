import React from 'react';
import { Grid } from '@chakra-ui/react';

export interface SectionContentProps {
  children: React.ReactNode;
}

const SectionContent: React.FC<SectionContentProps> = ({ children }) => {
  // Convert children to an array and filter out null or undefined values
  const childrenArray = React.Children.toArray(children).filter(
    (child) => child != null
  );

  return (
    <Grid
      role="grid"
      templateColumns="repeat(2, 1fr)"
      gap={6}
      rowGap={32}
      data-testid="section-content"
    >
      {childrenArray}
    </Grid>
  );
};

export { SectionContent };
