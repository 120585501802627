import React from 'react';

import {
  ActionMenuGroup,
  getTypographyToken,
  getActionMenuGroupStyles,
  ActionMenuGroupProps,
} from '@allergan-data-labs/alle-elements';

const ProfileMenuGroup = ({ children, ...props }: ActionMenuGroupProps) => {
  return (
    <ActionMenuGroup
      sx={{
        ...getActionMenuGroupStyles('light'),
        ...getTypographyToken('Caption/Medium/Regular'),
        color: `light.Text/Neutral/Support`,
        padding: '0',
        margin: '0',
      }}
      {...props}
    >
      {children}
    </ActionMenuGroup>
  );
};

export { ProfileMenuGroup };
