import React, { useCallback, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { usePaymentsAdminFeatureFlags } from '../../../hooks/usePaymentsAdminFeatureFlags';
import { useCanViewOfferDashboard } from '../../../views/offerBuilder/queries/useCanViewOfferDashboard';
import { Navigation } from '../navigation';
import { Routes } from '../../../constants';

interface A4APageContainerProps {
  children: React.ReactNode;
}

const A4APageContainer: React.FunctionComponent<A4APageContainerProps> = ({
  children,
}) => {
  const { isEnabledPaymentsAdmin } = usePaymentsAdminFeatureFlags();
  const canViewOfferDashboard = useCanViewOfferDashboard();

  const getRoutes = useCallback(
    () =>
      [
        {
          title: 'Consumers',
          path: Routes.a4aConsumerSearch,
          visible: true,
        },
        {
          title: 'Businesses',
          path: Routes.a4aBusiness,
          visible: true,
        },
        {
          title: 'Gift Card Lookup',
          path: Routes.a4aGiftCardSearch,
          visible: true,
        },
        {
          title: 'Offer Builder',
          path: Routes.a4aOfferBuilder,
          visible: canViewOfferDashboard,
        },
        {
          title: 'Payments Admin',
          path: Routes.a4aPaymentsAdmin,
          visible: isEnabledPaymentsAdmin,
        },
      ].filter((route) => route.visible),
    [canViewOfferDashboard, isEnabledPaymentsAdmin]
  );

  const routes = useMemo(getRoutes, [
    canViewOfferDashboard,
    isEnabledPaymentsAdmin,
    getRoutes,
  ]);

  return (
    <Box
      flexDirection="column"
      data-testid="a4a-page-container"
      position="sticky"
      width="100%"
      top="0"
      zIndex={999}
    >
      <Navigation routes={routes} />
      {children}
    </Box>
  );
};

export { A4APageContainer };
