import React from 'react';
import { Box, List, ListItem, ListIcon, Text, Flex } from '@chakra-ui/react';
import { SearchOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/searchOutlineIcon';
import { CloseIcon } from '@allergan-data-labs/alle-elements-icons/24/closeIcon';

interface RecentSearchTermProps {
  items: string[];
  size?: number;
  onSelect: (item: string) => void;
  onRemove?: (event: React.MouseEvent, item: string) => void;
}

const RecentSearchTerms = ({
  items,
  size = 3,
  onSelect,
  onRemove,
}: RecentSearchTermProps) => {
  const listStyles = {
    ':hover': {
      '.search-term-row': {
        cursor: 'pointer',
      },
    },
  };
  const listItemStyles = {
    '.remove-search-term-btn': {
      display: 'none',
    },
    ':hover': {
      display: 'flex',
      '.remove-search-term-btn': {
        display: 'inline',
      },
    },
  };
  const terms = items.filter((item) => !!item?.trim()).slice(0, size);

  return (
    <Box
      alignItems="left"
      justifyContent="left"
      data-testid="recent-search-terms-container"
    >
      <List sx={listStyles} paddingX={0} marginY={0}>
        {terms.map((val, idx) => (
          <ListItem
            onClick={() => onSelect(val)}
            key={`key-${idx}`}
            _hover={{
              bg: 'light.Container/Neutral/Transparent interactive/Hover',
            }}
            paddingX={16}
            display="flex"
            fontFamily="Graphik"
            fontSize="14px"
            fontWeight="400"
            lineHeight="21px"
            color="light.Text/Neutral/Default"
            alignItems="center"
            justifyContent="space-between"
            sx={listItemStyles}
            className="search-term-row"
          >
            <Flex alignItems="center">
              <ListIcon
                as={SearchOutlineIcon}
                height="20px"
                width="20px"
                marginRight="4px"
              />
              <Text lineHeight="normal" paddingY={12}>
                {val}
              </Text>
            </Flex>
            {onRemove ? (
              <ListIcon
                as={CloseIcon}
                data-testid="remove-search-term"
                height="18px"
                width="18px"
                className="remove-search-term-btn"
                onClick={(event) => onRemove(event, val)}
              />
            ) : null}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export { RecentSearchTerms };
