import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BaseComponentProps } from '../../common';

export interface OfferBuilderTableEmptyResultsProps extends BaseComponentProps {
  searchTerms?: string;
}

const OfferBuilderTableEmptyResults: React.FC<
  OfferBuilderTableEmptyResultsProps
> = ({ searchTerms = '', dataTestId = 'table-empty-results' }) => {
  return (
    <Flex
      data-testid={dataTestId}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyItems="center"
      rowGap="1rem"
      padding="4rem"
      width="100%"
    >
      <Text textStyle="Body/Medium/Medium">
        Your search {`- “${searchTerms}” -`} did not match any offers.
      </Text>
      <Text textStyle="Body/Extra small/Regular">
        <Text as="span" textStyle="Body/Extra small/Bold">
          Results may be on other tabs
        </Text>
        . Check your spelling or search for a different keyword.
      </Text>
    </Flex>
  );
};

export { OfferBuilderTableEmptyResults };
