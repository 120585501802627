import React from 'react';
import { Text } from '@chakra-ui/react';
import { Provider_User_Role_Assignment_State } from '@allergan-data-labs/alle-graphql-types';

import { Label } from '../../label';
import { Section } from '../../section';
import { SectionItem } from '../../sectionItem';
import { BusinessUserStatusIndicator } from '../../statusIndicator';

export interface BusinessUserDetailsProps {
  firstName?: string;
  lastName?: string;
  id: string;
  emailAddress: string;
  status?: Provider_User_Role_Assignment_State | 'INVITE_PENDING';
  phoneNumber?: null | string;
  alternateEmailAddress?: string;
}

const NotSpecifiedLabel = () => (
  <Text color="light.Text/Neutral/Support" as="span">
    Not specified
  </Text>
);

const BusinessUserDetails: React.FC<BusinessUserDetailsProps> = ({
  firstName,
  lastName,
  id,
  emailAddress,
  status,
  phoneNumber,
  alternateEmailAddress,
}) => {
  return (
    <Section title="User Details">
      <SectionItem title="Name">
        {!firstName && !lastName ? (
          <NotSpecifiedLabel />
        ) : (
          <Label text={`${firstName} ${lastName}`} />
        )}
      </SectionItem>
      <SectionItem title="Status">
        {status && <BusinessUserStatusIndicator status={status} />}
      </SectionItem>
      <SectionItem title="User ID">
        <Label text={id} isCopyEnabled />
      </SectionItem>
      <SectionItem title="Phone">
        {phoneNumber ? (
          <Label text={String(phoneNumber)} />
        ) : (
          <NotSpecifiedLabel />
        )}
      </SectionItem>
      <SectionItem title="Primary Email">
        {emailAddress ? <Label text={emailAddress} /> : <NotSpecifiedLabel />}
      </SectionItem>
      <SectionItem title="Secondary Email">
        {alternateEmailAddress ? (
          <Label text={alternateEmailAddress} />
        ) : (
          <NotSpecifiedLabel />
        )}
      </SectionItem>
    </Section>
  );
};
export { BusinessUserDetails };
