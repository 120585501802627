import { getSizeToken, getColorToken } from '@allergan-data-labs/alle-elements';

export const sizes = {
  skeleton: {
    td: {
      height: `${getSizeToken('20')}`,
    },
  },
  xs: {
    td: {
      height: `${getSizeToken('40')}`,
    },
  },
  sm: {
    td: {
      height: `${getSizeToken('48')}`,
    },
  },
  md: {
    td: {
      height: `${getSizeToken('56')}`,
    },
  },
  lg: {
    td: {
      height: `${getSizeToken('72')}`,
    },
  },
  xl: {
    td: {
      height: `${getSizeToken('96')}`,
    },
  },
};

export const getTableContainerStyles = (isLoadingFullSize: boolean) => ({
  border: 'var(--chakra-borders-none)',
  marginBottom: '0.313rem',
  width: 'var(--chakra-sizes-full)',
  borderRadius: 'Small',
  overflow: 'overly hidden',
  borderColor: isLoadingFullSize
    ? 'light.Container/Brand/Transparent interactive/Enable'
    : 'light.Border/Neutral/Subtle 2',
  borderWidth: 'Extra light',
  borderStyle: 'solid',
});

export const getTableStyles = () => ({
  borderCollapse: 'collapse',
  width: 'var(--chakra-sizes-full)',
  borderRadius: 'Small',
});

export const getTableTrStyles = (isLoadingFullSize?: boolean) => ({
  borderTopWidth: '0px !important',
  borderBottomWidth: isLoadingFullSize ? '0px !important' : '1px',
  _last: { borderBottomWidth: '0px !important' },
  _hover: {
    backgroundColor: isLoadingFullSize
      ? `${getColorToken(
          'Container/Brand/Transparent interactive/Enable',
          'light'
        )} !important`
      : `${getColorToken(
          'Container/Neutral/Interactive/Mute-enable',
          'light'
        )}`,
  },
});

export const getTableTdStyles = (
  isMobile: boolean,
  isLoadingFullSize?: boolean
) => {
  return {
    alignItems: 'center',
    marginBottom: isLoadingFullSize ? `${getSizeToken('16')} !important` : '0',
    gap: 8,
    ...(isMobile && {
      fontSize: '12px',
      '*': {
        fontSize: '12px',
      },
    }),
  };
};
