import React from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

import { alleColors } from '../colorPalette';

const InfoIcon = ({ size = 20, ...props }: any) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
    id={uuid()}
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
      fill={props.fill ?? alleColors.nude[500]}
    />
  </svg>
);

/**
 * Wrapper component to apply a circular, light-pink background
 */
const InfoIconCircularBackground = styled.div<{ backgroundColor?: string }>`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : alleColors.nude[200]};
  padding: 6px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export { InfoIcon, InfoIconCircularBackground };
