import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  Banner,
  Input,
} from '@allergan-data-labs/alle-elements';
import { Text, Flex, Box, Divider } from '@chakra-ui/react';
import { HelpSolidIcon } from '@allergan-data-labs/alle-elements-icons/24/helpSolidIcon';
import { isValidEmail } from '@allergan-data-labs/universal-component-library/src/helpers/format';

interface ChangePrimaryProps {
  organizationId: string;
  email: string;
  readonly refetch: () => void;
}

export interface ChangePrimaryBusinessAdminModalProps {
  organizationId: string;
  name: string;
  currentEmail: string;
  onClose: () => void;
  refetch: () => void;
  onChangePrimaryBusinessAdmin: (props: ChangePrimaryProps) => void;
  isOpen: boolean;
}

const ChangePrimaryBusinessAdminModal: React.FC<
  ChangePrimaryBusinessAdminModalProps
> = ({
  organizationId,
  name,
  currentEmail,
  onClose,
  isOpen = false,
  onChangePrimaryBusinessAdmin,
  refetch,
}) => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setTouched(true);
    setIsValid(isValidEmail(newEmail));
  };

  const handleSubmit = () => {
    if (isValid && email) {
      onChangePrimaryBusinessAdmin({ organizationId, email, refetch });
      onClose();
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} colorMode={'light'} size={'lg'}>
      <ModalOverlay />
      <ModalContent
        minWidth={'648px'}
        data-testid="change-primary-business-admin-modal"
      >
        <ModalHeader display="flex" alignItems="center" gap="8">
          <Text flex="1">Change Primary Business Admin</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box marginBottom={24}>
            <Text textStyle="Body/Medium/Regular">
              This change will{' '}
              <Text textStyle="Body/Medium/Medium" as="span">
                remove access
              </Text>{' '}
              for the current Primary Business Admin:
            </Text>
          </Box>

          <Box marginBottom={24}>
            <Text textStyle="Body/Medium/Medium">{name}</Text>
            <Text textStyle="Body/Medium/Regular">{currentEmail}</Text>
          </Box>

          <Box
            sx={{
              '& .chakra-text': {
                fontSize: '12px',
              },
            }}
          >
            <Banner
              text="Only one user can be designated as the 'primary' business admin. The current Primary Business Admin's account will be deleted once the new Primary Business Admin has been changed."
              icon={<HelpSolidIcon />}
              size="sm"
              isClosable={false}
              variant="info"
              isVisible={true}
            />
          </Box>

          <Divider marginY={20} borderColor={'light.Border/Neutral/Subtle 2'} />
          <Box marginBottom={24}>
            <Text textStyle="Body/Medium/Regular" marginBottom={16}>
              Enter the email of the new primary business admin to send an
              invite:
            </Text>
            <Text textStyle="Body/Medium/Medium" marginBottom={12}>
              New Primary Business Admin Email
            </Text>
            <Input
              placeholder="Email address"
              value={email}
              onChange={handleEmailChange}
              isInvalid={touched && !isValid}
              data-testid="change-primary-business-admin-email-input"
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup width="50%" size="sm">
            <Flex direction="row" width="100%" gap="8">
              <Button
                variant="solid"
                colorMode="light"
                colorScheme="action"
                flex="5 0 0"
                onClick={handleSubmit}
                order={2}
                data-testid="change-primary-business-admin-submit"
                isDisabled={!isValid || !email}
              >
                Change Primary Business Admin
              </Button>
              <Button
                variant="outline"
                colorMode="light"
                onClick={onClose}
                flex="1 0 0"
                order={1}
              >
                Cancel
              </Button>
            </Flex>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { ChangePrimaryBusinessAdminModal };
