import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { ProgressBar as AlleProgressBar } from '@allergan-data-labs/alle-elements';

export interface ProgressBarProps {
  label: string;
  description: string;
  value: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  label,
  description,
  value,
}: ProgressBarProps) => {
  return (
    <Flex flexDirection="column" gap={8} data-testid="progress-bar-container">
      <Text textStyle="Body/Medium/Medium" data-testid="progress-bar-label">
        {label}
      </Text>
      <AlleProgressBar value={value} />
      <Box>
        <Flex flexDirection="row" justifyContent="space-between">
          <Text
            textStyle="Body/Small/Regular"
            data-testid="progress-bar-description"
          >
            {description}
          </Text>
          <Text
            textStyle="Body/Small/Regular"
            data-testid="progress-bar-value-indicator"
          >{`${value}%`}</Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export { ProgressBar };
