import { State } from 'xstate';
import { ValidationError } from 'yup';
import {
  FormMachineContext,
  FormMachineEvents,
  FormStates,
} from '../stateMachines/formMachineTypes';

const pluckValidationErrors = <TFields>(
  context: State<FormMachineContext<TFields>, FormMachineEvents<TFields>>
): { [key: string]: string } => {
  return (context.matches(FormStates.validation_error) ||
    context.matches(FormStates.server_error) ||
    context.matches(FormStates.validation)) &&
    context.context &&
    context.context.validationError &&
    context.context.validationError.inner
    ? context.context.validationError.inner.reduce(
        (acc: { [key: string]: string }, error: ValidationError) => {
          acc[error.path] = error.message;
          return acc;
        },
        {}
      )
    : {};
};

export { pluckValidationErrors };
