/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv';
import * as Segment from './segment';

export interface AppDownloaded {
  activator?: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  explainer?: string;
  type?: string;
}
export interface CartCleared {
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator?: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
}
export interface FilterItem {
  /**
   * Name of fiter applie
   */
  name?: string;
  /**
   * Setting of fiter applied
   */
  value?: string;
}
export interface CartFiltered {
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
  /**
   * list of Name : value pairs used to filter the data
   */
  filter: FilterItem[];
  /**
   * Array of all {ProductItem} in cart.
   */
  list?: any[];
}
export interface LibraryVersion {
  ASTEvent?: string;
  ASTLib?: string;
}
export interface CartViewed {
  action_source?: string;
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  event_source_url?: string;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
  library_version?: LibraryVersion;
}
export interface CheckoutCancelled {
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  checkout_id?: string;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
  /**
   * Alle ID of user; if unavailable we can fall back to IP Address.
   */
  member_id?: string;
  /**
   * ID of the provider
   */
  provider_id?: string;
  /**
   * Human-readable name of the provider.
   */
  provider_name?: string;
}
export interface ProductsItem {
  brand?: string;
  brands?: any[];
  campaign_id?: string;
  campaign_name?: string;
  category?: string;
  id?: string;
  name?: string;
  offer_type?: string;
  price?: string;
  variant?: string;
}
export interface Purchase {
  affiliation?: string;
  coupon?: string;
  /**
   * Currency type used for transaction. Default assumes USD
   */
  currency?: string;
  id?: string;
  revenue?: string;
  shipping?: string;
  tax?: string;
}
export interface CheckoutCompleted {
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  /**
   * unique id for this instance of a checkout
   */
  checkout_id?: string;
  /**
   * Alle ID of user; if unavailable we can fall back to IP Address.
   */
  consumer_id?: string;
  /**
   * tells us why this event exists
   */
  explainer?: string;
  /**
   * Initiator of the event (Consumer, Provider, Admin, System)
   */
  invoked_by?: string;
  /**
   * number of AGN brands included in the transaction
   */
  num_agn_brands?: number;
  /**
   * number of nonAGN brands included in the transaction
   */
  num_nonagn_brands?: number;
  /**
   * was an offer applied to this transaction?
   */
  offer_applied?: boolean;
  /**
   * was an offer applied to this transaction?
   */
  offers_redeemed?: any[];
  /**
   * quantity of points earned on this transaction
   */
  points_earned?: number;
  /**
   * quantity of points earned on this transaction
   */
  points_redeemed?: number;
  /**
   * Compliant with GTM/GA product objects
   */
  products?: ProductsItem[];
  provider_id?: string;
  provider_name?: string;
  /**
   * Compliant with GTM/GA ecommerce.actionField
   */
  purchase?: Purchase;
}
export interface LibraryVersion1 {
  ASTEvent?: Record<string, any>;
  ASTLib?: Record<string, any>;
}
export interface CheckoutFailed {
  action_source?: Record<string, any>;
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  address?: Record<string, any>;
  address2?: Record<string, any>;
  city?: Record<string, any>;
  /**
   * Description of what went wrong, eg "Card Declined"
   */
  desc?: string;
  event_source_url?: Record<string, any>;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
  first_name?: Record<string, any>;
  last_name?: Record<string, any>;
  library_version?: LibraryVersion1;
  phone?: Record<string, any>;
  step?: Record<string, any>;
}
export interface LibraryVersion2 {
  ASTEvent?: string;
  ASTLib?: string;
}
export interface CheckoutStarted {
  action_source?: string;
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  event_source_url?: string;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
  library_version?: LibraryVersion2;
}
export interface LibraryVersion3 {
  ASTEvent?: string;
  ASTLib?: string;
}
export interface CheckoutStep {
  action_source?: string;
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  desc?: string;
  event_source_url?: string;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
  library_version?: LibraryVersion3;
  /**
   * Actual stage of the cart process, eg Edit. Keep it simple.
   */
  step?: string;
}
export interface LibraryVersion4 {
  ASTEvent?: string;
  ASTLib?: string;
}
export interface ProductsItem1 {
  brand?: string;
  brands?: any[];
  campaign_id?: string;
  campaign_name?: string;
  category?: string;
  id?: string;
  name?: string;
  offer_type?: string;
  price?: string;
  variant?: string;
}
export interface Purchase1 {
  affiliation?: string;
  coupon?: string;
  /**
   * Currency type used for transaction. Default assumes USD
   */
  currency?: string;
  id?: string;
  revenue?: string;
  shipping?: string;
  tax?: string;
}
export interface CheckoutSubmitted {
  action_source?: string;
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  /**
   * unique id for this instance of a checkout
   */
  checkout_id?: string;
  event_source_url?: string;
  /**
   * tells us why this event exists
   */
  explainer?: string;
  /**
   * Initiator of the event (Consumer, Provider, Admin, System)
   */
  invoked_by?: string;
  library_version?: LibraryVersion4;
  /**
   * Alle ID of user; if unavailable we can fall back to IP Address.
   */
  member_id?: string;
  /**
   * number of AGN brands included in the transaction
   */
  num_agn_brands?: number;
  /**
   * number of nonAGN brands included in the transaction
   */
  num_nonagn_brands?: number;
  /**
   * was an offer applied to this transaction?
   */
  offer_applied?: boolean;
  /**
   * was an offer applied to this transaction?
   */
  offers_redeemed?: boolean;
  /**
   * quantity of points earned on this transaction
   */
  points_earned?: number;
  /**
   * quantity of points earned on this transaction
   */
  points_redeemed?: number;
  /**
   * Compliant with GTM/GA product objects
   */
  products?: ProductsItem1[];
  provider_id?: string;
  provider_name?: string;
  /**
   * Compliant with GTM/GA ecommerce.actionField
   */
  purchase?: Purchase1;
}
export interface CheckoutUpdated {
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  /**
   * unique id for this instance of a checkout
   */
  checkout_id?: string;
  explainer?: string;
  /**
   * Alle ID of user; if unavailable we can fall back to IP Address.
   */
  member_id?: string;
  /**
   * was an offer applied to this transaction?
   */
  offer_applied?: boolean;
  /**
   * unique id for this instance of a provider
   */
  provider_id?: string;
  /**
   * human readable name for the practice
   */
  provider_name?: string;
}
export interface ContentEdited {
  /**
   * Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
   */
  activator: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_id?: string;
  /**
   * BTXC Day
   */
  content_campaign_name?: string;
  /**
   * wrinkles, crows feet, etc
   */
  content_concern_area?: any | null;
  /**
   * Botox Cosmetic
   */
  content_headline?: string;
  content_id?: string;
  /**
   * start treatment
   */
  content_journey?: any | null;
  content_product?: any | null;
  content_slug?: string;
  content_tag?: any | null;
  /**
   * article_1, article_2
   */
  content_template?: string;
  /**
   * treatment guide, article
   */
  content_type?: string;
  /**
   * , illustration, original, commissioned, stock, infographic, gif
   */
  content_visual?: any | null;
  explainer?: string;
  tactic_id?: string;
  tactic_summary?: string;
}
export interface ContentPresented {
  /**
   * Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
   */
  activator: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_id?: string;
  /**
   * BTXC Day
   */
  content_campaign_name?: string;
  /**
   * wrinkles, crows feet, etc
   */
  content_concern_area?: any | null;
  /**
   * Botox Cosmetic
   */
  content_headline?: string;
  content_id?: string;
  /**
   * start treatment
   */
  content_journey?: any | null;
  content_product?: any | null;
  content_slug?: string;
  content_tag?: any | null;
  /**
   * article_1, article_2
   */
  content_template?: string;
  /**
   * treatment guide, article
   */
  content_type?: string;
  /**
   * , illustration, original, commissioned, stock, infographic, gif
   */
  content_visual?: any | null;
  explainer?: string;
  tactic_id?: string;
  tactic_summary?: string;
}
export interface ContentScrolled {
  /**
   * Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
   */
  activator: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_id?: string;
  /**
   * BTXC Day
   */
  content_campaign_name?: string;
  /**
   * wrinkles, crows feet, etc
   */
  content_concern_area?: any | null;
  /**
   * Botox Cosmetic
   */
  content_headline?: string;
  content_id?: string;
  /**
   * start treatment
   */
  content_journey?: any | null;
  content_product?: any | null;
  content_slug?: string;
  content_tag?: any | null;
  /**
   * article_1, article_2
   */
  content_template?: string;
  /**
   * treatment guide, article
   */
  content_type?: string;
  /**
   * , illustration, original, commissioned, stock, infographic, gif
   */
  content_visual?: any | null;
  explainer?: string;
  percent_viewed?: number;
  tactic_id?: string;
  tactic_summary?: string;
}
export interface ContentSubmitted {
  /**
   * Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
   */
  activator: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_id?: string;
  /**
   * BTXC Day
   */
  content_campaign_name?: string;
  /**
   * wrinkles, crows feet, etc
   */
  content_concern_area?: any | null;
  /**
   * Botox Cosmetic
   */
  content_headline?: string;
  content_id?: string;
  /**
   * start treatment
   */
  content_journey?: any | null;
  content_product?: any | null;
  content_slug?: string;
  content_tag?: any | null;
  /**
   * article_1, article_2
   */
  content_template?: string;
  /**
   * treatment guide, article
   */
  content_type?: string;
  /**
   * , illustration, original, commissioned, stock, infographic, gif
   */
  content_visual?: any | null;
  explainer?: string;
  tactic_id?: string;
  tactic_summary?: string;
}
export interface ContentViewed {
  /**
   * Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
   */
  activator: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_id?: string;
  /**
   * BTXC Day
   */
  content_campaign_name?: string;
  /**
   * wrinkles, crows feet, etc
   */
  content_concern_area?: any | null;
  /**
   * Botox Cosmetic
   */
  content_headline?: string;
  content_id?: string;
  /**
   * start treatment
   */
  content_journey?: any | null;
  content_product?: any | null;
  content_slug?: string;
  content_tag?: any | null;
  /**
   * article_1, article_2
   */
  content_template?: string;
  /**
   * treatment guide, article
   */
  content_type?: string;
  /**
   * , illustration, original, commissioned, stock, infographic, gif
   */
  content_visual?: any | null;
  explainer?: string;
  tactic_id?: string;
  tactic_summary?: string;
}
export interface DownloadInitiated {
  activator?: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  explainer?: string;
  type?: string;
  url?: string;
}
export interface ElementEngaged {
  action?: string;
  activator?: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_name?: string;
  campaign_type?: string;
  content_concern_area?: string;
  content_journey?: string;
  content_product?: string;
  content_type?: string;
  explainer?: string;
  /**
   * DEPRECATED DO NOT USE
   */
  properties?: string;
}
export interface Libraries {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries;
}
export interface User {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface FlashAdded {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event;
  /**
   * Value given in dropdown
   */
  reason?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User;
}
export interface Libraries1 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event1 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries1;
}
export interface User1 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface FlashRemoved {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event1;
  /**
   * Value selected in dropdown
   */
  reason?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User1;
}
export interface InfoDownloaded {
  action?: string;
  activator?: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_name?: string;
  campaign_type?: string;
  explainer?: string;
  /**
   * DEPRECATED DO NOT USE
   */
  properties?: string;
}
export interface InfoRequested {
  action?: string;
  activator?: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_name?: string;
  campaign_type?: string;
  explainer?: string;
  /**
   * DEPRECATED DO NOT USE
   */
  properties?: string;
}
export interface InfoSent {
  action?: string;
  activator?: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_name?: string;
  campaign_type?: string;
  explainer?: string;
  /**
   * DEPRECATED DO NOT USE
   */
  properties?: string;
}
export interface InfoSubscribed {
  action?: string;
  activator?: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_name?: string;
  campaign_type?: string;
  explainer?: string;
  /**
   * DEPRECATED DO NOT USE
   */
  properties?: string;
}
export interface InfoUnsubscribed {
  action?: string;
  activator?: string;
  /**
   * May be used for site-specific needs; add nested properties to this object as you need them.
   */
  additional_data?: Record<string, any>;
  campaign_name?: string;
  campaign_type?: string;
  explainer?: string;
  /**
   * DEPRECATED DO NOT USE
   */
  properties?: string;
}
export interface Libraries2 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event2 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries2;
}
export interface Billing {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address {
  /**
   * Billing address, if known
   */
  billing?: Billing;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping;
}
export interface Ids {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User2 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface PasswordForgotten {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event2;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User2;
}
export interface Libraries3 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event3 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries3;
}
export interface Billing1 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office1 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping1 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address1 {
  /**
   * Billing address, if known
   */
  billing?: Billing1;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office1;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping1;
}
export interface Ids1 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media1 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider1 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address1;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids1;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media1;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User3 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface PasswordUpdated {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event3;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider1;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User3;
}
export interface Product {
  brand?: string;
  category?: string;
  id?: string;
  name?: string;
  price?: string;
  variant?: string;
}
export interface ProductAdded {
  action_source?: string;
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  event_source_url?: string;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
  label?: string;
  /**
   * A ProductItem object. See Products library.
   */
  product?: Product;
}
export interface ProductMoved {
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
  /**
   * A ProductItem object. See Products library.
   */
  product?: Record<string, any>;
}
export interface ProductRemoved {
  /**
   * Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
   */
  activator: string;
  /**
   * May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
   */
  additional_data?: Record<string, any>;
  /**
   * Explain why this is being tracked.
   */
  explainer?: string;
  /**
   * A ProductItem object. See Products library.
   */
  product: Record<string, any>;
}
export interface Libraries4 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event4 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries4;
}
export interface Billing2 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office2 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping2 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address2 {
  /**
   * Billing address, if known
   */
  billing?: Billing2;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office2;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping2;
}
export interface Ids2 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media2 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider2 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address2;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids2;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media2;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User4 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileClaimed {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event4;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider2;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User4;
}
export interface Libraries5 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event5 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries5;
}
export interface Billing3 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office3 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping3 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address3 {
  /**
   * Billing address, if known
   */
  billing?: Billing3;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office3;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping3;
}
export interface Ids3 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media3 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider3 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address3;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids3;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media3;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface BusinessHours {
  /**
   * num_days_closednumber of days that the location is closed (ie has been marked as closed)
   */
  num_days_closed?: number;
  /**
   * number of days that the location is open (ie has open and close times)
   */
  num_days_open?: number;
  /**
   * number of days of the week that have been set to either have an open and close time, or marked as closed
   */
  num_days_set?: number;
}
export interface ConsultationSettings {
  /**
   * consultation fee in US CENTS
   */
  cost?: number;
  /**
   * whether or not the consultation request form should be hidden in provider directory
   */
  hide_form?: boolean;
  /**
   * whether or not the consultation fee counts towards the cost of treatment
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * email address that consultation requests are sent to
   */
  send_requests_to_email?: string;
}
export interface ContactInformation {
  /**
   * provider contact information email address
   */
  email_address?: string;
  /**
   * provider contact information facebook url
   */
  facebook_url?: string;
  /**
   * provider contact information instagram handle (eg @handle)
   */
  instagram_handle?: string;
  /**
   * provider contact information phone number. 10 digitis, no punctuation
   */
  phone_number?: string;
  /**
   * provider contact information tiktok handle (eg @handle)
   */
  tiktok_handle?: string;
  /**
   * provider contact information website url
   */
  website_url?: string;
}
export interface GalleryPhotos {
  /**
   * total number of gallery_photos
   */
  num_gallery_photos?: number;
}
export interface Products {
  /**
   * total number of products selected in profile manager
   */
  num_products?: number;
}
export interface ProviderProfile {
  /**
   * fields related to provider business hours set in profile manager
   */
  business_hours?: BusinessHours;
  /**
   * fields related to consultation request settings in profile manager
   */
  consultation_settings?: ConsultationSettings;
  /**
   * fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.
   */
  contact_information?: ContactInformation;
  /**
   * fields related to gallery photos uploaded in profile manager
   */
  gallery_photos?: GalleryPhotos;
  /**
   * fields related to provider products selected in profile manager
   */
  products?: Products;
}
export interface User5 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileCompleted {
  ''?: Record<string, any>;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event5;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider3;
  provider_profile?: ProviderProfile;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User5;
}
export interface Libraries6 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event6 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries6;
}
export interface Billing4 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office4 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping4 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address4 {
  /**
   * Billing address, if known
   */
  billing?: Billing4;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office4;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping4;
}
export interface Ids4 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media4 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider4 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address4;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids4;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media4;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User6 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileCreated {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event6;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider4;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User6;
}
export interface Libraries7 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event7 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries7;
}
export interface Billing5 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office5 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping5 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address5 {
  /**
   * Billing address, if known
   */
  billing?: Billing5;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office5;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping5;
}
export interface Ids5 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media5 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider5 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address5;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids5;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media5;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User7 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileFailed {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event7;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider5;
  /**
   * Reason the Fail happened. Include any event codes, if known, in [brackets]  for easier parsing.
   */
  reason?: Record<string, any>;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User7;
}
export interface Libraries8 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event8 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries8;
}
export interface Billing6 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office6 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping6 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address6 {
  /**
   * Billing address, if known
   */
  billing?: Billing6;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office6;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping6;
}
export interface Ids6 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media6 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider6 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address6;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids6;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media6;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface BusinessHours1 {
  /**
   * num_days_closednumber of days that the location is closed (ie has been marked as closed)
   */
  num_days_closed?: number;
  /**
   * number of days that the location is open (ie has open and close times)
   */
  num_days_open?: number;
  /**
   * number of days of the week that have been set to either have an open and close time, or marked as closed
   */
  num_days_set?: number;
}
export interface ConsultationSettings1 {
  /**
   * consultation fee in US CENTS
   */
  cost?: number;
  /**
   * whether or not the consultation request form should be hidden in provider directory
   */
  hide_form?: boolean;
  /**
   * whether or not the consultation fee counts towards the cost of treatment
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * email address that consultation requests are sent to
   */
  send_requests_to_email?: string;
}
export interface ContactInformation1 {
  /**
   * provider contact information email address
   */
  email_address?: string;
  /**
   * provider contact information facebook url
   */
  facebook_url?: string;
  /**
   * provider contact information instagram handle (eg @handle)
   */
  instagram_handle?: string;
  /**
   * provider contact information phone number. 10 digitis, no punctuation
   */
  phone_number?: string;
  /**
   * provider contact information tiktok handle (eg @handle)
   */
  tiktok_handle?: string;
  /**
   * provider contact information website url
   */
  website_url?: string;
}
export interface GalleryPhotos1 {
  /**
   * total number of gallery_photos
   */
  num_gallery_photos?: number;
}
export interface Products1 {
  /**
   * total number of products selected in profile manager
   */
  num_products?: number;
}
export interface ProviderProfile1 {
  /**
   * fields related to provider business hours set in profile manager
   */
  business_hours?: BusinessHours1;
  /**
   * fields related to consultation request settings in profile manager
   */
  consultation_settings?: ConsultationSettings1;
  /**
   * fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.
   */
  contact_information?: ContactInformation1;
  /**
   * fields related to gallery photos uploaded in profile manager
   */
  gallery_photos?: GalleryPhotos1;
  /**
   * fields related to provider products selected in profile manager
   */
  products?: Products1;
}
export interface User8 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfilePublished {
  ''?: Record<string, any>;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event8;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider6;
  provider_profile?: ProviderProfile1;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User8;
}
export interface Libraries9 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event9 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries9;
}
export interface Billing7 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office7 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping7 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address7 {
  /**
   * Billing address, if known
   */
  billing?: Billing7;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office7;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping7;
}
export interface Ids7 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * legacy shipTo id. Will be deprecated soon, we think.
   */
  provider_id?: string;
}
export interface Media7 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider7 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address7;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids7;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media7;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User9 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileRemoved {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event9;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider7;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User9;
}
export interface Libraries10 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event10 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries10;
}
export interface Billing8 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office8 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping8 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address8 {
  /**
   * Billing address, if known
   */
  billing?: Billing8;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office8;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping8;
}
export interface Ids8 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media8 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider8 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address8;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids8;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media8;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface BusinessHours2 {
  /**
   * num_days_closednumber of days that the location is closed (ie has been marked as closed)
   */
  num_days_closed?: number;
  /**
   * number of days that the location is open (ie has open and close times)
   */
  num_days_open?: number;
  /**
   * number of days of the week that have been set to either have an open and close time, or marked as closed
   */
  num_days_set?: number;
}
export interface ConsultationSettings2 {
  /**
   * consultation fee in US CENTS
   */
  cost?: number;
  /**
   * whether or not the consultation request form should be hidden in provider directory
   */
  hide_form?: boolean;
  /**
   * whether or not the consultation fee counts towards the cost of treatment
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * email address that consultation requests are sent to
   */
  send_requests_to_email?: string;
}
export interface ContactInformation2 {
  /**
   * provider contact information email address
   */
  email_address?: string;
  /**
   * provider contact information facebook url
   */
  facebook_url?: string;
  /**
   * provider contact information instagram handle (eg @handle)
   */
  instagram_handle?: string;
  /**
   * provider contact information phone number. 10 digitis, no punctuation
   */
  phone_number?: string;
  /**
   * provider contact information tiktok handle (eg @handle)
   */
  tiktok_handle?: string;
  /**
   * provider contact information website url
   */
  website_url?: string;
}
export interface GalleryPhotos2 {
  /**
   * total number of gallery_photos
   */
  num_gallery_photos?: number;
}
export interface Products2 {
  /**
   * total number of products selected in profile manager
   */
  num_products?: number;
}
export interface ProviderProfile2 {
  /**
   * fields related to provider business hours set in profile manager
   */
  business_hours?: BusinessHours2;
  /**
   * fields related to consultation request settings in profile manager
   */
  consultation_settings?: ConsultationSettings2;
  /**
   * fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.
   */
  contact_information?: ContactInformation2;
  /**
   * fields related to gallery photos uploaded in profile manager
   */
  gallery_photos?: GalleryPhotos2;
  /**
   * fields related to provider products selected in profile manager
   */
  products?: Products2;
}
export interface User10 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileSubmitted {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event10;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider8;
  provider_profile?: ProviderProfile2;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User10;
}
export interface Libraries11 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event11 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries11;
}
export interface Billing9 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office9 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping9 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address9 {
  /**
   * Billing address, if known
   */
  billing?: Billing9;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office9;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping9;
}
export interface Ids9 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media9 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider9 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address9;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids9;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media9;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User11 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileUnpublished {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event11;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider9;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User11;
}
export interface Libraries12 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event12 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries12;
}
export interface Billing10 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office10 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping10 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address10 {
  /**
   * Billing address, if known
   */
  billing?: Billing10;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office10;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping10;
}
export interface Ids10 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media10 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider10 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address10;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids10;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media10;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface BusinessHours3 {
  /**
   * num_days_closednumber of days that the location is closed (ie has been marked as closed)
   */
  num_days_closed?: number;
  /**
   * number of days that the location is open (ie has open and close times)
   */
  num_days_open?: number;
  /**
   * number of days of the week that have been set to either have an open and close time, or marked as closed
   */
  num_days_set?: number;
}
export interface ConsultationSettings3 {
  /**
   * consultation fee in US CENTS
   */
  cost?: number;
  /**
   * whether or not the consultation request form should be hidden in provider directory
   */
  hide_form?: boolean;
  /**
   * whether or not the consultation fee counts towards the cost of treatment
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * email address that consultation requests are sent to
   */
  send_requests_to_email?: string;
}
export interface ContactInformation3 {
  /**
   * provider contact information email address
   */
  email_address?: string;
  /**
   * provider contact information facebook url
   */
  facebook_url?: string;
  /**
   * provider contact information instagram handle (eg @handle)
   */
  instagram_handle?: string;
  /**
   * provider contact information phone number. 10 digitis, no punctuation
   */
  phone_number?: string;
  /**
   * provider contact information tiktok handle (eg @handle)
   */
  tiktok_handle?: string;
  /**
   * provider contact information website url
   */
  website_url?: string;
}
export interface GalleryPhotos3 {
  /**
   * total number of gallery_photos
   */
  num_gallery_photos?: number;
}
export interface Products3 {
  /**
   * total number of products selected in profile manager
   */
  num_products?: number;
}
export interface ProviderProfile3 {
  /**
   * fields related to provider business hours set in profile manager
   */
  business_hours?: BusinessHours3;
  /**
   * fields related to consultation request settings in profile manager
   */
  consultation_settings?: ConsultationSettings3;
  /**
   * fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.
   */
  contact_information?: ContactInformation3;
  /**
   * fields related to gallery photos uploaded in profile manager
   */
  gallery_photos?: GalleryPhotos3;
  /**
   * fields related to provider products selected in profile manager
   */
  products?: Products3;
}
export interface User12 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileUpdated {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event12;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider10;
  provider_profile?: ProviderProfile3;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User12;
}
export interface Libraries13 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event13 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries13;
}
export interface Billing11 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office11 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping11 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address11 {
  /**
   * Billing address, if known
   */
  billing?: Billing11;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office11;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping11;
}
export interface Ids11 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media11 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider11 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address11;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids11;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media11;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User13 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileViewed {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event13;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider11;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User13;
}
export interface Libraries14 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event14 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries14;
}
export interface Billing12 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office12 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping12 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address12 {
  /**
   * Billing address, if known
   */
  billing?: Billing12;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office12;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping12;
}
export interface Ids12 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media12 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider12 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address12;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids12;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media12;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User14 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface RegistrationCompleted {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event14;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider12;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User14;
}
export interface Libraries15 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event15 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries15;
}
export interface Billing13 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office13 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping13 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address13 {
  /**
   * Billing address, if known
   */
  billing?: Billing13;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office13;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping13;
}
export interface Ids13 {
  /**
   * id of business
   */
  business_id?: string;
  /**
   * physical location id
   */
  location_id?: string;
  /**
   * legacy shipTo id. Will be deprecated soon, we think.
   */
  provider_id?: string;
}
export interface Media13 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider13 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address13;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids13;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media13;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User15 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface RegistrationStarted {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event15;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider13;
  registration_type?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User15;
}
export interface Libraries16 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event16 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries16;
}
export interface Billing14 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office14 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping14 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address14 {
  /**
   * Billing address, if known
   */
  billing?: Billing14;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office14;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping14;
}
export interface Ids14 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media14 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider14 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address14;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids14;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media14;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User16 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface RegistrationStep {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event16;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider14;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User16;
}
export interface Libraries17 {
  /**
   * Version of ASTEvent used to instrument this page, if applicable.
   */
  ASTEvent?: string;
  /**
   * Version of current ASTLib. Used to filter out zombie users and cached data.
   */
  ASTLib?: string;
  /**
   * Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
   */
  site?: string;
}
export interface Event17 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
   */
  libraries?: Libraries17;
}
export interface Billing15 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office15 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping15 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address15 {
  /**
   * Billing address, if known
   */
  billing?: Billing15;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office15;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping15;
}
export interface Ids15 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media15 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider15 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address15;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids15;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media15;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User17 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface RegistrationSubmitted {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event17;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider15;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User17;
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: Ajv.ErrorObject[]
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations
) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true });
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '7.4.1',
      },
    },
  };
}

/**
 * @typedef AppDownloaded
 * @property {string} [activator] -
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [explainer] -
 * @property {string} [type] -
 */
/**
 * @typedef CartCleared
 * @property {string} [activator] - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [explainer] - Explain why this is being tracked.
 */
/**
 * @typedef FilterItem
 * @property {string} [name] - Name of fiter applie
 * @property {string} [value] - Setting of fiter applied
 */
/**
 * @typedef CartFiltered
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [explainer] - Explain why this is being tracked.
 * @property {FilterItem[]} filter - list of Name : value pairs used to filter the data
 * @property {any[]} [list] - Array of all {ProductItem} in cart.
 */
/**
 * @typedef LibraryVersion
 * @property {string} [ASTEvent] -
 * @property {string} [ASTLib] -
 */
/**
 * @typedef CartViewed
 * @property {string} [action_source] -
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [event_source_url] -
 * @property {string} [explainer] - Explain why this is being tracked.
 * @property {LibraryVersion} [library_version] -
 */
/**
 * @typedef CheckoutCancelled
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [checkout_id] -
 * @property {string} [explainer] - Explain why this is being tracked.
 * @property {string} [member_id] - Alle ID of user; if unavailable we can fall back to IP Address.
 * @property {string} [provider_id] - ID of the provider
 * @property {string} [provider_name] - Human-readable name of the provider.
 */
/**
 * @typedef ProductsItem
 * @property {string} [brand] -
 * @property {any[]} [brands] -
 * @property {string} [campaign_id] -
 * @property {string} [campaign_name] -
 * @property {string} [category] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [offer_type] -
 * @property {string} [price] -
 * @property {string} [variant] -
 */
/**
 * @typedef Purchase
 * @property {string} [affiliation] -
 * @property {string} [coupon] -
 * @property {string} [currency] - Currency type used for transaction. Default assumes USD
 * @property {string} [id] -
 * @property {string} [revenue] -
 * @property {string} [shipping] -
 * @property {string} [tax] -
 */
/**
 * @typedef CheckoutCompleted
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [checkout_id] - unique id for this instance of a checkout
 * @property {string} [consumer_id] - Alle ID of user; if unavailable we can fall back to IP Address.
 * @property {string} [explainer] - tells us why this event exists
 * @property {string} [invoked_by] - Initiator of the event (Consumer, Provider, Admin, System)
 * @property {number} [num_agn_brands] - number of AGN brands included in the transaction
 * @property {number} [num_nonagn_brands] - number of nonAGN brands included in the transaction
 * @property {boolean} [offer_applied] - was an offer applied to this transaction?
 * @property {any[]} [offers_redeemed] - was an offer applied to this transaction?
 * @property {number} [points_earned] - quantity of points earned on this transaction
 * @property {number} [points_redeemed] - quantity of points earned on this transaction
 * @property {ProductsItem[]} [products] - Compliant with GTM/GA product objects
 * @property {string} [provider_id] -
 * @property {string} [provider_name] -
 * @property {Purchase} [purchase] - Compliant with GTM/GA ecommerce.actionField
 */
/**
 * @typedef LibraryVersion1
 * @property {Record<string, any>} [ASTEvent] -
 * @property {Record<string, any>} [ASTLib] -
 */
/**
 * @typedef CheckoutFailed
 * @property {Record<string, any>} [action_source] -
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {Record<string, any>} [address] -
 * @property {Record<string, any>} [address2] -
 * @property {Record<string, any>} [city] -
 * @property {string} [desc] - Description of what went wrong, eg "Card Declined"
 * @property {Record<string, any>} [event_source_url] -
 * @property {string} [explainer] - Explain why this is being tracked.
 * @property {Record<string, any>} [first_name] -
 * @property {Record<string, any>} [last_name] -
 * @property {LibraryVersion1} [library_version] -
 * @property {Record<string, any>} [phone] -
 * @property {Record<string, any>} [step] -
 */
/**
 * @typedef LibraryVersion2
 * @property {string} [ASTEvent] -
 * @property {string} [ASTLib] -
 */
/**
 * @typedef CheckoutStarted
 * @property {string} [action_source] -
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [event_source_url] -
 * @property {string} [explainer] - Explain why this is being tracked.
 * @property {LibraryVersion2} [library_version] -
 */
/**
 * @typedef LibraryVersion3
 * @property {string} [ASTEvent] -
 * @property {string} [ASTLib] -
 */
/**
 * @typedef CheckoutStep
 * @property {string} [action_source] -
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [desc] -
 * @property {string} [event_source_url] -
 * @property {string} [explainer] - Explain why this is being tracked.
 * @property {LibraryVersion3} [library_version] -
 * @property {string} [step] - Actual stage of the cart process, eg Edit. Keep it simple.
 */
/**
 * @typedef LibraryVersion4
 * @property {string} [ASTEvent] -
 * @property {string} [ASTLib] -
 */
/**
 * @typedef ProductsItem1
 * @property {string} [brand] -
 * @property {any[]} [brands] -
 * @property {string} [campaign_id] -
 * @property {string} [campaign_name] -
 * @property {string} [category] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [offer_type] -
 * @property {string} [price] -
 * @property {string} [variant] -
 */
/**
 * @typedef Purchase1
 * @property {string} [affiliation] -
 * @property {string} [coupon] -
 * @property {string} [currency] - Currency type used for transaction. Default assumes USD
 * @property {string} [id] -
 * @property {string} [revenue] -
 * @property {string} [shipping] -
 * @property {string} [tax] -
 */
/**
 * @typedef CheckoutSubmitted
 * @property {string} [action_source] -
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [checkout_id] - unique id for this instance of a checkout
 * @property {string} [event_source_url] -
 * @property {string} [explainer] - tells us why this event exists
 * @property {string} [invoked_by] - Initiator of the event (Consumer, Provider, Admin, System)
 * @property {LibraryVersion4} [library_version] -
 * @property {string} [member_id] - Alle ID of user; if unavailable we can fall back to IP Address.
 * @property {number} [num_agn_brands] - number of AGN brands included in the transaction
 * @property {number} [num_nonagn_brands] - number of nonAGN brands included in the transaction
 * @property {boolean} [offer_applied] - was an offer applied to this transaction?
 * @property {boolean} [offers_redeemed] - was an offer applied to this transaction?
 * @property {number} [points_earned] - quantity of points earned on this transaction
 * @property {number} [points_redeemed] - quantity of points earned on this transaction
 * @property {ProductsItem1[]} [products] - Compliant with GTM/GA product objects
 * @property {string} [provider_id] -
 * @property {string} [provider_name] -
 * @property {Purchase1} [purchase] - Compliant with GTM/GA ecommerce.actionField
 */
/**
 * @typedef CheckoutUpdated
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [checkout_id] - unique id for this instance of a checkout
 * @property {string} [explainer] -
 * @property {string} [member_id] - Alle ID of user; if unavailable we can fall back to IP Address.
 * @property {boolean} [offer_applied] - was an offer applied to this transaction?
 * @property {string} [provider_id] - unique id for this instance of a provider
 * @property {string} [provider_name] - human readable name for the practice
 */
/**
 * @typedef ContentEdited
 * @property {string} activator - Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_id] -
 * @property {string} [content_campaign_name] - BTXC Day
 * @property {any | null} [content_concern_area] - wrinkles, crows feet, etc
 * @property {string} [content_headline] - Botox Cosmetic
 * @property {string} [content_id] -
 * @property {any | null} [content_journey] - start treatment
 * @property {any | null} [content_product] -
 * @property {string} [content_slug] -
 * @property {any | null} [content_tag] -
 * @property {string} [content_template] - article_1, article_2
 * @property {string} [content_type] - treatment guide, article
 * @property {any | null} [content_visual] - , illustration, original, commissioned, stock, infographic, gif
 * @property {string} [explainer] -
 * @property {string} [tactic_id] -
 * @property {string} [tactic_summary] -
 */
/**
 * @typedef ContentPresented
 * @property {string} activator - Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_id] -
 * @property {string} [content_campaign_name] - BTXC Day
 * @property {any | null} [content_concern_area] - wrinkles, crows feet, etc
 * @property {string} [content_headline] - Botox Cosmetic
 * @property {string} [content_id] -
 * @property {any | null} [content_journey] - start treatment
 * @property {any | null} [content_product] -
 * @property {string} [content_slug] -
 * @property {any | null} [content_tag] -
 * @property {string} [content_template] - article_1, article_2
 * @property {string} [content_type] - treatment guide, article
 * @property {any | null} [content_visual] - , illustration, original, commissioned, stock, infographic, gif
 * @property {string} [explainer] -
 * @property {string} [tactic_id] -
 * @property {string} [tactic_summary] -
 */
/**
 * @typedef ContentScrolled
 * @property {string} activator - Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_id] -
 * @property {string} [content_campaign_name] - BTXC Day
 * @property {any | null} [content_concern_area] - wrinkles, crows feet, etc
 * @property {string} [content_headline] - Botox Cosmetic
 * @property {string} [content_id] -
 * @property {any | null} [content_journey] - start treatment
 * @property {any | null} [content_product] -
 * @property {string} [content_slug] -
 * @property {any | null} [content_tag] -
 * @property {string} [content_template] - article_1, article_2
 * @property {string} [content_type] - treatment guide, article
 * @property {any | null} [content_visual] - , illustration, original, commissioned, stock, infographic, gif
 * @property {string} [explainer] -
 * @property {number} [percent_viewed] -
 * @property {string} [tactic_id] -
 * @property {string} [tactic_summary] -
 */
/**
 * @typedef ContentSubmitted
 * @property {string} activator - Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_id] -
 * @property {string} [content_campaign_name] - BTXC Day
 * @property {any | null} [content_concern_area] - wrinkles, crows feet, etc
 * @property {string} [content_headline] - Botox Cosmetic
 * @property {string} [content_id] -
 * @property {any | null} [content_journey] - start treatment
 * @property {any | null} [content_product] -
 * @property {string} [content_slug] -
 * @property {any | null} [content_tag] -
 * @property {string} [content_template] - article_1, article_2
 * @property {string} [content_type] - treatment guide, article
 * @property {any | null} [content_visual] - , illustration, original, commissioned, stock, infographic, gif
 * @property {string} [explainer] -
 * @property {string} [tactic_id] -
 * @property {string} [tactic_summary] -
 */
/**
 * @typedef ContentViewed
 * @property {string} activator - Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_id] -
 * @property {string} [content_campaign_name] - BTXC Day
 * @property {any | null} [content_concern_area] - wrinkles, crows feet, etc
 * @property {string} [content_headline] - Botox Cosmetic
 * @property {string} [content_id] -
 * @property {any | null} [content_journey] - start treatment
 * @property {any | null} [content_product] -
 * @property {string} [content_slug] -
 * @property {any | null} [content_tag] -
 * @property {string} [content_template] - article_1, article_2
 * @property {string} [content_type] - treatment guide, article
 * @property {any | null} [content_visual] - , illustration, original, commissioned, stock, infographic, gif
 * @property {string} [explainer] -
 * @property {string} [tactic_id] -
 * @property {string} [tactic_summary] -
 */
/**
 * @typedef DownloadInitiated
 * @property {string} [activator] -
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [explainer] -
 * @property {string} [type] -
 * @property {string} [url] -
 */
/**
 * @typedef ElementEngaged
 * @property {string} [action] -
 * @property {string} [activator] -
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_name] -
 * @property {string} [campaign_type] -
 * @property {string} [content_concern_area] -
 * @property {string} [content_journey] -
 * @property {string} [content_product] -
 * @property {string} [content_type] -
 * @property {string} [explainer] -
 * @property {string} [properties] - DEPRECATED DO NOT USE
 */
/**
 * @typedef Libraries
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef User
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef FlashAdded
 * @property {Event} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [reason] - Value given in dropdown
 * @property {User} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries1
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event1
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries1} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef User1
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef FlashRemoved
 * @property {Event1} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [reason] - Value selected in dropdown
 * @property {User1} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef InfoDownloaded
 * @property {string} [action] -
 * @property {string} [activator] -
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_name] -
 * @property {string} [campaign_type] -
 * @property {string} [explainer] -
 * @property {string} [properties] - DEPRECATED DO NOT USE
 */
/**
 * @typedef InfoRequested
 * @property {string} [action] -
 * @property {string} [activator] -
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_name] -
 * @property {string} [campaign_type] -
 * @property {string} [explainer] -
 * @property {string} [properties] - DEPRECATED DO NOT USE
 */
/**
 * @typedef InfoSent
 * @property {string} [action] -
 * @property {string} [activator] -
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_name] -
 * @property {string} [campaign_type] -
 * @property {string} [explainer] -
 * @property {string} [properties] - DEPRECATED DO NOT USE
 */
/**
 * @typedef InfoSubscribed
 * @property {string} [action] -
 * @property {string} [activator] -
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_name] -
 * @property {string} [campaign_type] -
 * @property {string} [explainer] -
 * @property {string} [properties] - DEPRECATED DO NOT USE
 */
/**
 * @typedef InfoUnsubscribed
 * @property {string} [action] -
 * @property {string} [activator] -
 * @property {Record<string, any>} [additional_data] - May be used for site-specific needs; add nested properties to this object as you need them.
 * @property {string} [campaign_name] -
 * @property {string} [campaign_type] -
 * @property {string} [explainer] -
 * @property {string} [properties] - DEPRECATED DO NOT USE
 */
/**
 * @typedef Libraries2
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event2
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries2} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address
 * @property {Billing} [billing] - Billing address, if known
 * @property {Office} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider
 * @property {Address} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids} [ids] -
 * @property {Media} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User2
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef PasswordForgotten
 * @property {Event2} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User2} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries3
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event3
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries3} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing1
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office1
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping1
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address1
 * @property {Billing1} [billing] - Billing address, if known
 * @property {Office1} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping1} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids1
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media1
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider1
 * @property {Address1} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids1} [ids] -
 * @property {Media1} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User3
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef PasswordUpdated
 * @property {Event3} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider1} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User3} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Product
 * @property {string} [brand] -
 * @property {string} [category] -
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [price] -
 * @property {string} [variant] -
 */
/**
 * @typedef ProductAdded
 * @property {string} [action_source] -
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [event_source_url] -
 * @property {string} [explainer] - Explain why this is being tracked.
 * @property {string} [label] -
 * @property {Product} [product] - A ProductItem object. See Products library.
 */
/**
 * @typedef ProductMoved
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [explainer] - Explain why this is being tracked.
 * @property {Record<string, any>} [product] - A ProductItem object. See Products library.
 */
/**
 * @typedef ProductRemoved
 * @property {string} activator - Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image
 * @property {Record<string, any>} [additional_data] - May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.
 * @property {string} [explainer] - Explain why this is being tracked.
 * @property {Record<string, any>} product - A ProductItem object. See Products library.
 */
/**
 * @typedef Libraries4
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event4
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries4} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing2
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office2
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping2
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address2
 * @property {Billing2} [billing] - Billing address, if known
 * @property {Office2} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping2} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids2
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media2
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider2
 * @property {Address2} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids2} [ids] -
 * @property {Media2} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User4
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileClaimed
 * @property {Event4} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider2} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User4} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries5
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event5
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries5} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing3
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office3
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping3
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address3
 * @property {Billing3} [billing] - Billing address, if known
 * @property {Office3} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping3} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids3
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media3
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider3
 * @property {Address3} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids3} [ids] -
 * @property {Media3} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef BusinessHours
 * @property {number} [num_days_closed] - num_days_closednumber of days that the location is closed (ie has been marked as closed)
 * @property {number} [num_days_open] - number of days that the location is open (ie has open and close times)
 * @property {number} [num_days_set] - number of days of the week that have been set to either have an open and close time, or marked as closed
 */
/**
 * @typedef ConsultationSettings
 * @property {number} [cost] - consultation fee in US CENTS
 * @property {boolean} [hide_form] - whether or not the consultation request form should be hidden in provider directory
 * @property {boolean} [is_fee_towards_treatment_cost] - whether or not the consultation fee counts towards the cost of treatment
 * @property {string} [send_requests_to_email] - email address that consultation requests are sent to
 */
/**
 * @typedef ContactInformation
 * @property {string} [email_address] - provider contact information email address
 * @property {string} [facebook_url] - provider contact information facebook url
 * @property {string} [instagram_handle] - provider contact information instagram handle (eg @handle)
 * @property {string} [phone_number] - provider contact information phone number. 10 digitis, no punctuation
 * @property {string} [tiktok_handle] - provider contact information tiktok handle (eg @handle)
 * @property {string} [website_url] - provider contact information website url
 */
/**
 * @typedef GalleryPhotos
 * @property {number} [num_gallery_photos] - total number of gallery_photos
 */
/**
 * @typedef Products
 * @property {number} [num_products] - total number of products selected in profile manager
 */
/**
 * @typedef ProviderProfile
 * @property {BusinessHours} [business_hours] - fields related to provider business hours set in profile manager
 * @property {ConsultationSettings} [consultation_settings] - fields related to consultation request settings in profile manager
 * @property {ContactInformation} [contact_information] - fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.
 * @property {GalleryPhotos} [gallery_photos] - fields related to gallery photos uploaded in profile manager
 * @property {Products} [products] - fields related to provider products selected in profile manager
 */
/**
 * @typedef User5
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileCompleted
 * @property {Record<string, any>} [] -
 * @property {Event5} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider3} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {ProviderProfile} [provider_profile] -
 * @property {User5} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries6
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event6
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries6} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing4
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office4
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping4
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address4
 * @property {Billing4} [billing] - Billing address, if known
 * @property {Office4} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping4} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids4
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media4
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider4
 * @property {Address4} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids4} [ids] -
 * @property {Media4} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User6
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileCreated
 * @property {Event6} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider4} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User6} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries7
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event7
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries7} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing5
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office5
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping5
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address5
 * @property {Billing5} [billing] - Billing address, if known
 * @property {Office5} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping5} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids5
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media5
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider5
 * @property {Address5} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids5} [ids] -
 * @property {Media5} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User7
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileFailed
 * @property {Event7} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider5} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {Record<string, any>} [reason] - Reason the Fail happened. Include any event codes, if known, in [brackets]  for easier parsing.
 * @property {User7} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries8
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event8
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries8} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing6
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office6
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping6
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address6
 * @property {Billing6} [billing] - Billing address, if known
 * @property {Office6} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping6} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids6
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media6
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider6
 * @property {Address6} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids6} [ids] -
 * @property {Media6} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef BusinessHours1
 * @property {number} [num_days_closed] - num_days_closednumber of days that the location is closed (ie has been marked as closed)
 * @property {number} [num_days_open] - number of days that the location is open (ie has open and close times)
 * @property {number} [num_days_set] - number of days of the week that have been set to either have an open and close time, or marked as closed
 */
/**
 * @typedef ConsultationSettings1
 * @property {number} [cost] - consultation fee in US CENTS
 * @property {boolean} [hide_form] - whether or not the consultation request form should be hidden in provider directory
 * @property {boolean} [is_fee_towards_treatment_cost] - whether or not the consultation fee counts towards the cost of treatment
 * @property {string} [send_requests_to_email] - email address that consultation requests are sent to
 */
/**
 * @typedef ContactInformation1
 * @property {string} [email_address] - provider contact information email address
 * @property {string} [facebook_url] - provider contact information facebook url
 * @property {string} [instagram_handle] - provider contact information instagram handle (eg @handle)
 * @property {string} [phone_number] - provider contact information phone number. 10 digitis, no punctuation
 * @property {string} [tiktok_handle] - provider contact information tiktok handle (eg @handle)
 * @property {string} [website_url] - provider contact information website url
 */
/**
 * @typedef GalleryPhotos1
 * @property {number} [num_gallery_photos] - total number of gallery_photos
 */
/**
 * @typedef Products1
 * @property {number} [num_products] - total number of products selected in profile manager
 */
/**
 * @typedef ProviderProfile1
 * @property {BusinessHours1} [business_hours] - fields related to provider business hours set in profile manager
 * @property {ConsultationSettings1} [consultation_settings] - fields related to consultation request settings in profile manager
 * @property {ContactInformation1} [contact_information] - fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.
 * @property {GalleryPhotos1} [gallery_photos] - fields related to gallery photos uploaded in profile manager
 * @property {Products1} [products] - fields related to provider products selected in profile manager
 */
/**
 * @typedef User8
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfilePublished
 * @property {Record<string, any>} [] -
 * @property {Event8} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider6} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {ProviderProfile1} [provider_profile] -
 * @property {User8} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries9
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event9
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries9} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing7
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office7
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping7
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address7
 * @property {Billing7} [billing] - Billing address, if known
 * @property {Office7} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping7} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids7
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - legacy shipTo id. Will be deprecated soon, we think.
 */
/**
 * @typedef Media7
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider7
 * @property {Address7} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids7} [ids] -
 * @property {Media7} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User9
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileRemoved
 * @property {Event9} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider7} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User9} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries10
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event10
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries10} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing8
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office8
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping8
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address8
 * @property {Billing8} [billing] - Billing address, if known
 * @property {Office8} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping8} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids8
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media8
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider8
 * @property {Address8} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids8} [ids] -
 * @property {Media8} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef BusinessHours2
 * @property {number} [num_days_closed] - num_days_closednumber of days that the location is closed (ie has been marked as closed)
 * @property {number} [num_days_open] - number of days that the location is open (ie has open and close times)
 * @property {number} [num_days_set] - number of days of the week that have been set to either have an open and close time, or marked as closed
 */
/**
 * @typedef ConsultationSettings2
 * @property {number} [cost] - consultation fee in US CENTS
 * @property {boolean} [hide_form] - whether or not the consultation request form should be hidden in provider directory
 * @property {boolean} [is_fee_towards_treatment_cost] - whether or not the consultation fee counts towards the cost of treatment
 * @property {string} [send_requests_to_email] - email address that consultation requests are sent to
 */
/**
 * @typedef ContactInformation2
 * @property {string} [email_address] - provider contact information email address
 * @property {string} [facebook_url] - provider contact information facebook url
 * @property {string} [instagram_handle] - provider contact information instagram handle (eg @handle)
 * @property {string} [phone_number] - provider contact information phone number. 10 digitis, no punctuation
 * @property {string} [tiktok_handle] - provider contact information tiktok handle (eg @handle)
 * @property {string} [website_url] - provider contact information website url
 */
/**
 * @typedef GalleryPhotos2
 * @property {number} [num_gallery_photos] - total number of gallery_photos
 */
/**
 * @typedef Products2
 * @property {number} [num_products] - total number of products selected in profile manager
 */
/**
 * @typedef ProviderProfile2
 * @property {BusinessHours2} [business_hours] - fields related to provider business hours set in profile manager
 * @property {ConsultationSettings2} [consultation_settings] - fields related to consultation request settings in profile manager
 * @property {ContactInformation2} [contact_information] - fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.
 * @property {GalleryPhotos2} [gallery_photos] - fields related to gallery photos uploaded in profile manager
 * @property {Products2} [products] - fields related to provider products selected in profile manager
 */
/**
 * @typedef User10
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileSubmitted
 * @property {Event10} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider8} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {ProviderProfile2} [provider_profile] -
 * @property {User10} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries11
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event11
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries11} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing9
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office9
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping9
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address9
 * @property {Billing9} [billing] - Billing address, if known
 * @property {Office9} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping9} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids9
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media9
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider9
 * @property {Address9} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids9} [ids] -
 * @property {Media9} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User11
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileUnpublished
 * @property {Event11} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider9} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User11} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries12
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event12
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries12} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing10
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office10
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping10
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address10
 * @property {Billing10} [billing] - Billing address, if known
 * @property {Office10} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping10} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids10
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media10
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider10
 * @property {Address10} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids10} [ids] -
 * @property {Media10} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef BusinessHours3
 * @property {number} [num_days_closed] - num_days_closednumber of days that the location is closed (ie has been marked as closed)
 * @property {number} [num_days_open] - number of days that the location is open (ie has open and close times)
 * @property {number} [num_days_set] - number of days of the week that have been set to either have an open and close time, or marked as closed
 */
/**
 * @typedef ConsultationSettings3
 * @property {number} [cost] - consultation fee in US CENTS
 * @property {boolean} [hide_form] - whether or not the consultation request form should be hidden in provider directory
 * @property {boolean} [is_fee_towards_treatment_cost] - whether or not the consultation fee counts towards the cost of treatment
 * @property {string} [send_requests_to_email] - email address that consultation requests are sent to
 */
/**
 * @typedef ContactInformation3
 * @property {string} [email_address] - provider contact information email address
 * @property {string} [facebook_url] - provider contact information facebook url
 * @property {string} [instagram_handle] - provider contact information instagram handle (eg @handle)
 * @property {string} [phone_number] - provider contact information phone number. 10 digitis, no punctuation
 * @property {string} [tiktok_handle] - provider contact information tiktok handle (eg @handle)
 * @property {string} [website_url] - provider contact information website url
 */
/**
 * @typedef GalleryPhotos3
 * @property {number} [num_gallery_photos] - total number of gallery_photos
 */
/**
 * @typedef Products3
 * @property {number} [num_products] - total number of products selected in profile manager
 */
/**
 * @typedef ProviderProfile3
 * @property {BusinessHours3} [business_hours] - fields related to provider business hours set in profile manager
 * @property {ConsultationSettings3} [consultation_settings] - fields related to consultation request settings in profile manager
 * @property {ContactInformation3} [contact_information] - fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.
 * @property {GalleryPhotos3} [gallery_photos] - fields related to gallery photos uploaded in profile manager
 * @property {Products3} [products] - fields related to provider products selected in profile manager
 */
/**
 * @typedef User12
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileUpdated
 * @property {Event12} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider10} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {ProviderProfile3} [provider_profile] -
 * @property {User12} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries13
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event13
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries13} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing11
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office11
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping11
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address11
 * @property {Billing11} [billing] - Billing address, if known
 * @property {Office11} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping11} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids11
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media11
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider11
 * @property {Address11} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids11} [ids] -
 * @property {Media11} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User13
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileViewed
 * @property {Event13} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider11} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User13} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries14
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event14
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries14} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing12
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office12
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping12
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address12
 * @property {Billing12} [billing] - Billing address, if known
 * @property {Office12} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping12} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids12
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media12
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider12
 * @property {Address12} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids12} [ids] -
 * @property {Media12} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User14
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef RegistrationCompleted
 * @property {Event14} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider12} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User14} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries15
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event15
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries15} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing13
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office13
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping13
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address13
 * @property {Billing13} [billing] - Billing address, if known
 * @property {Office13} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping13} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids13
 * @property {string} [business_id] - id of business
 * @property {string} [location_id] - physical location id
 * @property {string} [provider_id] - legacy shipTo id. Will be deprecated soon, we think.
 */
/**
 * @typedef Media13
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider13
 * @property {Address13} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids13} [ids] -
 * @property {Media13} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User15
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef RegistrationStarted
 * @property {Event15} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider13} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {string} [registration_type] -
 * @property {User15} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries16
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event16
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries16} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing14
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office14
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping14
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address14
 * @property {Billing14} [billing] - Billing address, if known
 * @property {Office14} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping14} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids14
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media14
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider14
 * @property {Address14} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids14} [ids] -
 * @property {Media14} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User16
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef RegistrationStep
 * @property {Event16} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider14} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User16} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Libraries17
 * @property {string} [ASTEvent] - Version of ASTEvent used to instrument this page, if applicable.
 * @property {string} [ASTLib] - Version of current ASTLib. Used to filter out zombie users and cached data.
 * @property {string} [site] - Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,
 */
/**
 * @typedef Event17
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Libraries17} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.
 */
/**
 * @typedef Billing15
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office15
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping15
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address15
 * @property {Billing15} [billing] - Billing address, if known
 * @property {Office15} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping15} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids15
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media15
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider15
 * @property {Address15} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids15} [ids] -
 * @property {Media15} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User17
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef RegistrationSubmitted
 * @property {Event17} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Provider15} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User17} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */

/**
 * User manually downsloads an app
 *
 * @param {AppDownloaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function appDownloaded(
  props?: AppDownloaded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User manually downsloads an app',
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description: '',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          type: {
            description: '',
            id: '/properties/properties/properties/type',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'App Downloaded',
    type: 'object',
  };
  const message = {
    event: 'App Downloaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'App Downloaded',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When user completely clears their cart. Not currently in use, resrved for future use.
 *
 * @param {CartCleared} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartCleared(
  props?: CartCleared,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When user completely clears their cart. Not currently in use, resrved for future use.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Cart Cleared',
    type: 'object',
  };
  const message = {
    event: 'Cart Cleared',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Cart Cleared',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When user applies a filter to cart items
 *
 * @param {CartFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartFiltered(
  props: CartFiltered,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user applies a filter to cart items',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          filter: {
            description: 'list of Name : value pairs used to filter the data',
            id: '/properties/properties/properties/filter',
            items: {
              description: 'Array of filters being applied here',
              id: '/properties/properties/properties/filter/items',
              properties: {
                name: {
                  description: 'Name of fiter applie',
                  id: '/properties/properties/properties/filter/items/properties/name',
                  type: 'string',
                },
                value: {
                  description: 'Setting of fiter applied',
                  id: '/properties/properties/properties/filter/items/properties/value',
                  type: 'string',
                },
              },
              required: [],
              type: 'object',
            },
            type: 'array',
          },
          list: {
            description: 'Array of all {ProductItem} in cart.',
            id: '/properties/properties/properties/list',
            type: 'array',
          },
        },
        required: ['activator', 'filter'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Cart Filtered',
    type: 'object',
  };
  const message = {
    event: 'Cart Filtered',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Cart Filtered',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When user views their cart
 *
 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartViewed(
  props: CartViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user views their cart',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          action_source: {
            description: '',
            id: '/properties/properties/properties/action_source',
            type: 'string',
          },
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          event_source_url: {
            description: '',
            id: '/properties/properties/properties/event_source_url',
            type: 'string',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          library_version: {
            description: '',
            id: '/properties/properties/properties/library_version',
            properties: {
              ASTEvent: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTEvent',
                type: 'string',
              },
              ASTLib: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTLib',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Cart Viewed',
    type: 'object',
  };
  const message = {
    event: 'Cart Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Cart Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the user explicitly cancels the checkout process. See also Checkout Failed which is when the server stops it.
 *
 * @param {CheckoutCancelled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutCancelled(
  props: CheckoutCancelled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user explicitly cancels the checkout process. See also Checkout Failed which is when the server stops it.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          checkout_id: {
            description: '',
            id: '/properties/properties/properties/checkout_id',
            type: 'string',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          member_id: {
            description:
              'Alle ID of user; if unavailable we can fall back to IP Address.',
            id: '/properties/properties/properties/member_id',
            type: 'string',
          },
          provider_id: {
            description: 'ID of the provider',
            id: '/properties/properties/properties/provider_id',
            type: 'string',
          },
          provider_name: {
            description: 'Human-readable name of the provider.',
            id: '/properties/properties/properties/provider_name',
            type: 'string',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkout Cancelled',
    type: 'object',
  };
  const message = {
    event: 'Checkout Cancelled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Cancelled',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * If the user successfully makes a purchase. This should be sent from the server or upon entering the thank you page, if any.
 *
 * @param {CheckoutCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutCompleted(
  props: CheckoutCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'If the user successfully makes a purchase. This should be sent from the server or upon entering the thank you page, if any.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          checkout_id: {
            description: 'unique id for this instance of a checkout',
            id: '/properties/properties/properties/checkout_id',
            type: 'string',
          },
          consumer_id: {
            description:
              'Alle ID of user; if unavailable we can fall back to IP Address.',
            id: '/properties/properties/properties/consumer_id',
            type: 'string',
          },
          explainer: {
            description: 'tells us why this event exists',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          invoked_by: {
            description:
              'Initiator of the event (Consumer, Provider, Admin, System)',
            id: '/properties/properties/properties/invoked_by',
            type: 'string',
          },
          num_agn_brands: {
            description: 'number of AGN brands included in the transaction',
            id: '/properties/properties/properties/num_agn_brands',
            type: 'number',
          },
          num_nonagn_brands: {
            description: 'number of nonAGN brands included in the transaction',
            id: '/properties/properties/properties/num_nonagn_brands',
            type: 'number',
          },
          offer_applied: {
            description: 'was an offer applied to this transaction?',
            id: '/properties/properties/properties/offer_applied',
            type: 'boolean',
          },
          offers_redeemed: {
            description: 'was an offer applied to this transaction?',
            id: '/properties/properties/properties/offers_redeemed',
            type: 'array',
          },
          points_earned: {
            description: 'quantity of points earned on this transaction',
            id: '/properties/properties/properties/points_earned',
            type: 'number',
          },
          points_redeemed: {
            description: 'quantity of points earned on this transaction',
            id: '/properties/properties/properties/points_redeemed',
            type: 'number',
          },
          products: {
            description: 'Compliant with GTM/GA product objects',
            id: '/properties/properties/properties/products',
            items: {
              description: '',
              id: '/properties/properties/properties/products/items',
              properties: {
                brand: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/brand',
                  type: 'string',
                },
                brands: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/brands',
                  type: 'array',
                },
                campaign_id: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/campaign_id',
                  type: 'string',
                },
                campaign_name: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/campaign_name',
                  type: 'string',
                },
                category: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/category',
                  type: 'string',
                },
                id: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/id',
                  type: 'string',
                },
                name: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/name',
                  type: 'string',
                },
                offer_type: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/offer_type',
                  type: 'string',
                },
                price: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/price',
                  type: 'string',
                },
                variant: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/variant',
                  type: 'string',
                },
              },
              required: [],
              type: 'object',
            },
            type: 'array',
          },
          provider_id: {
            description: '',
            id: '/properties/properties/properties/provider_id',
            type: 'string',
          },
          provider_name: {
            description: '',
            id: '/properties/properties/properties/provider_name',
            type: 'string',
          },
          purchase: {
            description: 'Compliant with GTM/GA ecommerce.actionField',
            id: '/properties/properties/properties/purchase',
            properties: {
              affiliation: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/affiliation',
                type: 'string',
              },
              coupon: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/coupon',
                type: 'string',
              },
              currency: {
                description:
                  'Currency type used for transaction. Default assumes USD',
                id: '/properties/properties/properties/purchase/properties/currency',
                type: 'string',
              },
              id: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/id',
                type: 'string',
              },
              revenue: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/revenue',
                type: 'string',
              },
              shipping: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/shipping',
                type: 'string',
              },
              tax: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/tax',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkout Completed',
    type: 'object',
  };
  const message = {
    event: 'Checkout Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Completed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * If the users purchase is rejected for any reason
 *
 * @param {CheckoutFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutFailed(
  props: CheckoutFailed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'If the users purchase is rejected for any reason',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          action_source: {
            description: '',
            id: '/properties/properties/properties/action_source',
            type: 'object',
          },
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          address: {
            description: '',
            id: '/properties/properties/properties/address',
            type: 'object',
          },
          address2: {
            description: '',
            id: '/properties/properties/properties/address2',
            type: 'object',
          },
          city: {
            description: '',
            id: '/properties/properties/properties/city',
            type: 'object',
          },
          desc: {
            description: 'Description of what went wrong, eg "Card Declined"',
            id: '/properties/properties/properties/desc',
            type: 'string',
          },
          event_source_url: {
            description: '',
            id: '/properties/properties/properties/event_source_url',
            type: 'object',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          first_name: {
            description: '',
            id: '/properties/properties/properties/first_name',
            type: 'object',
          },
          last_name: {
            description: '',
            id: '/properties/properties/properties/last_name',
            type: 'object',
          },
          library_version: {
            description: '',
            id: '/properties/properties/properties/library_version',
            properties: {
              ASTEvent: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTEvent',
                type: 'object',
              },
              ASTLib: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTLib',
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          phone: {
            description: '',
            id: '/properties/properties/properties/phone',
            type: 'object',
          },
          step: {
            description: '',
            id: '/properties/properties/properties/step',
            type: 'object',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkout Failed',
    type: 'object',
  };
  const message = {
    event: 'Checkout Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Failed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the user begins the checkout process, eg the Checkout Now button
 *
 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
  props: CheckoutStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user begins the checkout process, eg the Checkout Now button',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          action_source: {
            description: '',
            id: '/properties/properties/properties/action_source',
            type: 'string',
          },
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          event_source_url: {
            description: '',
            id: '/properties/properties/properties/event_source_url',
            type: 'string',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          library_version: {
            description: '',
            id: '/properties/properties/properties/library_version',
            properties: {
              ASTEvent: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTEvent',
                type: 'string',
              },
              ASTLib: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTLib',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkout Started',
    type: 'object',
  };
  const message = {
    event: 'Checkout Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Started',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the user advances to a new step of the checkout process (e.g. shipping, billing etc)
 *
 * @param {CheckoutStep} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStep(
  props: CheckoutStep,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user advances to a new step of the checkout process (e.g. shipping, billing etc)',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          action_source: {
            description: '',
            id: '/properties/properties/properties/action_source',
            type: 'string',
          },
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          desc: {
            description: '',
            id: '/properties/properties/properties/desc',
            type: 'string',
          },
          event_source_url: {
            description: '',
            id: '/properties/properties/properties/event_source_url',
            type: 'string',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          library_version: {
            description: '',
            id: '/properties/properties/properties/library_version',
            properties: {
              ASTEvent: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTEvent',
                type: 'string',
              },
              ASTLib: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTLib',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          step: {
            description:
              'Actual stage of the cart process, eg Edit. Keep it simple.',
            id: '/properties/properties/properties/step',
            type: 'string',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkout Step',
    type: 'object',
  };
  const message = {
    event: 'Checkout Step',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Step',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when the user submits checkout data to the server. Can still be rejected or failed by server.
 *
 * @param {CheckoutSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutSubmitted(
  props: CheckoutSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires when the user submits checkout data to the server. Can still be rejected or failed by server.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          action_source: {
            description: '',
            id: '/properties/properties/properties/action_source',
            type: 'string',
          },
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          checkout_id: {
            description: 'unique id for this instance of a checkout',
            id: '/properties/properties/properties/checkout_id',
            type: 'string',
          },
          event_source_url: {
            description: '',
            id: '/properties/properties/properties/event_source_url',
            type: 'string',
          },
          explainer: {
            description: 'tells us why this event exists',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          invoked_by: {
            description:
              'Initiator of the event (Consumer, Provider, Admin, System)',
            id: '/properties/properties/properties/invoked_by',
            type: 'string',
          },
          library_version: {
            description: '',
            id: '/properties/properties/properties/library_version',
            properties: {
              ASTEvent: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTEvent',
                type: 'string',
              },
              ASTLib: {
                description: '',
                id: '/properties/properties/properties/library_version/properties/ASTLib',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          member_id: {
            description:
              'Alle ID of user; if unavailable we can fall back to IP Address.',
            id: '/properties/properties/properties/member_id',
            type: 'string',
          },
          num_agn_brands: {
            description: 'number of AGN brands included in the transaction',
            id: '/properties/properties/properties/num_agn_brands',
            type: 'number',
          },
          num_nonagn_brands: {
            description: 'number of nonAGN brands included in the transaction',
            id: '/properties/properties/properties/num_nonagn_brands',
            type: 'number',
          },
          offer_applied: {
            description: 'was an offer applied to this transaction?',
            id: '/properties/properties/properties/offer_applied',
            type: 'boolean',
          },
          offers_redeemed: {
            description: 'was an offer applied to this transaction?',
            id: '/properties/properties/properties/offers_redeemed',
            type: 'boolean',
          },
          points_earned: {
            description: 'quantity of points earned on this transaction',
            id: '/properties/properties/properties/points_earned',
            type: 'number',
          },
          points_redeemed: {
            description: 'quantity of points earned on this transaction',
            id: '/properties/properties/properties/points_redeemed',
            type: 'number',
          },
          products: {
            description: 'Compliant with GTM/GA product objects',
            id: '/properties/properties/properties/products',
            items: {
              description: '',
              id: '/properties/properties/properties/products/items',
              properties: {
                brand: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/brand',
                  type: 'string',
                },
                brands: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/brands',
                  type: 'array',
                },
                campaign_id: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/campaign_id',
                  type: 'string',
                },
                campaign_name: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/campaign_name',
                  type: 'string',
                },
                category: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/category',
                  type: 'string',
                },
                id: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/id',
                  type: 'string',
                },
                name: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/name',
                  type: 'string',
                },
                offer_type: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/offer_type',
                  type: 'string',
                },
                price: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/price',
                  type: 'string',
                },
                variant: {
                  description: '',
                  id: '/properties/properties/properties/products/items/properties/variant',
                  type: 'string',
                },
              },
              required: [],
              type: 'object',
            },
            type: 'array',
          },
          provider_id: {
            description: '',
            id: '/properties/properties/properties/provider_id',
            type: 'string',
          },
          provider_name: {
            description: '',
            id: '/properties/properties/properties/provider_name',
            type: 'string',
          },
          purchase: {
            description: 'Compliant with GTM/GA ecommerce.actionField',
            id: '/properties/properties/properties/purchase',
            properties: {
              affiliation: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/affiliation',
                type: 'string',
              },
              coupon: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/coupon',
                type: 'string',
              },
              currency: {
                description:
                  'Currency type used for transaction. Default assumes USD',
                id: '/properties/properties/properties/purchase/properties/currency',
                type: 'string',
              },
              id: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/id',
                type: 'string',
              },
              revenue: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/revenue',
                type: 'string',
              },
              shipping: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/shipping',
                type: 'string',
              },
              tax: {
                description: '',
                id: '/properties/properties/properties/purchase/properties/tax',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkout Submitted',
    type: 'object',
  };
  const message = {
    event: 'Checkout Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Submitted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When user modifies checkout criteria. Not to be confused with Cart updated.
 *
 * @param {CheckoutUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutUpdated(
  props: CheckoutUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When user modifies checkout criteria. Not to be confused with Cart updated.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          checkout_id: {
            description: 'unique id for this instance of a checkout',
            id: '/properties/properties/properties/checkout_id',
            type: 'string',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          member_id: {
            description:
              'Alle ID of user; if unavailable we can fall back to IP Address.',
            id: '/properties/properties/properties/member_id',
            type: 'string',
          },
          offer_applied: {
            description: 'was an offer applied to this transaction?',
            id: '/properties/properties/properties/offer_applied',
            type: 'boolean',
          },
          provider_id: {
            description: 'unique id for this instance of a provider',
            id: '/properties/properties/properties/provider_id',
            type: 'string',
          },
          provider_name: {
            description: 'human readable name for the practice',
            id: '/properties/properties/properties/provider_name',
            type: 'string',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Checkout Updated',
    type: 'object',
  };
  const message = {
    event: 'Checkout Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Updated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Rare. Fires when user (typically as an admin or provider) begins to edit content bu thas not submitted it yet.
 *
 * @param {ContentEdited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentEdited(
  props: ContentEdited,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Rare. Fires when user (typically as an admin or provider) begins to edit content bu thas not submitted it yet.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_id: {
            description: '',
            id: '/properties/properties/properties/campaign_id',
            type: 'string',
          },
          content_campaign_name: {
            description: 'BTXC Day',
            id: '/properties/properties/properties/content_campaign_name',
            type: 'string',
          },
          content_concern_area: {
            description: 'wrinkles, crows feet, etc',
            id: '/properties/properties/properties/content_concern_area',
          },
          content_headline: {
            description: 'Botox Cosmetic',
            id: '/properties/properties/properties/content_headline',
            type: 'string',
          },
          content_id: {
            description: '',
            id: '/properties/properties/properties/content_id',
            type: 'string',
          },
          content_journey: {
            description: 'start treatment',
            id: '/properties/properties/properties/content_journey',
          },
          content_product: {
            id: '/properties/properties/properties/content_product',
          },
          content_slug: {
            description: '',
            id: '/properties/properties/properties/content_slug',
            type: 'string',
          },
          content_tag: {
            description: '',
            id: '/properties/properties/properties/content_tag',
          },
          content_template: {
            description: 'article_1, article_2',
            id: '/properties/properties/properties/content_template',
            type: 'string',
          },
          content_type: {
            description: 'treatment guide, article',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          content_visual: {
            description:
              ', illustration, original, commissioned, stock, infographic, gif',
            id: '/properties/properties/properties/content_visual',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          tactic_id: {
            description: '',
            id: '/properties/properties/properties/tactic_id',
            type: 'string',
          },
          tactic_summary: {
            description: '',
            id: '/properties/properties/properties/tactic_summary',
            type: 'string',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Content Edited',
    type: 'object',
  };
  const message = {
    event: 'Content Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Edited',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when user is shown an infobox, etc but we don't necessarily know they have seen it.
 *
 * @param {ContentPresented} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentPresented(
  props: ContentPresented,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Fires when user is shown an infobox, etc but we don't necessarily know they have seen it. ",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_id: {
            description: '',
            id: '/properties/properties/properties/campaign_id',
            type: 'string',
          },
          content_campaign_name: {
            description: 'BTXC Day',
            id: '/properties/properties/properties/content_campaign_name',
            type: 'string',
          },
          content_concern_area: {
            description: 'wrinkles, crows feet, etc',
            id: '/properties/properties/properties/content_concern_area',
          },
          content_headline: {
            description: 'Botox Cosmetic',
            id: '/properties/properties/properties/content_headline',
            type: 'string',
          },
          content_id: {
            description: '',
            id: '/properties/properties/properties/content_id',
            type: 'string',
          },
          content_journey: {
            description: 'start treatment',
            id: '/properties/properties/properties/content_journey',
          },
          content_product: {
            id: '/properties/properties/properties/content_product',
          },
          content_slug: {
            description: '',
            id: '/properties/properties/properties/content_slug',
            type: 'string',
          },
          content_tag: {
            description: '',
            id: '/properties/properties/properties/content_tag',
          },
          content_template: {
            description: 'article_1, article_2',
            id: '/properties/properties/properties/content_template',
            type: 'string',
          },
          content_type: {
            description: 'treatment guide, article',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          content_visual: {
            description:
              ', illustration, original, commissioned, stock, infographic, gif',
            id: '/properties/properties/properties/content_visual',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          tactic_id: {
            description: '',
            id: '/properties/properties/properties/tactic_id',
            type: 'string',
          },
          tactic_summary: {
            description: '',
            id: '/properties/properties/properties/tactic_summary',
            type: 'string',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Content Presented',
    type: 'object',
  };
  const message = {
    event: 'Content Presented',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Presented',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when user is shown an infobox, etc but we don't necessarily know they have seen it.
 *
 * @param {ContentScrolled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentScrolled(
  props?: ContentScrolled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Fires when user is shown an infobox, etc but we don't necessarily know they have seen it. ",
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_id: {
            description: '',
            id: '/properties/properties/properties/campaign_id',
            type: 'string',
          },
          content_campaign_name: {
            description: 'BTXC Day',
            id: '/properties/properties/properties/content_campaign_name',
            type: 'string',
          },
          content_concern_area: {
            description: 'wrinkles, crows feet, etc',
            id: '/properties/properties/properties/content_concern_area',
          },
          content_headline: {
            description: 'Botox Cosmetic',
            id: '/properties/properties/properties/content_headline',
            type: 'string',
          },
          content_id: {
            description: '',
            id: '/properties/properties/properties/content_id',
            type: 'string',
          },
          content_journey: {
            description: 'start treatment',
            id: '/properties/properties/properties/content_journey',
          },
          content_product: {
            id: '/properties/properties/properties/content_product',
          },
          content_slug: {
            description: '',
            id: '/properties/properties/properties/content_slug',
            type: 'string',
          },
          content_tag: {
            description: '',
            id: '/properties/properties/properties/content_tag',
          },
          content_template: {
            description: 'article_1, article_2',
            id: '/properties/properties/properties/content_template',
            type: 'string',
          },
          content_type: {
            description: 'treatment guide, article',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          content_visual: {
            description:
              ', illustration, original, commissioned, stock, infographic, gif',
            id: '/properties/properties/properties/content_visual',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          percent_viewed: {
            description: '',
            id: '/properties/properties/properties/percent_viewed',
            type: 'number',
          },
          tactic_id: {
            description: '',
            id: '/properties/properties/properties/tactic_id',
            type: 'string',
          },
          tactic_summary: {
            description: '',
            id: '/properties/properties/properties/tactic_summary',
            type: 'string',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Content Scrolled',
    type: 'object',
  };
  const message = {
    event: 'Content Scrolled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Scrolled',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When user (in this case usually a provider) submits a change to existing content on the site.
 *
 * @param {ContentSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentSubmitted(
  props: ContentSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When user (in this case usually a provider) submits a change to existing content on the site.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_id: {
            description: '',
            id: '/properties/properties/properties/campaign_id',
            type: 'string',
          },
          content_campaign_name: {
            description: 'BTXC Day',
            id: '/properties/properties/properties/content_campaign_name',
            type: 'string',
          },
          content_concern_area: {
            description: 'wrinkles, crows feet, etc',
            id: '/properties/properties/properties/content_concern_area',
          },
          content_headline: {
            description: 'Botox Cosmetic',
            id: '/properties/properties/properties/content_headline',
            type: 'string',
          },
          content_id: {
            description: '',
            id: '/properties/properties/properties/content_id',
            type: 'string',
          },
          content_journey: {
            description: 'start treatment',
            id: '/properties/properties/properties/content_journey',
          },
          content_product: {
            id: '/properties/properties/properties/content_product',
          },
          content_slug: {
            description: '',
            id: '/properties/properties/properties/content_slug',
            type: 'string',
          },
          content_tag: {
            description: '',
            id: '/properties/properties/properties/content_tag',
          },
          content_template: {
            description: 'article_1, article_2',
            id: '/properties/properties/properties/content_template',
            type: 'string',
          },
          content_type: {
            description: 'treatment guide, article',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          content_visual: {
            description:
              ', illustration, original, commissioned, stock, infographic, gif',
            id: '/properties/properties/properties/content_visual',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          tactic_id: {
            description: '',
            id: '/properties/properties/properties/tactic_id',
            type: 'string',
          },
          tactic_summary: {
            description: '',
            id: '/properties/properties/properties/tactic_summary',
            type: 'string',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Content Submitted',
    type: 'object',
  };
  const message = {
    event: 'Content Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Submitted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when we know a user has seen an infobox. Typically this is done via a dismissal button, or calculation based on page scrolling.
 *
 * @param {ContentViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentViewed(
  props: ContentViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires when we know a user has seen an infobox. Typically this is done via a dismissal button, or calculation based on page scrolling.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of what was clicked/interacted with, eg "Large Red Button in Banner"',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_id: {
            description: '',
            id: '/properties/properties/properties/campaign_id',
            type: 'string',
          },
          content_campaign_name: {
            description: 'BTXC Day',
            id: '/properties/properties/properties/content_campaign_name',
            type: 'string',
          },
          content_concern_area: {
            description: 'wrinkles, crows feet, etc',
            id: '/properties/properties/properties/content_concern_area',
          },
          content_headline: {
            description: 'Botox Cosmetic',
            id: '/properties/properties/properties/content_headline',
            type: 'string',
          },
          content_id: {
            description: '',
            id: '/properties/properties/properties/content_id',
            type: 'string',
          },
          content_journey: {
            description: 'start treatment',
            id: '/properties/properties/properties/content_journey',
          },
          content_product: {
            id: '/properties/properties/properties/content_product',
          },
          content_slug: {
            description: '',
            id: '/properties/properties/properties/content_slug',
            type: 'string',
          },
          content_tag: {
            description: '',
            id: '/properties/properties/properties/content_tag',
          },
          content_template: {
            description: 'article_1, article_2',
            id: '/properties/properties/properties/content_template',
            type: 'string',
          },
          content_type: {
            description: 'treatment guide, article',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          content_visual: {
            description:
              ', illustration, original, commissioned, stock, infographic, gif',
            id: '/properties/properties/properties/content_visual',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          tactic_id: {
            description: '',
            id: '/properties/properties/properties/tactic_id',
            type: 'string',
          },
          tactic_summary: {
            description: '',
            id: '/properties/properties/properties/tactic_summary',
            type: 'string',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Content Viewed',
    type: 'object',
  };
  const message = {
    event: 'Content Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When user initiates a download action from another site, such as the app store. Used when the actual download process is s a black box to us.
 *
 * @param {DownloadInitiated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadInitiated(
  props?: DownloadInitiated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When user initiates a download action from another site, such as the app store. Used when the actual download process is s a black box to us.',
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description: '',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          type: {
            description: '',
            id: '/properties/properties/properties/type',
            type: 'string',
          },
          url: {
            description: '',
            id: '/properties/properties/properties/url',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Download Initiated',
    type: 'object',
  };
  const message = {
    event: 'Download Initiated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Download Initiated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * SHOULD ONLY BE USED ON INTERACTIVE GRAPHICS E.G. ON COOLSCULTPING. If you are using this for ANYTHING ELSE, contact Pete McBride, you are doing something wrong....
 *
 * @param {ElementEngaged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementEngaged(
  props?: ElementEngaged,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'SHOULD ONLY BE USED ON INTERACTIVE GRAPHICS E.G. ON COOLSCULTPING. If you are using this for ANYTHING ELSE, contact Pete McBride, you are doing something wrong....',
    properties: {
      context: {},
      properties: {
        properties: {
          action: {
            description: '',
            id: '/properties/properties/properties/action',
            type: 'string',
          },
          activator: {
            description: '',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_name: {
            description: '',
            id: '/properties/properties/properties/campaign_name',
            type: 'string',
          },
          campaign_type: {
            description: '',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          content_concern_area: {
            description: '',
            id: '/properties/properties/properties/content_concern_area',
            type: 'string',
          },
          content_journey: {
            description: '',
            id: '/properties/properties/properties/content_journey',
            type: 'string',
          },
          content_product: {
            description: '',
            id: '/properties/properties/properties/content_product',
            type: 'string',
          },
          content_type: {
            description: '',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          properties: {
            description: 'DEPRECATED DO NOT USE',
            id: '/properties/properties/properties/properties',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Element Engaged',
    type: 'object',
  };
  const message = {
    event: 'Element Engaged',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Element Engaged',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'Flash Added' track call.
 *
 * @param {FlashAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flashAdded(
  props?: FlashAdded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          reason: {
            description: 'Value given in dropdown',
            id: '/properties/properties/properties/reason',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Flash Added',
    type: 'object',
  };
  const message = {
    event: 'Flash Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Flash Added',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'Flash Removed' track call.
 *
 * @param {FlashRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flashRemoved(
  props?: FlashRemoved,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          reason: {
            description: 'Value selected in dropdown',
            id: '/properties/properties/properties/reason',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Flash Removed',
    type: 'object',
  };
  const message = {
    event: 'Flash Removed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Flash Removed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User manually downsloads info, such as a PDF.
 *
 * @param {InfoDownloaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function infoDownloaded(
  props?: InfoDownloaded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User manually downsloads info, such as a PDF.',
    properties: {
      context: {},
      properties: {
        properties: {
          action: {
            description: '',
            id: '/properties/properties/properties/action',
            type: 'string',
          },
          activator: {
            description: '',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_name: {
            description: '',
            id: '/properties/properties/properties/campaign_name',
            type: 'string',
          },
          campaign_type: {
            description: '',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          properties: {
            description: 'DEPRECATED DO NOT USE',
            id: '/properties/properties/properties/properties',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Info Downloaded',
    type: 'object',
  };
  const message = {
    event: 'Info Downloaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Info Downloaded',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User requests info from an external source
 *
 * @param {InfoRequested} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function infoRequested(
  props?: InfoRequested,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User requests info from an external source',
    properties: {
      context: {},
      properties: {
        properties: {
          action: {
            description: '',
            id: '/properties/properties/properties/action',
            type: 'string',
          },
          activator: {
            description: '',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_name: {
            description: '',
            id: '/properties/properties/properties/campaign_name',
            type: 'string',
          },
          campaign_type: {
            description: '',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          properties: {
            description: 'DEPRECATED DO NOT USE',
            id: '/properties/properties/properties/properties',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Info Requested',
    type: 'object',
  };
  const message = {
    event: 'Info Requested',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Info Requested',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * SERVER (usually). Fires when user successfully  gets their Info Request
 *
 * @param {InfoSent} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function infoSent(
  props?: InfoSent,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'SERVER (usually). Fires when user successfully  gets their Info Request ',
    properties: {
      context: {},
      properties: {
        properties: {
          action: {
            description: '',
            id: '/properties/properties/properties/action',
            type: 'string',
          },
          activator: {
            description: '',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_name: {
            description: '',
            id: '/properties/properties/properties/campaign_name',
            type: 'string',
          },
          campaign_type: {
            description: '',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          properties: {
            description: 'DEPRECATED DO NOT USE',
            id: '/properties/properties/properties/properties',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Info Sent',
    type: 'object',
  };
  const message = {
    event: 'Info Sent',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Info Sent', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Used for newsletters etc where info will be regularly sent to user
 *
 * @param {InfoSubscribed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function infoSubscribed(
  props?: InfoSubscribed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Used for newsletters etc where info will be regularly sent to user',
    properties: {
      context: {},
      properties: {
        properties: {
          action: {
            description: '',
            id: '/properties/properties/properties/action',
            type: 'string',
          },
          activator: {
            description: '',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_name: {
            description: '',
            id: '/properties/properties/properties/campaign_name',
            type: 'string',
          },
          campaign_type: {
            description: '',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          properties: {
            description: 'DEPRECATED DO NOT USE',
            id: '/properties/properties/properties/properties',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Info Subscribed',
    type: 'object',
  };
  const message = {
    event: 'Info Subscribed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Info Subscribed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'Info Unsubscribed' track call.
 *
 * @param {InfoUnsubscribed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function infoUnsubscribed(
  props?: InfoUnsubscribed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    properties: {
      context: {},
      properties: {
        properties: {
          action: {
            description: '',
            id: '/properties/properties/properties/action',
            type: 'string',
          },
          activator: {
            description: '',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used for site-specific needs; add nested properties to this object as you need them. ',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          campaign_name: {
            description: '',
            id: '/properties/properties/properties/campaign_name',
            type: 'string',
          },
          campaign_type: {
            description: '',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          explainer: {
            description: '',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          properties: {
            description: 'DEPRECATED DO NOT USE',
            id: '/properties/properties/properties/properties',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Info Unsubscribed',
    type: 'object',
  };
  const message = {
    event: 'Info Unsubscribed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Info Unsubscribed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * indicate when a provider user is in need of requesting a password change; we want to see how often this happens for optimization reasons
 *
 * @param {PasswordForgotten} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passwordForgotten(
  props?: PasswordForgotten,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'indicate when a provider user is in need of requesting a password change; we want to see how often this happens for optimization reasons',
    labels: {
      provider: ['registration', 'password'],
      usertype: 'provider',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Password Forgotten',
    type: 'object',
  };
  const message = {
    event: 'Password Forgotten',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Password Forgotten',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * indicate when a provider user successfully has updated their password; we want to see what % of consumers who start this flow, actually complete it successfully.
 *
 * @param {PasswordUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passwordUpdated(
  props?: PasswordUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'indicate when a provider user successfully has updated their password; we want to see what % of consumers who start this flow, actually complete it successfully.',
    labels: {
      provider: 'password',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Password Updated',
    type: 'object',
  };
  const message = {
    event: 'Password Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Password Updated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When user adds an item to their cart from any source.
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(
  props: ProductAdded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user adds an item to their cart from any source.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          action_source: {
            description: '',
            id: '/properties/properties/properties/action_source',
            type: 'string',
          },
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          event_source_url: {
            description: '',
            id: '/properties/properties/properties/event_source_url',
            type: 'string',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          label: {
            description: '',
            id: '/properties/properties/properties/label',
            type: 'string',
          },
          product: {
            description: 'A ProductItem object. See Products library.',
            id: '/properties/properties/properties/product',
            properties: {
              brand: {
                description: '',
                id: '/properties/properties/properties/product/properties/brand',
                type: 'string',
              },
              category: {
                description: '',
                id: '/properties/properties/properties/product/properties/category',
                type: 'string',
              },
              id: {
                description: '',
                id: '/properties/properties/properties/product/properties/id',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/product/properties/name',
                type: 'string',
              },
              price: {
                description: '',
                id: '/properties/properties/properties/product/properties/price',
                type: 'string',
              },
              variant: {
                description: '',
                id: '/properties/properties/properties/product/properties/variant',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Added',
    type: 'object',
  };
  const message = {
    event: 'Product Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Added',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When user moves an item from their cart. To Wishlist is implied.
 *
 * @param {ProductMoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productMoved(
  props: ProductMoved,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When user moves an item from their cart. To Wishlist is implied.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          product: {
            description: 'A ProductItem object. See Products library.',
            id: '/properties/properties/properties/product',
            type: 'object',
          },
        },
        required: ['activator'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Moved',
    type: 'object',
  };
  const message = {
    event: 'Product Moved',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Moved',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When user removes an item from their cart
 *
 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(
  props: ProductRemoved,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user removes an item from their cart',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          activator: {
            description:
              'Human-readable description of activator, eg Menu:More Info or Button:Save Now or Mid-page banner image',
            id: '/properties/properties/properties/activator',
            type: 'string',
          },
          additional_data: {
            description:
              'May be used to nest additional site-specific properties not covered by this library. These will be noted in your project plan.',
            id: '/properties/properties/properties/additional_data',
            type: 'object',
          },
          explainer: {
            description: 'Explain why this is being tracked.',
            id: '/properties/properties/properties/explainer',
            type: 'string',
          },
          product: {
            description: 'A ProductItem object. See Products library.',
            id: '/properties/properties/properties/product',
            type: 'object',
          },
        },
        required: ['activator', 'product'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Removed',
    type: 'object',
  };
  const message = {
    event: 'Product Removed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Product Removed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when Provider claims their Profile for the first time.
 *
 * @param {ProfileClaimed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileClaimed(
  props?: ProfileClaimed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fires when Provider claims their Profile for the first time.',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Claimed',
    type: 'object',
  };
  const message = {
    event: 'Profile Claimed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Claimed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when a Profile is published for public viewing.
 *
 * @param {ProfileCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileCompleted(
  props?: ProfileCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fires when a Profile is published for public viewing.',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          '': {
            description: '',
            id: '/properties/properties/properties/',
            type: 'object',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider_profile: {
            description: '',
            id: '/properties/properties/properties/provider_profile',
            properties: {
              business_hours: {
                description:
                  'fields related to provider business hours set in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/business_hours',
                properties: {
                  num_days_closed: {
                    description:
                      'num_days_closednumber of days that the location is closed (ie has been marked as closed)',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'number of days that the location is open (ie has open and close times)',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'number of days of the week that have been set to either have an open and close time, or marked as closed',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_set',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              consultation_settings: {
                description:
                  'fields related to consultation request settings in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/consultation_settings',
                properties: {
                  cost: {
                    description: 'consultation fee in US CENTS',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/cost',
                    type: 'integer',
                  },
                  hide_form: {
                    description:
                      'whether or not the consultation request form should be hidden in provider directory',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/hide_form',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'whether or not the consultation fee counts towards the cost of treatment',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  send_requests_to_email: {
                    description:
                      'email address that consultation requests are sent to',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/send_requests_to_email',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              contact_information: {
                description:
                  'fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.',
                id: '/properties/properties/properties/provider_profile/properties/contact_information',
                properties: {
                  email_address: {
                    description: 'provider contact information email address',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/email_address',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'provider contact information facebook url',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_handle: {
                    description:
                      'provider contact information instagram handle (eg @handle)',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/instagram_handle',
                    type: 'string',
                  },
                  phone_number: {
                    description:
                      'provider contact information phone number. 10 digitis, no punctuation',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/phone_number',
                    type: 'string',
                  },
                  tiktok_handle: {
                    description:
                      'provider contact information tiktok handle (eg @handle)',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/tiktok_handle',
                    type: 'string',
                  },
                  website_url: {
                    description: 'provider contact information website url',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/website_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              gallery_photos: {
                description:
                  'fields related to gallery photos uploaded in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/gallery_photos',
                properties: {
                  num_gallery_photos: {
                    description: 'total number of gallery_photos',
                    id: '/properties/properties/properties/provider_profile/properties/gallery_photos/properties/num_gallery_photos',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              products: {
                description:
                  'fields related to provider products selected in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/products',
                properties: {
                  num_products: {
                    description:
                      'total number of products selected in profile manager',
                    id: '/properties/properties/properties/provider_profile/properties/products/properties/num_products',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Completed',
    type: 'object',
  };
  const message = {
    event: 'Profile Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Completed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires from back end when user successfully submits a NEW profile.
 *
 * @param {ProfileCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileCreated(
  props?: ProfileCreated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires from back end when user successfully submits a NEW profile.',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Created',
    type: 'object',
  };
  const message = {
    event: 'Profile Created',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Created',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires from back end when changes to a profile fail for any reason..
 *
 * @param {ProfileFailed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileFailed(
  props?: ProfileFailed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires from back end when changes to a profile fail for any reason..',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          reason: {
            description:
              'Reason the Fail happened. Include any event codes, if known, in [brackets]  for easier parsing.',
            id: '/properties/properties/properties/reason',
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Failed',
    type: 'object',
  };
  const message = {
    event: 'Profile Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Failed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when a Profile is published for public viewing.
 *
 * @param {ProfilePublished} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profilePublished(
  props?: ProfilePublished,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fires when a Profile is published for public viewing.',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          '': {
            description: '',
            id: '/properties/properties/properties/',
            type: 'object',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider_profile: {
            description: '',
            id: '/properties/properties/properties/provider_profile',
            properties: {
              business_hours: {
                description:
                  'fields related to provider business hours set in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/business_hours',
                properties: {
                  num_days_closed: {
                    description:
                      'num_days_closednumber of days that the location is closed (ie has been marked as closed)',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'number of days that the location is open (ie has open and close times)',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'number of days of the week that have been set to either have an open and close time, or marked as closed',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_set',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              consultation_settings: {
                description:
                  'fields related to consultation request settings in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/consultation_settings',
                properties: {
                  cost: {
                    description: 'consultation fee in US CENTS',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/cost',
                    type: 'integer',
                  },
                  hide_form: {
                    description:
                      'whether or not the consultation request form should be hidden in provider directory',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/hide_form',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'whether or not the consultation fee counts towards the cost of treatment',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  send_requests_to_email: {
                    description:
                      'email address that consultation requests are sent to',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/send_requests_to_email',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              contact_information: {
                description:
                  'fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.',
                id: '/properties/properties/properties/provider_profile/properties/contact_information',
                properties: {
                  email_address: {
                    description: 'provider contact information email address',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/email_address',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'provider contact information facebook url',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_handle: {
                    description:
                      'provider contact information instagram handle (eg @handle)',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/instagram_handle',
                    type: 'string',
                  },
                  phone_number: {
                    description:
                      'provider contact information phone number. 10 digitis, no punctuation',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/phone_number',
                    type: 'string',
                  },
                  tiktok_handle: {
                    description:
                      'provider contact information tiktok handle (eg @handle)',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/tiktok_handle',
                    type: 'string',
                  },
                  website_url: {
                    description: 'provider contact information website url',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/website_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              gallery_photos: {
                description:
                  'fields related to gallery photos uploaded in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/gallery_photos',
                properties: {
                  num_gallery_photos: {
                    description: 'total number of gallery_photos',
                    id: '/properties/properties/properties/provider_profile/properties/gallery_photos/properties/num_gallery_photos',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              products: {
                description:
                  'fields related to provider products selected in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/products',
                properties: {
                  num_products: {
                    description:
                      'total number of products selected in profile manager',
                    id: '/properties/properties/properties/provider_profile/properties/products/properties/num_products',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Published',
    type: 'object',
  };
  const message = {
    event: 'Profile Published',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Published',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when a profile is permanently deleted or otherwise made unavailable. This implies permanent action as opposed to a simple Unpublish.
 *
 * @param {ProfileRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileRemoved(
  props?: ProfileRemoved,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires when a profile is permanently deleted or otherwise made unavailable. This implies permanent action as opposed to a simple Unpublish.',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'legacy shipTo id. Will be deprecated soon, we think.',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Removed',
    type: 'object',
  };
  const message = {
    event: 'Profile Removed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Removed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires from Front End when user is submitting or updating a Profile.
 *
 * @param {ProfileSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileSubmitted(
  props?: ProfileSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires from Front End when user is submitting or updating a Profile.',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider_profile: {
            description: '',
            id: '/properties/properties/properties/provider_profile',
            properties: {
              business_hours: {
                description:
                  'fields related to provider business hours set in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/business_hours',
                properties: {
                  num_days_closed: {
                    description:
                      'num_days_closednumber of days that the location is closed (ie has been marked as closed)',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'number of days that the location is open (ie has open and close times)',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'number of days of the week that have been set to either have an open and close time, or marked as closed',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_set',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              consultation_settings: {
                description:
                  'fields related to consultation request settings in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/consultation_settings',
                properties: {
                  cost: {
                    description: 'consultation fee in US CENTS',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/cost',
                    type: 'integer',
                  },
                  hide_form: {
                    description:
                      'whether or not the consultation request form should be hidden in provider directory',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/hide_form',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'whether or not the consultation fee counts towards the cost of treatment',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  send_requests_to_email: {
                    description:
                      'email address that consultation requests are sent to',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/send_requests_to_email',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              contact_information: {
                description:
                  'fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.',
                id: '/properties/properties/properties/provider_profile/properties/contact_information',
                properties: {
                  email_address: {
                    description: 'provider contact information email address',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/email_address',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'provider contact information facebook url',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_handle: {
                    description:
                      'provider contact information instagram handle (eg @handle)',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/instagram_handle',
                    type: 'string',
                  },
                  phone_number: {
                    description:
                      'provider contact information phone number. 10 digitis, no punctuation',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/phone_number',
                    type: 'string',
                  },
                  tiktok_handle: {
                    description:
                      'provider contact information tiktok handle (eg @handle)',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/tiktok_handle',
                    type: 'string',
                  },
                  website_url: {
                    description: 'provider contact information website url',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/website_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              gallery_photos: {
                description:
                  'fields related to gallery photos uploaded in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/gallery_photos',
                properties: {
                  num_gallery_photos: {
                    description: 'total number of gallery_photos',
                    id: '/properties/properties/properties/provider_profile/properties/gallery_photos/properties/num_gallery_photos',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              products: {
                description:
                  'fields related to provider products selected in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/products',
                properties: {
                  num_products: {
                    description:
                      'total number of products selected in profile manager',
                    id: '/properties/properties/properties/provider_profile/properties/products/properties/num_products',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Submitted',
    type: 'object',
  };
  const message = {
    event: 'Profile Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Submitted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when a profile is temporarily unpublished from public viewing.
 *
 * @param {ProfileUnpublished} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileUnpublished(
  props?: ProfileUnpublished,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires when a profile is temporarily unpublished from public viewing.',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Unpublished',
    type: 'object',
  };
  const message = {
    event: 'Profile Unpublished',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Unpublished',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires from back end when user successfully submits an update to an existing profile.
 *
 * @param {ProfileUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileUpdated(
  props?: ProfileUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires from back end when user successfully submits an update to an existing profile.',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider_profile: {
            description: '',
            id: '/properties/properties/properties/provider_profile',
            properties: {
              business_hours: {
                description:
                  'fields related to provider business hours set in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/business_hours',
                properties: {
                  num_days_closed: {
                    description:
                      'num_days_closednumber of days that the location is closed (ie has been marked as closed)',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_closed',
                    type: 'integer',
                  },
                  num_days_open: {
                    description:
                      'number of days that the location is open (ie has open and close times)',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_open',
                    type: 'integer',
                  },
                  num_days_set: {
                    description:
                      'number of days of the week that have been set to either have an open and close time, or marked as closed',
                    id: '/properties/properties/properties/provider_profile/properties/business_hours/properties/num_days_set',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              consultation_settings: {
                description:
                  'fields related to consultation request settings in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/consultation_settings',
                properties: {
                  cost: {
                    description: 'consultation fee in US CENTS',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/cost',
                    type: 'integer',
                  },
                  hide_form: {
                    description:
                      'whether or not the consultation request form should be hidden in provider directory',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/hide_form',
                    type: 'boolean',
                  },
                  is_fee_towards_treatment_cost: {
                    description:
                      'whether or not the consultation fee counts towards the cost of treatment',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/is_fee_towards_treatment_cost',
                    type: 'boolean',
                  },
                  send_requests_to_email: {
                    description:
                      'email address that consultation requests are sent to',
                    id: '/properties/properties/properties/provider_profile/properties/consultation_settings/properties/send_requests_to_email',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              contact_information: {
                description:
                  'fields related to provider contact information set in profile manager. IMPORTANT: This may be the same as or differ from the actual contact info as set in the Provider object.',
                id: '/properties/properties/properties/provider_profile/properties/contact_information',
                properties: {
                  email_address: {
                    description: 'provider contact information email address',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/email_address',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'provider contact information facebook url',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_handle: {
                    description:
                      'provider contact information instagram handle (eg @handle)',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/instagram_handle',
                    type: 'string',
                  },
                  phone_number: {
                    description:
                      'provider contact information phone number. 10 digitis, no punctuation',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/phone_number',
                    type: 'string',
                  },
                  tiktok_handle: {
                    description:
                      'provider contact information tiktok handle (eg @handle)',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/tiktok_handle',
                    type: 'string',
                  },
                  website_url: {
                    description: 'provider contact information website url',
                    id: '/properties/properties/properties/provider_profile/properties/contact_information/properties/website_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              gallery_photos: {
                description:
                  'fields related to gallery photos uploaded in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/gallery_photos',
                properties: {
                  num_gallery_photos: {
                    description: 'total number of gallery_photos',
                    id: '/properties/properties/properties/provider_profile/properties/gallery_photos/properties/num_gallery_photos',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
              products: {
                description:
                  'fields related to provider products selected in profile manager',
                id: '/properties/properties/properties/provider_profile/properties/products',
                properties: {
                  num_products: {
                    description:
                      'total number of products selected in profile manager',
                    id: '/properties/properties/properties/provider_profile/properties/products/properties/num_products',
                    type: 'integer',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Updated',
    type: 'object',
  };
  const message = {
    event: 'Profile Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Updated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires when Provider views their Profile. (Use the User object to determine if someone else is looking at the Profile page).
 *
 * @param {ProfileViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileViewed(
  props?: ProfileViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires when Provider views their Profile. (Use the User object to determine if someone else is looking at the Profile page).',
    labels: {
      provider: 'profile',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Viewed',
    type: 'object',
  };
  const message = {
    event: 'Profile Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * BE event indicated the Registration Completed was successful.
 *
 * @param {RegistrationCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationCompleted(
  props?: RegistrationCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'BE event indicated the Registration Completed was successful.',
    labels: {
      provider: 'registration',
      usertype: 'provider',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Registration Completed',
    type: 'object',
  };
  const message = {
    event: 'Registration Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Registration Completed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * indicate when someone has successfully started registration for Allē for Business . we’d want to measure how many of these businesses have started this task through our onboarding marketing efforts.
 *
 * @param {RegistrationStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationStarted(
  props?: RegistrationStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'indicate when someone has successfully started registration for Allē for Business . we’d want to measure how many of these businesses have started this task through our onboarding marketing efforts.',
    labels: {
      provider: 'registration',
      usertype: 'provider',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description: 'id of business',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description: 'physical location id',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'legacy shipTo id. Will be deprecated soon, we think.',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          registration_type: {
            description: '',
            id: '/properties/properties/properties/registration_type',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Registration Started',
    type: 'object',
  };
  const message = {
    event: 'Registration Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Registration Started',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fire on each page load during Registration to measure dropoff
 *
 * @param {RegistrationStep} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationStep(
  props?: RegistrationStep,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fire on each page load during Registration to measure dropoff',
    labels: {
      provider: 'registration',
      usertype: 'provider',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Registration Step',
    type: 'object',
  };
  const message = {
    event: 'Registration Step',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Registration Step',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires from FE when Provider hits submit to send their data to the Registration server.
 *
 * @param {RegistrationSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationSubmitted(
  props?: RegistrationSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Fires from FE when Provider hits submit to send their data to the Registration server.',
    labels: {
      provider: 'registration',
      usertype: 'provider',
    },
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  'Business reason, in human terms, this event was fired. Example "Tracking signup dropoffs" or "Tracking if users played video"',
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered ",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  "Tracks libraries used to generate this Event. This data is used to filter out cached data, 'zombie' users, and to detect fraud.",
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {
                  ASTEvent: {
                    description:
                      'Version of ASTEvent used to instrument this page, if applicable.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTEvent',
                    type: 'string',
                  },
                  ASTLib: {
                    description:
                      'Version of current ASTLib. Used to filter out zombie users and cached data.',
                    id: '/properties/properties/properties/event/properties/libraries/properties/ASTLib',
                    type: 'string',
                  },
                  site: {
                    description:
                      'Version/build number of the site/app/page hosting this Event.  If not specified we will assume the build that existed when the Event happened. This is important for debugging data issues and data funnelling, which may happen months or even years after the event,',
                    id: '/properties/properties/properties/event/properties/libraries/properties/site',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Registration Submitted',
    type: 'object',
  };
  const message = {
    event: 'Registration Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Registration Submitted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'Typewriter Test Event' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function typewriterTestEvent(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    labels: {},
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Typewriter Test Event',
    type: 'object',
  };
  const message = {
    event: 'Typewriter Test Event',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Typewriter Test Event',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * User manually downsloads an app
   *
   * @param {AppDownloaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  appDownloaded,
  /**
   * When user completely clears their cart. Not currently in use, resrved for future use.
   *
   * @param {CartCleared} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cartCleared,
  /**
   * When user applies a filter to cart items
   *
   * @param {CartFiltered} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cartFiltered,
  /**
   * When user views their cart
   *
   * @param {CartViewed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cartViewed,
  /**
   * When the user explicitly cancels the checkout process. See also Checkout Failed which is when the server stops it.
   *
   * @param {CheckoutCancelled} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutCancelled,
  /**
   * If the user successfully makes a purchase. This should be sent from the server or upon entering the thank you page, if any.
   *
   * @param {CheckoutCompleted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutCompleted,
  /**
   * If the users purchase is rejected for any reason
   *
   * @param {CheckoutFailed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutFailed,
  /**
   * When the user begins the checkout process, eg the Checkout Now button
   *
   * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutStarted,
  /**
   * When the user advances to a new step of the checkout process (e.g. shipping, billing etc)
   *
   * @param {CheckoutStep} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutStep,
  /**
   * Fires when the user submits checkout data to the server. Can still be rejected or failed by server.
   *
   * @param {CheckoutSubmitted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutSubmitted,
  /**
   * When user modifies checkout criteria. Not to be confused with Cart updated.
   *
   * @param {CheckoutUpdated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutUpdated,
  /**
   * Rare. Fires when user (typically as an admin or provider) begins to edit content bu thas not submitted it yet.
   *
   * @param {ContentEdited} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentEdited,
  /**
   * Fires when user is shown an infobox, etc but we don't necessarily know they have seen it.
   *
   * @param {ContentPresented} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentPresented,
  /**
   * Fires when user is shown an infobox, etc but we don't necessarily know they have seen it.
   *
   * @param {ContentScrolled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentScrolled,
  /**
   * When user (in this case usually a provider) submits a change to existing content on the site.
   *
   * @param {ContentSubmitted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentSubmitted,
  /**
   * Fires when we know a user has seen an infobox. Typically this is done via a dismissal button, or calculation based on page scrolling.
   *
   * @param {ContentViewed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentViewed,
  /**
   * When user initiates a download action from another site, such as the app store. Used when the actual download process is s a black box to us.
   *
   * @param {DownloadInitiated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  downloadInitiated,
  /**
   * SHOULD ONLY BE USED ON INTERACTIVE GRAPHICS E.G. ON COOLSCULTPING. If you are using this for ANYTHING ELSE, contact Pete McBride, you are doing something wrong....
   *
   * @param {ElementEngaged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  elementEngaged,
  /**
   * Fires a 'Flash Added' track call.
   *
   * @param {FlashAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  flashAdded,
  /**
   * Fires a 'Flash Removed' track call.
   *
   * @param {FlashRemoved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  flashRemoved,
  /**
   * User manually downsloads info, such as a PDF.
   *
   * @param {InfoDownloaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  infoDownloaded,
  /**
   * User requests info from an external source
   *
   * @param {InfoRequested} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  infoRequested,
  /**
   * SERVER (usually). Fires when user successfully  gets their Info Request
   *
   * @param {InfoSent} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  infoSent,
  /**
   * Used for newsletters etc where info will be regularly sent to user
   *
   * @param {InfoSubscribed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  infoSubscribed,
  /**
   * Fires a 'Info Unsubscribed' track call.
   *
   * @param {InfoUnsubscribed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  infoUnsubscribed,
  /**
   * indicate when a provider user is in need of requesting a password change; we want to see how often this happens for optimization reasons
   *
   * @param {PasswordForgotten} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  passwordForgotten,
  /**
   * indicate when a provider user successfully has updated their password; we want to see what % of consumers who start this flow, actually complete it successfully.
   *
   * @param {PasswordUpdated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  passwordUpdated,
  /**
   * When user adds an item to their cart from any source.
   *
   * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productAdded,
  /**
   * When user moves an item from their cart. To Wishlist is implied.
   *
   * @param {ProductMoved} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productMoved,
  /**
   * When user removes an item from their cart
   *
   * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productRemoved,
  /**
   * Fires when Provider claims their Profile for the first time.
   *
   * @param {ProfileClaimed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileClaimed,
  /**
   * Fires when a Profile is published for public viewing.
   *
   * @param {ProfileCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileCompleted,
  /**
   * Fires from back end when user successfully submits a NEW profile.
   *
   * @param {ProfileCreated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileCreated,
  /**
   * Fires from back end when changes to a profile fail for any reason..
   *
   * @param {ProfileFailed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileFailed,
  /**
   * Fires when a Profile is published for public viewing.
   *
   * @param {ProfilePublished} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profilePublished,
  /**
   * Fires when a profile is permanently deleted or otherwise made unavailable. This implies permanent action as opposed to a simple Unpublish.
   *
   * @param {ProfileRemoved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileRemoved,
  /**
   * Fires from Front End when user is submitting or updating a Profile.
   *
   * @param {ProfileSubmitted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileSubmitted,
  /**
   * Fires when a profile is temporarily unpublished from public viewing.
   *
   * @param {ProfileUnpublished} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileUnpublished,
  /**
   * Fires from back end when user successfully submits an update to an existing profile.
   *
   * @param {ProfileUpdated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileUpdated,
  /**
   * Fires when Provider views their Profile. (Use the User object to determine if someone else is looking at the Profile page).
   *
   * @param {ProfileViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileViewed,
  /**
   * BE event indicated the Registration Completed was successful.
   *
   * @param {RegistrationCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  registrationCompleted,
  /**
   * indicate when someone has successfully started registration for Allē for Business . we’d want to measure how many of these businesses have started this task through our onboarding marketing efforts.
   *
   * @param {RegistrationStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  registrationStarted,
  /**
   * Fire on each page load during Registration to measure dropoff
   *
   * @param {RegistrationStep} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  registrationStep,
  /**
   * Fires from FE when Provider hits submit to send their data to the Registration server.
   *
   * @param {RegistrationSubmitted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  registrationSubmitted,
  /**
   * Fires a 'Typewriter Test Event' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  typewriterTestEvent,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method
      )}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/allergan-dev/protocols/tracking-plans/rs_1hL0c6wYMxMwtMpc8DGjMqvezjq`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext()
        );
      }
    };
  },
});
