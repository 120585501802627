import SplitIO from '@splitsoftware/splitio/types/splitio';

const FEATURE_PREFIX = 'ff_';
const ATTRIBUTE_PREFIX = 'fa_';

const createFeaturesMap = (params: string): SplitIO.TreatmentsWithConfig => {
  const entries = new URLSearchParams(params) as any;
  return Array.from(entries.entries()).reduce((acc: any, [key, value]: any) => {
    if (!key.startsWith(FEATURE_PREFIX)) {
      return { ...acc };
    }

    return {
      ...acc,
      [key.slice(3)]: { treatment: value },
    };
  }, {}) as SplitIO.TreatmentsWithConfig;
};

const createAttributeMap = (params: string): SplitIO.Attributes => {
  const entries = new URLSearchParams(params) as any;

  return Array.from(entries.entries()).reduce((acc: any, [key, value]: any) => {
    if (!key.startsWith(ATTRIBUTE_PREFIX)) {
      return { ...acc };
    }

    return {
      ...acc,
      [key.slice(3)]: value,
    };
  }, {}) as SplitIO.Attributes;
};
export { createFeaturesMap, createAttributeMap };
