import { Machine, assign, MachineConfig, ActionFunctionMap } from 'xstate';
import { ValidationError } from 'yup';
import { AlleValidationError } from '@allergan-data-labs/shared-sdk/src/errors/alleValidationError';
import { AlleGeneralError } from '@allergan-data-labs/shared-sdk/src/errors/alleGeneralError';
import {
  FormEvents,
  FormStates,
  FormActions,
  FormServices,
  FormMachineSchema,
  FormMachineContext,
  FormMachineEvents,
} from './formMachineTypes';

interface StateMachineConfig<T> {
  schema: any;
  initialContext: any;
  name: string;
  onSubmit: (fields: T) => Promise<any>;
}

function createStateMachine<TFields>(config: StateMachineConfig<TFields>) {
  type TContext = FormMachineContext<TFields>;
  type TEvents = FormMachineEvents<TFields>;

  function getValidationErrors(data: TFields): ValidationError {
    try {
      config.schema.validateSync(data, { abortEarly: false });
      throw new Error('this should not be reachable');
    } catch (e) {
      // @ts-ignore
      return e;
    }
  }

  /**
   * this function will always reject in order to prevent from going
   * into the next state status
   */
  const handleOnBlur = (context: TContext): Promise<never> => {
    const { fields, validated = [], currentField } = context;
    const currentValidated = [
      ...validated,
      ...(currentField && !validated.includes(currentField)
        ? [currentField]
        : []),
    ];

    if (!config.schema.isValidSync(fields)) {
      const validationError = getValidationErrors(fields);

      // only validate a single field
      if (currentField) {
        return Promise.reject({
          fields,
          validated: currentValidated.filter((item) => item),
          validationError: {
            ...validationError,
            inner: validationError.inner.filter((item) =>
              currentValidated.includes(item.path)
            ),
          },
        });
      }
    }

    // @ts-ignore FIXME
    return Promise.reject({ fields, validated: Object.keys(fields) });
  };

  const handleHardSubmit = (context: TContext) => {
    if (!config.schema.isValidSync(context.fields)) {
      const validationError = getValidationErrors(context.fields);

      return Promise.reject({
        validationError,
        fields: context.fields,

        // @ts-ignore FIXME
        validated: Object.keys(context.fields),
      });
    }

    return Promise.resolve({
      fields: context.fields,

      // @ts-ignore FIXME
      validated: Object.keys(context.fields),
    });
  };

  const machineServices = {
    [FormServices.validate_fields]: (context: TContext): Promise<any> => {
      if (context.currentField === 'submit') {
        return handleHardSubmit(context);
      }

      return handleOnBlur(context);
    },
    [FormServices.submit_request]: (context: TContext): Promise<any> =>
      config.onSubmit(context.fields),
  };

  const machineActions: ActionFunctionMap<TContext, TEvents> = {
    [FormActions.idle_submit]: assign<TContext, TEvents>({
      fields: (_, event) => event.fields,
      currentField: (_, event) => event.currentField,
    }),
    [FormActions.validation_error]: assign<TContext, TEvents>({
      currentField: (_, event) => event.currentField,
      validationError: (_, event) => event.data && event.data.validationError,
      validated: (_, event) => event.data && event.data.validated,
      fields: (_, event) =>
        event.data ? event.data.fields : config.initialContext,
    }),
    [FormActions.validation_success]: assign<TContext, TEvents>({
      validated: (_, event) => event.data && event.data.validated,
      validationError: () => undefined,
      fields: (_, event) =>
        event.data ? event.data.fields : config.initialContext,
    }),
    [FormActions.validation_error_resubmit]: assign<TContext, TEvents>({
      currentField: (_, event) => event.currentField,
      fields: (_, event) => event.fields,
    }),
    [FormActions.validation_success_resubmit]: assign<TContext, TEvents>({
      currentField: (_, event) => event.currentField,
      fields: (_, event) => event.fields,
    }),
    [FormActions.server_success]: assign<TContext, TEvents>({
      serverSuccess: (_, event) => {
        return event.data ? event.data.message : 'Success';
      },
    }),
    [FormActions.reset_form]: assign<TContext, TEvents>({
      fields: () => config.initialContext,
      currentField: () => undefined,
      validationError: () => undefined,
      validated: () => [],
      serverError: () => undefined,
      serverSuccess: () => undefined,
    }),
    [FormActions.server_error]: assign<TContext, TEvents>({
      validationError: (_, event) => {
        if (event.data instanceof AlleValidationError) {
          if (
            event.data.validationErrors &&
            event.data.validationErrors.length
          ) {
            return {
              errors: [],
              inner: event.data.validationErrors.map((error: any) => ({
                path: error.fieldName,
                message: error.message,
                name: 'ValidationError',
                value: {},
                type: '',
                inner: [],
                errors: [],
              })),
              message: '',
              name: 'ValidationError',
              path: '',
              value: {},
              type: '',
            };
          }
          return undefined;
        } else if (event.data instanceof AlleGeneralError) {
          return undefined;
        } else {
          return undefined;
        }
      },
      serverError: (_, event) => {
        if (event.data instanceof AlleValidationError) {
          if (
            event.data &&
            event.data.validationErrors &&
            event.data.validationErrors.length
          ) {
            // No need to display server error if validation errors are present.
            return undefined;
          }
          return event.data.message;
        } else if (event.data instanceof AlleGeneralError) {
          return event.data.message;
        } else {
          return (
            (event.data && event.data.message) ||
            'An internal server error has occurred'
          );
        }
      },
    }),
    [FormActions.request_retry]: assign<TContext, TEvents>({
      validationError: () => undefined,
      serverError: () => undefined,
      serverSuccess: () => undefined,
      currentField: (_, event) => event.currentField,
      fields: (_, event) => event.fields,
    }),
  };

  const machine: MachineConfig<TContext, FormMachineSchema, TEvents> = {
    id: config.name,
    initial: FormStates.idle,
    states: {
      [FormStates.idle]: {
        on: {
          [FormEvents.IDLE_SUBMIT]: {
            target: [FormStates.validation],
            actions: FormActions.idle_submit,
          },
          [FormEvents.FORCE_ERROR]: {
            target: FormStates.server_error,
            actions: FormActions.server_error,
          },
        },
      },
      [FormStates.validation]: {
        invoke: {
          id: 'validate_input',
          src: FormServices.validate_fields,
          onDone: {
            target: FormStates.validation_success,
            actions: FormActions.validation_success,
          },
          onError: {
            target: FormStates.validation_error,
            actions: FormActions.validation_error,
          },
        },
      },
      [FormStates.validation_error]: {
        on: {
          [FormEvents.VALIDATION_ERROR_RESUBMIT]: {
            target: FormStates.validation,
            actions: FormActions.validation_error_resubmit,
          },
          [FormEvents.RESET_FORM]: {
            target: FormStates.idle,
            actions: [FormActions.reset_form],
          },
        },
      },
      [FormStates.validation_success]: {
        invoke: {
          id: 'submit_request',
          src: FormServices.submit_request,
          onDone: {
            target: [FormStates.server_success],
            actions: [FormActions.server_success],
          },
          onError: {
            target: [FormStates.server_error],
            actions: [FormActions.server_error],
          },
        },
      },
      [FormStates.server_success]: {
        on: {
          [FormEvents.RESET_FORM]: {
            target: FormStates.idle,
            actions: [FormActions.reset_form],
          },
        },
      },
      [FormStates.server_error]: {
        on: {
          [FormEvents.REQUEST_RETRY]: {
            target: FormStates.validation,
            actions: [FormActions.request_retry],
          },
          [FormEvents.RESET_FORM]: {
            target: FormStates.idle,
            actions: [FormActions.reset_form],
          },
        },
      },
    },
  };

  const finalMachine = Machine(machine)
    .withContext(config.initialContext)
    .withConfig({
      services: machineServices,
      actions: machineActions,
    });
  return finalMachine;
}

export { createStateMachine };
