import React from 'react';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';

// shared components
import { GlobalStyle } from '@allergan-data-labs/component-library/src/core/globalStyle';
import { useClearAllNotifications } from '@allergan-data-labs/component-library/src/hooks/useClearAllNotifications';
import { useDemoNotification } from '@allergan-data-labs/component-library/src/hooks/useDemoNotification';
import { useMaintenanceNotification } from '@allergan-data-labs/component-library/src/hooks/useMaintenanceNotification';
import { useRouteInactivity } from '@allergan-data-labs/component-library/src/hooks/useRouteInactivity';
import { useScrollToTop } from '@allergan-data-labs/component-library/src/hooks/useScrollToTop';
import { shared } from '@allergan-data-labs/split-feature-flag/src/shared';
import { useAlleTreatments } from '@allergan-data-labs/split-feature-flag/src/useAlleTreatments';

// components
import { useAuth } from './auth/AuthProvider';

// constants
import { inactivityTimer } from './application.config';
import { Routes } from './constants/routes';
import { useSegmentPageTracking } from './hooks/useSegmentPageTracking';
import { AuthState } from './auth/authMachine';
import { __APP_VERSION__ } from './application.config';

const App: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  React.useEffect(() => {
    console.log('App version: ', __APP_VERSION__);
  }, []);

  const history = useHistory();
  const { authState } = useAuth();
  useScrollToTop();
  useClearAllNotifications();
  useSegmentPageTracking();
  useRouteInactivity(() => {
    if (authState.matches(AuthState.isLoggedIn)) {
      history.push(Routes.logout);
    }
  }, inactivityTimer.session);
  useDemoNotification();
  const splitTreatments = useAlleTreatments({
    splitNames: [shared.maintenanceNotificationBanner],
  });
  const isMaintenanceEnabled =
    splitTreatments[shared.maintenanceNotificationBanner]?.treatment === 'on';
  const bannerCopy = splitTreatments[shared.maintenanceNotificationBanner]
    ?.config
    ? JSON.parse(
        splitTreatments[shared.maintenanceNotificationBanner].config as string
      ).bannerCopy
    : '';
  useMaintenanceNotification({
    cookieName: 'admin-scheduled-maintenance',
    isMaintenanceEnabled,
    bannerCopy,
  });

  return (
    <>
      <Helmet title="Allē" />
      <GlobalStyle />
      {children}
    </>
  );
};

export { App };
