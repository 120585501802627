import React from 'react';

const pluckRefValues = (
  refs: Array<React.RefObject<HTMLInputElement>>
): Array<string> => {
  return refs.map(
    (ref: React.RefObject<HTMLInputElement>) =>
      (ref && ref.current && ref.current.value) || ''
  );
};

export { pluckRefValues };
