import React from 'react';
import { Flex, Box, Text, forwardRef } from '@chakra-ui/react';
import {
  Button,
  IconButton,
  ButtonGroup,
  ActionMenu,
  ActionMenuContent,
  IconButtonProps,
  getActionContentStyles,
} from '@allergan-data-labs/alle-elements';
import { alleColors as colors } from '@allergan-data-labs/component-library/src/colorPalette';
import { OverflowIcon } from '@allergan-data-labs/alle-elements-icons/16/overflowIcon';

export interface SectionHeaderProps {
  title: string;
  description?: string;
  actionName?: string;
  onAction?: () => void;
  secondaryActionName?: string;
  onSecondaryAction?: () => void;
}

const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({
  title,
  description,
  actionName,
  onAction = () => {},
  secondaryActionName,
  onSecondaryAction = () => {},
}) => {
  const MenuIconButton = forwardRef<IconButtonProps, 'button'>((props, ref) => (
    <IconButton
      data-testid="section-header-menu-button"
      icon={<OverflowIcon color={colors.black} />}
      ref={ref}
      size="sm"
      variant="solid"
      colorMode="light"
      colorScheme="muted"
      {...props}
    />
  ));

  return (
    <Box
      paddingTop={24}
      paddingBottom={16}
      margin="0"
      data-testid="section-header-container"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text
            textStyle="Headline/Small/Primary medium"
            data-testid="section-header-title-label"
          >
            {title}
          </Text>
          {description && (
            <Text
              textStyle="Body/Medium/Regular"
              color="light.Text/Neutral/Support"
              data-testid="section-header-title-description"
            >
              {description}
            </Text>
          )}
        </Box>
        <Box>
          <ButtonGroup
            orientation="horizontal"
            colorMode="light"
            colorScheme="brand"
            variant="solid"
          >
            {actionName ? (
              <Button
                textStyle="Body/Small/Regular"
                colorScheme="muted"
                size="sm"
                color="light.Text/Neutral/Interactive/Enable"
                onClick={onAction}
                data-testid="section-header-action-button"
              >
                {actionName}
              </Button>
            ) : null}
            {secondaryActionName ? (
              <ActionMenu triggerElement={MenuIconButton}>
                <ActionMenuContent
                  sx={{
                    ...getActionContentStyles('light', false),
                    '&': {
                      padding: '0',
                      minWidth: '100%',
                      maxHeight: 'none',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    },
                  }}
                >
                  <Button
                    textStyle="Body/Small/Regular"
                    variant="ghost"
                    size="sm"
                    color="light.Text/Neutral/Interactive/Enable"
                    onClick={onSecondaryAction}
                    data-testid="section-header-secondary-action-button"
                  >
                    {secondaryActionName}
                  </Button>
                </ActionMenuContent>
              </ActionMenu>
            ) : null}
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
};

export { SectionHeader };
