import React from 'react';
import styled from 'styled-components';

import { H4 } from '../typography/typographyDEPRECATED';
import { colors } from '../colors';

const Container = styled.div`
  width: 49px;
  height: 49px;
  min-width: 49px;
  min-height: 49px;
  border-radius: 25px;
  background-color: ${colors.gray1};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Span = styled(H4).attrs({ as: 'span' })`
  color: ${colors.white};
  text-transform: uppercase;
`;

export interface AvatarProps {
  initials: string;
  className?: string;
}

const Avatar: React.FunctionComponent<AvatarProps> = ({
  initials,
  className,
}) => {
  return (
    <Container className={className}>
      <Span>{initials}</Span>
    </Container>
  );
};

export { Avatar };
