import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { useMachine } from '@xstate/react';
import { createStateMachine } from '@allergan-data-labs/universal-component-library/src/stateMachines/formMachine';

import { FormEvents } from '@allergan-data-labs/universal-component-library/src/stateMachines/formMachineTypes';
import { runValidation } from '@allergan-data-labs/component-library/src/stateMachines/utils';
import { pluckValidationErrors } from '@allergan-data-labs/universal-component-library/src/helpers/pluckValidationErrors';

export const optionsArray = [
  'Duplicate location',
  'Closed location',
  'Not familiar with location',
  'Other',
];

export const options = optionsArray.map((item) => ({
  value: item,
  label: item,
}));

export interface ArchiveLocationDialogProps {
  isOpen: boolean;
  locationId: string;
  onArchiveLocation: (args: {
    organizationId: string;
    archiveReason: string;
    reasonText?: string;
  }) => Promise<void>;
  onClose: () => void;
  isLoading: boolean;
}

export const ARCHIVE_CHOICE_VALIDATION_TXT = 'Please choose an archive reason.';
export const ARCHIVE_REASON_VALIDATION_TXT = 'Please type your archive reason.';
export const ARCHIVE_CONFIRMATION_VALIDATION_TXT =
  'Please type "ARCHIVE" to confirm.';

interface FormFields {
  organizationId: string;
  reasonText: string;
  archiveReason: string;
}

interface useFormMachineInput {
  onSubmit: (fields: FormFields) => Promise<void>;
  refs: {
    reasonTextInputRef: React.MutableRefObject<HTMLInputElement | null>;
    confirmationTextInputRef: React.MutableRefObject<HTMLInputElement | null>;
  };
  additionalFields: {
    organizationId: string;
    archiveReason: string;
  };
}

const useFormMachine = (input: useFormMachineInput) => {
  const machine = createStateMachine<FormFields>({
    name: 'archive_location_form',
    onSubmit: input.onSubmit,
    initialContext: {
      validationError: null,
      fields: {
        reasonText: undefined,
        archiveReason: undefined,
        confirmationText: undefined,
      },
    },
    schema: yup.object().shape({
      archiveReason: yup
        .string()
        .oneOf(optionsArray, ARCHIVE_CHOICE_VALIDATION_TXT),
      reasonText: yup.string().when('archiveReason', {
        is: 'Other',
        then: yup.string().required(ARCHIVE_REASON_VALIDATION_TXT),
      }),
      confirmationText: yup
        .string()
        .oneOf(['ARCHIVE'], ARCHIVE_CONFIRMATION_VALIDATION_TXT)
        .required(ARCHIVE_CONFIRMATION_VALIDATION_TXT),
    }),
  });

  const [current, send] = useMachine(machine);

  const validationErrors = pluckValidationErrors<FormFields>(current);

  const runMachine = (fieldName: string, archiveReason?: string) => () => {
    const refs = {
      reasonText: input.refs.reasonTextInputRef,
      confirmationText: input.refs.confirmationTextInputRef,
    };
    const currentState = current.value as string;
    runValidation(
      {
        refs,
        fieldName,
        currentState,
        additionalFields: {
          organizationId: input.additionalFields.organizationId,
          archiveReason: archiveReason
            ? archiveReason
            : input.additionalFields.archiveReason,
        },
      },
      send
    );
  };

  return { current, send, validationErrors, runMachine };
};

const useArchiveLocationDialog = ({
  locationId,
  onArchiveLocation,
  onClose,
}: Omit<ArchiveLocationDialogProps, 'isOpen' | 'isLoading'>) => {
  const reasonTextInputRef = useRef<HTMLInputElement>(null);
  const confirmationTextInputRef = useRef<HTMLInputElement>(null);
  const [radioValue, setRadioValue] = useState('');
  const archiveReasonRef = useRef(radioValue);

  const { current, runMachine, validationErrors, send } = useFormMachine({
    onSubmit: onArchiveLocation,
    refs: { reasonTextInputRef, confirmationTextInputRef },
    additionalFields: {
      organizationId: locationId,
      archiveReason: radioValue,
    },
  });

  const closeModal = () => {
    archiveReasonRef.current = '';
    setRadioValue('');
    send(FormEvents.RESET_FORM);
    onClose();
  };

  return {
    radioValue,
    confirmationTextInputRef,
    reasonTextInputRef,
    archiveReasonRef,
    current,
    runMachine,
    validationErrors,
    closeModal,
    setRadioValue,
  };
};

export { useArchiveLocationDialog };
