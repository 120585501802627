import React from 'react';
import { ProfileMenuGroup } from './profileMenuGroup';
import { UserRoleMockData, roleMenuItems } from '../../mocks/mockRoles';
import { Text } from '@chakra-ui/react';
import { ProfileMenuItem } from './profileMenuItem';
import { Link } from '@allergan-data-labs/alle-elements';

// TODO: Currently using UserRoleMockData for temporary mock implementation.
// Need to replace with final version once the actual UserRole data structure is defined and available.
interface ProfileRoleBasedMenuGroupProps {
  role: UserRoleMockData;
}

const ProfileRoleBasedMenuGroup: React.FC<ProfileRoleBasedMenuGroupProps> = ({
  role,
}) => {
  const menuItems = roleMenuItems[role] || [];

  return menuItems.length ? (
    <ProfileMenuGroup title="Internal Tools">
      {menuItems.map(({ name }, index) => (
        <ProfileMenuItem key={index} paddingLeft="12px">
          <Link
            href="#"
            isStandalone={true}
            sx={{
              _hover: { textDecoration: 'none' },
            }}
          >
            <Text
              color="light.Text/Neutral/Interactive/Enable"
              textStyle="Body/Small/Regular"
            >
              {name}
            </Text>
          </Link>
        </ProfileMenuItem>
      ))}
    </ProfileMenuGroup>
  ) : null;
};
export { ProfileRoleBasedMenuGroup };
