// Material
import { InputProps as MaterialInputProps } from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/CheckRounded';
import WarningIcon from '@material-ui/icons/WarningRounded';
import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
import { above } from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';
// Core
import { ThemeTypes } from '../../components/theme';
import { colors } from '../colors';
import {
  graphikFontFamily,
  heading5Style,
} from '../typography/typographyDEPRECATED';
import { typographyStyles } from '@allergan-data-labs/universal-component-library/src/atoms/typography';
import { alleColors } from '../../colorPalette';
import { InputLabel } from './inputLabel';

export interface InputProps extends ThemeTypes {
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  defaultValue?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  name?: string;
  margin?: 'none' | 'dense' | 'normal';
  fullWidth?: boolean;
  type?: string;
  color?: string;
  label: string;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  placeholder?: string;
  id: string;
  helperText?: string;
  error?: boolean;
  success?: boolean;
  pinnedLabel?: boolean;
  hide?: boolean;
  disabled?: boolean;
  inputProps?: {
    size?: number;
    min?: number;
    maxLength?: number;
    step?: string;
    inputMode?: string;
    'aria-describedby'?: string;
  };
  InputProps?: Partial<MaterialInputProps>;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  endAdornmentIcon?: React.ReactNode;
  startAdornmentIcon?: React.ReactNode;
  focusedBackgroundColor?: string;
  onFocus?: any;
  onBlur?: any;
  value?: string;
  onChange?: any;
  showAdornment?: boolean;
  showUnderline?: boolean;
  endClickableAdornment?: boolean;
  startClickableAdornment?: boolean;
  secondaryLabel?: string;
  tooltipMessage?: string;
  onEndAdornmentClick?: React.MouseEventHandler<HTMLDivElement>;
}

const StyledTextField = styled(
  ({
    focusedBackgroundColor,
    hide,
    error,
    success,
    showUnderline,
    dark,
    color,
    secondaryLabel,
    ...forwardProps
  }) => <TextField {...forwardProps} />
)`
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};

  && .MuiInputLabel-root {
    width: ${(props) => (props.secondaryLabel ? '100%' : 'auto')};
    z-index: 1;
    cursor: text;
    ${typographyStyles('caption-M-regular')}
    ${(props) => (props.dark ? `color: ${colors.white};` : '')}

    &.MuiInputLabel-shrink {
      transform: none;
    }
  }

  &.MuiTextField-root {
    margin: 0;
  }

  /* Make room for pinned label when present */
  label + .MuiInput-formControl {
    margin-top: 24px;
  }

  .MuiInput-input {
    padding: 10px 12px;
    font-family: ${graphikFontFamily};
    font-size: 16px;
    ${above.desktop`
      font-size: 15px;
    `}
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    height: 26px;
    letter-spacing: normal;
    color: ${(props) => (props.dark ? colors.white : colors.black)};

    &::placeholder {
      opacity: initial;
      color: ${(props) => props.color || colors.accent};
    }
    &::-ms-clear {
      display: none;
    }
  }

  .MuiFormHelperText-root {
    ${heading5Style}
    padding: 0 12px;
    margin-top: 8px;
  }

  .MuiInput-root {
    &.Mui-focused {
      border-radius: ${(props) => (props.dark ? '4px' : '0')};
      background-color: ${(props) =>
        props.dark
          ? colors.darkGray
          : props.focusedBackgroundColor || colors.gray300};
      &:before,
      &:after {
        /* If dark is true, force the border-bottom-color to be transparent while focused */
        ${(props) =>
          props.dark ? `border-bottom-color: transparent !important;` : ''}
      }
    }
  }
  .MuiInput-underline {
    &:before {
      border-bottom: 1px solid
        ${(props) =>
          props.success
            ? colors.positive
            : props.error
            ? colors.negative
            : colors.gray200};
      transition: none;
    }
    &:after {
      border-bottom: 1px solid
        ${(props) =>
          props.success
            ? colors.positive
            : props.error
            ? colors.negative
            : colors.gray300};
      transition: none;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid
        ${(props) =>
          props.success
            ? colors.positive
            : props.error
            ? colors.negative
            : colors.gray200};
    }
    &:before {
      /* If showUnderline is false, force the border-bottom-color to be transparent */
      ${(props) =>
        props.showUnderline
          ? ''
          : `border-bottom-color: transparent !important;`}
    }
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0;
    margin-right: 0;

    > .MuiSvgIcon-root {
      font-size: 20px;
    }

    .MuiIconButton-root:hover {
      background: transparent;
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }

  & .Mui-disabled {
    &.MuiInputBase-root {
      background-color: ${alleColors.gray[100]};
    }
    input {
      color: ${alleColors.gray[300]};
    }
  }
`;

const StyledInputAdornment = styled(
  ({ clickableAdornment, dark, ...forwardProps }) => (
    <InputAdornment {...forwardProps} />
  )
)<ThemeTypes>`
  pointer-events: ${({ clickableAdornment }) =>
    clickableAdornment ? 'auto' : 'none'};
  cursor: ${({ clickableAdornment }) =>
    clickableAdornment ? 'pointer' : 'text'};
  color: ${({ dark }) => (dark ? colors.white : colors.black)};
`;

/**
 * API - https://material-ui.com/api/text-field/
 * Examples - https://material-ui.com/components/text-fields/
 */
const Input: React.FunctionComponent<InputProps> = (props) => {
  const {
    dark,
    hide = false,
    placeholder,
    label,
    helperText,
    id,
    error,
    success,
    startAdornmentIcon,
    endAdornmentIcon,
    inputProps = {},
    InputProps = {},
    pinnedLabel = false,
    showAdornment = false,
    showUnderline = true,
    endClickableAdornment = false,
    startClickableAdornment = false,
    disabled = false,
    secondaryLabel,
    tooltipMessage,
    onEndAdornmentClick,
    ...tempForwardProps
  } = props;

  if (showAdornment && success) {
    tempForwardProps.endAdornment = (
      <StyledInputAdornment dark={dark} position="end">
        <CheckIcon />
      </StyledInputAdornment>
    );
  }

  if (showAdornment && error) {
    tempForwardProps.endAdornment = (
      <StyledInputAdornment dark={dark} position="end">
        <WarningIcon />
      </StyledInputAdornment>
    );
  }

  if (startAdornmentIcon) {
    tempForwardProps.startAdornment = (
      <StyledInputAdornment
        clickableAdornment={startClickableAdornment}
        dark={dark}
        position="start"
      >
        {startAdornmentIcon}
      </StyledInputAdornment>
    );
  }

  if (endAdornmentIcon) {
    tempForwardProps.endAdornment = (
      <StyledInputAdornment
        dark={dark}
        clickableAdornment={endClickableAdornment}
        position="end"
        onClick={props.onEndAdornmentClick}
      >
        {endAdornmentIcon}
      </StyledInputAdornment>
    );
  }

  const labelNode: React.ReactNode =
    secondaryLabel || tooltipMessage ? (
      <InputLabel
        labelText={label}
        tooltipMessage={tooltipMessage}
        secondaryLabel={secondaryLabel}
      />
    ) : (
      label
    );

  const { endAdornment, startAdornment, ...forwardProps } = tempForwardProps;

  return (
    <StyledTextField
      {...forwardProps}
      dark={dark}
      hide={hide}
      key={props.color}
      color={props.color}
      error={error}
      success={success}
      id={id}
      label={pinnedLabel ? labelNode : undefined}
      secondaryLabel={secondaryLabel}
      placeholder={placeholder}
      inputProps={{
        'data-testid': id,
        'aria-label': label,
        'aria-invalid': !!error,
        ...inputProps,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      helperText={helperText}
      showUnderline={showUnderline}
      disabled={disabled}
      InputProps={{
        endAdornment,
        startAdornment,
        ...InputProps,
      }}
    />
  );
};

export { Input };
