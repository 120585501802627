import React from 'react';

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  fullWidth?: boolean;
  ['data-testid']?: string;
  dangerouslySetInnerHTML?: any;
}

const FormComponent = (
  { children, onSubmit, fullWidth, ...forwardProps }: FormProps,
  ref: any
) => (
  <form
    {...forwardProps}
    style={{
      ...(fullWidth && { width: '100%' }),
    }}
    data-testid={forwardProps['data-testid'] || 'base-form'}
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit && onSubmit(e);
    }}
    ref={ref}
  >
    {children}
  </form>
);

const Form = React.forwardRef(FormComponent);

export { Form };
