import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Badge } from '@allergan-data-labs/alle-elements';
import { ProfileOutlineIcon } from '@allergan-data-labs/alle-elements-icons/16/profileOutlineIcon';

import { BaseComponentProps } from '../../common';

export interface BusinessUserHeaderProps extends BaseComponentProps {
  firstName?: string;
  lastName?: string;
  isPending: boolean;
}

const BusinessUserHeader: React.FC<BusinessUserHeaderProps> = ({
  firstName,
  lastName,
  isPending,
  dataTestId = 'business-user-header',
}) => {
  return (
    <Box data-testid={dataTestId} paddingBottom={24}>
      <Box>
        <Text textStyle="Headline/Large/Primary medium">
          {isPending ? 'Invited User' : `${firstName} ${lastName}`}
        </Text>
        <Box marginTop="8px">
          <Badge
            variant="outline"
            color="light.Text/Neutral/Default"
            icon={<ProfileOutlineIcon />}
          >
            Business User
          </Badge>
        </Box>
      </Box>
    </Box>
  );
};

export { BusinessUserHeader };
