import React, { ReactElement } from 'react';
import {
  LinkProps as RouterLinkProps,
  Link as RouterLink,
} from 'react-router-dom';
import { Link } from '@allergan-data-labs/alle-elements';

interface NavLinkProps<T extends unknown = unknown> extends RouterLinkProps<T> {
  isStandalone?: boolean;
  isExternal?: boolean;
  icon?: ReactElement;
  className?: string;
  /**
   Force link to open in same tab, even if it is an external link
   */
  isSelf?: boolean;
  to: string;
}
const NavLink: React.FunctionComponent<NavLinkProps> = ({
  isStandalone,
  isExternal,
  children,
  isSelf,
  icon,
  to,
}) => {
  const linkStyles = {
    textDecoration: 'none',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  };

  if (isExternal) {
    return (
      <Link
        target={isSelf ? '_self' : '_blank'}
        isStandalone={isStandalone}
        rel="noopener noreferrer"
        icon={icon}
        href={to}
      >
        {children}
      </Link>
    );
  }
  return (
    <RouterLink to={to} style={linkStyles}>
      {children}
    </RouterLink>
  );
};

export { NavLink };
