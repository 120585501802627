/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv';
import * as Segment from './segment';

export interface Event {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Billing {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address {
  /**
   * Billing address, if known
   */
  billing?: Billing;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping;
}
export interface Ids {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface CheckoutCancelled {
  /**
   * NEEDS DESC
   */
  checkout_id?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event;
  /**
   * NEEDS DESC
   */
  member_id?: string;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User;
}
export interface Event1 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Billing1 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office1 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping1 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address1 {
  /**
   * Billing address, if known
   */
  billing?: Billing1;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office1;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping1;
}
export interface Ids1 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media1 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider1 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address1;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids1;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media1;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User1 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface CheckoutCompleted {
  /**
   * NEEDS DESC
   */
  checkout_id?: string;
  /**
   * NEEDS DESC
   */
  consumer_id?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event1;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * NEEDS DESC
   */
  num_agn_brands?: number;
  /**
   * NEEDS DESC
   */
  num_nonagn_brands?: number;
  /**
   * NEEDS DESC
   */
  offers_redeemed?: any[];
  /**
   * NEEDS DESC
   */
  points_earned?: number;
  /**
   * NEEDS DESC
   */
  points_redeemed?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider1;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User1;
}
export interface Event2 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Billing2 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office2 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping2 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address2 {
  /**
   * Billing address, if known
   */
  billing?: Billing2;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office2;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping2;
}
export interface Ids2 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media2 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider2 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address2;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids2;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media2;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User2 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface CheckoutStarted {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event2;
  /**
   * NEEDS DESC
   */
  member_id?: string;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider2;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User2;
}
export interface Event3 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Billing3 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office3 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping3 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address3 {
  /**
   * Billing address, if known
   */
  billing?: Billing3;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office3;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping3;
}
export interface Ids3 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media3 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider3 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address3;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids3;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media3;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User3 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface CheckoutSubmitted {
  /**
   * NEEDS DESC
   */
  checkout_id?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event3;
  /**
   * NEEDS DESC
   */
  member_id?: string;
  /**
   * NEEDS DESC
   */
  num_agn_brands?: number;
  /**
   * NEEDS DESC
   */
  num_nonagn_brands?: number;
  /**
   * NEEDS DESC
   */
  offers_redeemed?: any[];
  /**
   * NEEDS DESC
   */
  points_earned?: number;
  /**
   * NEEDS DESC
   */
  points_redeemed?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider3;
  /**
   * NEEDS DESC
   */
  staff_ids?: any[];
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User3;
}
export interface Event4 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Billing4 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office4 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping4 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address4 {
  /**
   * Billing address, if known
   */
  billing?: Billing4;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office4;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping4;
}
export interface Ids4 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media4 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider4 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address4;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids4;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media4;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User4 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface CheckoutUpdated {
  /**
   * NEEDS DESC
   */
  checkout_id?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event4;
  /**
   * NEEDS DESC
   */
  member_id?: string;
  /**
   * NEEDS DESC
   */
  num_agn_brands?: number;
  /**
   * NEEDS DESC
   */
  num_nonagn_brands?: number;
  /**
   * NEEDS DESC
   */
  offers_redeemed?: any[];
  /**
   * NEEDS DESC
   */
  points_earned?: number;
  /**
   * NEEDS DESC
   */
  points_redeemed?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider4;
  /**
   * NEEDS DESC
   */
  staff_ids?: any[];
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User4;
}
export interface Event5 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
}
export interface Billing5 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office5 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping5 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address5 {
  /**
   * Billing address, if known
   */
  billing?: Billing5;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office5;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping5;
}
export interface Ids5 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media5 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider5 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address5;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids5;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media5;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number.  10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User5 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ContentScrolled {
  /**
   * TO DO
   */
  content?: Record<string, any>;
  /**
   * NEEDS DESC
   */
  content_type?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event5;
  /**
   * NEEDS DESC
   */
  expiresAt?: string;
  /**
   * NEEDS DESC
   */
  fbclid?: string;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider5;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User5;
}
export interface Content {
  campaign?: string;
  campaign_id?: string;
  campaign_name?: string;
  concern_area?: string;
  content_id?: string;
  headline?: string;
  journey?: string;
  product?: string;
  publish_date?: string;
  slug?: string;
  tag?: string;
  template?: string;
  /**
   * image/dropdown/popup/callout/etc
   */
  type?: string;
  visual?: string;
}
export interface Event6 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Billing6 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office6 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping6 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address6 {
  /**
   * Billing address, if known
   */
  billing?: Billing6;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office6;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping6;
}
export interface Ids6 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media6 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider6 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address6;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids6;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media6;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User6 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ContentViewed {
  /**
   * NEEDS DESC
   */
  alle_accepted_hipaa?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_promotions_email?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_sms?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_tc?: boolean;
  /**
   * NEEDS DESC
   */
  alle_account_type?: string;
  /**
   * NEEDS DESC
   */
  alle_device_type?: string;
  /**
   * NEEDS DESC
   */
  alle_id?: string;
  /**
   * NEEDS DESC
   */
  alle_zip_code?: string;
  /**
   * NEEDS DESC
   */
  anonymousId?: string;
  /**
   * NEEDS DESC
   */
  campaign_id?: string;
  /**
   * Details on the Content object this event is about.
   */
  content?: Content;
  /**
   * NEEDS DESC
   */
  content_campaign?: string;
  /**
   * NEEDS DESC
   */
  content_campaign_name?: string;
  /**
   * NEEDS DESC
   */
  content_concern_area?: string;
  /**
   * NEEDS DESC
   */
  content_headline?: string;
  /**
   * NEEDS DESC
   */
  content_id?: string;
  /**
   * NEEDS DESC
   */
  content_journey?: string;
  /**
   * NEEDS DESC
   */
  content_product?: string;
  /**
   * NEEDS DESC
   */
  content_publish_date?: string;
  /**
   * NEEDS DESC
   */
  content_slug?: string;
  /**
   * NEEDS DESC
   */
  content_tag?: string;
  /**
   * NEEDS DESC
   */
  content_template?: string;
  /**
   * NEEDS DESC
   */
  content_type?: string;
  /**
   * NEEDS DESC
   */
  content_visual?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event6;
  /**
   * NEEDS DESC
   */
  expiresAt?: number;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider6;
  /**
   * NEEDS DESC
   */
  tactic_id?: string;
  /**
   * NEEDS DESC
   */
  tactic_name?: string;
  /**
   * NEEDS DESC
   */
  tactic_summary?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User6;
  /**
   * NEEDS DESC
   */
  userId?: string;
}
export interface Event7 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Offer {
  activation?: string;
  brands?: any[];
  expiration?: string;
  name?: string;
  offer_id?: string;
  slug?: string;
  type?: string;
  value?: number;
}
export interface User7 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface OfferAdded {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event7;
  /**
   * NEEDS DESC
   */
  id?: string;
  offer?: Offer;
  /**
   * NEEDS DESC
   */
  offer_activation?: string;
  /**
   * NEEDS DESC
   */
  offer_brands?: any[];
  /**
   * NEEDS DESC
   */
  offer_expiration?: string;
  /**
   * NEEDS DESC
   */
  offer_id?: string;
  /**
   * NEEDS DESC
   */
  offer_name?: string;
  /**
   * NEEDS DESC
   */
  offer_type?: string;
  /**
   * NEEDS DESC
   */
  offer_value?: number;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User7;
}
export interface Event8 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Offer1 {
  activation?: string;
  brands?: any[];
  expiration?: string;
  name?: string;
  offer_id?: string;
  slug?: string;
  type?: string;
  value?: number;
}
export interface Billing7 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office7 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping7 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address7 {
  /**
   * Billing address, if known
   */
  billing?: Billing7;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office7;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping7;
}
export interface Ids7 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media7 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider7 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address7;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids7;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media7;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User8 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface OfferClaimed {
  /**
   * NEEDS DESC
   */
  alle_device_type?: string;
  /**
   * NEEDS DESC
   */
  alle_id?: string;
  /**
   * NEEDS DESC
   */
  anonymousId?: string;
  /**
   * NEEDS DESC
   */
  bd_migration?: string;
  /**
   * NEEDS DESC
   */
  campaign_name?: string;
  /**
   * NEEDS DESC
   */
  campaign_type?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event8;
  /**
   * NEEDS DESC
   */
  expiresAt?: number;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * NEEDS DESC
   */
  new_registration?: boolean;
  offer?: Offer1;
  /**
   * NEEDS DESC
   */
  offer_activation?: string;
  /**
   * NEEDS DESC
   */
  offer_brand?: string;
  /**
   * NEEDS DESC
   */
  offer_brands?: any[];
  /**
   * NEEDS DESC
   */
  offer_expiration?: string;
  /**
   * NEEDS DESC
   */
  offer_id?: string;
  /**
   * NEEDS DESC
   */
  offer_name?: string;
  /**
   * NEEDS DESC
   */
  offer_type?: string;
  /**
   * NEEDS DESC
   */
  offer_value?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider7;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User8;
  /**
   * NEEDS DESC
   */
  userId?: string;
}
export interface Event9 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Offer2 {
  activation?: string;
  brands?: any[];
  expiration?: string;
  name?: string;
  offer_id?: string;
  slug?: string;
  type?: string;
  value?: number;
}
export interface Billing8 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office8 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping8 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address8 {
  /**
   * Billing address, if known
   */
  billing?: Billing8;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office8;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping8;
}
export interface Ids8 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media8 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider8 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address8;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids8;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media8;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User9 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface OfferFailed {
  /**
   * NEEDS DESC
   */
  bd_migration?: string;
  /**
   * NEEDS DESC
   */
  campaign_name?: string;
  /**
   * NEEDS DESC
   */
  campaign_type?: string;
  /**
   * NEEDS DESC
   */
  error_reason?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event9;
  /**
   * NEEDS DESC
   */
  expiresAt?: number;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * NEEDS DESC
   */
  new_registration?: boolean;
  offer?: Offer2;
  /**
   * NEEDS DESC
   */
  offer_activation?: string;
  /**
   * NEEDS DESC
   */
  offer_brand?: string;
  /**
   * NEEDS DESC
   */
  offer_brands?: any[];
  /**
   * NEEDS DESC
   */
  offer_id?: string;
  /**
   * NEEDS DESC
   */
  offer_type?: string;
  /**
   * NEEDS DESC
   */
  offer_value?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider8;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User9;
}
export interface Event10 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Offer3 {
  activation?: string;
  brands?: any[];
  expiration?: string;
  name?: string;
  offer_id?: string;
  slug?: string;
  type?: string;
  value?: number;
}
export interface Billing9 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office9 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping9 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address9 {
  /**
   * Billing address, if known
   */
  billing?: Billing9;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office9;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping9;
}
export interface Ids9 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media9 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider9 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address9;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids9;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media9;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User10 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface OfferPresented {
  /**
   * NEEDS DESC
   */
  alle_accepted_hipaa?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_promotions_email?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_sms?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_tc?: boolean;
  /**
   * NEEDS DESC
   */
  alle_account_type?: string;
  /**
   * NEEDS DESC
   */
  alle_device_type?: string;
  /**
   * NEEDS DESC
   */
  alle_id?: string;
  /**
   * NEEDS DESC
   */
  alle_zip_code?: string;
  /**
   * NEEDS DESC
   */
  anonymousId?: string;
  /**
   * NEEDS DESC
   */
  bd_consumer_id?: string;
  /**
   * NEEDS DESC
   */
  campaign_name?: string;
  /**
   * NEEDS DESC
   */
  campaign_type?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event10;
  /**
   * NEEDS DESC
   */
  expiresAt?: number;
  /**
   * NEEDS DESC
   */
  id?: string;
  offer?: Offer3;
  /**
   * NEEDS DESC
   */
  offer_activation?: string;
  /**
   * NEEDS DESC
   */
  offer_brand?: string;
  /**
   * NEEDS DESC
   */
  offer_brands?: any[];
  /**
   * NEEDS DESC
   */
  offer_expiration?: string;
  /**
   * NEEDS DESC
   */
  offer_id?: string;
  /**
   * NEEDS DESC
   */
  offer_name?: string;
  /**
   * NEEDS DESC
   */
  offer_type?: string;
  /**
   * NEEDS DESC
   */
  offer_value?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider9;
  /**
   * NEEDS DESC
   */
  provider_id?: string;
  /**
   * NEEDS DESC
   */
  template_id?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User10;
  /**
   * NEEDS DESC
   */
  userId?: string;
}
export interface Event11 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Offer4 {
  activation?: string;
  brands?: any[];
  expiration?: string;
  name?: string;
  offer_id?: string;
  slug?: string;
  type?: string;
  value?: number;
}
export interface Billing10 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office10 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping10 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address10 {
  /**
   * Billing address, if known
   */
  billing?: Billing10;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office10;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping10;
}
export interface Ids10 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media10 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider10 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address10;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids10;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media10;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User11 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface OfferRedeemed {
  /**
   * NEEDS DESC
   */
  campaign_type?: string;
  /**
   * NEEDS DESC
   */
  checkout_id?: string;
  /**
   * NEEDS DESC
   */
  consumer_id?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event11;
  /**
   * NEEDS DESC
   */
  id?: string;
  offer?: Offer4;
  /**
   * NEEDS DESC
   */
  offer_activation?: string;
  /**
   * NEEDS DESC
   */
  offer_brand?: string;
  /**
   * NEEDS DESC
   */
  offer_brands?: any[];
  /**
   * NEEDS DESC
   */
  offer_expiration?: string;
  /**
   * NEEDS DESC
   */
  offer_id?: string;
  /**
   * NEEDS DESC
   */
  offer_name?: string;
  /**
   * NEEDS DESC
   */
  offer_type?: string;
  /**
   * NEEDS DESC
   */
  offer_value?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider10;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User11;
}
export interface Event12 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Offer5 {
  activation?: string;
  brands?: any[];
  expiration?: string;
  name?: string;
  offer_id?: string;
  slug?: string;
  type?: string;
  value?: number;
}
export interface User12 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface OfferRemoved {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event12;
  /**
   * NEEDS DESC
   */
  expiresAt?: number;
  /**
   * NEEDS DESC
   */
  id?: string;
  offer?: Offer5;
  /**
   * NEEDS DESC
   */
  offer_activation?: string;
  /**
   * NEEDS DESC
   */
  offer_brands?: any[];
  /**
   * NEEDS DESC
   */
  offer_expiration?: string;
  /**
   * NEEDS DESC
   */
  offer_id?: string;
  /**
   * NEEDS DESC
   */
  offer_name?: string;
  /**
   * NEEDS DESC
   */
  offer_type?: string;
  /**
   * NEEDS DESC
   */
  offer_value?: number;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User12;
}
export interface Event13 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Offer6 {
  activation?: string;
  brands?: any[];
  expiration?: string;
  name?: string;
  offer_id?: string;
  slug?: string;
  type?: string;
  value?: number;
}
export interface Billing11 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office11 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping11 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address11 {
  /**
   * Billing address, if known
   */
  billing?: Billing11;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office11;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping11;
}
export interface Ids11 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media11 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider11 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address11;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids11;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media11;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User13 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface OfferShared {
  /**
   * NEEDS DESC
   */
  alle_device_type?: string;
  /**
   * NEEDS DESC
   */
  alle_id?: string;
  /**
   * NEEDS DESC
   */
  anonymousId?: string;
  /**
   * NEEDS DESC
   */
  email_shared?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event13;
  /**
   * NEEDS DESC
   */
  expiresAt?: number;
  /**
   * NEEDS DESC
   */
  id?: string;
  offer?: Offer6;
  /**
   * NEEDS DESC
   */
  offer_activation?: string;
  /**
   * NEEDS DESC
   */
  offer_brand?: string;
  /**
   * NEEDS DESC
   */
  offer_brands?: any[];
  /**
   * NEEDS DESC
   */
  offer_expiration?: string;
  /**
   * NEEDS DESC
   */
  offer_id?: string;
  /**
   * NEEDS DESC
   */
  offer_name?: string;
  /**
   * NEEDS DESC
   */
  offer_type?: string;
  /**
   * NEEDS DESC
   */
  offer_value?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider11;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User13;
  /**
   * NEEDS DESC
   */
  userId?: string;
}
export interface Event14 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Offer7 {
  activation?: string;
  brands?: any[];
  expiration?: string;
  name?: string;
  offer_id?: string;
  slug?: string;
  type?: string;
  value?: number;
}
export interface Billing12 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office12 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping12 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address12 {
  /**
   * Billing address, if known
   */
  billing?: Billing12;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office12;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping12;
}
export interface Ids12 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media12 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider12 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address12;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids12;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media12;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User14 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface OfferSubmitted {
  /**
   * NEEDS DESC
   */
  alle_accepted_hipaa?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_promotions_email?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_sms?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_tc?: boolean;
  /**
   * NEEDS DESC
   */
  alle_account_type?: string;
  /**
   * NEEDS DESC
   */
  alle_device_type?: string;
  /**
   * NEEDS DESC
   */
  alle_id?: string;
  /**
   * NEEDS DESC
   */
  anonymousId?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event14;
  /**
   * NEEDS DESC
   */
  expiresAt?: number;
  /**
   * NEEDS DESC
   */
  id?: string;
  offer?: Offer7;
  /**
   * NEEDS DESC
   */
  offer_activation?: string;
  /**
   * NEEDS DESC
   */
  offer_brand?: string;
  /**
   * NEEDS DESC
   */
  offer_brands?: any[];
  /**
   * NEEDS DESC
   */
  offer_expiration?: string;
  /**
   * NEEDS DESC
   */
  offer_id?: string;
  /**
   * NEEDS DESC
   */
  offer_name?: string;
  /**
   * NEEDS DESC
   */
  offer_type?: string;
  /**
   * NEEDS DESC
   */
  offer_value?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider12;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User14;
  /**
   * NEEDS DESC
   */
  userId?: string;
}
export interface Event15 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Offer8 {
  activation?: string;
  brands?: any[];
  expiration?: string;
  name?: string;
  offer_id?: string;
  slug?: string;
  type?: string;
  value?: number;
}
export interface Billing13 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office13 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping13 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address13 {
  /**
   * Billing address, if known
   */
  billing?: Billing13;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office13;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping13;
}
export interface Ids13 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media13 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider13 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address13;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids13;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media13;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User15 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface OfferSuppressed {
  /**
   * NEEDS DESC
   */
  alle_accepted_hipaa?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_promotions_email?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_sms?: boolean;
  /**
   * NEEDS DESC
   */
  alle_accepted_tc?: boolean;
  /**
   * NEEDS DESC
   */
  alle_account_type?: string;
  /**
   * NEEDS DESC
   */
  alle_device_type?: string;
  /**
   * NEEDS DESC
   */
  alle_id?: string;
  /**
   * NEEDS DESC
   */
  alle_zip_code?: string;
  /**
   * NEEDS DESC
   */
  anonymousId?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event15;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * NEEDS DESC
   */
  member_id?: string;
  offer?: Offer8;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider13;
  /**
   * NEEDS DESC
   */
  reason?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User15;
  /**
   * NEEDS DESC
   */
  userId?: string;
}
export interface Event16 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Billing14 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office14 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping14 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address14 {
  /**
   * Billing address, if known
   */
  billing?: Billing14;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office14;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping14;
}
export interface Ids14 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media14 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider14 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address14;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids14;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media14;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface ProviderProfile {
  contact_email_address?: string;
  contact_instagram_handle?: string;
  contact_phone_number?: string;
  hide_form?: boolean;
  is_fee_towards_treatment_cost?: boolean;
  num_days_closed?: number;
  num_days_open?: number;
  num_days_set?: number;
  num_gallery_photos?: number;
  num_products?: number;
  send_requests_to_email?: string;
}
export interface User16 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileCompleted {
  /**
   * NEEDS DESC
   */
  booking_url?: string;
  /**
   * NEEDS DESC
   */
  channel?: string;
  /**
   * NEEDS DESC
   */
  contact_email_address?: string;
  /**
   * NEEDS DESC
   */
  contact_instagram_handle?: string;
  /**
   * NEEDS DESC
   */
  contact_phone_number?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event16;
  /**
   * NEEDS DESC
   */
  hide_consultation_form?: boolean;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * NEEDS DESC
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * NEEDS DESC
   */
  num_days_closed?: number;
  /**
   * NEEDS DESC
   */
  num_days_open?: number;
  /**
   * NEEDS DESC
   */
  num_days_set?: number;
  /**
   * NEEDS DESC
   */
  num_gallery_photos?: number;
  /**
   * NEEDS DESC
   */
  num_products?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider14;
  /**
   * Data specific to the Provider Profile
   */
  provider_profile?: ProviderProfile;
  /**
   * NEEDS DESC
   */
  publish_type?: string;
  /**
   * NEEDS DESC
   */
  send_requests_to_email?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User16;
}
export interface Event17 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Billing15 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office15 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping15 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address15 {
  /**
   * Billing address, if known
   */
  billing?: Billing15;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office15;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping15;
}
export interface Ids15 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media15 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider15 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address15;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids15;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media15;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface ProviderProfile1 {
  contact_email_address?: string;
  contact_instagram_handle?: string;
  contact_phone_number?: string;
  hide_form?: boolean;
  is_fee_towards_treatment_cost?: boolean;
  num_days_closed?: number;
  num_days_open?: number;
  num_days_set?: number;
  num_gallery_photos?: number;
  num_products?: number;
  send_requests_to_email?: string;
}
export interface User17 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfilePublished {
  /**
   * NEEDS DESC
   */
  booking_url?: string;
  /**
   * NEEDS DESC
   */
  channel?: string;
  /**
   * NEEDS DESC
   */
  consultation_cost?: string;
  /**
   * NEEDS DESC
   */
  consultation_enabled?: boolean;
  /**
   * NEEDS DESC
   */
  contact_info_added?: boolean;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event17;
  /**
   * NEEDS DESC
   */
  hours_added?: boolean;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * NEEDS DESC
   */
  location_count?: number;
  /**
   * NEEDS DESC
   */
  locations_updated?: any[];
  /**
   * NEEDS DESC
   */
  photos_added?: boolean;
  /**
   * NEEDS DESC
   */
  practitioner_added?: boolean;
  /**
   * NEEDS DESC
   */
  profile_completeness?: number;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider15;
  /**
   * Data specific to the Provider Profile
   */
  provider_profile?: ProviderProfile1;
  /**
   * NEEDS DESC
   */
  publish_type?: string;
  /**
   * NEEDS DESC
   */
  success?: boolean;
  /**
   * NEEDS DESC
   */
  treatments_added?: boolean;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User17;
}
export interface Event18 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface ProviderProfile2 {
  contact_email_address?: string;
  contact_instagram_handle?: string;
  contact_phone_number?: string;
  hide_form?: boolean;
  is_fee_towards_treatment_cost?: boolean;
  num_days_closed?: number;
  num_days_open?: number;
  num_days_set?: number;
  num_gallery_photos?: number;
  num_products?: number;
  send_requests_to_email?: string;
}
export interface User18 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ProfileUpdated {
  /**
   * NEEDS DESC
   */
  booking_url?: string;
  /**
   * NEEDS DESC
   */
  channel?: string;
  /**
   * NEEDS DESC
   */
  contact_email_address?: string;
  /**
   * NEEDS DESC
   */
  contact_instagram_handle?: string;
  /**
   * NEEDS DESC
   */
  contact_phone_number?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event18;
  /**
   * NEEDS DESC
   */
  hide_consultation_form?: boolean;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * NEEDS DESC
   */
  is_fee_towards_treatment_cost?: boolean;
  /**
   * NEEDS DESC
   */
  num_days_closed?: number;
  /**
   * NEEDS DESC
   */
  num_days_open?: number;
  /**
   * NEEDS DESC
   */
  num_days_set?: number;
  /**
   * NEEDS DESC
   */
  num_gallery_photos?: number;
  /**
   * NEEDS DESC
   */
  num_products?: number;
  /**
   * Data specific to the Provider Profile
   */
  provider_profile?: ProviderProfile2;
  /**
   * NEEDS DESC
   */
  send_requests_to_email?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User18;
}
export interface Event19 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface User19 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ResultsFiltered {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event19;
  /**
   * Freeform object used to qualify searches. Use name value pairs as specified by business needs
   */
  filter_data?: Record<string, any>;
  /**
   * Freeform object used to qualify searches. Use name value pairs as specified by business needs
   */
  filters?: Record<string, any>;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User19;
}
export interface Event20 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface User20 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface ResultsViewed {
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event20;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * NEEDS DESC
   */
  results_type?: string;
  /**
   * NEEDS DESC
   */
  sort_type?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User20;
}
export interface Event21 {
  /**
   * The root source of the event. See also invoked_by. Valid options are website | app
   */
  action_source?: string;
  /**
   * The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
   */
  activator?: string;
  /**
   * Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
   */
  explainer?: string;
  /**
   * Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
   */
  extra_data?: Record<string, any>;
  /**
   * What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
   */
  invoked_by?: string;
  /**
   * Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
   */
  libraries?: Record<string, any>;
  /**
   * Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
   */
  step?: string;
  /**
   * Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
   */
  type?: string;
}
export interface Billing16 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Office16 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Shipping16 {
  city?: string;
  country?: string;
  county?: string;
  postal?: string;
  street?: string;
}
export interface Address16 {
  /**
   * Billing address, if known
   */
  billing?: Billing16;
  /**
   * Principal address. If specifics of shipping/billing etc are not specified, use this one.
   */
  office?: Office16;
  /**
   * Shipping address, if known to be different from office address
   */
  shipping?: Shipping16;
}
export interface Ids16 {
  /**
   * The uuid of the BUSINESS providerOrganization in the ProviderOrg service
   */
  business_id?: string;
  /**
   * The uuid of the LOCATION providerOrganization in the ProviderOrg service
   */
  location_id?: string;
  /**
   * The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
   */
  provider_id?: string;
}
export interface Media16 {
  /**
   * Brilliant Connections
   */
  brilliantconnections_url?: string;
  /**
   * Facebook
   */
  facebook_url?: string;
  /**
   * InstaGram
   */
  instagram_url?: string;
  /**
   * LinkedIn
   */
  linkedin_url?: string;
  /**
   * TikTok
   */
  tiktok_url?: string;
  /**
   * Twitter
   */
  twitter_url?: string;
  /**
   * WhatsApp
   */
  whatsapp_url?: string;
  /**
   * YouTube channel. If we need to track providers videos for some reason, use event.extra_data
   */
  youtube_url?: string;
}
export interface Provider16 {
  /**
   * Address Object for provider locations. In general, use office address if the specifics are unknown.
   */
  address?: Address16;
  /**
   * Freeform object for contact information. Speculative until we know more about structure required.
   */
  contacts?: Record<string, any>;
  /**
   * providers primary email
   */
  email?: string;
  ids?: Ids16;
  /**
   * Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
   */
  media?: Media16;
  /**
   * Name of Provider
   */
  name?: string;
  /**
   * providers primary phone number. 10 digits only, use phone_extension if anything else is required.
   */
  phone?: string;
  /**
   * Additional Phone information, if required.
   */
  phone_extension?: string;
  /**
   * url of providers website
   */
  url?: string;
}
export interface User21 {
  /**
   * user id. This can also be consumer id or provider id
   */
  id?: string;
  /**
   * logged_in | logged_out
   */
  status?: string;
  /**
   * consumer | provider | admin | provider_associate
   */
  type?: string;
}
export interface SiteNavigated {
  /**
   * NEEDS DESC
   */
  card_position?: string;
  /**
   * NEEDS DESC
   */
  content_campaign?: string;
  /**
   * NEEDS DESC
   */
  content_concern_area?: string;
  /**
   * NEEDS DESC
   */
  content_headline?: string;
  /**
   * NEEDS DESC
   */
  content_journey?: string;
  /**
   * NEEDS DESC
   */
  content_product?: string;
  /**
   * NEEDS DESC
   */
  content_tag?: string;
  /**
   * NEEDS DESC
   */
  content_template?: string;
  /**
   * NEEDS DESC
   */
  content_type?: string;
  /**
   * NEEDS DESC
   */
  content_visual?: string;
  /**
   * Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
   */
  event?: Event21;
  /**
   * NEEDS DESC
   */
  event_source_url?: string;
  /**
   * NEEDS DESC
   */
  expiresAt?: number;
  /**
   * NEEDS DESC
   */
  id?: string;
  /**
   * NEEDS DESC
   */
  orientation?: string;
  /**
   * Data pertaining to the Provider. Populate what you have access to, please.
   */
  provider?: Provider16;
  /**
   * NEEDS DESC
   */
  section?: string;
  /**
   * NEEDS DESC
   */
  section_position?: number;
  /**
   * NEEDS DESC
   */
  text?: string;
  /**
   * NEEDS DESC
   */
  url?: string;
  /**
   * Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
   */
  user?: User21;
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: Ajv.ErrorObject[]
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations
) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true });
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '7.4.1',
      },
    },
  };
}

/**
 * @typedef Event
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Billing
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address
 * @property {Billing} [billing] - Billing address, if known
 * @property {Office} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider
 * @property {Address} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids} [ids] -
 * @property {Media} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef CheckoutCancelled
 * @property {string} [checkout_id] - NEEDS DESC
 * @property {Event} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [member_id] - NEEDS DESC
 * @property {Provider} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event1
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Billing1
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office1
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping1
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address1
 * @property {Billing1} [billing] - Billing address, if known
 * @property {Office1} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping1} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids1
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media1
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider1
 * @property {Address1} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids1} [ids] -
 * @property {Media1} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User1
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef CheckoutCompleted
 * @property {string} [checkout_id] - NEEDS DESC
 * @property {string} [consumer_id] - NEEDS DESC
 * @property {Event1} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [id] - NEEDS DESC
 * @property {number} [num_agn_brands] - NEEDS DESC
 * @property {number} [num_nonagn_brands] - NEEDS DESC
 * @property {any[]} [offers_redeemed] - NEEDS DESC
 * @property {number} [points_earned] - NEEDS DESC
 * @property {number} [points_redeemed] - NEEDS DESC
 * @property {Provider1} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User1} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event2
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Billing2
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office2
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping2
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address2
 * @property {Billing2} [billing] - Billing address, if known
 * @property {Office2} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping2} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids2
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media2
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider2
 * @property {Address2} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids2} [ids] -
 * @property {Media2} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User2
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef CheckoutStarted
 * @property {Event2} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [member_id] - NEEDS DESC
 * @property {Provider2} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User2} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event3
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Billing3
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office3
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping3
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address3
 * @property {Billing3} [billing] - Billing address, if known
 * @property {Office3} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping3} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids3
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media3
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider3
 * @property {Address3} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids3} [ids] -
 * @property {Media3} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User3
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef CheckoutSubmitted
 * @property {string} [checkout_id] - NEEDS DESC
 * @property {Event3} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [member_id] - NEEDS DESC
 * @property {number} [num_agn_brands] - NEEDS DESC
 * @property {number} [num_nonagn_brands] - NEEDS DESC
 * @property {any[]} [offers_redeemed] - NEEDS DESC
 * @property {number} [points_earned] - NEEDS DESC
 * @property {number} [points_redeemed] - NEEDS DESC
 * @property {Provider3} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {any[]} [staff_ids] - NEEDS DESC
 * @property {User3} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event4
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Billing4
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office4
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping4
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address4
 * @property {Billing4} [billing] - Billing address, if known
 * @property {Office4} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping4} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids4
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media4
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider4
 * @property {Address4} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids4} [ids] -
 * @property {Media4} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User4
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef CheckoutUpdated
 * @property {string} [checkout_id] - NEEDS DESC
 * @property {Event4} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [member_id] - NEEDS DESC
 * @property {number} [num_agn_brands] - NEEDS DESC
 * @property {number} [num_nonagn_brands] - NEEDS DESC
 * @property {any[]} [offers_redeemed] - NEEDS DESC
 * @property {number} [points_earned] - NEEDS DESC
 * @property {number} [points_redeemed] - NEEDS DESC
 * @property {Provider4} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {any[]} [staff_ids] - NEEDS DESC
 * @property {User4} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event5
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 */
/**
 * @typedef Billing5
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office5
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping5
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address5
 * @property {Billing5} [billing] - Billing address, if known
 * @property {Office5} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping5} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids5
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media5
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider5
 * @property {Address5} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids5} [ids] -
 * @property {Media5} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number.  10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User5
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ContentScrolled
 * @property {Record<string, any>} [content] - TO DO
 * @property {string} [content_type] - NEEDS DESC
 * @property {Event5} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [expiresAt] - NEEDS DESC
 * @property {string} [fbclid] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {Provider5} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User5} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Content
 * @property {string} [campaign] -
 * @property {string} [campaign_id] -
 * @property {string} [campaign_name] -
 * @property {string} [concern_area] -
 * @property {string} [content_id] -
 * @property {string} [headline] -
 * @property {string} [journey] -
 * @property {string} [product] -
 * @property {string} [publish_date] -
 * @property {string} [slug] -
 * @property {string} [tag] -
 * @property {string} [template] -
 * @property {string} [type] - image/dropdown/popup/callout/etc
 * @property {string} [visual] -
 */
/**
 * @typedef Event6
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Billing6
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office6
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping6
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address6
 * @property {Billing6} [billing] - Billing address, if known
 * @property {Office6} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping6} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids6
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media6
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider6
 * @property {Address6} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids6} [ids] -
 * @property {Media6} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User6
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ContentViewed
 * @property {boolean} [alle_accepted_hipaa] - NEEDS DESC
 * @property {boolean} [alle_accepted_promotions_email] - NEEDS DESC
 * @property {boolean} [alle_accepted_sms] - NEEDS DESC
 * @property {boolean} [alle_accepted_tc] - NEEDS DESC
 * @property {string} [alle_account_type] - NEEDS DESC
 * @property {string} [alle_device_type] - NEEDS DESC
 * @property {string} [alle_id] - NEEDS DESC
 * @property {string} [alle_zip_code] - NEEDS DESC
 * @property {string} [anonymousId] - NEEDS DESC
 * @property {string} [campaign_id] - NEEDS DESC
 * @property {Content} [content] - Details on the Content object this event is about.
 * @property {string} [content_campaign] - NEEDS DESC
 * @property {string} [content_campaign_name] - NEEDS DESC
 * @property {string} [content_concern_area] - NEEDS DESC
 * @property {string} [content_headline] - NEEDS DESC
 * @property {string} [content_id] - NEEDS DESC
 * @property {string} [content_journey] - NEEDS DESC
 * @property {string} [content_product] - NEEDS DESC
 * @property {string} [content_publish_date] - NEEDS DESC
 * @property {string} [content_slug] - NEEDS DESC
 * @property {string} [content_tag] - NEEDS DESC
 * @property {string} [content_template] - NEEDS DESC
 * @property {string} [content_type] - NEEDS DESC
 * @property {string} [content_visual] - NEEDS DESC
 * @property {Event6} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {number} [expiresAt] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {Provider6} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {string} [tactic_id] - NEEDS DESC
 * @property {string} [tactic_name] - NEEDS DESC
 * @property {string} [tactic_summary] - NEEDS DESC
 * @property {User6} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 * @property {string} [userId] - NEEDS DESC
 */
/**
 * @typedef Event7
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Offer
 * @property {string} [activation] -
 * @property {any[]} [brands] -
 * @property {string} [expiration] -
 * @property {string} [name] -
 * @property {string} [offer_id] -
 * @property {string} [slug] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef User7
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef OfferAdded
 * @property {Event7} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [id] - NEEDS DESC
 * @property {Offer} [offer] -
 * @property {string} [offer_activation] - NEEDS DESC
 * @property {any[]} [offer_brands] - NEEDS DESC
 * @property {string} [offer_expiration] - NEEDS DESC
 * @property {string} [offer_id] - NEEDS DESC
 * @property {string} [offer_name] - NEEDS DESC
 * @property {string} [offer_type] - NEEDS DESC
 * @property {number} [offer_value] - NEEDS DESC
 * @property {User7} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event8
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Offer1
 * @property {string} [activation] -
 * @property {any[]} [brands] -
 * @property {string} [expiration] -
 * @property {string} [name] -
 * @property {string} [offer_id] -
 * @property {string} [slug] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef Billing7
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office7
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping7
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address7
 * @property {Billing7} [billing] - Billing address, if known
 * @property {Office7} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping7} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids7
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media7
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider7
 * @property {Address7} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids7} [ids] -
 * @property {Media7} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User8
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef OfferClaimed
 * @property {string} [alle_device_type] - NEEDS DESC
 * @property {string} [alle_id] - NEEDS DESC
 * @property {string} [anonymousId] - NEEDS DESC
 * @property {string} [bd_migration] - NEEDS DESC
 * @property {string} [campaign_name] - NEEDS DESC
 * @property {string} [campaign_type] - NEEDS DESC
 * @property {Event8} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {number} [expiresAt] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {boolean} [new_registration] - NEEDS DESC
 * @property {Offer1} [offer] -
 * @property {string} [offer_activation] - NEEDS DESC
 * @property {string} [offer_brand] - NEEDS DESC
 * @property {any[]} [offer_brands] - NEEDS DESC
 * @property {string} [offer_expiration] - NEEDS DESC
 * @property {string} [offer_id] - NEEDS DESC
 * @property {string} [offer_name] - NEEDS DESC
 * @property {string} [offer_type] - NEEDS DESC
 * @property {number} [offer_value] - NEEDS DESC
 * @property {Provider7} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User8} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 * @property {string} [userId] - NEEDS DESC
 */
/**
 * @typedef Event9
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Offer2
 * @property {string} [activation] -
 * @property {any[]} [brands] -
 * @property {string} [expiration] -
 * @property {string} [name] -
 * @property {string} [offer_id] -
 * @property {string} [slug] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef Billing8
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office8
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping8
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address8
 * @property {Billing8} [billing] - Billing address, if known
 * @property {Office8} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping8} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids8
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media8
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider8
 * @property {Address8} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids8} [ids] -
 * @property {Media8} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User9
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef OfferFailed
 * @property {string} [bd_migration] - NEEDS DESC
 * @property {string} [campaign_name] - NEEDS DESC
 * @property {string} [campaign_type] - NEEDS DESC
 * @property {string} [error_reason] - NEEDS DESC
 * @property {Event9} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {number} [expiresAt] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {boolean} [new_registration] - NEEDS DESC
 * @property {Offer2} [offer] -
 * @property {string} [offer_activation] - NEEDS DESC
 * @property {string} [offer_brand] - NEEDS DESC
 * @property {any[]} [offer_brands] - NEEDS DESC
 * @property {string} [offer_id] - NEEDS DESC
 * @property {string} [offer_type] - NEEDS DESC
 * @property {number} [offer_value] - NEEDS DESC
 * @property {Provider8} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User9} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event10
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Offer3
 * @property {string} [activation] -
 * @property {any[]} [brands] -
 * @property {string} [expiration] -
 * @property {string} [name] -
 * @property {string} [offer_id] -
 * @property {string} [slug] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef Billing9
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office9
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping9
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address9
 * @property {Billing9} [billing] - Billing address, if known
 * @property {Office9} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping9} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids9
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media9
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider9
 * @property {Address9} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids9} [ids] -
 * @property {Media9} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User10
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef OfferPresented
 * @property {boolean} [alle_accepted_hipaa] - NEEDS DESC
 * @property {boolean} [alle_accepted_promotions_email] - NEEDS DESC
 * @property {boolean} [alle_accepted_sms] - NEEDS DESC
 * @property {boolean} [alle_accepted_tc] - NEEDS DESC
 * @property {string} [alle_account_type] - NEEDS DESC
 * @property {string} [alle_device_type] - NEEDS DESC
 * @property {string} [alle_id] - NEEDS DESC
 * @property {string} [alle_zip_code] - NEEDS DESC
 * @property {string} [anonymousId] - NEEDS DESC
 * @property {string} [bd_consumer_id] - NEEDS DESC
 * @property {string} [campaign_name] - NEEDS DESC
 * @property {string} [campaign_type] - NEEDS DESC
 * @property {Event10} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {number} [expiresAt] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {Offer3} [offer] -
 * @property {string} [offer_activation] - NEEDS DESC
 * @property {string} [offer_brand] - NEEDS DESC
 * @property {any[]} [offer_brands] - NEEDS DESC
 * @property {string} [offer_expiration] - NEEDS DESC
 * @property {string} [offer_id] - NEEDS DESC
 * @property {string} [offer_name] - NEEDS DESC
 * @property {string} [offer_type] - NEEDS DESC
 * @property {number} [offer_value] - NEEDS DESC
 * @property {Provider9} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {string} [provider_id] - NEEDS DESC
 * @property {string} [template_id] - NEEDS DESC
 * @property {User10} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 * @property {string} [userId] - NEEDS DESC
 */
/**
 * @typedef Event11
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Offer4
 * @property {string} [activation] -
 * @property {any[]} [brands] -
 * @property {string} [expiration] -
 * @property {string} [name] -
 * @property {string} [offer_id] -
 * @property {string} [slug] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef Billing10
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office10
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping10
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address10
 * @property {Billing10} [billing] - Billing address, if known
 * @property {Office10} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping10} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids10
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media10
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider10
 * @property {Address10} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids10} [ids] -
 * @property {Media10} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User11
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef OfferRedeemed
 * @property {string} [campaign_type] - NEEDS DESC
 * @property {string} [checkout_id] - NEEDS DESC
 * @property {string} [consumer_id] - NEEDS DESC
 * @property {Event11} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [id] - NEEDS DESC
 * @property {Offer4} [offer] -
 * @property {string} [offer_activation] - NEEDS DESC
 * @property {string} [offer_brand] - NEEDS DESC
 * @property {any[]} [offer_brands] - NEEDS DESC
 * @property {string} [offer_expiration] - NEEDS DESC
 * @property {string} [offer_id] - NEEDS DESC
 * @property {string} [offer_name] - NEEDS DESC
 * @property {string} [offer_type] - NEEDS DESC
 * @property {number} [offer_value] - NEEDS DESC
 * @property {Provider10} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User11} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event12
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Offer5
 * @property {string} [activation] -
 * @property {any[]} [brands] -
 * @property {string} [expiration] -
 * @property {string} [name] -
 * @property {string} [offer_id] -
 * @property {string} [slug] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef User12
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef OfferRemoved
 * @property {Event12} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {number} [expiresAt] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {Offer5} [offer] -
 * @property {string} [offer_activation] - NEEDS DESC
 * @property {any[]} [offer_brands] - NEEDS DESC
 * @property {string} [offer_expiration] - NEEDS DESC
 * @property {string} [offer_id] - NEEDS DESC
 * @property {string} [offer_name] - NEEDS DESC
 * @property {string} [offer_type] - NEEDS DESC
 * @property {number} [offer_value] - NEEDS DESC
 * @property {User12} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event13
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Offer6
 * @property {string} [activation] -
 * @property {any[]} [brands] -
 * @property {string} [expiration] -
 * @property {string} [name] -
 * @property {string} [offer_id] -
 * @property {string} [slug] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef Billing11
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office11
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping11
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address11
 * @property {Billing11} [billing] - Billing address, if known
 * @property {Office11} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping11} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids11
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media11
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider11
 * @property {Address11} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids11} [ids] -
 * @property {Media11} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User13
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef OfferShared
 * @property {string} [alle_device_type] - NEEDS DESC
 * @property {string} [alle_id] - NEEDS DESC
 * @property {string} [anonymousId] - NEEDS DESC
 * @property {string} [email_shared] - NEEDS DESC
 * @property {Event13} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {number} [expiresAt] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {Offer6} [offer] -
 * @property {string} [offer_activation] - NEEDS DESC
 * @property {string} [offer_brand] - NEEDS DESC
 * @property {any[]} [offer_brands] - NEEDS DESC
 * @property {string} [offer_expiration] - NEEDS DESC
 * @property {string} [offer_id] - NEEDS DESC
 * @property {string} [offer_name] - NEEDS DESC
 * @property {string} [offer_type] - NEEDS DESC
 * @property {number} [offer_value] - NEEDS DESC
 * @property {Provider11} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User13} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 * @property {string} [userId] - NEEDS DESC
 */
/**
 * @typedef Event14
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Offer7
 * @property {string} [activation] -
 * @property {any[]} [brands] -
 * @property {string} [expiration] -
 * @property {string} [name] -
 * @property {string} [offer_id] -
 * @property {string} [slug] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef Billing12
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office12
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping12
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address12
 * @property {Billing12} [billing] - Billing address, if known
 * @property {Office12} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping12} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids12
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media12
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider12
 * @property {Address12} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids12} [ids] -
 * @property {Media12} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User14
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef OfferSubmitted
 * @property {boolean} [alle_accepted_hipaa] - NEEDS DESC
 * @property {boolean} [alle_accepted_promotions_email] - NEEDS DESC
 * @property {boolean} [alle_accepted_sms] - NEEDS DESC
 * @property {boolean} [alle_accepted_tc] - NEEDS DESC
 * @property {string} [alle_account_type] - NEEDS DESC
 * @property {string} [alle_device_type] - NEEDS DESC
 * @property {string} [alle_id] - NEEDS DESC
 * @property {string} [anonymousId] - NEEDS DESC
 * @property {Event14} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {number} [expiresAt] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {Offer7} [offer] -
 * @property {string} [offer_activation] - NEEDS DESC
 * @property {string} [offer_brand] - NEEDS DESC
 * @property {any[]} [offer_brands] - NEEDS DESC
 * @property {string} [offer_expiration] - NEEDS DESC
 * @property {string} [offer_id] - NEEDS DESC
 * @property {string} [offer_name] - NEEDS DESC
 * @property {string} [offer_type] - NEEDS DESC
 * @property {number} [offer_value] - NEEDS DESC
 * @property {Provider12} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {User14} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 * @property {string} [userId] - NEEDS DESC
 */
/**
 * @typedef Event15
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Offer8
 * @property {string} [activation] -
 * @property {any[]} [brands] -
 * @property {string} [expiration] -
 * @property {string} [name] -
 * @property {string} [offer_id] -
 * @property {string} [slug] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef Billing13
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office13
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping13
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address13
 * @property {Billing13} [billing] - Billing address, if known
 * @property {Office13} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping13} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids13
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media13
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider13
 * @property {Address13} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids13} [ids] -
 * @property {Media13} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User15
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef OfferSuppressed
 * @property {boolean} [alle_accepted_hipaa] - NEEDS DESC
 * @property {boolean} [alle_accepted_promotions_email] - NEEDS DESC
 * @property {boolean} [alle_accepted_sms] - NEEDS DESC
 * @property {boolean} [alle_accepted_tc] - NEEDS DESC
 * @property {string} [alle_account_type] - NEEDS DESC
 * @property {string} [alle_device_type] - NEEDS DESC
 * @property {string} [alle_id] - NEEDS DESC
 * @property {string} [alle_zip_code] - NEEDS DESC
 * @property {string} [anonymousId] - NEEDS DESC
 * @property {Event15} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [id] - NEEDS DESC
 * @property {string} [member_id] - NEEDS DESC
 * @property {Offer8} [offer] -
 * @property {Provider13} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {string} [reason] - NEEDS DESC
 * @property {User15} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 * @property {string} [userId] - NEEDS DESC
 */
/**
 * @typedef Event16
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Billing14
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office14
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping14
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address14
 * @property {Billing14} [billing] - Billing address, if known
 * @property {Office14} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping14} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids14
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media14
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider14
 * @property {Address14} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids14} [ids] -
 * @property {Media14} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef ProviderProfile
 * @property {string} [contact_email_address] -
 * @property {string} [contact_instagram_handle] -
 * @property {string} [contact_phone_number] -
 * @property {boolean} [hide_form] -
 * @property {boolean} [is_fee_towards_treatment_cost] -
 * @property {number} [num_days_closed] -
 * @property {number} [num_days_open] -
 * @property {number} [num_days_set] -
 * @property {number} [num_gallery_photos] -
 * @property {number} [num_products] -
 * @property {string} [send_requests_to_email] -
 */
/**
 * @typedef User16
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileCompleted
 * @property {string} [booking_url] - NEEDS DESC
 * @property {string} [channel] - NEEDS DESC
 * @property {string} [contact_email_address] - NEEDS DESC
 * @property {string} [contact_instagram_handle] - NEEDS DESC
 * @property {string} [contact_phone_number] - NEEDS DESC
 * @property {Event16} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {boolean} [hide_consultation_form] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {boolean} [is_fee_towards_treatment_cost] - NEEDS DESC
 * @property {number} [num_days_closed] - NEEDS DESC
 * @property {number} [num_days_open] - NEEDS DESC
 * @property {number} [num_days_set] - NEEDS DESC
 * @property {number} [num_gallery_photos] - NEEDS DESC
 * @property {number} [num_products] - NEEDS DESC
 * @property {Provider14} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {ProviderProfile} [provider_profile] - Data specific to the Provider Profile
 * @property {string} [publish_type] - NEEDS DESC
 * @property {string} [send_requests_to_email] - NEEDS DESC
 * @property {User16} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event17
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Billing15
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office15
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping15
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address15
 * @property {Billing15} [billing] - Billing address, if known
 * @property {Office15} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping15} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids15
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media15
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider15
 * @property {Address15} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids15} [ids] -
 * @property {Media15} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef ProviderProfile1
 * @property {string} [contact_email_address] -
 * @property {string} [contact_instagram_handle] -
 * @property {string} [contact_phone_number] -
 * @property {boolean} [hide_form] -
 * @property {boolean} [is_fee_towards_treatment_cost] -
 * @property {number} [num_days_closed] -
 * @property {number} [num_days_open] -
 * @property {number} [num_days_set] -
 * @property {number} [num_gallery_photos] -
 * @property {number} [num_products] -
 * @property {string} [send_requests_to_email] -
 */
/**
 * @typedef User17
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfilePublished
 * @property {string} [booking_url] - NEEDS DESC
 * @property {string} [channel] - NEEDS DESC
 * @property {string} [consultation_cost] - NEEDS DESC
 * @property {boolean} [consultation_enabled] - NEEDS DESC
 * @property {boolean} [contact_info_added] - NEEDS DESC
 * @property {Event17} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {boolean} [hours_added] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {number} [location_count] - NEEDS DESC
 * @property {any[]} [locations_updated] - NEEDS DESC
 * @property {boolean} [photos_added] - NEEDS DESC
 * @property {boolean} [practitioner_added] - NEEDS DESC
 * @property {number} [profile_completeness] - NEEDS DESC
 * @property {Provider15} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {ProviderProfile1} [provider_profile] - Data specific to the Provider Profile
 * @property {string} [publish_type] - NEEDS DESC
 * @property {boolean} [success] - NEEDS DESC
 * @property {boolean} [treatments_added] - NEEDS DESC
 * @property {User17} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event18
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef ProviderProfile2
 * @property {string} [contact_email_address] -
 * @property {string} [contact_instagram_handle] -
 * @property {string} [contact_phone_number] -
 * @property {boolean} [hide_form] -
 * @property {boolean} [is_fee_towards_treatment_cost] -
 * @property {number} [num_days_closed] -
 * @property {number} [num_days_open] -
 * @property {number} [num_days_set] -
 * @property {number} [num_gallery_photos] -
 * @property {number} [num_products] -
 * @property {string} [send_requests_to_email] -
 */
/**
 * @typedef User18
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ProfileUpdated
 * @property {string} [booking_url] - NEEDS DESC
 * @property {string} [channel] - NEEDS DESC
 * @property {string} [contact_email_address] - NEEDS DESC
 * @property {string} [contact_instagram_handle] - NEEDS DESC
 * @property {string} [contact_phone_number] - NEEDS DESC
 * @property {Event18} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {boolean} [hide_consultation_form] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {boolean} [is_fee_towards_treatment_cost] - NEEDS DESC
 * @property {number} [num_days_closed] - NEEDS DESC
 * @property {number} [num_days_open] - NEEDS DESC
 * @property {number} [num_days_set] - NEEDS DESC
 * @property {number} [num_gallery_photos] - NEEDS DESC
 * @property {number} [num_products] - NEEDS DESC
 * @property {ProviderProfile2} [provider_profile] - Data specific to the Provider Profile
 * @property {string} [send_requests_to_email] - NEEDS DESC
 * @property {User18} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event19
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef User19
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ResultsFiltered
 * @property {Event19} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {Record<string, any>} [filter_data] - Freeform object used to qualify searches. Use name value pairs as specified by business needs
 * @property {Record<string, any>} [filters] - Freeform object used to qualify searches. Use name value pairs as specified by business needs
 * @property {User19} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event20
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef User20
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef ResultsViewed
 * @property {Event20} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [id] - NEEDS DESC
 * @property {string} [results_type] - NEEDS DESC
 * @property {string} [sort_type] - NEEDS DESC
 * @property {User20} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */
/**
 * @typedef Event21
 * @property {string} [action_source] - The root source of the event. See also invoked_by. Valid options are website | app
 * @property {string} [activator] - The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*='abbvie']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.
 * @property {string} [explainer] - Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'
 * @property {Record<string, any>} [extra_data] - Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere
 * @property {string} [invoked_by] - What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)
 * @property {Record<string, any>} [libraries] - Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.
 * @property {string} [step] - Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.
 * @property {string} [type] - Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.
 */
/**
 * @typedef Billing16
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Office16
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Shipping16
 * @property {string} [city] -
 * @property {string} [country] -
 * @property {string} [county] -
 * @property {string} [postal] -
 * @property {string} [street] -
 */
/**
 * @typedef Address16
 * @property {Billing16} [billing] - Billing address, if known
 * @property {Office16} [office] - Principal address. If specifics of shipping/billing etc are not specified, use this one.
 * @property {Shipping16} [shipping] - Shipping address, if known to be different from office address
 */
/**
 * @typedef Ids16
 * @property {string} [business_id] - The uuid of the BUSINESS providerOrganization in the ProviderOrg service
 * @property {string} [location_id] - The uuid of the LOCATION providerOrganization in the ProviderOrg service
 * @property {string} [provider_id] - The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think
 */
/**
 * @typedef Media16
 * @property {string} [brilliantconnections_url] - Brilliant Connections
 * @property {string} [facebook_url] - Facebook
 * @property {string} [instagram_url] - InstaGram
 * @property {string} [linkedin_url] - LinkedIn
 * @property {string} [tiktok_url] - TikTok
 * @property {string} [twitter_url] - Twitter
 * @property {string} [whatsapp_url] - WhatsApp
 * @property {string} [youtube_url] - YouTube channel. If we need to track providers videos for some reason, use event.extra_data
 */
/**
 * @typedef Provider16
 * @property {Address16} [address] - Address Object for provider locations. In general, use office address if the specifics are unknown.
 * @property {Record<string, any>} [contacts] - Freeform object for contact information. Speculative until we know more about structure required.
 * @property {string} [email] - providers primary email
 * @property {Ids16} [ids] -
 * @property {Media16} [media] - Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format
 * @property {string} [name] - Name of Provider
 * @property {string} [phone] - providers primary phone number. 10 digits only, use phone_extension if anything else is required.
 * @property {string} [phone_extension] - Additional Phone information, if required.
 * @property {string} [url] - url of providers website
 */
/**
 * @typedef User21
 * @property {string} [id] - user id. This can also be consumer id or provider id
 * @property {string} [status] - logged_in | logged_out
 * @property {string} [type] - consumer | provider | admin | provider_associate
 */
/**
 * @typedef SiteNavigated
 * @property {string} [card_position] - NEEDS DESC
 * @property {string} [content_campaign] - NEEDS DESC
 * @property {string} [content_concern_area] - NEEDS DESC
 * @property {string} [content_headline] - NEEDS DESC
 * @property {string} [content_journey] - NEEDS DESC
 * @property {string} [content_product] - NEEDS DESC
 * @property {string} [content_tag] - NEEDS DESC
 * @property {string} [content_template] - NEEDS DESC
 * @property {string} [content_type] - NEEDS DESC
 * @property {string} [content_visual] - NEEDS DESC
 * @property {Event21} [event] - Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.
 * @property {string} [event_source_url] - NEEDS DESC
 * @property {number} [expiresAt] - NEEDS DESC
 * @property {string} [id] - NEEDS DESC
 * @property {string} [orientation] - NEEDS DESC
 * @property {Provider16} [provider] - Data pertaining to the Provider. Populate what you have access to, please.
 * @property {string} [section] - NEEDS DESC
 * @property {number} [section_position] - NEEDS DESC
 * @property {string} [text] - NEEDS DESC
 * @property {string} [url] - NEEDS DESC
 * @property {User21} [user] - Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.
 */

/**
 * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was a planned action which now won't take place
 *
 * @param {CheckoutCancelled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutCancelled(
  props?: CheckoutCancelled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was a planned action which now won't take place",
    properties: {
      context: {},
      properties: {
        properties: {
          checkout_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/checkout_id',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          member_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/member_id',
            type: 'string',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Checkout Cancelled',
    type: 'object',
  };
  const message = {
    event: 'Checkout Cancelled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Cancelled',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was finished/committed to a database
 *
 * @param {CheckoutCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutCompleted(
  props?: CheckoutCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was finished/committed to a database',
    properties: {
      context: {},
      properties: {
        properties: {
          checkout_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/checkout_id',
            type: 'string',
          },
          consumer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/consumer_id',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          num_agn_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_agn_brands',
            type: 'number',
          },
          num_nonagn_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_nonagn_brands',
            type: 'number',
          },
          offers_redeemed: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offers_redeemed',
            type: 'array',
          },
          points_earned: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/points_earned',
            type: 'number',
          },
          points_redeemed: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/points_redeemed',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Checkout Completed',
    type: 'object',
  };
  const message = {
    event: 'Checkout Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Completed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was set in operation
 *
 * @param {CheckoutStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
  props?: CheckoutStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was set in operation',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          member_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/member_id',
            type: 'string',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Checkout Started',
    type: 'object',
  };
  const message = {
    event: 'Checkout Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Started',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was given the intention to commit to a database
 *
 * @param {CheckoutSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutSubmitted(
  props?: CheckoutSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was given the intention to commit to a database',
    properties: {
      context: {},
      properties: {
        properties: {
          checkout_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/checkout_id',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          member_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/member_id',
            type: 'string',
          },
          num_agn_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_agn_brands',
            type: 'number',
          },
          num_nonagn_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_nonagn_brands',
            type: 'number',
          },
          offers_redeemed: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offers_redeemed',
            type: 'array',
          },
          points_earned: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/points_earned',
            type: 'number',
          },
          points_redeemed: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/points_redeemed',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          staff_ids: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/staff_ids',
            type: 'array',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Checkout Submitted',
    type: 'object',
  };
  const message = {
    event: 'Checkout Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Submitted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was changed from an older version to a newer
 *
 * @param {CheckoutUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutUpdated(
  props?: CheckoutUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was changed from an older version to a newer',
    properties: {
      context: {},
      properties: {
        properties: {
          checkout_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/checkout_id',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          member_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/member_id',
            type: 'string',
          },
          num_agn_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_agn_brands',
            type: 'number',
          },
          num_nonagn_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_nonagn_brands',
            type: 'number',
          },
          offers_redeemed: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offers_redeemed',
            type: 'array',
          },
          points_earned: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/points_earned',
            type: 'number',
          },
          points_redeemed: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/points_redeemed',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          staff_ids: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/staff_ids',
            type: 'array',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Checkout Updated',
    type: 'object',
  };
  const message = {
    event: 'Checkout Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Checkout Updated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, The permission for something to happen or agreement to do something, was seen by an end user
 *
 * @param {ContentScrolled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentScrolled(
  props?: ContentScrolled,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, The permission for something to happen or agreement to do something, was seen by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          content: {
            description: 'TO DO',
            id: '/properties/properties/properties/content',
            properties: {},
            required: [],
            type: 'object',
          },
          content_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'string',
          },
          fbclid: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/fbclid',
            type: 'string',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number.  10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Content Scrolled',
    type: 'object',
  };
  const message = {
    event: 'Content Scrolled',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Scrolled',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a message or article made available by Allē, was seen by an end user
 *
 * @param {ContentViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentViewed(
  props?: ContentViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a message or article made available by Allē, was seen by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          alle_accepted_hipaa: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_hipaa',
            type: 'boolean',
          },
          alle_accepted_promotions_email: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_promotions_email',
            type: 'boolean',
          },
          alle_accepted_sms: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_sms',
            type: 'boolean',
          },
          alle_accepted_tc: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_tc',
            type: 'boolean',
          },
          alle_account_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_account_type',
            type: 'string',
          },
          alle_device_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_device_type',
            type: 'string',
          },
          alle_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_id',
            type: 'string',
          },
          alle_zip_code: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_zip_code',
            type: 'string',
          },
          anonymousId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/anonymousId',
            type: 'string',
          },
          campaign_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/campaign_id',
            type: 'string',
          },
          content: {
            description: 'Details on the Content object this event is about.',
            id: '/properties/properties/properties/content',
            properties: {
              campaign: {
                description: '',
                id: '/properties/properties/properties/content/properties/campaign',
                type: 'string',
              },
              campaign_id: {
                description: '',
                id: '/properties/properties/properties/content/properties/campaign_id',
                type: 'string',
              },
              campaign_name: {
                description: '',
                id: '/properties/properties/properties/content/properties/campaign_name',
                type: 'string',
              },
              concern_area: {
                description: '',
                id: '/properties/properties/properties/content/properties/concern_area',
                type: 'string',
              },
              content_id: {
                description: '',
                id: '/properties/properties/properties/content/properties/content_id',
                type: 'string',
              },
              headline: {
                description: '',
                id: '/properties/properties/properties/content/properties/headline',
                type: 'string',
              },
              journey: {
                description: '',
                id: '/properties/properties/properties/content/properties/journey',
                type: 'string',
              },
              product: {
                description: '',
                id: '/properties/properties/properties/content/properties/product',
                type: 'string',
              },
              publish_date: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/content/properties/publish_date',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/content/properties/slug',
                type: 'string',
              },
              tag: {
                description: '',
                id: '/properties/properties/properties/content/properties/tag',
                type: 'string',
              },
              template: {
                description: '',
                id: '/properties/properties/properties/content/properties/template',
                type: 'string',
              },
              type: {
                description: 'image/dropdown/popup/callout/etc',
                id: '/properties/properties/properties/content/properties/type',
                type: 'string',
              },
              visual: {
                description: '',
                id: '/properties/properties/properties/content/properties/visual',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          content_campaign: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_campaign',
            type: 'string',
          },
          content_campaign_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_campaign_name',
            type: 'string',
          },
          content_concern_area: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_concern_area',
            type: 'string',
          },
          content_headline: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_headline',
            type: 'string',
          },
          content_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_id',
            type: 'string',
          },
          content_journey: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_journey',
            type: 'string',
          },
          content_product: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_product',
            type: 'string',
          },
          content_publish_date: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_publish_date',
            type: 'string',
          },
          content_slug: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_slug',
            type: 'string',
          },
          content_tag: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_tag',
            type: 'string',
          },
          content_template: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_template',
            type: 'string',
          },
          content_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          content_visual: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_visual',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'number',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          tactic_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/tactic_id',
            type: 'string',
          },
          tactic_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/tactic_name',
            type: 'string',
          },
          tactic_summary: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/tactic_summary',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          userId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/userId',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Content Viewed',
    type: 'object',
  };
  const message = {
    event: 'Content Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Content Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was attached to an account
 *
 * @param {OfferAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerAdded(
  props?: OfferAdded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was attached to an account',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              activation: {
                description: '',
                id: '/properties/properties/properties/offer/properties/activation',
                type: 'string',
              },
              brands: {
                description: '',
                id: '/properties/properties/properties/offer/properties/brands',
                type: 'array',
              },
              expiration: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/expiration',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/offer/properties/name',
                type: 'string',
              },
              offer_id: {
                description: '',
                id: '/properties/properties/properties/offer/properties/offer_id',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/offer/properties/slug',
                type: 'string',
              },
              type: {
                description: '',
                id: '/properties/properties/properties/offer/properties/type',
                type: 'string',
              },
              value: {
                description: '',
                id: '/properties/properties/properties/offer/properties/value',
                type: 'number',
              },
            },
            required: [],
            type: 'object',
          },
          offer_activation: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_activation',
            type: 'string',
          },
          offer_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brands',
            type: 'array',
          },
          offer_expiration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_expiration',
            type: 'string',
          },
          offer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_id',
            type: 'string',
          },
          offer_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_name',
            type: 'string',
          },
          offer_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_type',
            type: 'string',
          },
          offer_value: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_value',
            type: 'number',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Added',
    type: 'object',
  };
  const message = {
    event: 'Offer Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Added',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, became owned by an end user
 *
 * @param {OfferClaimed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerClaimed(
  props?: OfferClaimed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, became owned by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          alle_device_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_device_type',
            type: 'string',
          },
          alle_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_id',
            type: 'string',
          },
          anonymousId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/anonymousId',
            type: 'string',
          },
          bd_migration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/bd_migration',
            type: 'string',
          },
          campaign_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/campaign_name',
            type: 'string',
          },
          campaign_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'number',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          new_registration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/new_registration',
            type: 'boolean',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              activation: {
                description: '',
                id: '/properties/properties/properties/offer/properties/activation',
                type: 'string',
              },
              brands: {
                description: '',
                id: '/properties/properties/properties/offer/properties/brands',
                type: 'array',
              },
              expiration: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/expiration',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/offer/properties/name',
                type: 'string',
              },
              offer_id: {
                description: '',
                id: '/properties/properties/properties/offer/properties/offer_id',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/offer/properties/slug',
                type: 'string',
              },
              type: {
                description: '',
                id: '/properties/properties/properties/offer/properties/type',
                type: 'string',
              },
              value: {
                description: '',
                id: '/properties/properties/properties/offer/properties/value',
                type: 'number',
              },
            },
            required: [],
            type: 'object',
          },
          offer_activation: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_activation',
            type: 'string',
          },
          offer_brand: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brand',
            type: 'string',
          },
          offer_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brands',
            type: 'array',
          },
          offer_expiration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_expiration',
            type: 'string',
          },
          offer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_id',
            type: 'string',
          },
          offer_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_name',
            type: 'string',
          },
          offer_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_type',
            type: 'string',
          },
          offer_value: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_value',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          userId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/userId',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Claimed',
    type: 'object',
  };
  const message = {
    event: 'Offer Claimed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Claimed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was unsuccessful at completing it's intended action
 *
 * @param {OfferFailed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerFailed(
  props?: OfferFailed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Whereby, an incentive from Allergan or a provider, was unsuccessful at completing it's intended action",
    properties: {
      context: {},
      properties: {
        properties: {
          bd_migration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/bd_migration',
            type: 'string',
          },
          campaign_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/campaign_name',
            type: 'string',
          },
          campaign_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          error_reason: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/error_reason',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'number',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          new_registration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/new_registration',
            type: 'boolean',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              activation: {
                description: '',
                id: '/properties/properties/properties/offer/properties/activation',
                type: 'string',
              },
              brands: {
                description: '',
                id: '/properties/properties/properties/offer/properties/brands',
                type: 'array',
              },
              expiration: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/expiration',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/offer/properties/name',
                type: 'string',
              },
              offer_id: {
                description: '',
                id: '/properties/properties/properties/offer/properties/offer_id',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/offer/properties/slug',
                type: 'string',
              },
              type: {
                description: '',
                id: '/properties/properties/properties/offer/properties/type',
                type: 'string',
              },
              value: {
                description: '',
                id: '/properties/properties/properties/offer/properties/value',
                type: 'number',
              },
            },
            required: [],
            type: 'object',
          },
          offer_activation: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_activation',
            type: 'string',
          },
          offer_brand: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brand',
            type: 'string',
          },
          offer_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brands',
            type: 'array',
          },
          offer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_id',
            type: 'string',
          },
          offer_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_type',
            type: 'string',
          },
          offer_value: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_value',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Failed',
    type: 'object',
  };
  const message = {
    event: 'Offer Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Failed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was seen by an end user
 *
 * @param {OfferPresented} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerPresented(
  props?: OfferPresented,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was seen by an end user',
    properties: {
      context: {},
      properties: {
        properties: {
          alle_accepted_hipaa: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_hipaa',
            type: 'boolean',
          },
          alle_accepted_promotions_email: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_promotions_email',
            type: 'boolean',
          },
          alle_accepted_sms: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_sms',
            type: 'boolean',
          },
          alle_accepted_tc: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_tc',
            type: 'boolean',
          },
          alle_account_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_account_type',
            type: 'string',
          },
          alle_device_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_device_type',
            type: 'string',
          },
          alle_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_id',
            type: 'string',
          },
          alle_zip_code: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_zip_code',
            type: 'string',
          },
          anonymousId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/anonymousId',
            type: 'string',
          },
          bd_consumer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/bd_consumer_id',
            type: 'string',
          },
          campaign_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/campaign_name',
            type: 'string',
          },
          campaign_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'number',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              activation: {
                description: '',
                id: '/properties/properties/properties/offer/properties/activation',
                type: 'string',
              },
              brands: {
                description: '',
                id: '/properties/properties/properties/offer/properties/brands',
                type: 'array',
              },
              expiration: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/expiration',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/offer/properties/name',
                type: 'string',
              },
              offer_id: {
                description: '',
                id: '/properties/properties/properties/offer/properties/offer_id',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/offer/properties/slug',
                type: 'string',
              },
              type: {
                description: '',
                id: '/properties/properties/properties/offer/properties/type',
                type: 'string',
              },
              value: {
                description: '',
                id: '/properties/properties/properties/offer/properties/value',
                type: 'number',
              },
            },
            required: [],
            type: 'object',
          },
          offer_activation: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_activation',
            type: 'string',
          },
          offer_brand: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brand',
            type: 'string',
          },
          offer_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brands',
            type: 'array',
          },
          offer_expiration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_expiration',
            type: 'string',
          },
          offer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_id',
            type: 'string',
          },
          offer_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_name',
            type: 'string',
          },
          offer_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_type',
            type: 'string',
          },
          offer_value: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_value',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/provider_id',
            type: 'string',
          },
          template_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/template_id',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          userId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/userId',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Presented',
    type: 'object',
  };
  const message = {
    event: 'Offer Presented',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Presented',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was exchanged as a form of value
 *
 * @param {OfferRedeemed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerRedeemed(
  props?: OfferRedeemed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was exchanged as a form of value',
    properties: {
      context: {},
      properties: {
        properties: {
          campaign_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/campaign_type',
            type: 'string',
          },
          checkout_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/checkout_id',
            type: 'string',
          },
          consumer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/consumer_id',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              activation: {
                description: '',
                id: '/properties/properties/properties/offer/properties/activation',
                type: 'string',
              },
              brands: {
                description: '',
                id: '/properties/properties/properties/offer/properties/brands',
                type: 'array',
              },
              expiration: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/expiration',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/offer/properties/name',
                type: 'string',
              },
              offer_id: {
                description: '',
                id: '/properties/properties/properties/offer/properties/offer_id',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/offer/properties/slug',
                type: 'string',
              },
              type: {
                description: '',
                id: '/properties/properties/properties/offer/properties/type',
                type: 'string',
              },
              value: {
                description: '',
                id: '/properties/properties/properties/offer/properties/value',
                type: 'number',
              },
            },
            required: [],
            type: 'object',
          },
          offer_activation: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_activation',
            type: 'string',
          },
          offer_brand: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brand',
            type: 'string',
          },
          offer_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brands',
            type: 'array',
          },
          offer_expiration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_expiration',
            type: 'string',
          },
          offer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_id',
            type: 'string',
          },
          offer_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_name',
            type: 'string',
          },
          offer_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_type',
            type: 'string',
          },
          offer_value: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_value',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Redeemed',
    type: 'object',
  };
  const message = {
    event: 'Offer Redeemed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Redeemed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was subtracted or pulled back
 *
 * @param {OfferRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerRemoved(
  props?: OfferRemoved,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was subtracted or pulled back',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'number',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              activation: {
                description: '',
                id: '/properties/properties/properties/offer/properties/activation',
                type: 'string',
              },
              brands: {
                description: '',
                id: '/properties/properties/properties/offer/properties/brands',
                type: 'array',
              },
              expiration: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/expiration',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/offer/properties/name',
                type: 'string',
              },
              offer_id: {
                description: '',
                id: '/properties/properties/properties/offer/properties/offer_id',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/offer/properties/slug',
                type: 'string',
              },
              type: {
                description: '',
                id: '/properties/properties/properties/offer/properties/type',
                type: 'string',
              },
              value: {
                description: '',
                id: '/properties/properties/properties/offer/properties/value',
                type: 'number',
              },
            },
            required: [],
            type: 'object',
          },
          offer_activation: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_activation',
            type: 'string',
          },
          offer_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brands',
            type: 'array',
          },
          offer_expiration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_expiration',
            type: 'string',
          },
          offer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_id',
            type: 'string',
          },
          offer_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_name',
            type: 'string',
          },
          offer_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_type',
            type: 'string',
          },
          offer_value: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_value',
            type: 'number',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Removed',
    type: 'object',
  };
  const message = {
    event: 'Offer Removed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Removed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was distributed to others
 *
 * @param {OfferShared} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerShared(
  props?: OfferShared,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was distributed to others',
    properties: {
      context: {},
      properties: {
        properties: {
          alle_device_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_device_type',
            type: 'string',
          },
          alle_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_id',
            type: 'string',
          },
          anonymousId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/anonymousId',
            type: 'string',
          },
          email_shared: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/email_shared',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'number',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              activation: {
                description: '',
                id: '/properties/properties/properties/offer/properties/activation',
                type: 'string',
              },
              brands: {
                description: '',
                id: '/properties/properties/properties/offer/properties/brands',
                type: 'array',
              },
              expiration: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/expiration',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/offer/properties/name',
                type: 'string',
              },
              offer_id: {
                description: '',
                id: '/properties/properties/properties/offer/properties/offer_id',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/offer/properties/slug',
                type: 'string',
              },
              type: {
                description: '',
                id: '/properties/properties/properties/offer/properties/type',
                type: 'string',
              },
              value: {
                description: '',
                id: '/properties/properties/properties/offer/properties/value',
                type: 'number',
              },
            },
            required: [],
            type: 'object',
          },
          offer_activation: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_activation',
            type: 'string',
          },
          offer_brand: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brand',
            type: 'string',
          },
          offer_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brands',
            type: 'array',
          },
          offer_expiration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_expiration',
            type: 'string',
          },
          offer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_id',
            type: 'string',
          },
          offer_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_name',
            type: 'string',
          },
          offer_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_type',
            type: 'string',
          },
          offer_value: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_value',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          userId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/userId',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Shared',
    type: 'object',
  };
  const message = {
    event: 'Offer Shared',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Shared',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was given the intention to commit to a database
 *
 * @param {OfferSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerSubmitted(
  props?: OfferSubmitted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was given the intention to commit to a database',
    properties: {
      context: {},
      properties: {
        properties: {
          alle_accepted_hipaa: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_hipaa',
            type: 'boolean',
          },
          alle_accepted_promotions_email: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_promotions_email',
            type: 'boolean',
          },
          alle_accepted_sms: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_sms',
            type: 'boolean',
          },
          alle_accepted_tc: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_tc',
            type: 'boolean',
          },
          alle_account_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_account_type',
            type: 'string',
          },
          alle_device_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_device_type',
            type: 'string',
          },
          alle_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_id',
            type: 'string',
          },
          anonymousId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/anonymousId',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'number',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              activation: {
                description: '',
                id: '/properties/properties/properties/offer/properties/activation',
                type: 'string',
              },
              brands: {
                description: '',
                id: '/properties/properties/properties/offer/properties/brands',
                type: 'array',
              },
              expiration: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/expiration',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/offer/properties/name',
                type: 'string',
              },
              offer_id: {
                description: '',
                id: '/properties/properties/properties/offer/properties/offer_id',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/offer/properties/slug',
                type: 'string',
              },
              type: {
                description: '',
                id: '/properties/properties/properties/offer/properties/type',
                type: 'string',
              },
              value: {
                description: '',
                id: '/properties/properties/properties/offer/properties/value',
                type: 'number',
              },
            },
            required: [],
            type: 'object',
          },
          offer_activation: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_activation',
            type: 'string',
          },
          offer_brand: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brand',
            type: 'string',
          },
          offer_brands: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_brands',
            type: 'array',
          },
          offer_expiration: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_expiration',
            type: 'string',
          },
          offer_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_id',
            type: 'string',
          },
          offer_name: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_name',
            type: 'string',
          },
          offer_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_type',
            type: 'string',
          },
          offer_value: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/offer_value',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          userId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/userId',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Submitted',
    type: 'object',
  };
  const message = {
    event: 'Offer Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Submitted',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, an incentive from Allergan or a provider, was prevented from being shown
 *
 * @param {OfferSuppressed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerSuppressed(
  props?: OfferSuppressed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, an incentive from Allergan or a provider, was prevented from being shown',
    properties: {
      context: {},
      properties: {
        properties: {
          alle_accepted_hipaa: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_hipaa',
            type: 'boolean',
          },
          alle_accepted_promotions_email: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_promotions_email',
            type: 'boolean',
          },
          alle_accepted_sms: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_sms',
            type: 'boolean',
          },
          alle_accepted_tc: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_accepted_tc',
            type: 'boolean',
          },
          alle_account_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_account_type',
            type: 'string',
          },
          alle_device_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_device_type',
            type: 'string',
          },
          alle_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_id',
            type: 'string',
          },
          alle_zip_code: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/alle_zip_code',
            type: 'string',
          },
          anonymousId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/anonymousId',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          member_id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/member_id',
            type: 'string',
          },
          offer: {
            description: '',
            id: '/properties/properties/properties/offer',
            properties: {
              activation: {
                description: '',
                id: '/properties/properties/properties/offer/properties/activation',
                type: 'string',
              },
              brands: {
                description: '',
                id: '/properties/properties/properties/offer/properties/brands',
                type: 'array',
              },
              expiration: {
                description: '',
                format: 'date-time',
                id: '/properties/properties/properties/offer/properties/expiration',
                type: 'string',
              },
              name: {
                description: '',
                id: '/properties/properties/properties/offer/properties/name',
                type: 'string',
              },
              offer_id: {
                description: '',
                id: '/properties/properties/properties/offer/properties/offer_id',
                type: 'string',
              },
              slug: {
                description: '',
                id: '/properties/properties/properties/offer/properties/slug',
                type: 'string',
              },
              type: {
                description: '',
                id: '/properties/properties/properties/offer/properties/type',
                type: 'string',
              },
              value: {
                description: '',
                id: '/properties/properties/properties/offer/properties/value',
                type: 'number',
              },
            },
            required: [],
            type: 'object',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          reason: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/reason',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          userId: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/userId',
            type: 'string',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offer Suppressed',
    type: 'object',
  };
  const message = {
    event: 'Offer Suppressed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Offer Suppressed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a human-formed description of a person or organization, was finished/committed to a database
 *
 * @param {ProfileCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileCompleted(
  props?: ProfileCompleted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a human-formed description of a person or organization, was finished/committed to a database',
    properties: {
      context: {},
      properties: {
        properties: {
          booking_url: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/booking_url',
            type: 'string',
          },
          channel: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/channel',
            type: 'string',
          },
          contact_email_address: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/contact_email_address',
            type: 'string',
          },
          contact_instagram_handle: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/contact_instagram_handle',
            type: 'string',
          },
          contact_phone_number: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/contact_phone_number',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          hide_consultation_form: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/hide_consultation_form',
            type: 'boolean',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          is_fee_towards_treatment_cost: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/is_fee_towards_treatment_cost',
            type: 'boolean',
          },
          num_days_closed: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_days_closed',
            type: 'number',
          },
          num_days_open: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_days_open',
            type: 'number',
          },
          num_days_set: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_days_set',
            type: 'number',
          },
          num_gallery_photos: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_gallery_photos',
            type: 'number',
          },
          num_products: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_products',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider_profile: {
            description: 'Data specific to the Provider Profile',
            id: '/properties/properties/properties/provider_profile',
            properties: {
              contact_email_address: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/contact_email_address',
                type: 'string',
              },
              contact_instagram_handle: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/contact_instagram_handle',
                type: 'string',
              },
              contact_phone_number: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/contact_phone_number',
                type: 'string',
              },
              hide_form: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/hide_form',
                type: 'boolean',
              },
              is_fee_towards_treatment_cost: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/is_fee_towards_treatment_cost',
                type: 'boolean',
              },
              num_days_closed: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_days_closed',
                type: 'integer',
              },
              num_days_open: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_days_open',
                type: 'integer',
              },
              num_days_set: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_days_set',
                type: 'integer',
              },
              num_gallery_photos: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_gallery_photos',
                type: 'integer',
              },
              num_products: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_products',
                type: 'integer',
              },
              send_requests_to_email: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/send_requests_to_email',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          publish_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/publish_type',
            type: 'string',
          },
          send_requests_to_email: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/send_requests_to_email',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Completed',
    type: 'object',
  };
  const message = {
    event: 'Profile Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Completed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a human-formed description of a person or organization, was prepared for public readership.
 *
 * @param {ProfilePublished} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profilePublished(
  props?: ProfilePublished,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a human-formed description of a person or organization, was prepared for public readership.',
    properties: {
      context: {},
      properties: {
        properties: {
          booking_url: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/booking_url',
            type: 'string',
          },
          channel: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/channel',
            type: 'string',
          },
          consultation_cost: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/consultation_cost',
            type: 'string',
          },
          consultation_enabled: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/consultation_enabled',
            type: 'boolean',
          },
          contact_info_added: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/contact_info_added',
            type: 'boolean',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          hours_added: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/hours_added',
            type: 'boolean',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          location_count: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/location_count',
            type: 'number',
          },
          locations_updated: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/locations_updated',
            type: 'array',
          },
          photos_added: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/photos_added',
            type: 'boolean',
          },
          practitioner_added: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/practitioner_added',
            type: 'boolean',
          },
          profile_completeness: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/profile_completeness',
            type: 'number',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          provider_profile: {
            description: 'Data specific to the Provider Profile',
            id: '/properties/properties/properties/provider_profile',
            properties: {
              contact_email_address: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/contact_email_address',
                type: 'string',
              },
              contact_instagram_handle: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/contact_instagram_handle',
                type: 'string',
              },
              contact_phone_number: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/contact_phone_number',
                type: 'string',
              },
              hide_form: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/hide_form',
                type: 'boolean',
              },
              is_fee_towards_treatment_cost: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/is_fee_towards_treatment_cost',
                type: 'boolean',
              },
              num_days_closed: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_days_closed',
                type: 'integer',
              },
              num_days_open: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_days_open',
                type: 'integer',
              },
              num_days_set: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_days_set',
                type: 'integer',
              },
              num_gallery_photos: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_gallery_photos',
                type: 'integer',
              },
              num_products: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_products',
                type: 'integer',
              },
              send_requests_to_email: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/send_requests_to_email',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          publish_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/publish_type',
            type: 'string',
          },
          success: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/success',
            type: 'boolean',
          },
          treatments_added: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/treatments_added',
            type: 'boolean',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Published',
    type: 'object',
  };
  const message = {
    event: 'Profile Published',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Published',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a human-formed description of a person or organization, was changed from an older version to a newer
 *
 * @param {ProfileUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileUpdated(
  props?: ProfileUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a human-formed description of a person or organization, was changed from an older version to a newer',
    properties: {
      context: {},
      properties: {
        properties: {
          booking_url: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/booking_url',
            type: 'string',
          },
          channel: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/channel',
            type: 'string',
          },
          contact_email_address: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/contact_email_address',
            type: 'string',
          },
          contact_instagram_handle: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/contact_instagram_handle',
            type: 'string',
          },
          contact_phone_number: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/contact_phone_number',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          hide_consultation_form: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/hide_consultation_form',
            type: 'boolean',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          is_fee_towards_treatment_cost: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/is_fee_towards_treatment_cost',
            type: 'boolean',
          },
          num_days_closed: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_days_closed',
            type: 'number',
          },
          num_days_open: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_days_open',
            type: 'number',
          },
          num_days_set: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_days_set',
            type: 'number',
          },
          num_gallery_photos: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_gallery_photos',
            type: 'number',
          },
          num_products: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/num_products',
            type: 'number',
          },
          provider_profile: {
            description: 'Data specific to the Provider Profile',
            id: '/properties/properties/properties/provider_profile',
            properties: {
              contact_email_address: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/contact_email_address',
                type: 'string',
              },
              contact_instagram_handle: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/contact_instagram_handle',
                type: 'string',
              },
              contact_phone_number: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/contact_phone_number',
                type: 'string',
              },
              hide_form: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/hide_form',
                type: 'boolean',
              },
              is_fee_towards_treatment_cost: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/is_fee_towards_treatment_cost',
                type: 'boolean',
              },
              num_days_closed: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_days_closed',
                type: 'integer',
              },
              num_days_open: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_days_open',
                type: 'integer',
              },
              num_days_set: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_days_set',
                type: 'integer',
              },
              num_gallery_photos: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_gallery_photos',
                type: 'integer',
              },
              num_products: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/num_products',
                type: 'integer',
              },
              send_requests_to_email: {
                description: '',
                id: '/properties/properties/properties/provider_profile/properties/send_requests_to_email',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          send_requests_to_email: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/send_requests_to_email',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Profile Updated',
    type: 'object',
  };
  const message = {
    event: 'Profile Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Profile Updated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a set of search results, was made more succinct by querying against some specific criteria
 *
 * @param {ResultsFiltered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resultsFiltered(
  props?: ResultsFiltered,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a set of search results, was made more succinct by querying against some specific criteria',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          filter_data: {
            description:
              'Freeform object used to qualify searches. Use name value pairs as specified by business needs',
            id: '/properties/properties/properties/event',
            required: [],
            type: 'object',
          },
          filters: {
            description:
              'Freeform object used to qualify searches. Use name value pairs as specified by business needs',
            id: '/properties/properties/properties/event',
            required: [],
            type: 'object',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Results Filtered',
    type: 'object',
  };
  const message = {
    event: 'Results Filtered',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Results Filtered',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a set of search results, was seen by an end user.
 *
 * @param {ResultsViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resultsViewed(
  props?: ResultsViewed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Whereby, a set of search results, was seen by an end user.',
    properties: {
      context: {},
      properties: {
        properties: {
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          results_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/results_type',
            type: 'string',
          },
          sort_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/sort_type',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Results Viewed',
    type: 'object',
  };
  const message = {
    event: 'Results Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Results Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Whereby, a web or native experience, was engaged with resulting in moving from one page to another
 *
 * @param {SiteNavigated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function siteNavigated(
  props?: SiteNavigated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Whereby, a web or native experience, was engaged with resulting in moving from one page to another',
    properties: {
      context: {},
      properties: {
        properties: {
          card_position: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/card_position',
            type: 'string',
          },
          content_campaign: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_campaign',
            type: 'string',
          },
          content_concern_area: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_concern_area',
            type: 'string',
          },
          content_headline: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_headline',
            type: 'string',
          },
          content_journey: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_journey',
            type: 'string',
          },
          content_product: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_product',
            type: 'string',
          },
          content_tag: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_tag',
            type: 'string',
          },
          content_template: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_template',
            type: 'string',
          },
          content_type: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_type',
            type: 'string',
          },
          content_visual: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/content_visual',
            type: 'string',
          },
          event: {
            description:
              'Data pertaining to this actual event. Used by numerous parties including analytsts, business, qa, and engineering. Engineers: Please populate what you can, when you can.',
            id: '/properties/properties/properties/event',
            properties: {
              action_source: {
                description:
                  'The root source of the event. See also invoked_by. Valid options are website | app',
                id: '/properties/properties/properties/event/properties/action_source',
                type: 'string',
              },
              activator: {
                description:
                  'The DOM object (or in the case of back end calls, the java/react method) whcih triggered the event. Can be human-readable ("Big red button in hamburger menu") or more specific ("a[href*=\'abbvie\']") as needed. Please be specific as multiple screen objects may make the exact same Event call, and data funnelers will want to know which method is more popular.',
                id: '/properties/properties/properties/event/properties/activator',
                type: 'string',
              },
              explainer: {
                description:
                  "Business reason, in human terms, this event was fired. Example 'Tracking signup dropoffs' or 'Tracking if users played video'",
                id: '/properties/properties/properties/event/properties/explainer',
                type: 'string',
              },
              extra_data: {
                description:
                  "Freeform nested Object to contain any additional information that the Brand may want to record, which isn't covered elsewhere",
                id: '/properties/properties/properties/event/properties/extra_data',
                type: 'object',
              },
              invoked_by: {
                description:
                  'What actually invoked the event? See also action_source. Valid options are consumer | provider | admin for user interactions and app | server | gui for automated events (eg timeouts, calculations, credit card transactions, etc)',
                id: '/properties/properties/properties/event/properties/invoked_by',
                type: 'string',
              },
              libraries: {
                description:
                  'Tracks libraries used to generate this Event. This data is used to filter out cached data, zombie users, and to detect fraud.',
                id: '/properties/properties/properties/event/properties/libraries',
                properties: {},
                required: [],
                type: 'object',
              },
              step: {
                description:
                  'Used to identify a step in a multi-phase event, so that we can detect funnelling and dropoffs. For example Regitration might be Billing Address entry. Be verbose when possible.',
                id: '/properties/properties/properties/event/properties/step',
                type: 'string',
              },
              type: {
                description:
                  'Used to provide specifics when an Event name is ambiguous, e.g. Element Engaged or Site Navigated might have Carousel or Template Viewer while Registration might provide step specifics such as the field type.',
                id: '/properties/properties/properties/event/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          event_source_url: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/event_source_url',
            type: 'string',
          },
          expiresAt: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/expiresAt',
            type: 'number',
          },
          id: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/id',
            type: 'string',
          },
          orientation: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/orientation',
            type: 'string',
          },
          provider: {
            description:
              'Data pertaining to the Provider. Populate what you have access to, please.',
            id: '/properties/properties/properties/provider',
            properties: {
              address: {
                description:
                  'Address Object for provider locations. In general, use office address if the specifics are unknown.',
                id: '/properties/properties/properties/provider/properties/address',
                properties: {
                  billing: {
                    description: 'Billing address, if known',
                    id: '/properties/properties/properties/provider/properties/address/properties/billing',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/billing/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  office: {
                    description:
                      'Principal address. If specifics of shipping/billing etc are not specified, use this one.',
                    id: '/properties/properties/properties/provider/properties/address/properties/office',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/office/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                  shipping: {
                    description:
                      'Shipping address, if known to be different from office address',
                    id: '/properties/properties/properties/provider/properties/address/properties/shipping',
                    properties: {
                      city: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/city',
                        type: 'string',
                      },
                      country: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/country',
                        type: 'string',
                      },
                      county: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/county',
                        type: 'string',
                      },
                      postal: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/postal',
                        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
                        type: 'string',
                      },
                      street: {
                        description: '',
                        id: '/properties/properties/properties/provider/properties/address/properties/shipping/properties/street',
                        type: 'string',
                      },
                    },
                    required: [],
                    type: 'object',
                  },
                },
                required: [],
                type: 'object',
              },
              contacts: {
                description:
                  'Freeform object for contact information. Speculative until we know more about structure required.',
                id: '/properties/properties/properties/provider/properties/contacts',
                type: 'object',
              },
              email: {
                description: 'providers primary email',
                id: '/properties/properties/properties/provider/properties/email',
                type: 'string',
              },
              ids: {
                description: '',
                id: '/properties/properties/properties/provider/properties/ids',
                properties: {
                  business_id: {
                    description:
                      'The uuid of the BUSINESS providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/business_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  location_id: {
                    description:
                      'The uuid of the LOCATION providerOrganization in the ProviderOrg service',
                    id: '/properties/properties/properties/provider/properties/ids/properties/location_id',
                    pattern:
                      '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
                    type: 'string',
                  },
                  provider_id: {
                    description:
                      'The legacy SAP ShipTo Account Number (with any leading zeroes); will soon be deprecated I think',
                    id: '/properties/properties/properties/provider/properties/ids/properties/provider_id',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              media: {
                description:
                  "Provider's media links, such as facebook or brilliant connections. This is a freeform object, feel free to add new links, just follow the format",
                id: '/properties/properties/properties/provider/properties/media',
                properties: {
                  brilliantconnections_url: {
                    description: 'Brilliant Connections',
                    id: '/properties/properties/properties/provider/properties/media/properties/brilliantconnections_url',
                    type: 'string',
                  },
                  facebook_url: {
                    description: 'Facebook',
                    id: '/properties/properties/properties/provider/properties/media/properties/facebook_url',
                    type: 'string',
                  },
                  instagram_url: {
                    description: 'InstaGram',
                    id: '/properties/properties/properties/provider/properties/media/properties/instagram_url',
                    type: 'string',
                  },
                  linkedin_url: {
                    description: 'LinkedIn',
                    id: '/properties/properties/properties/provider/properties/media/properties/linkedin_url',
                    type: 'string',
                  },
                  tiktok_url: {
                    description: 'TikTok',
                    id: '/properties/properties/properties/provider/properties/media/properties/tiktok_url',
                    type: 'string',
                  },
                  twitter_url: {
                    description: 'Twitter',
                    id: '/properties/properties/properties/provider/properties/media/properties/twitter_url',
                    type: 'string',
                  },
                  whatsapp_url: {
                    description: 'WhatsApp',
                    id: '/properties/properties/properties/provider/properties/media/properties/whatsapp_url',
                    type: 'string',
                  },
                  youtube_url: {
                    description:
                      'YouTube channel. If we need to track providers videos for some reason, use event.extra_data',
                    id: '/properties/properties/properties/provider/properties/media/properties/youtube_url',
                    type: 'string',
                  },
                },
                required: [],
                type: 'object',
              },
              name: {
                description: 'Name of Provider',
                id: '/properties/properties/properties/provider/properties/name',
                type: 'string',
              },
              phone: {
                description:
                  'providers primary phone number. 10 digits only, use phone_extension if anything else is required.',
                id: '/properties/properties/properties/provider/properties/phone',
                pattern: '\\d{10}',
                type: 'string',
              },
              phone_extension: {
                description: 'Additional Phone information, if required.',
                id: '/properties/properties/properties/provider/properties/phone_extension',
                type: 'string',
              },
              url: {
                description: 'url of providers website',
                id: '/properties/properties/properties/provider/properties/url',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
          section: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/section',
            type: 'string',
          },
          section_position: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/section_position',
            type: 'number',
          },
          text: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/text',
            type: 'string',
          },
          url: {
            description: 'NEEDS DESC',
            id: '/properties/properties/properties/url',
            type: 'string',
          },
          user: {
            description:
              'Data pertaining to the current user. While not always required by business, analysts and engineers will want to know this info, so please populate if you know it.',
            id: '/properties/properties/properties/user',
            properties: {
              id: {
                description:
                  'user id. This can also be consumer id or provider id',
                id: '/properties/properties/properties/user/properties/id',
                type: 'string',
              },
              status: {
                description: 'logged_in | logged_out',
                id: '/properties/properties/properties/user/properties/status',
                type: 'string',
              },
              type: {
                description: 'consumer | provider | admin | provider_associate',
                id: '/properties/properties/properties/user/properties/type',
                type: 'string',
              },
            },
            required: [],
            type: 'object',
          },
        },
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Site Navigated',
    type: 'object',
  };
  const message = {
    event: 'Site Navigated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track(
      'Site Navigated',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was a planned action which now won't take place
   *
   * @param {CheckoutCancelled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutCancelled,
  /**
   * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was finished/committed to a database
   *
   * @param {CheckoutCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutCompleted,
  /**
   * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was set in operation
   *
   * @param {CheckoutStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutStarted,
  /**
   * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was given the intention to commit to a database
   *
   * @param {CheckoutSubmitted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutSubmitted,
  /**
   * Whereby, a Procedural step where a consumer exchanges value with a customer or through Allē, was changed from an older version to a newer
   *
   * @param {CheckoutUpdated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutUpdated,
  /**
   * Whereby, The permission for something to happen or agreement to do something, was seen by an end user
   *
   * @param {ContentScrolled} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentScrolled,
  /**
   * Whereby, a message or article made available by Allē, was seen by an end user
   *
   * @param {ContentViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contentViewed,
  /**
   * Whereby, an incentive from Allergan or a provider, was attached to an account
   *
   * @param {OfferAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerAdded,
  /**
   * Whereby, an incentive from Allergan or a provider, became owned by an end user
   *
   * @param {OfferClaimed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerClaimed,
  /**
   * Whereby, an incentive from Allergan or a provider, was unsuccessful at completing it's intended action
   *
   * @param {OfferFailed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerFailed,
  /**
   * Whereby, an incentive from Allergan or a provider, was seen by an end user
   *
   * @param {OfferPresented} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerPresented,
  /**
   * Whereby, an incentive from Allergan or a provider, was exchanged as a form of value
   *
   * @param {OfferRedeemed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerRedeemed,
  /**
   * Whereby, an incentive from Allergan or a provider, was subtracted or pulled back
   *
   * @param {OfferRemoved} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerRemoved,
  /**
   * Whereby, an incentive from Allergan or a provider, was distributed to others
   *
   * @param {OfferShared} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerShared,
  /**
   * Whereby, an incentive from Allergan or a provider, was given the intention to commit to a database
   *
   * @param {OfferSubmitted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerSubmitted,
  /**
   * Whereby, an incentive from Allergan or a provider, was prevented from being shown
   *
   * @param {OfferSuppressed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offerSuppressed,
  /**
   * Whereby, a human-formed description of a person or organization, was finished/committed to a database
   *
   * @param {ProfileCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileCompleted,
  /**
   * Whereby, a human-formed description of a person or organization, was prepared for public readership.
   *
   * @param {ProfilePublished} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profilePublished,
  /**
   * Whereby, a human-formed description of a person or organization, was changed from an older version to a newer
   *
   * @param {ProfileUpdated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  profileUpdated,
  /**
   * Whereby, a set of search results, was made more succinct by querying against some specific criteria
   *
   * @param {ResultsFiltered} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resultsFiltered,
  /**
   * Whereby, a set of search results, was seen by an end user.
   *
   * @param {ResultsViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resultsViewed,
  /**
   * Whereby, a web or native experience, was engaged with resulting in moving from one page to another
   *
   * @param {SiteNavigated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  siteNavigated,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method
      )}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/allergan-dev/protocols/tracking-plans/rs_2KkOc53E84pphEYjhIsYv8a5ZIc`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext()
        );
      }
    };
  },
});
