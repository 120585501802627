import React from 'react';
import { ColorMode } from '@allergan-data-labs/alle-elements-core';
import { Box, Stack } from '@chakra-ui/react';
import { Skeleton } from '@allergan-data-labs/alle-elements';
import {
  getSkeletonContainerStyles,
  getSkeletonRowStyles,
} from '../searchField.theme';

const BASE_FONT_SIZE_PX = 16;

const SkeletonLoader = ({
  count = 1,
  colorMode = 'light',
  height = 65,
}: {
  count?: number;
  colorMode?: ColorMode;
  height?: number;
}) => {
  return (
    <Stack sx={getSkeletonContainerStyles()} className="alle-skeleton-loader">
      {[...Array(count)].map((_, index) => (
        <Box sx={getSkeletonRowStyles()} key={index}>
          <Skeleton
            colorMode={colorMode}
            height={`${height / BASE_FONT_SIZE_PX}rem`}
          />
        </Box>
      ))}
    </Stack>
  );
};

export default SkeletonLoader;
