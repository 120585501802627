import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { BaseComponentProps } from '../../common';

export interface OfferBuilderBannerProps extends BaseComponentProps {
  searchTerms?: string;
  foundIDType?: string;
}

const OfferBuilderBanner: React.FC<OfferBuilderBannerProps> = ({
  searchTerms = '',
  foundIDType = '',
  dataTestId = 'offer-builder-banner',
}) => {
  return (
    <Box marginTop="1.5rem" padding=".25rem" data-testid={dataTestId}>
      <Box
        sx={{
          borderRadius: '0.5rem',
          borderColor: 'light.Border/Neutral/Subtle 2',
          borderWidth: '1px',
          borderStyle: 'solid',
          backgroundColor: 'light.Container/Neutral/Mute 1',
          padding: '0.75rem 1rem',
        }}
      >
        <Text>
          The offer below has the {foundIDType}:&nbsp;
          <Text as="span" fontWeight="bold">
            {searchTerms}
          </Text>
        </Text>
      </Box>
    </Box>
  );
};

export { OfferBuilderBanner };
