import React from 'react';
import { Flex, Box, Text, forwardRef } from '@chakra-ui/react';
import {
  ButtonGroup,
  Button,
  ActionMenu,
  ActionMenuContent,
  getActionContentStyles,
  IconButton,
  IconButtonProps,
} from '@allergan-data-labs/alle-elements';
import { alleColors as colors } from '@allergan-data-labs/component-library/src/colorPalette';
import { OverflowIcon } from '@allergan-data-labs/alle-elements-icons/16/overflowIcon';
import { BackButton } from '../../backButton';

export interface PageHeaderProps {
  title: string;
  backLabel?: string;
  onBack?: () => void;
  description?: string;
  actionLabel?: string;
  onAction?: () => void;
  secondaryActionLabel?: string;
  onSecondaryAction?: () => void;
}

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  title,
  backLabel,
  onBack = () => {},
  description,
  actionLabel,
  onAction,
  secondaryActionLabel,
  onSecondaryAction,
}) => {
  const MenuIconButton = forwardRef<IconButtonProps, 'button'>((props, ref) => (
    <IconButton
      data-testid="section-header-menu-button"
      icon={<OverflowIcon color={colors.black} />}
      ref={ref}
      size="sm"
      variant="solid"
      colorMode="light"
      colorScheme="muted"
      {...props}
    />
  ));

  return (
    <Box
      paddingTop={24}
      paddingBottom={16}
      margin="0"
      flexGrow={1}
      data-testid="page-header-container"
    >
      {backLabel && <BackButton label={backLabel} onBack={onBack} />}

      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text textStyle="Headline/Large/Primary medium" margin="0">
            {title}
          </Text>
          {description ? (
            <Text
              marginTop={8}
              textStyle="Body/Medium/Regular"
              color="Color/Text/Neutral/Default"
              data-testid="section-header-title-description"
            >
              {description}
            </Text>
          ) : null}
        </Box>
        <Box></Box>

        <Box>
          <ButtonGroup
            orientation="horizontal"
            colorScheme="brand"
            variant="solid"
          >
            {actionLabel ? (
              <Button
                textStyle="Body/Small/Regular"
                colorScheme="action"
                size="sm"
                onClick={onAction}
                data-testid="section-header-action-button"
              >
                {actionLabel}
              </Button>
            ) : null}
            {onSecondaryAction ? (
              <ActionMenu triggerElement={MenuIconButton}>
                <ActionMenuContent
                  sx={{
                    ...getActionContentStyles('light', false),
                    '&': {
                      padding: '0',
                      minWidth: '100%',
                      maxHeight: 'none',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    },
                  }}
                >
                  <Button
                    textStyle="Body/Small/Regular"
                    variant="ghost"
                    size="sm"
                    color="light.Text/Neutral/Interactive/Enable"
                    onClick={onSecondaryAction}
                    data-testid="section-header-secondary-action-button"
                  >
                    {secondaryActionLabel}
                  </Button>
                </ActionMenuContent>
              </ActionMenu>
            ) : null}
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
};

export { PageHeader };
