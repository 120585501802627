import {
  Error as SdkError,
  Maybe,
} from '@allergan-data-labs/alle-graphql-types';

class AlleGeneralError extends Error {
  public code: string;
  public message: string;

  constructor(error?: Maybe<SdkError>, ...params: any[]) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AlleGeneralError);
    }

    this.name = 'AlleGeneralError';

    // Custom debugging information
    this.code = error ? error.code : '';
    this.message = error
      ? error.friendlyMessage || error.message
      : 'An internal server error has occurred';
  }
}

export { AlleGeneralError };
