import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { Provider_User_Role_Assignment_State } from '@allergan-data-labs/alle-graphql-types';
import { Indicator, IndicatorVariant } from '@allergan-data-labs/alle-elements';
import { BaseComponentProps } from '../../../common';

export type AccountStatus =
  | Provider_User_Role_Assignment_State
  | 'INVITE_PENDING';

export interface AccountStatusCellProps extends BaseComponentProps, BoxProps {
  status: AccountStatus;
}

export const StatusToTextMap = {
  [Provider_User_Role_Assignment_State.Active]: 'Active',
  [Provider_User_Role_Assignment_State.Suggested]: 'Suggested',
  [Provider_User_Role_Assignment_State.Suspended]: 'Removed',
  INVITE_PENDING: 'Invite Pending',
};

const StatusToVariantMap = {
  [Provider_User_Role_Assignment_State.Active]: 'success',
  [Provider_User_Role_Assignment_State.Suggested]: 'info',
  [Provider_User_Role_Assignment_State.Suspended]: 'alert',
  INVITE_PENDING: 'warning',
};

export const AccountStatusCell = ({
  status,
  ...boxProps
}: AccountStatusCellProps) => (
  <Box {...boxProps}>
    <Indicator
      variant={StatusToVariantMap[status] as IndicatorVariant}
      size="md"
      text={StatusToTextMap[status]}
    />
  </Box>
);
