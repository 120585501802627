import React from 'react';
import { renderToString } from 'react-dom/server';
import { Box, Text } from '@chakra-ui/react';
import { getColorToken } from '@allergan-data-labs/alle-elements';
import { ColorMode } from '@allergan-data-labs/alle-elements-core';

export interface TextHighlighterProps extends React.PropsWithChildren {
  searchTerm?: string;
  highlightColor?: string;
  colorMode?: ColorMode;
  as?: React.ElementType;
}

export const TextHighlighter = ({
  searchTerm,
  children,
  highlightColor = 'Container/Brand/Disabled/Default',
  colorMode = 'light',
  as = 'span',
}: TextHighlighterProps) => {
  const term = searchTerm?.trim();
  if (!term) {
    return <>{children}</>;
  }
  const innerHtml = renderToString(children as React.ReactElement).replace(
    new RegExp(term, 'gi'),
    (match) => {
      return renderToString(
        <Text
          as="span"
          sx={{
            backgroundColor: getColorToken(highlightColor, colorMode),
          }}
          data-testid="a4a-search-term"
        >
          {match}
        </Text>
      );
    }
  );
  return <Box as={as} dangerouslySetInnerHTML={{ __html: innerHtml }} />;
};
