import { createContext } from 'react';

interface AdminPreferencesType {
  patientOffersPerPage?: number;
  patientTransactionsPerPage?: number;
  practiceOffersPerPage?: number;
  practiceTransactionsPerPage?: number;
  showPatientGiftCardPicture?: boolean;
  showPatientOfferPicture?: boolean;
  showPracticeOfferPicture?: boolean;
}

export interface ValueType {
  adminPreferences?: AdminPreferencesType;
}

export interface LocalStorageContextValue {
  values: ValueType;
  setValue: <K extends keyof ValueType>(key: K, value: ValueType[K]) => void;
}

const LocalStorageContext = createContext<LocalStorageContextValue>({
  values: {},
  setValue: () => {
    console.error('LocalStorageContext not implemented!');
  },
});

export { LocalStorageContext };
