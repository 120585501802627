import React, { useState, useEffect } from 'react';
import {
  useDebounce,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputProps,
} from '@allergan-data-labs/alle-elements';

import { isValidEmail } from '@allergan-data-labs/universal-component-library/src/helpers/format';

export interface EmailInputProps extends InputProps {
  label?: string;
  error?: string;
}

const EmailInput: React.FC<EmailInputProps> = ({
  label,
  error,
  ...inputProps
}) => {
  const [isValid, setIsValid] = useState(true);
  const debouncedEmailInput = useDebounce(inputProps.value as string, 1000);
  const errorMessage =
    error || 'Please enter valid emails, separated by commas';
  useEffect(() => {
    // only validate email when we have the first comma, otherwhise validate in the form
    const validateInput = debouncedEmailInput.split(',').length > 1;
    if (debouncedEmailInput && validateInput) {
      const emails = debouncedEmailInput
        .split(',')
        .map((email) => email.trim());
      // only validate emails before the last comma/dont validate the last, potentially incomplete email
      emails.pop();
      const invalidEmailsExist = emails.some((email) => !isValidEmail(email));
      setIsValid(!invalidEmailsExist);
    } else {
      setIsValid(true); // Assume empty input to be valid
    }
  }, [debouncedEmailInput]);

  return (
    <FormControl isInvalid={!isValid || !!error}>
      {label && <FormLabel htmlFor="email-input">{label}</FormLabel>}
      <Input
        type="text"
        data-testid="email-input"
        id="email-input"
        {...inputProps}
      />
      {(!isValid || error) && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export { EmailInput };
