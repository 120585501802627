import { createContext } from 'react';
import { State, EventData } from 'xstate';
import { AuthMachineContext, AuthMachineEvent } from './authMachine';
import { Permission } from './permission';

type AuthState = State<AuthMachineContext, AuthMachineEvent, any, any>;

export interface AuthContextValue {
  authState: AuthState;
  sendAuthEvent: (
    event: AuthMachineEvent['type'],
    payload?: EventData | undefined
  ) => void;
  permissions?: Permission[];
}

const defaultContext: AuthContextValue = {
  // @ts-ignore
  authState: null,
  sendAuthEvent: () => {},
};

const AuthContext = createContext<AuthContextValue>(defaultContext);

export { AuthContext };
