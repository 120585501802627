import React from 'react';
import styled from 'styled-components';

import { Typography } from '@allergan-data-labs/universal-component-library/src/atoms/typography/typography';
import { alleColors } from '../../colorPalette';
import { InfoIcon } from '../../icons/infoIcon';
import { Tooltip } from '../tooltip';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LabelRow = styled.div`
  display: flex;
  align-items: center;
`;

const InputLabel = ({
  labelText,
  tooltipMessage,
  secondaryLabel,
}: {
  labelText: string;
  tooltipMessage?: string;
  secondaryLabel?: string;
}) => {
  return (
    <Container>
      <LabelRow>
        <Typography preset="caption-M-regular" component="span">
          {labelText}
        </Typography>
        {tooltipMessage ? (
          <Tooltip
            title={tooltipMessage}
            placement="right"
            css="display: flex; margin-left: 1rem; margin-right: 1rem;"
          >
            <InfoIcon />
          </Tooltip>
        ) : null}
      </LabelRow>
      {secondaryLabel ? (
        <Typography
          preset="body-S-regular"
          component="span"
          css={`
            color: ${alleColors.gray[600]};
          `}
        >
          {secondaryLabel}
        </Typography>
      ) : null}
    </Container>
  );
};

export { InputLabel };
