import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Pagination } from '../../../dataTable/pagination';

export interface LocationUsersTablePaginationProps {
  totalUsers: number;
  onPaginationChange: (value: number) => void;
  onPaginationSizeChange: (value: number) => void;
}

const LocationUsersTablePagination: React.FunctionComponent<
  LocationUsersTablePaginationProps
> = ({ totalUsers, onPaginationChange, onPaginationSizeChange }) => {
  return (
    <Flex justifyContent={'space-between'} alignItems={'center'}>
      <Text
        data-testid={'location-users-pagination-count'}
        textStyle={'Body/Small/Regular'}
      >{`Total Users: ${totalUsers}`}</Text>
      <Pagination
        total={totalUsers}
        onPageChange={onPaginationChange}
        onPageSizeChange={onPaginationSizeChange}
      />
    </Flex>
  );
};

export { LocationUsersTablePagination };
