import React, { useState } from 'react';
import {
  ColumnSort,
  DataTable,
  DataTableColumn,
  TextButton,
} from '@allergan-data-labs/alle-elements';
import { Box, Flex, Text } from '@chakra-ui/react';
import { formatNumberWithCommas } from '@allergan-data-labs/universal-component-library/src/helpers/format';
import { OfferStatusBadge } from '@allergan-data-labs/admin-business-component-library/src/components/offerBuilder/offerStatusBadge/offerStatusBadge';
import {
  OfferStatus,
  Offer,
} from '@allergan-data-labs/admin-business-component-library/src/components/offerBuilder/types';
import { ChevronRightIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronRightIcon';
import { BaseComponentProps } from '../../common';
import { getSortedItems } from '../../../utils';
import { ContextMenuCell, MenuActionType } from '../cells';
import { format } from 'date-fns';

export interface OfferBuilderPracticeToConsumerTableProps
  extends BaseComponentProps {
  data: Array<Offer>;
  isLoading?: boolean;
  onInternalNameClick: (internalName: string) => void;
  onActionItemClick: (offer: Offer, actionType: MenuActionType) => void;
}

const OfferBuilderPracticeToConsumerTable: React.FC<
  OfferBuilderPracticeToConsumerTableProps
> = ({
  data,
  onInternalNameClick,
  onActionItemClick,
  dataTestId = 'practice-to-consumer-table',
  isLoading = false,
}) => {
  const columns: Array<DataTableColumn<Offer>> = [
    {
      column: 'Offer Name',
      field: 'name',
      renderCell: (_, name) => {
        return (
          <Flex flexDirection="column" minWidth="530px">
            <TextButton
              variant="ghost"
              colorScheme="brand"
              onClick={() => onInternalNameClick(_.id)}
              rightIcon={<ChevronRightIcon />}
              justifyContent="flex-start"
            >
              <Text textStyle="Body/Medium/Regular" as="span" isTruncated>
                {name as string}
              </Text>
            </TextButton>
          </Flex>
        );
      },
    },
    {
      column: 'Status',
      field: 'status',
      renderCell: (_, status) => {
        return (
          <Box minWidth="100px">
            <OfferStatusBadge
              status={status as OfferStatus}
              pendingChangeRequests={false}
            />
          </Box>
        );
      },
    },
    {
      column: 'Start Date',
      field: 'startDate',
      renderCell: (offer) => {
        if (!offer.startDate) {
          return (
            <Box minWidth="200px">
              <Text
                textStyle="Body/Medium/Regular"
                color="light.Text/Neutral/Support"
                as="span"
              >
                ----
              </Text>
            </Box>
          );
        }

        const formattedDate = format(offer.startDate, 'MMM dd, yyyy');
        const formattedTime = format(offer.startDate, 'hh:mm a') + ' PT';

        return (
          <Box minWidth="200px">
            <Text textStyle="Body/Medium/Regular" as="p">
              {formattedDate}
            </Text>
            <Text
              textStyle="Body/Small/Regular"
              color="light.Text/Neutral/Support"
              as="span"
            >
              {formattedTime}
            </Text>
          </Box>
        );
      },
    },
    {
      column: 'Redeemed',
      field: 'redeemed',
      renderCell: (_, redeemed) => {
        return (
          <Text
            textStyle="Body/Medium/Regular"
            as="span"
            align="right"
            width="100%"
            paddingRight="40px"
          >
            {formatNumberWithCommas(redeemed as string)}
          </Text>
        );
      },
    },
    {
      column: 'Remaining',
      field: 'remaining',
      renderCell: (_, remaining) => {
        return (
          <>
            <Text
              textStyle="Body/Medium/Regular"
              as="span"
              align="right"
              width="100%"
              paddingRight="40px"
            >
              {formatNumberWithCommas(remaining as string)}
            </Text>
          </>
        );
      },
    },
    {
      column: '',
      field: 'menu',
      renderCell: (offer) => {
        return (
          <>
            <ContextMenuCell
              flexGrow={1}
              offer={offer as any}
              onActionItemClick={onActionItemClick}
              data-testid={`user-${(offer as any).id}-context-menu`}
            />
          </>
        );
      },
    },
  ];

  const [sortConfig, setSortConfig] = useState<ColumnSort>({
    id: null,
    direction: 'asc',
  });

  const handleSort = (sortingState: ColumnSort) => {
    setSortConfig(sortingState);
  };

  return (
    <Box
      sx={{
        'th:first-child button': {
          display: 'none',
        },
        'th:last-child div': {
          display: 'none',
        },
      }}
    >
      <DataTable
        isLoading={isLoading}
        data-testid={dataTestId}
        columns={columns}
        rowGap={8}
        data={getSortedItems(sortConfig, data)}
        onSort={handleSort}
        variant="closed"
        sx={{
          '& tbody tr': { cursor: 'initial !important' },
        }}
      />
    </Box>
  );
};

export { OfferBuilderPracticeToConsumerTable };
