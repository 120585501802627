import React from 'react';
import { Box } from '@chakra-ui/react';

import { Account_Status, Maybe } from '@allergan-data-labs/alle-graphql-types';
import {
  IndicatorVariant,
  IndicatorProps,
  IndicatorSize,
  Indicator,
} from '@allergan-data-labs/alle-elements';

export const LocationStatusTextMap = {
  [Account_Status.Active]: 'Active',
  [Account_Status.Inactive]: 'Inactive',
  [Account_Status.NewEntity]: 'Pending',
  [Account_Status.PendingBusinessOwnerRegistration]: 'Pending',
  [Account_Status.RequiresAction]: 'Requires Action',
  [Account_Status.UserArchived]: 'Archived',
};

const StatusColorMap = {
  [Account_Status.Active]: 'success',
  [Account_Status.Inactive]: 'info',
  [Account_Status.NewEntity]: 'warning',
  [Account_Status.PendingBusinessOwnerRegistration]: 'warning',
  [Account_Status.RequiresAction]: 'alert',
  [Account_Status.UserArchived]: 'info',
} as unknown as IndicatorVariant;

export interface BusinessLocationStatusIndicatorProps extends IndicatorProps {
  /** Business and location account status: ACTIVE | INACTIVE | NEW_ENTITY | PENDING_BUSINESS_OWNER_REGISTRATION | REQUIRES_ACTION | USER_ARCHIVED
   * @default undefined
   */
  status: Maybe<Account_Status>;
  /** Status indicator size
   * @default sm
   */
  size?: IndicatorSize;
  /** Provide custom data-testid
   * @default undefined
   */
  dataTestId?: string;
}

export const BusinessLocationStatusIndicator: React.FC<
  BusinessLocationStatusIndicatorProps
> = ({ size = 'sm', dataTestId, status }) => {
  const isValidStatusIndicator =
    status && Object.hasOwn(LocationStatusTextMap, status);
  const testId = dataTestId || 'businees-location-status-indicator-container';

  return isValidStatusIndicator ? (
    <Box data-testid={testId}>
      <Indicator
        size={size}
        variant={StatusColorMap[status as any] as IndicatorVariant}
        text={LocationStatusTextMap[status]}
      />
    </Box>
  ) : null;
};
