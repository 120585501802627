import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { Query } from '@allergan-data-labs/alle-graphql-types';
import { useAdminFeatureFlags } from '../hooks/useAdminFeatureFlags';

export const VIEWER_PERMISSIONS_QUERY = gql`
  query ViewerPermissions {
    viewerPermissions {
      permissions
      expireAt {
        format
      }
    }
  }
`;

const useViewerPermissionsLazyQuery = <T extends string>() => {
  const { isIamServiceEnabled } = useAdminFeatureFlags();
  const [loadPermissions, { data, ...rest }] = useLazyQuery<Query>(
    VIEWER_PERMISSIONS_QUERY
  );
  const permissions = (data?.viewerPermissions?.permissions ?? []) as T[];

  if (isIamServiceEnabled) {
    return {
      loadPermissions,
      permissions,
      ...rest,
    };
  } else {
    return {
      loadPermissions: () => {},
      permissions: [] as T[],
    };
  }
};

export { useViewerPermissionsLazyQuery };
