import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { colors } from '../core/colors';
import { IconContainer } from './iconContainer';

interface CashProps {
  fill?: string;
  stroke?: string;
  className?: string;
}

const Cash: React.FunctionComponent<CashProps> = ({
  fill = 'transparent',
  stroke = colors.black,
  className,
}) => (
  <IconContainer
    width="24px"
    height="24px"
    pathFill={fill}
    className={className}
  >
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.52 23.04C17.8823 23.04 23.04 17.8823 23.04 11.52C23.04 5.15768 17.8823 0 11.52 0C5.15768 0 0 5.15768 0 11.52C0 17.8823 5.15768 23.04 11.52 23.04Z"
        fill={fill}
      />
      <path
        d="M13.9201 8.9854C13.8267 8.3921 13.5192 7.8536 13.0558 7.4715C12.5924 7.0895 12.0052 6.8904 11.405 6.9118C11.0652 6.8861 10.724 6.9365 10.4061 7.0592C10.0882 7.1819 9.80171 7.3739 9.56721 7.6212C9.33281 7.8685 9.15641 8.1649 9.05091 8.4888C8.94531 8.8128 8.91321 9.1562 8.95691 9.4942C9.33131 12.211 13.5649 10.5118 14.1025 13.5454C14.16 13.8807 14.1395 14.2247 14.0426 14.5508C13.9458 14.8769 13.7753 15.1764 13.5442 15.426C13.3131 15.6757 13.0277 15.8688 12.71 15.9905C12.3923 16.1122 12.0509 16.1591 11.7121 16.1278C11.0898 16.1664 10.4727 15.9928 9.96181 15.6354C9.45091 15.2779 9.07631 14.7577 8.89941 14.1598"
        stroke={stroke}
      />
      <path d="M11.5195 18.0766V16.1182" stroke={stroke} />
      <path d="M11.5195 6.91222V4.96338" stroke={stroke} />
    </SvgIcon>
  </IconContainer>
);

export { Cash };
