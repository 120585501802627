import React from 'react';
import { Box } from '@chakra-ui/react';

export interface PageWrapperProps {
  children: React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  const childrenArray = React.Children.toArray(children).filter(
    (child) => child != null
  );
  return (
    <Box
      paddingRight={102}
      paddingLeft={86}
      paddingTop={48}
      paddingBottom={20}
      maxWidth={1300}
      minWidth={1050}
      margin="0 auto"
      data-testid="a4a-page-wrapper-container"
    >
      {childrenArray}
    </Box>
  );
};

export { PageWrapper };
