import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Link } from '@allergan-data-labs/alle-elements';

const ViewAll = ({ query = '' }: { query: string }) => {
  return (
    <Flex
      data-testid="view-all-results-link"
      justifyContent={'center'}
      paddingTop={'16px'}
      paddingBottom={'8px'}
    >
      <Link
        color="light.Text/Neutral/Default"
        href={`/a4a/business-search?query=${query}`}
        isStandalone
        sx={{
          '&:visited': {
            color: 'light.Text/Neutral/Default',
          },
        }}
      >
        View all results
      </Link>
    </Flex>
  );
};

export default ViewAll;
