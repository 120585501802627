import { ValidationError } from '@allergan-data-labs/alle-graphql-types';
import { Maybe } from '@allergan-data-labs/alle-graphql-types';
import { ValidationError as YupValidationError } from 'yup';

export enum FormEvents {
  REQUEST_RETRY = 'REQUEST_RETRY',
  IDLE_SUBMIT = 'IDLE_SUBMIT',
  VALIDATION_ERROR_RESUBMIT = 'VALIDATION_ERROR_RESUBMIT',
  RESET_FORM = 'RESET_FORM',
  FORCE_ERROR = 'FORCE_ERROR',
}

export enum FormStates {
  idle = 'idle',
  validation = 'validation',
  validation_error = 'validation_error',
  validation_success = 'validation_success',
  server_error = 'server_error',
  server_success = 'server_success',
}

export enum FormActions {
  idle_submit = 'idle_submit',
  validation_success = 'validation_success',
  validation_error = 'validation_error',
  validation_error_resubmit = 'validation_error_resubmit',
  validation_success_resubmit = 'validation_success_resubmit',
  server_error = 'server_error',
  server_success = 'server_success',
  request_retry = 'request_retry',
  reset_form = 'reset_form',
}

export enum FormServices {
  submit_request = 'submit_request',
  validate_fields = 'validate_fields',
}

export interface FormMachineSchema {
  states: {
    idle: {};
    validation: {};
    validation_error: {};
    validation_success: {};
    server_error: {};
    server_success: {};
  };
}

export interface FormMachineContext<T> {
  serverSuccess?: string;
  serverError?: string;
  validationError?: YupValidationError;
  fields: T;
  currentField?: string;
  validated?: Array<string>;
}

export interface FormMachineEvents<T> {
  type:
    | FormEvents.IDLE_SUBMIT
    | FormEvents.VALIDATION_ERROR_RESUBMIT
    | FormEvents.RESET_FORM
    | FormEvents.REQUEST_RETRY
    | FormEvents.FORCE_ERROR;
  fields: T;
  currentField?: string;
  data?: {
    fields: T;
    currentField?: string;
    validated?: Array<string>;
    validationError?: YupValidationError;
    validationErrors?: Maybe<ValidationError[]>;
    message?: string;
  };
}
