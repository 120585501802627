import React from 'react';
import { Section } from '../../section';
import { SectionItem } from '../../sectionItem';
import { Label } from '../../label';
import { MultiLineLabel } from '../../multiLineLabel';

export interface BusinessInformationProps {
  soldToId: string;
  businessName: string;
  address: string[];
}

const BusinessInformation: React.FC<BusinessInformationProps> = ({
  businessName,
  soldToId,
  address,
}) => {
  return (
    <Section title="Business Information">
      <SectionItem title="AbbVie SAP Soldto ID">
        <Label text={soldToId} isCopyEnabled />
      </SectionItem>
      <SectionItem title="Business Name">
        <Label text={businessName} />
      </SectionItem>
      <SectionItem title="Address">
        <MultiLineLabel texts={address} isCopyEnabled />
      </SectionItem>
    </Section>
  );
};
export { BusinessInformation };
