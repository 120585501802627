import React, { useState, useRef, useCallback, ChangeEvent, FC } from 'react';
import {
  getColorToken,
  Select,
  Button,
  ButtonGroup,
} from '@allergan-data-labs/alle-elements';

import { Text, Flex } from '@chakra-ui/react';

import { TimezoneItem } from './types';

import { TimezoneOptions as TimezoneItems } from '@allergan-data-labs/component-library/src/utils/timezone';

export interface TimezoneSelectProps {
  items?: TimezoneItem[];
  value?: string;
  placeholder?: string;
  label?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  onCancel?: () => void;
  onConfirm: (value: string) => void;
  onChange?: (value: string) => void;
}

const TimezoneSelect: FC<TimezoneSelectProps> = ({
  items = TimezoneItems,
  value,
  placeholder,
  label,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  onCancel,
  onConfirm,
  onChange,
}) => {
  const defaultValue = value || TimezoneItems[0].value;
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const newTimezoneRef = useRef(defaultValue);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setCurrentValue(event.target.value);
      onChange?.(event.target.value);
    },
    [onChange]
  );

  const handleOnConfirm = () => {
    newTimezoneRef.current = currentValue;
    if (currentValue) onConfirm(currentValue);
  };

  const handleOnCancel = () => {
    onCancel?.();
    setCurrentValue(newTimezoneRef.current);
  };

  return (
    <Flex direction="column" gap={8}>
      {label ? (
        <Text
          textStyle={'Body/Medium/Medium'}
          color={getColorToken('Text/Neutral/Default')}
          data-testid="time-zone-label"
        >
          {label}
        </Text>
      ) : null}
      <Select
        value={currentValue}
        onChange={handleChange}
        placeholder={placeholder}
        data-testid="time-zone-select"
      >
        {items.map(({ value: itemValue, label: labelTimezone }) => (
          <option
            key={itemValue}
            value={itemValue}
            data-testid={`${itemValue}-item`}
          >
            {labelTimezone}
          </option>
        ))}
      </Select>
      <ButtonGroup spacing={2} data-testid="time-zone-actions">
        <Button onClick={handleOnConfirm}>{confirmLabel}</Button>
        <Button onClick={handleOnCancel} variant="outline">
          {cancelLabel}
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export { TimezoneSelect };
