import React from 'react';
import styled from 'styled-components';
import BaseTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { colors } from '../colors';
import { P } from '../typography/typographyDEPRECATED';
import { Typography } from '@allergan-data-labs/universal-component-library/src/atoms/typography';

const SkinTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.skin,
    padding: '0px 5px',
  },
}))(BaseTooltip);

interface AlleTooltipProps {
  text: string;
  children: React.ReactElement;
  className?: string;
}

const AlleTooltip: React.FunctionComponent<AlleTooltipProps> = ({
  text,
  children,
  className,
}) => (
  <SkinTooltip
    className={className}
    title={
      <P variant="bodyText3" color={colors.white} weight="normal">
        {text}
      </P>
    }
  >
    <div>{children}</div>
  </SkinTooltip>
);

const InfoTooltipComponent = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.black,
    padding: '4px 8px',
    maxWidth: '186px',
  },
  arrow: {
    color: colors.black,
  },
}))(BaseTooltip);

const InfoTooltip = ({
  title,
  children,
  className,
}: {
  title: string | React.ReactElement;
  children: React.ReactElement;
  className?: string;
}) => {
  return (
    <InfoTooltipComponent
      className={className}
      title={
        <Typography preset="body-S-regular">
          <span style={{ color: colors.white }}>{title}</span>
        </Typography>
      }
      arrow
    >
      <div style={{ display: 'inline-block' }}>{children}</div>
    </InfoTooltipComponent>
  );
};

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
}))(BaseTooltip);

const StyledTooltip = styled((props) => {
  const { light, ...forwardProps } = props;

  if (light) {
    return <LightTooltip {...forwardProps} />;
  }

  return <BaseTooltip {...forwardProps} />;
})``;

export interface TooltipProps {
  openOnlyOnClick?: boolean;
  children: React.ReactNode;
  title: string | React.ReactNode;
  placement?: string;
  light?: boolean;
  className?: string;
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  openOnlyOnClick = false,
  children,
  ...forwardProps
}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return openOnlyOnClick ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <StyledTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          {...forwardProps}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={handleTooltipOpen}
            onKeyDown={(e) => {
              (e.key === ' ' || e.key === 'Enter') && handleTooltipOpen();
            }}
            onBlur={handleTooltipClose}
          >
            {children}
          </div>
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <StyledTooltip {...forwardProps}>
      <div>{children}</div>
    </StyledTooltip>
  );
};

export { AlleTooltip, InfoTooltip, Tooltip };
