import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

import { Button } from '@allergan-data-labs/alle-elements';
import { AddIcon } from '@allergan-data-labs/alle-elements-icons/16/addIcon';
import { BaseComponentProps } from '../../common';
import { DropdownVariant, SearchField } from '../../searchField';

export interface OfferBuilderHeaderProps extends BaseComponentProps {
  recentSearchTerms: any;
  onSearch: (query: string) => Promise<any>;
  onNewOfferClick: () => void;
  onRemoveRecentSearch: (event: React.MouseEvent, term: string) => void;
  onSelectRecentSearchTerm: (keyword: string) => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const OfferBuilderHeader: React.FC<OfferBuilderHeaderProps> = ({
  recentSearchTerms,
  onSearch,
  onNewOfferClick,
  onRemoveRecentSearch,
  onSelectRecentSearchTerm,
  onKeyPress,
  dataTestId = 'offer-builder-header',
}) => {
  return (
    <Box data-testid={dataTestId} paddingBottom={24}>
      <Flex justifyContent="space-between">
        <Box flex="1">
          <Text textStyle="Headline/Large/Primary medium">Offers</Text>
        </Box>
        <Flex
          columnGap="16"
          sx={{
            input: {
              minWidth: '566px',
              ':focus': {
                outline: 'none',
                boxShadow: 'none',
              },
            },
          }}
        >
          <SearchField
            size="sm"
            placeholder="Search by offer name, offer ID, universal marketing ID, or promo ID"
            colorMode="light"
            dropdownVariant={DropdownVariant.GENERIC}
            recentSearches={recentSearchTerms}
            recentVisitedSearchResults={[]}
            disableFocus={true}
            onSearch={onSearch}
            onSelectRecentSearchTerm={onSelectRecentSearchTerm}
            onSelectResults={() => {}}
            onRemoveRecentSearchTerm={onRemoveRecentSearch}
            onKeyDown={onKeyPress}
          />
          <Button leftIcon={<AddIcon />} size="sm" onClick={onNewOfferClick}>
            New Offer
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export { OfferBuilderHeader };
