import React from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import { OrganizationDetails } from '../../organizationDetails';
import { OrganizationTableCell } from '../../dataTable/organizationTableCell';
import { BusinessLocationStatusIndicator } from '../../statusIndicator';
import { Badge } from '@allergan-data-labs/alle-elements';
import { getResultsRowsStyles } from '../searchResultsDropdown/searchResultsDropdown.theme';

import { Provider_Organization_Type_V2 } from '@allergan-data-labs/alle-graphql-types';
import { OrganizationItem } from '../../common';

interface OrganizationResultRowProps {
  result: OrganizationItem;
  type: Provider_Organization_Type_V2;
  searchTerm?: string;
}

const OrganizationResultRow: React.FC<OrganizationResultRowProps> = ({
  result,
  type,
  searchTerm = '',
}: OrganizationResultRowProps) => {
  return (
    <Flex
      sx={getResultsRowsStyles()}
      data-testid="organization-result-row"
      w="full"
    >
      <OrganizationDetails
        type={type}
        title={result.name || ''}
        searchTerm={searchTerm}
        subTitle={result.address}
        isSmall
      />

      <Flex>
        {Object.entries(result.accountInfo).map(([key, value], idx) => (
          <Box key={idx}>
            <OrganizationTableCell title={key} value={value} isSmall />
          </Box>
        ))}
      </Flex>

      <Flex justify="flex-end">
        {result.tags && result.tags.length ? (
          <>
            {result.tags?.map((tag: string, key: number) => (
              <Badge key={key}>{tag}</Badge>
            ))}
          </>
        ) : null}
        <VStack ml="8" justify="center">
          {result.status ? (
            <BusinessLocationStatusIndicator status={result.status} size="xs" />
          ) : null}
        </VStack>
      </Flex>
    </Flex>
  );
};

export { OrganizationResultRow };
