import React from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import { OrganizationDetails } from '../../organizationDetails';
import { Provider_User_Category } from '@allergan-data-labs/alle-graphql-types';

import { BusinessUserStatusIndicator } from '../../statusIndicator';
import { getResultsRowsStyles } from '../searchResultsDropdown/searchResultsDropdown.theme';
import { OrganizationTableCell } from '../../dataTable/organizationTableCell';
import { BusinessUserItem } from '../../common';

interface BusinessUserResultRowProps {
  result: BusinessUserItem;
  type: Provider_User_Category.BusinessUser;
  searchTerm?: string;
}

const BusinessUserResultRow: React.FC<BusinessUserResultRowProps> = ({
  result,
  type,
  searchTerm = '',
}: BusinessUserResultRowProps) => {
  return (
    <Flex
      sx={getResultsRowsStyles()}
      data-testid="business-user-result-row"
      w="full"
    >
      <OrganizationDetails
        type={type}
        title={result.name}
        searchTerm={searchTerm}
        subTitle={result.emailAddress}
        isSmall
      />

      <Flex>
        {Object.entries(result.accountInfo).map(([key, value], idx) => (
          <Box key={idx}>
            <OrganizationTableCell title={key} value={value} isSmall />
          </Box>
        ))}
      </Flex>

      <Flex justify="flex-end">
        <VStack ml="8" justify="center">
          <BusinessUserStatusIndicator status={result.status} size="xs" />
        </VStack>
      </Flex>
    </Flex>
  );
};

export { BusinessUserResultRow };
