import React, { useState } from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';
import { IconButton } from '@allergan-data-labs/alle-elements';
import copy from 'copy-to-clipboard';
import { CopyOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/copyOutlineIcon';
import { CheckOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/checkOutlineIcon';

export interface LabelProps {
  text: string;
  textProps?: TextProps;
  isCopyEnabled?: boolean;
}

const Label: React.FC<LabelProps> = ({
  text,
  textProps,
  isCopyEnabled = false,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (textToCopy: string) => {
    copy(textToCopy);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1200);
  };

  return (
    <Flex alignItems="center" data-testid="label-container">
      <Text
        textStyle="Body/Medium/Regular"
        {...textProps}
        data-testid="label-text"
      >
        {text}
      </Text>
      {isCopyEnabled ? (
        <IconButton
          data-testid="label-icon-button"
          onClick={() => handleCopy(text)}
          icon={
            isCopied ? (
              <CheckOutlineIcon
                sx={{ color: 'light.Container/Status/Success bold 1' }}
              />
            ) : (
              <CopyOutlineIcon sx={{ color: 'light.Icon/Neutral/Support' }} />
            )
          }
          colorScheme="muted"
          aria-label="Copy"
          variant="ghost"
          sx={{
            backgroundColor: 'white',
            width: '24px',
            minWidth: '24px',
            height: '24px',
            _hover: {},
          }}
        />
      ) : null}
    </Flex>
  );
};

export { Label };
