import React from 'react';
import { useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

// shared sdk
import { setClient } from '@allergan-data-labs/shared-sdk/src/client';
import {
  AlleApolloClientWeb,
  ApolloClientWebFactory,
} from '@allergan-data-labs/shared-sdk/src/client-web';

// shared components
import { Datadog } from '@allergan-data-labs/component-library/src/datadog/dataDog';
import { DatePickerProvider } from '@allergan-data-labs/component-library/src/core/datePicker';
import { LinkProvider } from '@allergan-data-labs/component-library/src/core/linkContext';
import { NotificationProvider } from '@allergan-data-labs/component-library/src/notificationBar/notificationContext';
import { MarkdownDialogProvider } from '@allergan-data-labs/component-library/src/markdownDialogStateManagement';
import { ThemeProvider } from '@allergan-data-labs/alle-elements';

// admin component library
import { SegmentProvider } from '@allergan-data-labs/admin-component-library/src/segment/segmentContext';

// admin components
import { AuthProvider } from './auth/AuthProvider';

// utils
import { getAccessToken as getOktaAccessToken } from './auth/oktaClient';
import { allerganAdvantageClient } from './auth/allerganAdvantageClient';
import { routeConfig } from './routeConfig';
import { LocalStorageProvider } from './helpers/localStorageProvider';

// split
import { coreShared } from '@allergan-data-labs/split-feature-flag/src/coreSquad';
import { useAlleTreatments } from '@allergan-data-labs/split-feature-flag/src/useAlleTreatments';
import { __DEV__, __APP_VERSION__ } from './application.config';
import { useAdminFeatureFlags } from './hooks/useAdminFeatureFlags';

// Initialize the global Apollo client
const originalApolloClient = AlleApolloClientWeb({
  name: 'admin-web',
  version: __APP_VERSION__,
  getAccessToken: async () => {
    const aaToken = allerganAdvantageClient.getAccessToken();
    if (aaToken) {
      return aaToken;
    }
    return await getOktaAccessToken();
  },
});

const iamServiceApolloClient = ApolloClientWebFactory({
  name: 'admin-web',
  version: __APP_VERSION__,
  getAccessToken: async () => {
    const aaToken = allerganAdvantageClient.getAccessToken();
    if (aaToken) {
      return aaToken;
    }
    return await getOktaAccessToken();
  },
  overrideRequest: async (operation) => {
    let accessToken = allerganAdvantageClient.getAccessToken();
    if (!accessToken) {
      accessToken = await getOktaAccessToken();
    }
    if (accessToken) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'adl-authorization-context': 'allergan',
        },
      });
    }
  },
});

// Temp Wrapper to be removed after the migration is complete
const ConsumerSegmentWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { [coreShared.segmentAST3Migration]: ast3TrackingPlan } =
    useAlleTreatments({
      splitNames: [coreShared.segmentAST3Migration],
    });

  const isNewTrackingPlanEnabled = ast3TrackingPlan.treatment === 'on';
  React.useEffect(() => {
    if (__DEV__) {
      console.log('⬇⬇⬇⬇⬇⬇⬇⬇⬇⬇');
      console.log('AST3 Plan Enabled:', isNewTrackingPlanEnabled);
      console.log('⬆⬆⬆⬆⬆⬆⬆⬆⬆⬆');
    }
  }, [isNewTrackingPlanEnabled]);

  return (
    <SegmentProvider enableNewTrackingPlan={isNewTrackingPlanEnabled}>
      {children}
    </SegmentProvider>
  );
};

const Providers: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { pathname } = useLocation();
  const { isIamServiceEnabled } = useAdminFeatureFlags();

  const apolloClient = React.useMemo(() => {
    if (isIamServiceEnabled) {
      setClient(iamServiceApolloClient);
      return iamServiceApolloClient;
    } else {
      setClient(originalApolloClient);
      return originalApolloClient;
    }
  }, [isIamServiceEnabled]);
  return (
    <Datadog application="admin" version={__APP_VERSION__}>
      <ConsumerSegmentWrapper>
        <LocalStorageProvider>
          <ApolloProvider client={apolloClient}>
            <AuthProvider>
              <LinkProvider routes={routeConfig}>
                <MarkdownDialogProvider>
                  <DatePickerProvider>
                    <NotificationProvider pathname={pathname}>
                      <ThemeProvider>{children}</ThemeProvider>
                    </NotificationProvider>
                  </DatePickerProvider>
                </MarkdownDialogProvider>
              </LinkProvider>
            </AuthProvider>
          </ApolloProvider>
        </LocalStorageProvider>
      </ConsumerSegmentWrapper>
    </Datadog>
  );
};

export { Providers };
