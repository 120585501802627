import React, { FC } from 'react';
import { v4 as uuid } from 'uuid';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';
import { alleColors } from '../colorPalette';

export interface SearchIconProps {
  pathFill?: string;
  className?: string;
}

const SearchIcon: FC<SearchIconProps> = ({
  pathFill = alleColors.black,
  className,
}) => (
  <IconContainer pathFill={pathFill} className={className}>
    <SvgIcon width="22" height="22" viewBox="0 0 22 22">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M14,9.5 C14,11.98523 11.98523,14 9.5,14 C7.01471,14 5,11.98523 5,9.5 C5,7.01477 7.01471,5 9.5,5 C11.98523,5 14,7.01477 14,9.5 Z M13.3346,14.7489 C12.25964,15.5355 10.93408,16 9.5,16 C5.91016,16 3,13.0898 3,9.5 C3,5.91016 5.91016,3 9.5,3 C13.0898,3 16,5.91016 16,9.5 C16,10.93408 15.5355,12.25964 14.7489,13.3346 L18.7072,17.2928 C19.0977,17.6835 19.0977,18.3165 18.7072,18.7072 C18.3165,19.0977 17.6835,19.0977 17.2928,18.7072 L13.3346,14.7489 Z"
          id={uuid()}
        ></path>
      </g>
    </SvgIcon>
  </IconContainer>
);

export { SearchIcon };
