import { MenuActionType } from '@allergan-data-labs/admin-business-component-library/src/components/offerBuilder/cells';
import {
  SortDirection,
  SortableColumns,
  OfferType,
} from '@allergan-data-labs/a4a-offers-bff-client/api';
import { Counts200Response } from '@allergan-data-labs/a4a-offers-bff-client';

export interface Offer {
  id: string;
  name: string;
  status: OfferStatus;
  value: string;
  startDate: Date;
  type: OfferType;
  valueType: OfferValueType;
  activation: OfferActivation;
  scanned?: string;
  quantity?: string;
  claimed: string;
  redeemed: string;
  remaining: string;
  allocated: string;

  /* To validate if will exist or not */
  universalMarketingId?: string;

  /* For the table column customization */
  menu?: string;
}

export enum OfferStatus {
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Live = 'LIVE',
  OnHold = 'ON_HOLD',
  PendingApproval = 'PENDING_APPROVAL',
  Declined = 'DECLINED',
  Scheduled = 'SCHEDULED',
  Approved = 'APPROVED',
  DistributionScheduled = 'DISTRIBUTION_SCHEDULED',
  DistributionError = 'DISTRIBUTION_ERROR',
}

export enum OfferValueType {
  DollarsOff = 'DOLLARS_OFF',
  Points = 'POINTS',
  GiftCard = 'GIFT_CARD',
}

export enum OfferActivation {
  Earned = 'EARNED',
  Given = 'GIVEN',
  OptIn = 'OPT_IN',
  Purchased = 'PURCHASED',
  Flash = 'FLASH',
}

export interface TabsData {
  directToConsumerCount?: number | null;
  repDispensedFlashCount?: number | null;
  practiceToConsumerCount?: number | null;
  directToConsumerData: Offer[];
  repDispensedFlashData: Offer[];
  practiceToConsumerData: Offer[];
}

export interface OffersPagination {
  page: number;
  size: number;
  totalPage: number;
  totalSize: number;
}

export interface OfferBuilderTabPanelProps {
  offers: OffersData | undefined;
  isLoading: boolean;
  currentPage: number;
  pageSize: number;
  onPaginationSizeChange: (pageSize: number) => void;
  onPaginationChange: (pageSize: number) => void;
  onActionItemClick: (offer: Offer, actionType: MenuActionType) => void;
  searchTerms?: string;
}

export enum SortType {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortBy {
  STATE = 'state',
  VALUE = 'value',
  START_DATE = 'start_date',
  REDEEMED = 'redeemed',
  REMAINING = 'remaining',
  QUANTITY_DISTRIBUTED = 'quantity_distributed',
}

export interface OffersFilter {
  page?: number | undefined;
  limit?: number | undefined;
  sortDir?: SortDirection | undefined;
  sortBy?: SortableColumns | undefined;
  searchTerm?: string;
  type?: OfferType | undefined;
}

export interface OffersData {
  data: Offer[];
  pagination: OffersPagination;
}

export interface OffersDataResponse {
  offersData: OffersData;
  offerCounts: Counts200Response;
}

export const OfferStatusLabelMap = {
  [OfferStatus.Draft]: 'Draft',
  [OfferStatus.Live]: 'Live',
  [OfferStatus.Expired]: 'Expired',
  [OfferStatus.OnHold]: 'On Hold',
  [OfferStatus.PendingApproval]: 'Pending Approval',
  [OfferStatus.Declined]: 'Declined',
  [OfferStatus.Approved]: 'Approved',
  [OfferStatus.Scheduled]: 'Scheduled',
  [OfferStatus.DistributionScheduled]: 'Distribution Scheduled',
  [OfferStatus.DistributionError]: 'Distribution Failed',
};

export const OfferActivationLabelMap = {
  [OfferActivation.Earned]: 'Earned',
  [OfferActivation.Given]: 'Given',
  [OfferActivation.OptIn]: 'Opt In',
  [OfferActivation.Purchased]: 'Purchased',
  [OfferActivation.Flash]: 'Flash',
};

export const OfferValueTypeLabelMap = {
  [OfferValueType.DollarsOff]: 'Dollars Off',
  [OfferValueType.GiftCard]: 'Gift Card',
  [OfferValueType.Points]: 'Points',
};
