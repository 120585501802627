import React, { useEffect, useId } from 'react';
import { Box } from '@chakra-ui/react';
import {
  ActionListItem,
  ActionMenu,
  ActionMenuContent,
  ActionMenuGroup,
} from '@allergan-data-labs/alle-elements';
import {
  useSearchFieldContext,
  useSearchFieldEvents,
} from '../searchField.hook';
import {
  getFocusedActionListItemStyles,
  getResultsContainerStyles,
  getResultStyles,
} from '../searchField.theme';
import { SearchResultsProps, DropdownProps } from '../types';
import { getNoResultsStyles } from '../searchField.theme';
import SkeletonLoader from './skeletonLoader';

/**
 *  Renders search results or No Results for "{query}"
 */
const SearchResults = ({
  results,
  onSelect,
  colorMode,
  query,
}: SearchResultsProps) => {
  const { focusedIndex, inputRef } = useSearchFieldContext();
  const { onFocus, onMouseOut } = useSearchFieldEvents();
  const hasResults = results?.length > 0;

  useEffect(() => {
    requestAnimationFrame(() => {
      inputRef?.current?.focus();
    });
  }, [inputRef]);

  return hasResults ? (
    <ActionMenuGroup>
      {results.map((keyword, index) => (
        <ActionListItem
          key={keyword}
          onFocus={onFocus}
          onMouseOut={onMouseOut}
          sx={{
            ...getResultStyles(colorMode),
            ...(index === focusedIndex
              ? getFocusedActionListItemStyles(colorMode)
              : {}),
          }}
          onClick={() => onSelect(keyword)}
        >
          {keyword}
        </ActionListItem>
      ))}
    </ActionMenuGroup>
  ) : (
    <ActionMenuGroup>
      <ActionListItem sx={{ ...getNoResultsStyles(colorMode) }}>
        No Results for &quot;{query}&quot;
      </ActionListItem>
    </ActionMenuGroup>
  );
};

/**
 *  Handles logic of displaying Recent Searches, Skeleton loader, or search results
 */
export const DefaultDropdown: React.FC<DropdownProps> = ({
  isBusinessUsersLoading,
  isOrganizationsLoading,
  initialState,
  results,
  recentSearches,
  onSelect,
  colorMode,
  menuVisible,
  query,
}) => {
  const {
    focusedIndex,
    inputRef,
    setResultsCount,
    shouldFocusInput,
    setShouldFocusInput,
  } = useSearchFieldContext();
  const searchResultMenuId = `search-results-menu-${useId()}`;
  const { onFocus, onMouseOut } = useSearchFieldEvents();
  let actionMenuContent;
  useEffect(() => {
    setResultsCount(initialState ? recentSearches?.length : results?.length);
  }, [initialState, recentSearches?.length, results?.length, setResultsCount]);

  useEffect(() => {
    if (shouldFocusInput) {
      requestAnimationFrame(() => {
        inputRef?.current?.focus();
        // Reset the state variable after focusing to avoid unnecessary focus on future renders
        setShouldFocusInput(false);
      });
    }
  }, [shouldFocusInput, inputRef, setShouldFocusInput]);

  if (
    (isBusinessUsersLoading || isOrganizationsLoading) &&
    inputRef.current?.value
  ) {
    actionMenuContent = <SkeletonLoader />;
  } else {
    actionMenuContent = initialState ? (
      <ActionMenuGroup title="Recent Searches">
        {recentSearches.map((keyword, index) => (
          <ActionListItem
            key={keyword}
            onFocus={onFocus}
            onMouseOut={onMouseOut}
            onClick={() => onSelect(keyword)}
            sx={{
              ...getResultStyles(colorMode),
              ...(index === focusedIndex
                ? getFocusedActionListItemStyles(colorMode)
                : {}),
            }}
          >
            {keyword}
          </ActionListItem>
        ))}
      </ActionMenuGroup>
    ) : (
      <SearchResults
        results={results}
        colorMode={colorMode}
        onSelect={onSelect}
        query={query}
      />
    );
  }

  return (
    <Box sx={getResultsContainerStyles()}>
      <ActionMenu
        colorMode={colorMode}
        isOpen={menuVisible}
        id={searchResultMenuId}
        initialFocusRef={inputRef}
      >
        <ActionMenuContent>{actionMenuContent}</ActionMenuContent>
      </ActionMenu>
    </Box>
  );
};
