import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { OpenNewIcon } from '@allergan-data-labs/alle-elements-icons/16/openNewIcon';
// import { UserRoleMockData } from '@allergan-data-labs/admin-business-component-library/src/mocks/mockRoles';
import { ProfileMenu } from '@allergan-data-labs/admin-business-component-library/src/components/profileMenu';
import { useAdminUserProfile } from '@allergan-data-labs/admin-component-library/src/hooks/useAdminUserProfile';

import { NavLink } from './link/link';
import { Header } from './header/header';
import { Routes } from '../../../constants';

interface RouteOptions {
  title: string;
  path: string;
}

interface TopMenuProps {
  homeRoute?: string;
  routes: RouteOptions[];
}
const Navigation: React.FunctionComponent<TopMenuProps> = ({
  homeRoute,
  routes,
}) => {
  const history = useHistory();
  const [profile] = useAdminUserProfile();

  const onSignOut = React.useCallback(() => {
    history.push(Routes.logout);
  }, [history]);

  // if homeRoute specified use that, else use first route from array, if empty use root a4a
  const home = homeRoute
    ? homeRoute
    : routes?.[0]
    ? routes?.[0].path
    : Routes.a4aConsumerSearch;

  const headerRightContent = (
    <Flex justifyContent="flex-end" gap="10px" align="center">
      <NavLink
        icon={<OpenNewIcon color="light.Text/Neutral/Interactive/Enable" />}
        to="https://app.surfly.io/sso/company/allergan/"
        isStandalone
        isExternal
      >
        <Text
          _hover={{
            color: 'light.Text/Neutral/Interactive/Hover',
          }}
          color="light.Text/Neutral/Interactive/Enable"
          fontSize="14px"
          margin="0"
        >
          Open Surfly
        </Text>
      </NavLink>
      {/* TODO: FIX ME
        We will need to come with the profile for the current user */}
      <ProfileMenu
        user={{
          name: `${profile?.firstName} ${profile?.lastName}`,
          role: undefined, // UserRoleMockData.TPS,
        }}
        onSignOut={onSignOut}
      />
    </Flex>
  );

  return (
    <Header rightContent={headerRightContent} homeRoute={home} items={routes} />
  );
};

export { Navigation };
