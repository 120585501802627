import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BaseComponentProps } from '../../common';

export interface OfferBuilderEmptyTableProps extends BaseComponentProps {
  label: string;
}

const OfferBuilderEmptyTable: React.FC<OfferBuilderEmptyTableProps> = ({
  dataTestId = 'table-empty',
  label,
}) => {
  return (
    <Flex
      data-testid={dataTestId}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyItems="center"
      rowGap="1rem"
      padding="4rem"
      width="100%"
    >
      <Text textStyle="Body/Medium/Medium">This table is empty.</Text>
      <Text textStyle="Body/Extra small/Regular">
        {`Please check back after ${label} offers have been created.`}
      </Text>
    </Flex>
  );
};

export { OfferBuilderEmptyTable };
