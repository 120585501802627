import { OktaAuth } from '@okta/okta-auth-js';

import {
  oktaConfigs,
  userGroups,
} from '@allergan-data-labs/shared-sdk/src/application.config';
import { getEnvConfig } from '@allergan-data-labs/shared-sdk/src/getEnvConfig';
import {
  generateOktaHelpers,
  validateRole,
} from '@allergan-data-labs/shared-sdk/src/oktaHelpers';
import { customOktaSettings, oktaTokenIds } from '../application.config';

const oktaAuth = new OktaAuth({
  ...getEnvConfig(oktaConfigs, process.env.REACT_APP_OKTA_ENV),
  ...customOktaSettings,
});

const {
  signOut,
  closeSession,
  getAccessToken,
  validateToken,
  validateTokenSync,
  subscribeToErrorEvents,
  unsubscribeToErrorEvents,
  getUniqueId,
} = generateOktaHelpers(oktaAuth, {
  additionalValidation: [validateRole(userGroups.admin)],
  accessTokenId: oktaTokenIds.accessToken,
  idTokenId: oktaTokenIds.idToken,
});

export {
  signOut,
  closeSession,
  getAccessToken,
  validateToken,
  validateTokenSync,
  subscribeToErrorEvents,
  unsubscribeToErrorEvents,
  getUniqueId,
  oktaAuth,
};
