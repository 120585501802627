import React from 'react';
import {
  TextButton,
  TextButtonProps,
  ButtonSizes,
} from '@allergan-data-labs/alle-elements';
import { ArrowLeftIcon } from '@allergan-data-labs/alle-elements-icons/16/arrowLeftIcon';

interface BackButtonProps extends TextButtonProps {
  label: string;
  size?: ButtonSizes;
  onBack?: () => void;
}

const BackButton: React.FunctionComponent<BackButtonProps> = ({
  label,
  size = 'xs',
  onBack = () => {},
}) => {
  return (
    <TextButton
      variant="outline"
      leftIcon={<ArrowLeftIcon />}
      size={size}
      color="light.Text/Neutral/Support"
      onClick={onBack}
      data-testid="back-page-label-button"
    >
      Back to {label}
    </TextButton>
  );
};

export { BackButton };
