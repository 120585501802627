import React, { forwardRef, MouseEventHandler } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import {
  ActionListItem,
  ActionMenu,
  ActionMenuContent,
  Button,
  ButtonProps,
  TextButton,
} from '@allergan-data-labs/alle-elements';
import { getColorToken } from '@allergan-data-labs/alle-elements';
import { ChevronDownIcon } from '@allergan-data-labs/alle-elements-icons/20/chevronDownIcon';

export interface SectionItemOptionItem {
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface SectionItemProps {
  title: string;
  actionName?: string;
  onAction?: () => void;
  children: React.ReactNode;
  options?: SectionItemOptionItem[];
}

// TODO: Remove this once alle-elements adds support for refs forwarding to TextButton
const TextButtonCustom = forwardRef<HTMLButtonElement>(
  (props: ButtonProps, ref) => (
    <Button
      {...props}
      ref={ref}
      sx={{
        border: '0 none',
        padding: '0',
        backgroundColor: 'transparent',
        _hover: {
          backgroundColor: 'transparent',
        },
        _active: {
          backgroundColor: 'transparent',
        },
      }}
    />
  )
);

const SectionItem: React.FC<SectionItemProps> = ({
  title,
  children,
  actionName,
  onAction,
  options = [],
}) => {
  let actionMenu = null;
  if (Array.isArray(options) && options.length) {
    actionMenu = (
      <ActionMenu
        triggerElement={TextButtonCustom}
        triggerText="Options"
        triggerElementProps={{
          variant: 'outline',
          colorScheme: 'brand',
          rightIcon: <ChevronDownIcon />,
          'data-testid': 'section-item-action-menu',
        }}
        placement="bottom-start"
        offset={[20, -10]}
      >
        <ActionMenuContent>
          {options.map(({ label, onClick }, index) => (
            <ActionListItem key={index} onClick={onClick}>
              {label}
            </ActionListItem>
          ))}
        </ActionMenuContent>
      </ActionMenu>
    );
  }
  return (
    <Flex direction="column" data-testid="section-item">
      <HStack>
        <Text
          textStyle={'Body/Medium/Medium'}
          color={getColorToken('Text/Neutral/Default')}
          data-testid="section-item-title"
        >
          {title}
        </Text>
        {actionMenu}
      </HStack>
      <Flex>
        <Box>{children}</Box>
        {actionName && onAction ? (
          <TextButton
            ml={8}
            onClick={onAction}
            variant="outline"
            color="light.Text/Brand/Interactive/Enable"
            size="md"
            data-testid="section-item-action-button"
          >
            {actionName}
          </TextButton>
        ) : null}
      </Flex>
    </Flex>
  );
};

export { SectionItem };
