import { SystemStyleObject } from '@chakra-ui/react';
import { ColorMode } from '@allergan-data-labs/alle-elements-core';
import { getActionListItemStyles } from '@allergan-data-labs/alle-elements';

const heights = {
  sm: '48px',
  md: '56px',
  lg: '72px',
};

const paddingY = {
  sm: 12,
  md: 16,
  lg: 20,
};

type Size = 'sm' | 'md' | 'lg';

export const getLeftElementStyles = (): SystemStyleObject =>
  getElementStyle('left');

export const getRightElementStyles = (): SystemStyleObject =>
  getElementStyle('right');

const getElementStyle = (side: 'left' | 'right'): SystemStyleObject => ({
  width: '20',
  height: '100%',
  [`${side === 'left' ? 'marginLeft' : 'marginRight'}`]: '12',
  '& svg': {
    width: '20',
    height: '20',
  },
});

export const getCloseIconStyles = (
  colorMode: ColorMode
): SystemStyleObject => ({
  color: `${colorMode}.Icon/Neutral/Inverted`,
  backgroundColor: `${colorMode}.Icon/Neutral/Default`,
  height: '20',
  width: '20',
  borderRadius: 'Circle',
});

export const getClearIconStyles = (): SystemStyleObject => ({
  borderRadius: 'Circle',
  padding: '0',
  margin: '0',
  height: '1',
  width: '1',
  minWidth: '1',
});

export const getResultsContainerStyles = (): SystemStyleObject => ({
  position: 'relative',
  marginTop: '1rem',
  '> div': {
    width: '100%',
  },
  display: 'block',
  width: '100%',
});

export const getSearchIconStyles = (
  colorMode: ColorMode
): SystemStyleObject => ({
  color: `${colorMode}.Icon/Neutral/Default`,
});

export const getAdditionalTextFieldStyles = (
  colorMode: ColorMode,
  size: Size
): SystemStyleObject => ({
  minHeight: heights[size],
  backgroundColor: `${colorMode}.Container/Neutral/Base`,
  color: `${colorMode}.Text/Neutral/Default`,
  paddingLeft: '42',
  paddingRight: '42',
  py: paddingY[size],
  borderRadius: 'Small',
  minH: heights[size],
  _hover: {
    borderColor: `${colorMode}.Border/Neutral/Text field/Hover`,
  },
  _focus: {
    borderColor: `${colorMode}.Border/Neutral/Text field/Hover`,
    outlineColor: `${colorMode}.Border/Brand/Focus ring/Default`,
  },
});

export const getSkeletonContainerStyles = (): SystemStyleObject => ({
  padding: '0',
  marginTop: 8,
  '> :first-child': {
    paddingBottom: '0',
  },
});

export const getResultStyles = (colorMode: ColorMode): SystemStyleObject => ({
  color: `${colorMode}.Text/Neutral/Default`,
  padding: '0 2rem',
});

export const getNoResultsStyles = (
  colorMode?: ColorMode
): SystemStyleObject => {
  const listItemStyles = colorMode ? getActionListItemStyles(colorMode) : {};
  return {
    ...listItemStyles,
    _focus: {
      background: 'none',
      border: 'none',
    },
    _focusVisible: {
      background: 'none',
      border: 'none',
    },
    width: '100%',
    textAlign: 'center',
    padding: '2rem 0',
    '.heading': {
      fontSize: '1rem',
      fontWeight: '500',
      margin: '0',
      lineHeight: '150%',
      letterSpacing: '-0.2px',
      textAlign: 'center',
      color: `${colorMode}.Body/Medium/Medium`,
    },
    '> p': {
      fontSize: '0.75rem',
      margin: '0',
      lineHeight: '150%',
      letterSpacing: '-0.2px',
      textAlign: 'center',
      fontWeight: '400',
    },
  };
};

export const getSkeletonRowStyles = (): SystemStyleObject => ({
  paddingBottom: '8',
});

export const getFocusedActionListItemStyles = (
  colorMode: ColorMode
): SystemStyleObject | undefined => {
  const styles = getActionListItemStyles(colorMode) as Partial<{
    _focusVisible: SystemStyleObject;
  }>;

  return styles._focusVisible;
};

export const getRecentSearchesAndResultsDropdownStyles =
  (): SystemStyleObject => ({
    position: 'absolute',
    top: '60px',
    left: 0,
    flexDirection: 'column',
    flexShrink: 0,
    padding: '1rem 0 0.2rem',
    borderRadius: '8px',
    overflowY: 'auto',
    zIndex: 1000,
    backgroundColor: 'light.Container/Neutral/Base',
    width: '100%',
    boxShadow: `0px 3px 7px 0px rgba(0, 0, 0, 0.12), 
      0px 13px 23px 0px rgba(0, 0, 0, 0.10), 
      0px 30px 18px 0px rgba(0, 0, 0, 0.06), 
      0px 54px 22px 0px rgba(0, 0, 0, 0.02)`,
    'li:hover': {
      cursor: 'pointer',
    },
  });
