import React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorPage } from './errorPage';

interface ErrorPage404Props {
  homeRoute: string;
}

const ErrorPage404: React.FunctionComponent<ErrorPage404Props> = ({
  homeRoute,
}) => (
  <>
    <Helmet title="Allē - Page Not Found" />
    <ErrorPage
      title="404"
      subtitle={
        <span>
          The page you’re looking for
          <br />
          can’t be found.
        </span>
      }
      buttonRoute={homeRoute}
      buttonText="Back Home"
    />
  </>
);

export { ErrorPage404 };
