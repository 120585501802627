import React from 'react';
import { Flex, Box, Text, Button } from '@chakra-ui/react';
import { Badge } from '@allergan-data-labs/alle-elements';
import { BuildingOutlineIcon } from '@allergan-data-labs/alle-elements-icons/16/buildingOutlineIcon';
import { LocationOutlineIcon } from '@allergan-data-labs/alle-elements-icons/16/locationOutlineIcon';
import {
  Provider_Organization_Type_V2,
  Provider_Organization_Tag,
} from '@allergan-data-labs/alle-graphql-types';

import { PageHeaderProps } from '../pageHeader';
import { BackButton } from '../../backButton';

interface OrganizationHeaderProps extends PageHeaderProps {
  organizationType: Provider_Organization_Type_V2;
  accountType?: Provider_Organization_Tag;
  backLabel?: string;
  actionLabel?: string;
  onBack?: () => void;
  onAction?: () => void;
}

const OrganizationHeader: React.FunctionComponent<OrganizationHeaderProps> = ({
  title,
  organizationType,
  backLabel,
  actionLabel,
  onBack,
  onAction,
  accountType = undefined,
}) => {
  const displayAccountType = (type: Provider_Organization_Tag) => {
    if (type === Provider_Organization_Tag.StrategicAccount) {
      return 'Strategic';
    }

    if (type === Provider_Organization_Tag.ContractAccount) {
      return 'Contract';
    }

    return 'Premier';
  };

  return (
    <Box paddingBottom="24px" margin="0" data-testid="section-header-container">
      {backLabel && <BackButton label={backLabel} onBack={onBack} />}

      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text fontSize="36px" lineHeight="45px" fontWeight={500} margin="0">
            {title}
          </Text>
          <Box marginTop="8px">
            <Badge
              marginRight="8px"
              icon={
                organizationType === Provider_Organization_Type_V2.Business ? (
                  <BuildingOutlineIcon />
                ) : (
                  <LocationOutlineIcon />
                )
              }
              color="light.Text/Neutral/Default"
            >
              {organizationType === Provider_Organization_Type_V2.Business
                ? 'Business: Soldto'
                : 'Location: Shipto'}
            </Badge>
            {accountType ? (
              <Badge>{displayAccountType(accountType)}</Badge>
            ) : null}
          </Box>
        </Box>

        {onAction && (
          <Box>
            <Button size="xs" onClick={onAction}>
              {actionLabel}
            </Button>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export { OrganizationHeader };
