import {
  Account_Status,
  Provider_Organization_Type_V2,
  Provider_User_Category,
  Provider_User_Role_Assignment_State,
  Maybe,
  Scalars,
} from '@allergan-data-labs/alle-graphql-types';

export const organizationTypesList = [
  Provider_Organization_Type_V2.Location,
  Provider_Organization_Type_V2.Business,
  Provider_User_Category.BusinessUser,
] as const;

export const businessUserTypesList = [
  Provider_User_Category.BusinessUser,
] as const;

export type OrganizationType = (typeof organizationTypesList)[number];

export type BusinessUserType = (typeof businessUserTypesList)[number];

export interface OrganizationItem {
  type: Provider_Organization_Type_V2;
  accountInfo: Record<string, string>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Account_Status>;
  tags?: string[];
  address: string;
  shortId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  practiceId?: Scalars['ID'];
  details?: {
    type: Provider_Organization_Type_V2;
    title?: Maybe<Scalars['String']>;
    subtitle?: string;
  };
}

export interface BusinessUserItem {
  type: Provider_User_Category;
  accountInfo: Record<string, string>;
  name: string;
  emailAddress: Scalars['String'];
  status: Provider_User_Role_Assignment_State;
  id: Scalars['ID'];
  details?: {
    type: Provider_User_Category;
    title?: Maybe<Scalars['String']>;
    subtitle?: string;
  };
}
