import { ColumnSort } from '@allergan-data-labs/alle-elements';
import {
  Provider_User_Role,
  ProviderUser,
} from '@allergan-data-labs/alle-graphql-types';

export const calculatePages = (
  length: number | undefined,
  itemsPerPage: number
): number => (length ? Math.ceil(length / itemsPerPage) : 0);

export const getInitials = (name: string) => {
  const parts = name.split(' ');
  const initials =
    parts.length > 1
      ? `${parts[0].charAt(0)}${parts[parts.length - 1].charAt(0)}`
      : parts[0].charAt(0);
  return initials.toUpperCase();
};

export const isBusinessAdmin = (user: ProviderUser): boolean =>
  user.roleAssignments.edges.some(({ node: { role } }) =>
    [
      Provider_User_Role.BusinessOwner,
      Provider_User_Role.BusinessAdmin,
    ].includes(role)
  );

export const getSortedItems = (sortConfig: ColumnSort, data: any[]): any[] => {
  if (!sortConfig.id || sortConfig.direction === null) {
    return data;
  }
  const sortKey = sortConfig.id as keyof any;
  return [...data].sort((a, b) => {
    const aValue = a[sortKey] || '';
    const bValue = b[sortKey] || '';
    if (sortConfig.direction === 'asc') {
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    }
    return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
  });
};
