import React from 'react';

import {
  Pagination as CorePagination,
  PaginationProps as CorePaginationProps,
} from '@allergan-data-labs/alle-elements';
import { Box } from '@chakra-ui/react';
import { BaseComponentProps } from '../../common';

export interface PaginationProps
  extends CorePaginationProps,
    BaseComponentProps {
  scrollToTopOnChange?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  total,
  currentPage,
  defaultPageSize,
  onPageChange,
  onPageSizeChange,
  showSizeChanger = true,
  scrollToTopOnChange = false,
  dataTestId = 'pagination',
}) => {
  const handlePageChange = (newPage: number) => {
    if (scrollToTopOnChange) window.scrollTo(0, 0);
    if (typeof onPageChange === 'function') onPageChange(newPage);
  };

  return (
    <Box data-testid={dataTestId}>
      <CorePagination
        currentPage={currentPage}
        defaultPageSize={defaultPageSize || 10}
        showSizeChanger={showSizeChanger}
        pageSizeOptions={[10, 25, 50, 100]}
        total={total}
        onPageChange={handlePageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </Box>
  );
};

export { Pagination };
