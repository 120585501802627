import React from 'react';
import { Box } from '@chakra-ui/react';

import { Provider_User_Role_Assignment_State } from '@allergan-data-labs/alle-graphql-types';
import {
  IndicatorVariant,
  IndicatorProps,
  IndicatorSize,
  Indicator,
} from '@allergan-data-labs/alle-elements';

export const StatusTextMap = {
  [Provider_User_Role_Assignment_State.Active]: 'Active',
  [Provider_User_Role_Assignment_State.Suggested]: 'Suggested',
  [Provider_User_Role_Assignment_State.Suspended]: 'Suspended',
  INVITE_PENDING: 'Invite Pending',
};

const StatusColorMap = {
  [Provider_User_Role_Assignment_State.Active]: 'success',
  [Provider_User_Role_Assignment_State.Suggested]: 'warning',
  [Provider_User_Role_Assignment_State.Suspended]: 'alert',
  INVITE_PENDING: 'warning',
} as unknown as IndicatorVariant;

export interface BusinessUserStatusIndicatorProps extends IndicatorProps {
  /** Business and location account status: ACTIVE | SUGGESTED | SUSPENDED | INVITE_PENDING
   * @default undefined
   */
  status: Provider_User_Role_Assignment_State | 'INVITE_PENDING';
  /** Status indicator size
   * @default sm
   */
  size?: IndicatorSize;
  /** Provide custom data-testid
   * @default undefined
   */
  dataTestId?: string;
}

export const BusinessUserStatusIndicator: React.FC<
  BusinessUserStatusIndicatorProps
> = ({ size = 'sm', dataTestId, status }) => {
  const isValidStatusIndicator = status && Object.hasOwn(StatusTextMap, status);
  const testId = dataTestId || 'businees-user-status-indicator-container';

  return isValidStatusIndicator ? (
    <Box data-testid={testId}>
      <Indicator
        size={size}
        variant={StatusColorMap[status as any] as IndicatorVariant}
        text={StatusTextMap[status]}
      />
    </Box>
  ) : null;
};
