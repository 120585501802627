import React from 'react';

import { getActionListItemStyles } from '@allergan-data-labs/alle-elements';
import { Box } from '@chakra-ui/react';

interface ProfileMenuItemProps {
  children: React.ReactNode;
  onClick?: () => void;
  paddingLeft?: string;
}

const ProfileMenuItem: React.FC<ProfileMenuItemProps> = ({
  children,
  onClick,
  paddingLeft,
}) => {
  return (
    <Box
      onClick={onClick}
      className="box__menuitem"
      display="flex"
      alignItems="center"
      sx={{
        ...getActionListItemStyles('light'),
        padding: `0 8px 0 ${paddingLeft || '8px'}`,
        marginBottom: '8px',
        height: '36px',
        _hover: {
          background: 'light.Container/Brand/Navlink/Hover',
        },
        _active: {
          background: 'light.Container/Brand/Navlink/Active',
        },
        _focusVisible: {
          background: 'light.Container/Brand/Navlink/Hover',
          outline: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
};

export { ProfileMenuItem };
