import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Provider_Organization_Type_V2 } from '@allergan-data-labs/alle-graphql-types';
import { SearchResultsDropdown } from '../resultsDropdown/searchResultsDropdown';

import {
  OrganizationResultRow,
  BusinessUserResultRow,
} from '../resultsDropdown/resultRow';
import {
  OrganizationType,
  BaseComponentProps,
  OrganizationItem,
  BusinessUserItem,
} from '../common';
import { SearchResultsParams } from '../searchField';

export interface SearchResultData {
  type: OrganizationType;
  data: BusinessUserItem | OrganizationItem;
}

export interface RecentlyVisitedResultsDropdownProps
  extends BaseComponentProps {
  title?: string;
  recentlyVisitedResults: SearchResultData[];
  onRenderItem?: (item: any) => JSX.Element;
  onSelect: (params: SearchResultsParams) => void;
}

const RecentlyVisitedResultsDropdown: React.FC<
  RecentlyVisitedResultsDropdownProps
> = ({
  title = 'Recently Visited',
  recentlyVisitedResults,
  onRenderItem,
  onSelect,
  dataTestId = 'recently-visited-results-dropdown',
}) => {
  const renderItem = (item: SearchResultData) => {
    if (
      item.type === Provider_Organization_Type_V2.Location ||
      item.type === Provider_Organization_Type_V2.Business
    ) {
      const organization = item.data as OrganizationItem;
      const result = {
        name: organization.name || '',
        type: item.type,
        address: organization.address || '',
        accountInfo: organization.accountInfo || {},
        tags: organization.tags || [],
        status: organization.status,
        id: organization.id,
        details: organization.details,
      };

      return (
        <Flex
          key={organization.id}
          onClick={() =>
            onSelect({
              type: item.type,
              data: organization,
            })
          }
          data-testid={`recently-visited-item-${organization.id}`}
          role="button"
          tabIndex={0}
        >
          <OrganizationResultRow result={result} type={item.type} />
        </Flex>
      );
    } else {
      const user = item.data as BusinessUserItem;

      const result = {
        name: user.name || '',
        type: item.type,
        emailAddress: user.emailAddress || '',
        accountInfo: user.accountInfo || {},
        status: user.status,
        id: user.id,
      };

      return (
        <Flex
          key={user.id}
          onClick={() =>
            onSelect({
              type: item.type,
              data: user,
            })
          }
          data-testid={`recently-visited-item-${user.id}`}
          role="button"
          tabIndex={0}
        >
          <BusinessUserResultRow result={result} type={item.type} />
        </Flex>
      );
    }
  };

  return (
    <SearchResultsDropdown sectionTitle={title} data-testid={dataTestId}>
      {recentlyVisitedResults.map((item) =>
        onRenderItem ? onRenderItem(item) : renderItem(item)
      )}
    </SearchResultsDropdown>
  );
};

export { RecentlyVisitedResultsDropdown };
