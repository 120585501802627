import React, { useState, useEffect } from 'react';
import { Flex, Text, Box, Divider } from '@chakra-ui/react';

import {
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
} from '@allergan-data-labs/alle-elements';
import { BaseComponentProps } from '../common';

export interface BusinessDeleteUserDialogProps extends BaseComponentProps {
  isDeleUserLoading?: boolean;
  isOpen: boolean;
  name: string;
  primaryEmail: string;
  phoneNumber: string;
  secondaryEmail?: string;
  onClose: () => void;
  onDelete: () => void;
}

const BusinessDeleteUserDialog: React.FC<BusinessDeleteUserDialogProps> = ({
  isDeleUserLoading = false,
  isOpen,
  name,
  primaryEmail,
  phoneNumber,
  secondaryEmail,
  onClose,
  onDelete,
  dataTestId = 'business-delete-user-dialog',
}) => {
  const [isUnderstood, setIsUnderstood] = useState(false);

  useEffect(() => {
    if (!isDeleUserLoading) {
      setIsUnderstood(false);
    }
  }, [isDeleUserLoading]);

  const handleUnderstoodClick = () =>
    setIsUnderstood((understood) => !understood);

  const handleOnDelete = () => {
    onDelete();
  };

  const handleOnClose = () => {
    setIsUnderstood(false);
    onClose();
  };

  return (
    <Modal size="md" isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent data-testid={dataTestId}>
        <ModalHeader display="flex" alignItems="center">
          <Text flex="1">Delete Business User</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box marginBottom={24}>
            <Text textStyle="Body/Medium/Regular">
              Are you sure you want to delete this user and release the
              associated phone number and email addresses?
            </Text>
          </Box>

          <Box marginBottom={24}>
            <Text textStyle="Body/Medium/Medium">{name}</Text>
            <Text textStyle="Body/Medium/Regular">
              <Text color="light.Text/Neutral/Support" as="span">
                Primary Email:
              </Text>{' '}
              {primaryEmail || 'Not specified'}
            </Text>
            <Text textStyle="Body/Medium/Regular">
              <Text color="light.Text/Neutral/Support" as="span">
                Phone number:
              </Text>{' '}
              {phoneNumber || 'Not specified'}
            </Text>
            {secondaryEmail ? (
              <Text textStyle="Body/Medium/Regular">
                <Text color="light.Text/Neutral/Support" as="span">
                  Secondary Email:
                </Text>{' '}
                {secondaryEmail}
              </Text>
            ) : null}
          </Box>

          <Divider marginY={20} borderColor={'light.Border/Neutral/Subtle 2'} />

          <Checkbox
            size="lg"
            isChecked={isUnderstood}
            onChange={handleUnderstoodClick}
            data-testid="understood-check"
          >
            <Text textStyle="Body/Medium/Regular">
              I understand that this action cannot be undone
            </Text>
          </Checkbox>
          <ModalFooter padding="16px 0">
            <ButtonGroup size="sm">
              <Flex direction="row" width="100%" gap="8">
                <Button
                  variant="outline"
                  onClick={handleOnClose}
                  isDisabled={isDeleUserLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  onClick={handleOnDelete}
                  isDisabled={!isUnderstood}
                  isLoading={isDeleUserLoading}
                >
                  Delete User
                </Button>
              </Flex>
            </ButtonGroup>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { BusinessDeleteUserDialog };
