import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Pagination } from '../../dataTable/pagination';
import { TotalResults } from '../../dataTable/totalResults';

interface OfferBuilderPaginationProps {
  /* Display the total of offers in the current page */
  totalOffersLabel: number;

  /* Total items for the pagination */
  totalItems: number;

  /* Current page for the pagination */
  currentPage: number;

  /* Page size to display the limit of items in the current page */
  pageSize?: number;

  dataTestId: string;

  onPaginationChange: (value: number) => void;
  onPaginationSizeChange: (value: number) => void;
}

const OfferBuilderPagination: React.FunctionComponent<
  OfferBuilderPaginationProps
> = ({
  totalOffersLabel,
  totalItems,
  currentPage = 1,
  pageSize = 10,
  dataTestId,
  onPaginationChange,
  onPaginationSizeChange,
}) => {
  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} width="100%">
      <TotalResults
        label="Total Offers"
        value={totalOffersLabel}
        data-testid={dataTestId}
      />
      <Pagination
        total={totalItems}
        currentPage={currentPage}
        defaultPageSize={pageSize}
        onPageChange={onPaginationChange}
        onPageSizeChange={onPaginationSizeChange}
      />
    </Flex>
  );
};

export { OfferBuilderPagination };
