import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import {
  ActionListItem,
  ActionMenu,
  ActionMenuContent,
  ActionMenuGroup,
  IconButton,
} from '@allergan-data-labs/alle-elements';
import { crFeatureKeys } from '@allergan-data-labs/split-feature-flag/src/admin';
import { useAlleTreatments } from '@allergan-data-labs/split-feature-flag/src/useAlleTreatments';
import { OverflowIcon } from '@allergan-data-labs/alle-elements-icons/16/overflowIcon';
import { EyeShowIcon } from '@allergan-data-labs/alle-elements-icons/20/eyeShowIcon';
import { EditOutlineIcon } from '@allergan-data-labs/alle-elements-icons/20/editOutlineIcon';
import { LockClosedOutlineIcon } from '@allergan-data-labs/alle-elements-icons/20/lockClosedOutlineIcon';
import { DeleteOutlineIcon } from '@allergan-data-labs/alle-elements-icons/20/deleteOutlineIcon';
import { CopyOutlineIcon } from '@allergan-data-labs/alle-elements-icons/20/copyOutlineIcon';
import { RefreshIcon } from '@allergan-data-labs/alle-elements-icons/20/refreshIcon';
import { BaseComponentProps } from '../../common';
import { Offer, OfferStatus } from '../types';

export enum MenuActionType {
  QUICK_VIEW = 'QUICK_VIEW',
  EDIT = 'EDIT',
  APPROVALS = 'APPROVALS',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
  COPY_OFFER_ID = 'COPY_OFFER_ID',
  COPY_PROMO_ID = 'COPY_PROMO_ID',
}

export interface ContextMenuCellProps extends BaseComponentProps, BoxProps {
  offer: Offer;
  onActionItemClick: (offer: Offer, actionType: MenuActionType) => void;
}

// TODO - implement this part when API is ready
export const hasPendingChangeRequest = (): boolean | undefined => {
  return false;
};

export const ContextMenuCell = ({
  offer,
  onActionItemClick,
  dataTestId = 'context-menu-cell',
  ...props
}: ContextMenuCellProps) => {
  const splitTreatments = useAlleTreatments({
    splitNames: [
      crFeatureKeys.offerBuilder.creationFlow,
      crFeatureKeys.offerBuilder.changeRequestApprovalPublishFlow,
    ],
  });

  const isOfferBlockedToEdit =
    offer.status === OfferStatus.DistributionError ||
    offer.status === OfferStatus.PendingApproval ||
    offer.status === OfferStatus.Approved;

  const isOfferBlockedToApprove =
    offer.status === OfferStatus.DistributionError ||
    offer.status === OfferStatus.Draft ||
    offer.status === OfferStatus.Scheduled ||
    offer.status === OfferStatus.Live ||
    offer.status === OfferStatus.DistributionScheduled;

  const isEnabledChangeRequestApprovalPublishFlow =
    splitTreatments[crFeatureKeys.offerBuilder.changeRequestApprovalPublishFlow]
      ?.treatment === 'on';

  // TODO - implement this part when API is ready
  const displayEditActionItem = () => {
    if (hasPendingChangeRequest()) {
      if (isEnabledChangeRequestApprovalPublishFlow) {
        return (
          <ActionListItem
            icon={<RefreshIcon />}
            isDisabled={!hasPendingChangeRequest()}
            onClick={() => onActionItemClick(offer, MenuActionType.EDIT)}
          >
            Review Change Request
          </ActionListItem>
        );
      }

      return (
        <ActionListItem
          icon={<EditOutlineIcon />}
          isDisabled={isOfferBlockedToEdit}
          onClick={() => onActionItemClick(offer, MenuActionType.EDIT)}
        >
          Edit
        </ActionListItem>
      );
    }

    return (
      <ActionListItem
        icon={<EditOutlineIcon />}
        isDisabled={isOfferBlockedToEdit}
        onClick={() => onActionItemClick(offer, MenuActionType.EDIT)}
      >
        Edit
      </ActionListItem>
    );
  };

  return (
    <Box padding="1rem" data-testid={dataTestId} {...props}>
      <ActionMenu
        triggerElement={IconButton}
        triggerElementProps={{
          icon: <OverflowIcon />,
          variant: 'solid',
          size: 'xs',
          'aria-label': 'Options',
          colorScheme: 'muted',
          colorMode: 'light',
        }}
      >
        <ActionMenuContent>
          <ActionMenuGroup>
            <ActionListItem
              icon={<EyeShowIcon />}
              onClick={() =>
                onActionItemClick(offer, MenuActionType.QUICK_VIEW)
              }
            >
              Quick View
            </ActionListItem>
            {displayEditActionItem()}
            <ActionListItem
              icon={<LockClosedOutlineIcon />}
              isDisabled={isOfferBlockedToApprove}
              onClick={() => onActionItemClick(offer, MenuActionType.APPROVALS)}
            >
              Approvals
            </ActionListItem>
            <ActionListItem
              icon={<DeleteOutlineIcon />}
              isDisabled={offer.status !== OfferStatus.Draft}
              onClick={() => onActionItemClick(offer, MenuActionType.DELETE)}
            >
              Delete
            </ActionListItem>
            <ActionListItem
              icon={<CopyOutlineIcon />}
              onClick={() => onActionItemClick(offer, MenuActionType.DUPLICATE)}
            >
              Duplicate
            </ActionListItem>
            <ActionListItem
              icon={<CopyOutlineIcon />}
              onClick={() =>
                onActionItemClick(offer, MenuActionType.COPY_OFFER_ID)
              }
            >
              Offer ID
            </ActionListItem>
            <ActionListItem
              icon={<CopyOutlineIcon />}
              onClick={() =>
                onActionItemClick(offer, MenuActionType.COPY_PROMO_ID)
              }
            >
              Promo ID
            </ActionListItem>
          </ActionMenuGroup>
        </ActionMenuContent>
      </ActionMenu>
    </Box>
  );
};
