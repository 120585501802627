import React from 'react';
import { Flex } from '@chakra-ui/react';
import { SearchResultsDropdown } from '../searchResultsDropdown';
import { OrganizationItem } from '../../common';
import { OrganizationResultRow } from '../resultRow';
import { Provider_Organization_Type_V2 } from '@allergan-data-labs/alle-graphql-types';
import { SearchResultsParams } from '../../searchField';

export interface OrganizationResultsDropdownProps {
  results: OrganizationItem[];
  searchTerm?: string;
  onSelect: (params: SearchResultsParams) => void;
}

const OrganizationsResultsDropdown = ({
  results,
  searchTerm = '',
  onSelect,
}: OrganizationResultsDropdownProps) => (
  <SearchResultsDropdown
    sectionTitle="Businesses and Locations"
    data-testid="organizations-results-dropdown"
  >
    {results.map((result, index) => (
      <Flex
        key={result.id}
        onClick={() => {
          onSelect({
            type: result.type,
            data: result as OrganizationItem,
          });
        }}
        data-testid={`organizations-result-${index}`}
      >
        <OrganizationResultRow
          result={result}
          type={result.type as Provider_Organization_Type_V2}
          searchTerm={searchTerm}
        />
      </Flex>
    ))}
  </SearchResultsDropdown>
);

export { OrganizationsResultsDropdown };
