import React from 'react';
import { Box } from '@chakra-ui/react';

import { BriefcaseOutlineIcon } from '@allergan-data-labs/alle-elements-icons/20/briefcaseOutlineIcon';
import { LocationOutlineIcon } from '@allergan-data-labs/alle-elements-icons/20/locationOutlineIcon';
import { PersonOutlineIcon } from '@allergan-data-labs/alle-elements-icons/20/personOutlineIcon';

import {
  BaseComponentProps,
  organizationTypesList,
  OrganizationType,
} from '../common';

export interface OrganizationAvatarProps extends BaseComponentProps {
  /** Business type
   * @default undefined
   */
  type: OrganizationType;

  /** Adds border and padding to the avatar
   * @default false
   */
  isBordered?: boolean;
}

const OrganizationAvatar = ({
  isBordered = false,
  type,
  dataTestId,
}: OrganizationAvatarProps) => {
  if (!type || !organizationTypesList.includes(type)) return null;

  const testId = dataTestId || 'account-avatar-container';

  const theme = isBordered
    ? {
        borderColor: 'light.Border/Neutral/Subtle 2',
        background: 'light.Icon/Neutral/Inverted',
        borderWidth: 'Extra light',
        borderRadius: 'Circle',
        borderStyle: 'solid',
        height: '41px',
        width: '41px',
      }
    : {};

  const typeComponentMap = {
    BUSINESS_USER: <PersonOutlineIcon />,
    BUSINESS: <BriefcaseOutlineIcon />,
    LOCATION: <LocationOutlineIcon />,
  };

  return (
    <Box
      data-testid={testId}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '24px',
        width: '24px',
        ...theme,
      }}
    >
      {typeComponentMap[type]}
    </Box>
  );
};

export { OrganizationAvatar };
