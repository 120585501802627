import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { SectionHeader, SectionHeaderProps } from '../layout/sectionHeader';
import { SectionContent, SectionContentProps } from '../sectionContent';

export interface SectionProps extends SectionHeaderProps, SectionContentProps {
  bottomDivider?: boolean;
}

const Section: React.FC<SectionProps> = ({
  bottomDivider,
  children,
  ...headerProps
}) => {
  const theme = bottomDivider
    ? {
        borderBottomWidth: 'Extra light',
        borderBottomColor: 'light.Border/Neutral/Subtle 2',
        borderBottomStyle: 'solid',
      }
    : { borderStyle: 'none' };
  return (
    <Box
      data-testid="section-container"
      sx={{
        ...theme,
      }}
      paddingBottom={24}
      marginBottom={24}
    >
      <Flex justifyContent="space-between" flexDirection="column">
        <Box>
          <SectionHeader {...headerProps} />
        </Box>
        <Box>
          <SectionContent>{children}</SectionContent>
        </Box>
      </Flex>
    </Box>
  );
};

export { Section };
