import React from 'react';

const AlleLogo = () => (
  <svg
    aria-describedby="alle-logo"
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="24"
    viewBox="0 0 50 24"
    fill="none"
  >
    <g clipPath="url(#clip0_2202_2891)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5235 23.7239H20.9959V0H23.5235V23.7239ZM0 23.7239H2.46728L4.54337 17.4015H13.4195L15.5258 23.7239H18.1135L10.8319 1.77983H7.40178L0 23.7239ZM8.99603 3.98976L12.727 15.2838H5.26506L8.99603 3.98976ZM27.9107 23.7239H30.438V0H27.9107V23.7239ZM47.8092 19.0896C47.2676 22.3123 44.6498 24 41.0993 24C36.6464 24 33.487 20.8083 33.487 15.867V15.6215C33.487 10.7724 36.5259 7.39623 40.8587 7.39623C44.4993 7.39623 47.9294 9.6368 47.9294 15.3761V16.2046H36.0747C36.1948 19.8569 37.9099 21.8823 41.1293 21.8823C43.5666 21.8823 44.9809 20.9619 45.312 19.0896H47.8092ZM36.1641 14.1486C36.5853 11.2942 38.3003 9.51425 40.8577 9.51425C43.4454 9.51425 45.1304 10.834 45.371 14.1486H36.1641ZM36.917 1.87208V3.83624H44.8004V1.87208H36.917Z"
        fill="#090909"
      />
      <path
        d="M48.136 23.3734H48.3526V22.9809H48.456L48.6953 23.3734H48.9206L48.6593 22.9438C48.7764 22.9067 48.8622 22.8237 48.8622 22.6711V22.6667C48.8622 22.4587 48.7133 22.3617 48.4834 22.3617H48.136V23.3734ZM48.3526 22.8284V22.5326H48.4787C48.5869 22.5326 48.6503 22.5694 48.6503 22.6759V22.6803C48.6503 22.7774 48.5915 22.8284 48.4787 22.8284H48.3526ZM48.4787 23.7339C48.0188 23.7339 47.6581 23.3921 47.6581 22.8839C47.6581 22.3801 48.0008 22.0244 48.4787 22.0244C48.9566 22.0244 49.2994 22.3753 49.2994 22.8791C49.2994 23.3829 48.9433 23.7339 48.4787 23.7339ZM48.4787 23.9004C49.0381 23.9004 49.4799 23.4568 49.4799 22.8791C49.4799 22.3107 49.0471 21.8579 48.4787 21.8579C47.9194 21.8579 47.4775 22.3154 47.4775 22.8839C47.4775 23.4612 47.9241 23.9004 48.4787 23.9004Z"
        fill="#090909"
      />
    </g>
    <defs>
      <clipPath id="clip0_2202_2891">
        <rect width="49.8462" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { AlleLogo };
