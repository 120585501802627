import { useEffect, useMemo, useRef, useState } from 'react';
import { ActionListItemProps } from '@allergan-data-labs/alle-elements';
import { createContext } from '@allergan-data-labs/alle-elements-core';

export const useSearchFieldState = (externalRef = null) => {
  const internalRef = useRef<HTMLInputElement | null>(null);
  const inputRef = externalRef || internalRef;

  useEffect(() => {
    if (inputRef.current && !externalRef) {
      inputRef.current.focus();
    }
  }, [inputRef, externalRef]);

  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [resultsCount, setResultsCount] = useState(0);
  const [shouldFocusInput, setShouldFocusInput] = useState(false);

  const value = useMemo(
    () => ({
      focusedIndex,
      resultsCount,
      inputRef,
      shouldFocusInput,
      setShouldFocusInput,
      setFocusedIndex,
      setResultsCount,
    }),
    [focusedIndex, resultsCount, shouldFocusInput, inputRef]
  );

  return value;
};

export const useSearchFieldEvents = () => {
  const { setFocusedIndex, setShouldFocusInput, inputRef } =
    useSearchFieldContext();
  const events = useMemo<Pick<ActionListItemProps, 'onFocus' | 'onMouseOut'>>(
    () => ({
      onFocus: () => {
        inputRef.current?.focus();
        setFocusedIndex(null);
      },
      onMouseOut: () => {
        setFocusedIndex(null);
        setShouldFocusInput(true);
      },
    }),
    [inputRef, setFocusedIndex, setShouldFocusInput]
  );

  return events;
};

export type SearchFieldContextProps = ReturnType<typeof useSearchFieldState>;

export const [SearchFieldProvider, useSearchFieldContext] =
  createContext<SearchFieldContextProps>({
    requiresProvider: true,
    name: 'SearchFieldContext',
  });
