import React from 'react';
import { Box, List, ListItem, ListIcon, Text, Flex } from '@chakra-ui/react';
import { SearchOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/searchOutlineIcon';
import { CloseIcon } from '@allergan-data-labs/alle-elements-icons/20/closeIcon';

interface GenericRecentSearchTermProps {
  items: string[];
  size?: number;
  onSelect: (item: string) => void;
  onRemove?: (event: any, item: string) => void;
}

const GenericRecentSearchTerms = ({
  items,
  size = 4,
  onSelect,
  onRemove,
}: GenericRecentSearchTermProps) => {
  const listStyles = {
    ':hover': {
      '.generic-search-term-row': {
        cursor: 'pointer',
      },
    },
  };
  const terms = items.filter((item) => !!item?.trim()).slice(0, size);

  return (
    <Box
      alignItems="left"
      justifyContent="left"
      data-testid="generic-recent-search-terms-container"
    >
      {terms.length ? (
        <>
          <Text
            color="light.Text/Neutral/Default"
            textStyle="Body/Small/Medium"
            padding="0 0 0 16px"
          >
            Recently Searched
          </Text>

          <List sx={listStyles} paddingX={0} marginY={0}>
            {terms.map((term, idx) => (
              <ListItem
                key={`key-${idx}`}
                padding="0 0.5rem 0 1rem"
                display="flex"
                fontFamily="Graphik"
                fontSize="14px"
                fontWeight="400"
                lineHeight="21px"
                color="light.Text/Neutral/Default"
                alignItems="center"
                justifyContent="space-between"
                className="generic-search-term-row"
                _hover={{
                  backgroundColor:
                    'light.Container/Neutral/Transparent interactive/Hover',
                }}
                onClick={() => onSelect(term)}
              >
                <Flex alignItems="center">
                  <ListIcon
                    as={SearchOutlineIcon}
                    height="20px"
                    width="20px"
                    marginRight="10px"
                  />
                  <Text lineHeight="normal" paddingY={12}>
                    {term}
                  </Text>
                </Flex>
                <ListIcon
                  as={CloseIcon}
                  data-testid="generic-remove-search-term"
                  className="generic-remove-search-term-btn"
                  padding="5px"
                  margin={0}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    if (onRemove) {
                      onRemove(event, term);
                    }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : null}
    </Box>
  );
};

export { GenericRecentSearchTerms };
