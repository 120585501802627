import React from 'react';
import { useLocation } from 'react-router-dom';

import { useSegment } from '@allergan-data-labs/admin-component-library/src/segment/segmentContext';

const useSegmentPageTracking = () => {
  const { pathname } = useLocation();
  const { page } = useSegment();

  React.useEffect(() => {
    page();
  }, [pathname, page]);
};

export { useSegmentPageTracking };
