export interface DatadogConfig {
  prod: {
    clientToken: string;
    applicationId: string;
    trackInteractions: boolean;
    service: string;
    env: 'production' | 'stage';
  };
  stage: {
    clientToken: string;
    applicationId: string;
    trackInteractions: boolean;
    service: string;
    env: 'production' | 'stage';
  };
}

// need to add landing project to datadog
export type Application = 'admin';

const datadogConfig: Record<Application, DatadogConfig> = {
  admin: {
    prod: {
      clientToken: 'pub405f137404e477ea10e654806059f977',
      applicationId: '05ae9f25-c286-4cf3-8dbd-59792d0be410',
      trackInteractions: false,
      env: 'production',
      service: 'admin-web-prod',
    },
    stage: {
      clientToken: 'pub245d157c9282c2af36a4590c40d231c3',
      applicationId: '8bc00667-0bf8-474f-9b8f-652f58cc0360',
      trackInteractions: true,
      env: 'stage',
      service: 'admin-web-stage',
    },
  },
};

const getDatadogConfig = (applicationName: Application) => {
  if (
    process.env.REACT_APP_DATADOG_ENV === 'production' ||
    process.env.NEXT_PUBLIC_DATADOG_ENV === 'production'
  ) {
    return datadogConfig[applicationName].prod;
  }

  return datadogConfig[applicationName].stage;
};

export { datadogConfig, getDatadogConfig };
