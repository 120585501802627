import React, { ReactElement, useMemo, useCallback } from 'react';
import { Box, forwardRef, PlacementWithLogical } from '@chakra-ui/react';

import { OverflowIcon } from '@allergan-data-labs/alle-elements-icons/24/overflowIcon';
import {
  ActionMenuContent,
  ActionMenuGroup,
  ActionMenuProps,
  IconButtonProps,
  ActionListItem,
  ActionMenu,
  IconButton,
} from '@allergan-data-labs/alle-elements';

import { BaseComponentProps } from '../common';

export interface OptionsMenuItem {
  onClick: () => void;
  name: string;
}

const buttonStyles = {
  '> .chakra-button': {
    background: 'light.Container/Neutral/Transparent interactive/Hover',
  },
};

export interface OptionsMenuProps
  extends Pick<ActionMenuProps, 'placement'>,
    BaseComponentProps {
  /** Menu items definition
   * @default undefined
   */
  items: OptionsMenuItem[];

  /** Button Shape
   * @default square
   */
  shape?: 'square' | 'circle';

  /** Button Size
   * @default sm
   */
  size?: 'xs' | 'sm' | 'md' | 'lg';

  /** Button Icon
   * @default <OverflowIcon />
   */
  icon?: ReactElement;

  /** Modal position
   * @default bottom-end
   */
  placement?: PlacementWithLogical;
}

const OptionsMenu: React.FC<OptionsMenuProps> = React.memo(
  ({
    icon = <OverflowIcon />,
    dataTestId = 'options-menu',
    shape = 'square',
    placement = 'bottom-end',
    items,
    size = 'sm',
    ...otherProps
  }) => {
    const MenuIconButton = useMemo(
      () =>
        forwardRef<IconButtonProps, 'button'>((props, ref) => (
          <IconButton
            data-testid={dataTestId}
            variant="ghost"
            shape={shape}
            icon={icon}
            size="sm"
            ref={ref}
            {...props}
          />
        )),
      [dataTestId, shape, icon]
    );

    const getMenuItemDataTestId = useCallback(
      (name: string) => `${dataTestId}-${name.toLowerCase().replace(' ', '-')}`,
      [dataTestId]
    );

    return (
      <Box sx={buttonStyles} data-testid={dataTestId}>
        <ActionMenu
          triggerElement={MenuIconButton}
          placement={placement}
          triggerElementProps={{
            icon: <OverflowIcon />,
            variant: 'solid',
            size,
            'aria-label': 'Options',
            colorScheme: 'muted',
            colorMode: 'light',
          }}
          {...otherProps}
        >
          <ActionMenuContent>
            <ActionMenuGroup>
              {items.map((item, index) => (
                <ActionListItem
                  data-testid={getMenuItemDataTestId(item.name)}
                  onClick={item.onClick}
                  key={index}
                >
                  {item.name}
                </ActionListItem>
              ))}
            </ActionMenuGroup>
          </ActionMenuContent>
        </ActionMenu>
      </Box>
    );
  }
);

export { OptionsMenu };
