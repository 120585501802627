import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { colors } from '../core/colors';
import { IconContainer } from './iconContainer';

interface HelpProps {
  fill?: string;
  width?: string;
  height?: string;
  dataTestId?: string;
}

const Help: React.FunctionComponent<HelpProps> = ({
  fill = colors.black,
  width = '22px',
  height = '22px',
  dataTestId,
}) => (
  <IconContainer
    pathFill={fill}
    width={width}
    height={height}
    dataTestId={dataTestId}
  >
    <SvgIcon width={width} height={height} viewBox="0 0 22 22">
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"
        id="path-1"
      />
    </SvgIcon>
  </IconContainer>
);

export { Help };
