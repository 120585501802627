import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import copy from 'copy-to-clipboard';
import { CopyOutlineIcon } from '@allergan-data-labs/alle-elements-icons/20/copyOutlineIcon';
import { IconButton, useToast } from '@allergan-data-labs/alle-elements';

import { BaseComponentProps } from '../../common';

export interface OrganizationTableCellProps extends BaseComponentProps {
  /** Text to display in the Cell title
   * @default undefined
   */
  title: string;
  /** Text to display in the Cell value
   * @default undefined
   */
  value: string;
  /** Enable the copy button
   * @default false
   */
  isCopyEnabled?: boolean;
  /** Display optimized space OrganizationTableCell
   * @default false
   */
  isSmall?: boolean;
}

const OrganizationTableCell = ({
  isCopyEnabled = false,
  isSmall = false,
  dataTestId,
  value,
  title,
}: OrganizationTableCellProps) => {
  const showToast = useToast();

  const testId = dataTestId || 'table-cell-container';

  const handleCopy = (text: string) => {
    copy(text);
    showToast({
      duration: 1000,
      render: () => <Box>Copied to clipboard</Box>,
    });
  };

  const containerStyle = isSmall
    ? { display: 'flex', alignItems: 'center' }
    : {};

  const textValueStyle = isSmall
    ? { textStyle: 'Body/Small/Regular', paddingLeft: '5px' }
    : { textStyle: 'Body/Medium/Regular' };

  return (
    <Box data-testid={testId} {...containerStyle}>
      {title && (
        <Text color="light.Text/Neutral/Support" textStyle="Body/Small/Regular">
          {title}
        </Text>
      )}
      {value && (
        <Flex alignItems="center">
          <Text color="Text/Neutral/Default" {...textValueStyle}>
            {value}
          </Text>
          {isCopyEnabled && (
            <IconButton
              onClick={() => handleCopy(value)}
              icon={<CopyOutlineIcon />}
              isStandalone={false}
              colorScheme="muted"
              aria-label="Copy"
              variant="ghost"
              size="xs"
              height={24}
            />
          )}
        </Flex>
      )}
    </Box>
  );
};

OrganizationTableCell.displayName = 'OrganizationTableCell';

export { OrganizationTableCell };
