import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from 'react';
import { Button } from '@allergan-data-labs/alle-elements';
import {
  Box,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useOutsideClick,
} from '@chakra-ui/react';
import { SearchOutlineIcon } from '@allergan-data-labs/alle-elements-icons/24/searchOutlineIcon';
import { CloseIcon } from '@allergan-data-labs/alle-elements-icons/16/closeIcon';
import { useDebounce } from '@allergan-data-labs/alle-elements-core';
import {
  getAdditionalTextFieldStyles,
  getClearIconStyles,
  getCloseIconStyles,
  getLeftElementStyles,
  getRightElementStyles,
  getSearchIconStyles,
} from './searchField.theme';
import {
  SearchFieldProvider,
  useSearchFieldContext,
  useSearchFieldState,
} from './searchField.hook';
import {
  DropdownVariant,
  SearchFieldProps,
  SearchResultsParams,
} from './types';
import { DefaultDropdown, RecentSearchesAndResultsDropdown } from './dropdown';
import { GenericRecentSearchesAndResultsDropdown } from './dropdown/genericRecentSearchesAndResultsDropdown';

function SearchFieldImpl(props: SearchFieldProps) {
  const {
    initialQuery = '',
    recentSearches,
    placeholder = 'Search',
    colorMode = 'light',
    size = 'md',
    dataTestId = 'a4a-search-field',
    dropdownVariant = DropdownVariant.ALLE_ELEMENTS,
    disableFocus = false,
    isOrganizationsLoading = false,
    isBusinessUsersLoading = false,
    dropdownActivated = true,
    recentVisitedSearchResults = [],
    organizationsSearchResults = [],
    businessUsersSearchResults = [],
    onSearch,
    onRemoveRecentSearchTerm,
    onSelectRecentSearchTerm,
    onSelectResults,
    onRecentVisitedSearchResultsItem = undefined,
    onKeyDownHandler,
    ...containerAttributes
  } = props;
  const [query, setQuery] = useState(initialQuery);
  const [results, setResults] = useState([]);
  const [previousQuery, setPreviousQuery] = useState('');
  const [menuVisible, setMenuVisible] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [hasFocus, setFocus] = useState(disableFocus);
  const [initialState, setInitialState] = useState(true);
  const [isDropdownActive, setDropdownActive] = useState(true);
  const debouncedQuery = useDebounce(query, 1500);
  const {
    resultsCount,
    setResultsCount,
    inputRef,
    focusedIndex,
    setFocusedIndex,
  } = useSearchFieldContext();

  const componentContainerRef = useRef<HTMLInputElement | null>(null);

  useOutsideClick({
    ref: componentContainerRef,
    handler: () => {
      setMenuVisible(false);
      setFocusedIndex(null);
    },
  });

  const clearInput = () => {
    setQuery('');
    setFocusedIndex(null);
    setInitialState(true);
    setIsTyping(false);
    setMenuVisible(false);
    setDropdownActive(false);
    onSearch('');
    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMenuVisible(true);
    setIsTyping(true);
    setQuery(e.target.value);
    setDropdownActive(true);
    if (e.target.value.length === 0) {
      setInitialState(true);
      setIsTyping(false);
      onSearch('');
      setResults([]);
      setMenuVisible(false);
      setPreviousQuery('');
    }
    requestAnimationFrame(() => {
      inputRef.current?.focus();
    });
  };

  const selectResult = (keyword: string) => {
    setQuery(keyword);
    setPreviousQuery(keyword);
    setMenuVisible(false);
  };

  useEffect(() => {
    const queryChanged = debouncedQuery !== previousQuery;
    if (debouncedQuery.length && queryChanged && menuVisible) {
      setIsTyping(true);
      onSearch(debouncedQuery).then((data) => {
        if (initialState) {
          setInitialState(false);
        }

        /**
         * This condition is to prevent the results from showing if the user clears the input
         * while the search is still in progress
         */
        if (!inputRef.current?.value) {
          setIsTyping(false);
          setFocusedIndex(null);
          setInitialState(true);
          requestAnimationFrame(() => {
            inputRef.current?.focus();
          });
          return;
        }
        setPreviousQuery(debouncedQuery);
        setResults(data);
        if (dropdownVariant === DropdownVariant.ADMIN_BUSINESS) {
          setResultsCount(data?.length ?? 0);
        }
        setIsTyping(false);
        setFocusedIndex(null);
        requestAnimationFrame(() => {
          inputRef.current?.focus();
        });
      });
    }
  }, [
    debouncedQuery,
    menuVisible,
    previousQuery,
    initialState,
    inputRef,
    onSearch,
    setFocusedIndex,
    dropdownVariant,
    setResultsCount,
  ]);

  useEffect(() => {
    if (
      document.hasFocus() &&
      inputRef.current &&
      inputRef.current.contains(document.activeElement)
    ) {
      setFocus(true);
    }
  }, [inputRef]);

  const handleKeyUpPress = (e: any) => {
    if (e.key === 'Enter' && menuVisible) {
      setMenuVisible(false);
    }
  };

  const handleOnSelectResult = (args: SearchResultsParams) => {
    if (onSelectResults) {
      onSelectResults(args);
    }
    setMenuVisible(false);
  };

  const handleOnSelectRecentSearchTerm = (term: string) => {
    if (onSelectRecentSearchTerm) {
      onSelectRecentSearchTerm(term);
    }
    setQuery(term);
    setMenuVisible(false);
  };

  const onKeyDownLocalHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDownHandler) onKeyDownHandler(e, query);
      const numChildren =
        dropdownVariant === DropdownVariant.ALLE_ELEMENTS
          ? resultsCount
          : resultsCount + recentSearches.length;
      switch (e.code) {
        case 'ArrowDown': {
          e.preventDefault();
          setFocusedIndex((prev) =>
            prev === null ? 0 : (prev + 1) % numChildren
          );
          break;
        }
        case 'Enter':
        case 'Space': {
          if (focusedIndex !== null) {
            e.preventDefault();
            if (dropdownVariant === DropdownVariant.ALLE_ELEMENTS) {
              if (initialState) {
                selectResult(recentSearches[focusedIndex]);
              } else {
                selectResult(results[focusedIndex]);
              }
            } else if (focusedIndex > recentSearches.length - 1) {
              selectResult(results[focusedIndex - recentSearches.length]);
            } else {
              selectResult(recentSearches[focusedIndex]);
            }
            setFocusedIndex(null);
          }
          break;
        }
        case 'ArrowUp': {
          e.preventDefault();
          setFocusedIndex((prev) => {
            const index =
              prev === null ? numChildren - 1 : (prev - 1) % numChildren;
            return index < 0 ? index + numChildren : index;
          });
          break;
        }
        case 'Tab': {
          setMenuVisible(false);
          break;
        }
      }
    },
    [
      dropdownVariant,
      focusedIndex,
      initialState,
      recentSearches,
      results,
      resultsCount,
      setFocusedIndex,
    ]
  );

  const isQueryOrSearchResultPopulated =
    query.length ||
    (recentSearches && recentSearches.length) ||
    (results && results.length);

  const isDropdownActivatedAndVariantIsAlleElements =
    dropdownActivated && dropdownVariant === DropdownVariant.ALLE_ELEMENTS;

  const isDropdownActivatedAndVariantIsGeneric =
    isDropdownActive &&
    dropdownActivated &&
    dropdownVariant === DropdownVariant.GENERIC;

  const isDropdownActivatedAndVariantIsAdminBusiness =
    dropdownActivated &&
    dropdownVariant === DropdownVariant.ADMIN_BUSINESS &&
    isQueryOrSearchResultPopulated;

  return (
    <Box
      {...containerAttributes}
      ref={componentContainerRef}
      data-testid={dataTestId}
      sx={{ margin: '0 auto', maxWidth: 1408, position: 'relative' }}
    >
      <InputGroup size={size}>
        <InputLeftElement sx={getLeftElementStyles()}>
          <SearchOutlineIcon sx={getSearchIconStyles(colorMode)} />
        </InputLeftElement>
        <ChakraInput
          ref={inputRef}
          sx={getAdditionalTextFieldStyles(colorMode, size)}
          placeholder={placeholder}
          onFocus={() => {
            setFocus(true);
            if (dropdownVariant === DropdownVariant.GENERIC) {
              setMenuVisible(!hasFocus);
              if (hasFocus === true) {
                inputRef.current?.blur();
              }
            } else {
              setMenuVisible(true);
            }
          }}
          onBlur={() => {
            setFocus(false);
          }}
          onKeyDown={onKeyDownLocalHandler}
          onKeyUp={handleKeyUpPress}
          onInput={handleChange}
          onChange={handleChange}
          value={query}
          data-testid="search-field"
          width="100%"
        />
        <InputRightElement sx={getRightElementStyles()}>
          {(query !== undefined && query.length > 0) ||
          dropdownVariant === DropdownVariant.GENERIC ? (
            <Button
              aria-label="Clear"
              className={`${
                hasFocus ? '' : 'screen-reader'
              } screen-reader-focusable `}
              sx={getClearIconStyles()}
              onClick={clearInput}
              data-testid="clear-search-field"
            >
              <CloseIcon sx={getCloseIconStyles(colorMode)} />
            </Button>
          ) : null}
        </InputRightElement>
      </InputGroup>

      {isDropdownActivatedAndVariantIsAlleElements ? (
        <DefaultDropdown
          isTyping={isTyping}
          isOrganizationsLoading={isOrganizationsLoading}
          isBusinessUsersLoading={isBusinessUsersLoading}
          initialState={initialState}
          results={results}
          recentSearches={recentSearches}
          onSelect={selectResult}
          onSelectResults={handleOnSelectResult}
          colorMode={colorMode}
          menuVisible={menuVisible}
          query={query}
          recentVisitedSearchResults={recentVisitedSearchResults}
        />
      ) : null}

      {isDropdownActivatedAndVariantIsGeneric ? (
        <GenericRecentSearchesAndResultsDropdown
          isTyping={isTyping}
          query={query}
          results={results}
          recentSearches={recentSearches}
          placeholder={placeholder}
          recentVisitedSearchResults={recentVisitedSearchResults}
          menuVisible={menuVisible}
          colorMode={colorMode}
          onRecentVisitedSearchResultsItem={onRecentVisitedSearchResultsItem}
          onSelect={selectResult}
          onSelectResults={handleOnSelectResult}
          onRemoveRecentSearchTerm={onRemoveRecentSearchTerm}
          onSelectRecentSearchTerm={handleOnSelectRecentSearchTerm}
        />
      ) : null}

      {isDropdownActivatedAndVariantIsAdminBusiness ? (
        <RecentSearchesAndResultsDropdown
          results={results}
          recentSearches={recentSearches}
          onSelectRecentSearchTerm={handleOnSelectRecentSearchTerm}
          onRemoveRecentSearchTerm={onRemoveRecentSearchTerm}
          colorMode={colorMode}
          menuVisible={menuVisible}
          isTyping={isTyping}
          isOrganizationsLoading={isOrganizationsLoading}
          isBusinessUsersLoading={isBusinessUsersLoading}
          query={query}
          onSelect={selectResult}
          onSelectResults={handleOnSelectResult}
          recentVisitedSearchResults={recentVisitedSearchResults}
          organizationsSearchResults={organizationsSearchResults}
          businessUsersSearchResults={businessUsersSearchResults}
        />
      ) : null}
    </Box>
  );
}

export const SearchField = forwardRef((props: SearchFieldProps, ref) => (
  <SearchFieldProvider value={useSearchFieldState(ref as any)}>
    <SearchFieldImpl {...props} />
  </SearchFieldProvider>
));

export default SearchField;
