import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const TrendingUp: React.FunctionComponent = () => (
  <IconContainer>
    <SvgIcon width="22" height="22" viewBox="0 0 22 22">
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M16 6L18.29 8.29L13.41 13.17L9.41 9.17L2 16.59L3.41 18L9.41 12L13.41 16L19.71 9.71L22 12V6H16Z"
          fill="#D1D1D1"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M16 6L18.29 8.29L13.41 13.17L9.41 9.17L2 16.59L3.41 18L9.41 12L13.41 16L19.71 9.71L22 12V6H16Z"
          fill="black"
          fillOpacity="0.25"
        />
      </g>
    </SvgIcon>
  </IconContainer>
);

export { TrendingUp };
