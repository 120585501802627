import { useEffect, useState } from 'react';
import {
  InputProps,
  DataTableProps,
  ColumnSort,
} from '@allergan-data-labs/alle-elements';

/*
TODO Remove this Hook once its exposed by the Alle Elements
https://adl-technology.atlassian.net/browse/UC-2791
 */

export type UseDataTablePropsTemp<T> = {
  records: T[];
  onFilter?: (searchTerm: string, records: T[]) => T[];
  onSort?: (sortingState: ColumnSort) => void;
} & Omit<DataTableProps<T>, 'data'>;

export type UseDataTableReturn = ReturnType<typeof useDataTable>;

export const useDataTable = <T,>(props: UseDataTablePropsTemp<T>) => {
  const { records, onFilter, onClearFilter, onSort, ...dataTableProps } = props;

  const [data, setData] = useState(records);
  const [inputFilterValue, setInputFilterValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!data) {
      setData(records);
    }
  }, [data, records]);

  const getDataTableProps = (
    tableProps?: Pick<DataTableProps<T>, 'isLoading'>
  ) => ({
    data,
    onClearFilter: () => {
      setInputFilterValue('');
      onClearFilter?.();
    },
    onSort,
    inputFilterValue,
    ...dataTableProps,
    ...tableProps,
  });

  const getInputFilterProps = (inputProps?: InputProps) => ({
    ...inputProps,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onFilter) {
        setIsLoading(true);
        const filteredRecords = onFilter(event.target.value, records);
        setData(filteredRecords);
        setIsLoading(false);
      }
      setInputFilterValue(event.target.value);
      inputProps?.onChange?.(event);
    },
    value: inputFilterValue,
  });

  return {
    data,
    inputFilterValue,
    isLoading,
    setData,
    setInputFilterValue,
    setIsLoading,
    getDataTableProps,
    getInputFilterProps,
  };
};
