import React from 'react';
import { Box } from '@chakra-ui/react';
import { Section } from '../section';
import { SectionItem } from '../sectionItem';
import { ProgressBar } from '../progressBar';
import { Link } from '@allergan-data-labs/alle-elements';
import { OpenNewIcon } from '@allergan-data-labs/alle-elements-icons/16/openNewIcon';

export interface AlleDirectoryListingProps {
  description: string;
  progressStep: string;
  progressValue: number;
  profileUrl: string;
}

const AlleDirectoryListing: React.FC<AlleDirectoryListingProps> = ({
  description,
  progressValue,
  progressStep,
  profileUrl,
}) => {
  return (
    <Section
      title="Allē Directory Listing"
      bottomDivider={true}
      description={description}
      data-testid="alle-directory-listing-container"
    >
      <Box sx={{ marginRight: 48 }}>
        <ProgressBar
          label="Profile Completion"
          description={progressStep}
          value={progressValue}
        />
      </Box>

      <SectionItem title="Directory Profile URL">
        <Link
          isExternal
          href={profileUrl}
          isStandalone={true}
          sx={{ _hover: { textDecoration: 'none' } }}
          icon={<OpenNewIcon />}
        >
          Listing
        </Link>
      </SectionItem>
    </Section>
  );
};
export { AlleDirectoryListing };
