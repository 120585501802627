import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import { OfferStatus, OfferStatusLabelMap } from '../types';

const defaultColor = 'light.Icon/Neutral/Support';

const OfferStatusColorMap = {
  [OfferStatus.Draft]: defaultColor,
  [OfferStatus.Approved]: defaultColor,
  [OfferStatus.Live]: 'light.Text/Status/Success bold 1',
  [OfferStatus.Expired]: defaultColor,
  [OfferStatus.OnHold]: defaultColor,
  [OfferStatus.PendingApproval]: defaultColor,
  [OfferStatus.Declined]: defaultColor,
  [OfferStatus.Scheduled]: defaultColor,
  [OfferStatus.DistributionScheduled]: defaultColor,
  [OfferStatus.DistributionError]: defaultColor,
} as any;

export interface OfferStatusBadgeProps {
  status: OfferStatus;
  pendingChangeRequests?: boolean;
  size?: 'small' | 'large';
}

const OfferStatusBadge = ({
  status,
  pendingChangeRequests,
  size = 'small',
}: OfferStatusBadgeProps) => {
  return (
    <Box
      data-testid="offer-status-badge"
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap="5px"
    >
      <Box
        data-testid="offer-status-badge-circle"
        borderRadius="50%"
        width={size === 'small' ? '8px' : '10px'}
        height={size === 'small' ? '8px' : '10px'}
        backgroundColor={
          pendingChangeRequests ? defaultColor : OfferStatusColorMap[status]
        }
      />
      <Text
        data-testid="offer-status-badge-text"
        textStyle="Body/Medium/Regular"
        textColor="light.Text/Neutral/Default"
      >
        {OfferStatusLabelMap[status]}&nbsp;
        {pendingChangeRequests ? <>(CR Pending)</> : null}
      </Text>
    </Box>
  );
};

export { OfferStatusBadge };
