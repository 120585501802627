import gql from 'graphql-tag';
import { client } from './client';
import { AdminUserProfile } from '@allergan-data-labs/alle-graphql-types';
import { __DEV__, __TEST__ } from '../src/application.config';

const ADMIN_USER_PROFILE_QUERY = gql`
  query {
    viewer {
      profile {
        __typename
        ... on AdminUserProfile {
          firstName
          lastName
        }
      }
    }
  }
`;

const adminUserProfile = (): Promise<AdminUserProfile> => {
  if (__DEV__ && !__TEST__) {
    console.warn(
      '@allergan-data-labs/shared-sdk is deprecated - please consider refactoring this code into the application'
    );
  }
  return client
    .query({
      query: ADMIN_USER_PROFILE_QUERY,
      fetchPolicy: 'no-cache',
    })
    .then(({ data }) => data.viewer.profile as AdminUserProfile);
};

export { adminUserProfile };
