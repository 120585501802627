import { getEnvConfig } from './getEnvConfig';
import { graphQLProxyUrls } from './application.config';
import {
  AlleApolloClient,
  ApolloClientFactory,
  WriteDataOptions,
} from './client';
import { Resolvers, Operation } from 'apollo-boost';

export interface WebClientConfig {
  name?: string;
  version?: string;
  getAccessToken: () => Promise<string | undefined>;
  resolvers?: Resolvers | Resolvers[];
  initialCache?: WriteDataOptions<any>;
  overrideRequest?: (operation: Operation) => void;
  onError?: Parameters<typeof ApolloClientFactory>[0]['onError'];
}

const uri = getEnvConfig(graphQLProxyUrls, process.env.REACT_APP_ALLE_PROXY);

const AlleApolloClientWeb = ({
  name,
  version,
  getAccessToken,
  resolvers,
  initialCache,
  overrideRequest,
  onError,
}: WebClientConfig) => {
  const request = async (operation: Operation) => {
    const token = await getAccessToken();
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };
  return AlleApolloClient(
    {
      name,
      version,
      uri,
      request: overrideRequest ?? request,
      onError,
    },
    {
      ...(initialCache ? { initialCache } : {}),
      ...(resolvers ? { resolvers } : {}),
    }
  );
};

// Create a client instance without affecting the global client
const ApolloClientWebFactory = ({
  name,
  version,
  getAccessToken,
  resolvers,
  initialCache,
  overrideRequest,
  onError,
}: WebClientConfig) => {
  const request = async (operation: Operation) => {
    const token = await getAccessToken();
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };
  return ApolloClientFactory(
    {
      name,
      version,
      uri,
      request: overrideRequest ?? request,
      onError,
    },
    {
      ...(initialCache ? { initialCache } : {}),
      ...(resolvers ? { resolvers } : {}),
    }
  );
};

export { AlleApolloClientWeb, ApolloClientWebFactory };
