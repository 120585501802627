import React from 'react';

import { Form } from '@allergan-data-labs/component-library/src/components/form';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@allergan-data-labs/alle-elements';
import { Flex, Box, Text } from '@chakra-ui/react';
import { FormStates } from '@allergan-data-labs/universal-component-library/src/stateMachines/formMachineTypes';

import {
  ArchiveLocationDialogProps,
  options,
  useArchiveLocationDialog,
} from './useArchiveLocationDialog';

export const ArchiveLocationDialog = ({
  isOpen,
  locationId,
  onArchiveLocation,
  onClose,
  isLoading,
}: ArchiveLocationDialogProps) => {
  const {
    radioValue,
    current,
    setRadioValue,
    runMachine,
    closeModal,
    reasonTextInputRef,
    archiveReasonRef,
    validationErrors,
    confirmationTextInputRef,
  } = useArchiveLocationDialog({ locationId, onArchiveLocation, onClose });

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={() => {
        if (current.matches(FormStates.validation_success)) {
          return;
        }
        closeModal();
      }}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader display="flex" alignItems="center" gap="8">
          <Text flex="1">Archive Location? </Text>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Box fontSize="5" sx={{ marginBottom: '24px' }}>
            Archiving this location means it cannot be used in Allē for
            Business.
          </Box>
          <Form onSubmit={runMachine('submit')}>
            <FormControl sx={{ marginBottom: '24px' }}>
              <RadioGroup
                display="flex"
                flexDirection="column"
                gap="12"
                name="archiveReason"
                defaultValue=""
                onChange={(val) => {
                  setRadioValue(val);
                  if (reasonTextInputRef.current?.value) {
                    reasonTextInputRef.current.value = '';
                  }
                  archiveReasonRef.current = val;
                  if (val) {
                    if (val !== 'Other') {
                      runMachine('archiveReason', val)();
                    } else {
                      runMachine('reasonText')();
                    }
                  }
                }}
              >
                {options.map((option) => (
                  <Radio
                    isChecked={radioValue === option.value}
                    value={option.value}
                    key={option.value}
                  >
                    {option.label}
                  </Radio>
                ))}
              </RadioGroup>
              {validationErrors.archiveReason ? (
                <Box
                  sx={{
                    marginTop: '10px',
                    paddingLeft: '16px',
                    color: 'light.Text/Status/Error medium 3',
                  }}
                  fontSize="3"
                >
                  {validationErrors.archiveReason}
                </Box>
              ) : null}
            </FormControl>

            {radioValue === 'Other' ? (
              <>
                <FormLabel
                  fontSize={'5'}
                  fontWeight={'semibold'}
                  sx={{ marginBottom: '8px' }}
                >
                  Please Enter Reason
                </FormLabel>
                <FormControl
                  sx={{ marginBottom: '24px' }}
                  isInvalid={!!validationErrors.reasonText}
                >
                  <Input
                    data-testid="reason-text-field"
                    id="reason-text"
                    placeholder="Reason"
                    onBlur={runMachine('reasonText')}
                    margin="normal"
                    ref={reasonTextInputRef}
                  />
                  {validationErrors.reasonText ? (
                    <FormErrorMessage>
                      {validationErrors.reasonText}
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              </>
            ) : null}
            <FormLabel
              fontSize={'5'}
              fontWeight={'semibold'}
              sx={{ marginBottom: '8px' }}
            >
              Please Confirm
            </FormLabel>
            <FormControl isInvalid={!!validationErrors.confirmationText}>
              <Input
                data-testid="confirmation-text-field"
                id="confirmation-text"
                placeholder="Type ARCHIVE to confirm"
                onBlur={runMachine('confirmationText')}
                margin="normal"
                ref={confirmationTextInputRef}
              />
              {validationErrors.confirmationText ? (
                <FormErrorMessage>
                  {validationErrors.confirmationText}
                </FormErrorMessage>
              ) : null}
            </FormControl>
            <ModalFooter padding={'16px 0 24px'}>
              <Flex direction="row" width="33%" gap="8">
                <Button
                  size="sm"
                  variant="solid"
                  colorScheme="action"
                  flex="1 0 0"
                  order={2}
                  type="submit"
                  isLoading={isLoading}
                  sx={{
                    '&<span': {
                      opacity: '1',
                    },
                  }}
                >
                  Archive
                </Button>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={closeModal}
                  flex="1 0 0"
                  order={1}
                >
                  Cancel
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
