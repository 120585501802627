import { paymentsFeatureKeys } from '@allergan-data-labs/split-feature-flag/src/admin';
import { useAlleTreatments } from '@allergan-data-labs/split-feature-flag/src/useAlleTreatments';

const usePaymentsAdminFeatureFlags = () => {
  const treatments = useAlleTreatments({
    splitNames: [paymentsFeatureKeys.admin.paymentsAdmin],
  });

  const isEnabledPaymentsAdmin =
    treatments[paymentsFeatureKeys.admin.paymentsAdmin]?.treatment === 'on';

  return {
    isEnabledPaymentsAdmin,
  };
};

export { usePaymentsAdminFeatureFlags };
