import React, {
  FocusEventHandler,
  MouseEventHandler,
  useEffect,
  useId,
} from 'react';
import { Divider, Flex } from '@chakra-ui/react';
import {
  useSearchFieldContext,
  useSearchFieldEvents,
} from '../searchField.hook';
import { DropdownProps } from '../types';
import { getRecentSearchesAndResultsDropdownStyles } from '../searchField.theme';
import SkeletonLoader from './skeletonLoader';
import ViewAll from './viewAll';
import { RecentlyVisitedResultsDropdown } from '../../../components/recentlyVisitedResultsDropdown';
import { OrganizationsResultsDropdown } from '../../resultsDropdown/organizationsResults';
import { BusinessUsersResultDropdown } from '../../resultsDropdown/businessUsersResults';
import { RecentSearchTerms } from '../../recentSearchTerms';
import { EmptyResult } from '../../dataTable';

//TODO: use it later in the component
// function mapFocusedIndexToSectionIndex(
//   focusedIndex: number,
//   resultsCountBySection: number[],
//   sectionNumber: number
// ): number {
//   const sectionStartIndex = resultsCountBySection
//     .slice(0, sectionNumber)
//     .reduce((acc, count) => acc + count, 0);
//   return focusedIndex - sectionStartIndex;
// }

interface PanelProps extends React.HTMLAttributes<any> {
  isOpen: boolean;
  initialFocus?: React.MutableRefObject<HTMLInputElement | null>;
  children?: React.ReactNode;
  onFocus?: FocusEventHandler<HTMLElement>;
  onMouseOut?: MouseEventHandler<HTMLElement>;
  dataTestId?: string;
}

const Panel: React.FC<PanelProps> = ({
  isOpen,
  children,
  dataTestId = 'search-results-dropdown',
}) => {
  return isOpen ? (
    <Flex
      sx={getRecentSearchesAndResultsDropdownStyles()}
      data-testid={dataTestId}
    >
      {children}
    </Flex>
  ) : null;
};

export const RecentSearchesAndResultsDropdown: React.FC<
  Omit<DropdownProps, 'initialState'>
> = ({
  isBusinessUsersLoading,
  isOrganizationsLoading,
  isTyping,
  recentSearches,
  onSelectResults,
  menuVisible,
  query,
  onSelectRecentSearchTerm,
  onRemoveRecentSearchTerm,
  recentVisitedSearchResults = [],
  organizationsSearchResults = [],
  businessUsersSearchResults = [],
}) => {
  const { inputRef, shouldFocusInput, setShouldFocusInput } =
    useSearchFieldContext();
  const searchResultMenuId = `search-results-menu-${useId()}`;
  const { onFocus, onMouseOut } = useSearchFieldEvents();

  useEffect(() => {
    if (shouldFocusInput) {
      requestAnimationFrame(() => {
        inputRef?.current?.focus();
        // Reset the state variable after focusing to avoid unnecessary focus on future renders
        setShouldFocusInput(false);
      });
    }
  }, [shouldFocusInput, inputRef, setShouldFocusInput]);

  const hasSearchResults =
    organizationsSearchResults.length || businessUsersSearchResults.length;
  const isLoading =
    isBusinessUsersLoading || isOrganizationsLoading || isTyping;
  const organizationsLoading = isOrganizationsLoading || isTyping;
  const businessUsersLoading = isBusinessUsersLoading || isTyping;
  const hasRecentSearchTerms =
    Array.isArray(recentSearches) && recentSearches.length;

  return (
    <Panel
      isOpen={menuVisible}
      id={searchResultMenuId}
      initialFocus={inputRef}
      onFocus={onFocus}
      onMouseOut={onMouseOut}
    >
      {hasRecentSearchTerms ? (
        <RecentSearchTerms
          items={recentSearches}
          onSelect={onSelectRecentSearchTerm ?? (() => {})}
          onRemove={onRemoveRecentSearchTerm}
        />
      ) : null}

      <Divider />

      {recentVisitedSearchResults.length && query === '' && onFocus ? (
        <RecentlyVisitedResultsDropdown
          recentlyVisitedResults={recentVisitedSearchResults}
          onSelect={onSelectResults}
        />
      ) : null}

      {organizationsLoading ? (
        <SkeletonLoader />
      ) : query && organizationsSearchResults.length && onFocus ? (
        <OrganizationsResultsDropdown
          results={organizationsSearchResults}
          searchTerm={query}
          onSelect={onSelectResults}
        />
      ) : null}

      {businessUsersLoading ? (
        <SkeletonLoader />
      ) : query && businessUsersSearchResults.length && onFocus ? (
        <BusinessUsersResultDropdown
          results={businessUsersSearchResults}
          searchTerm={query}
          onSelect={onSelectResults}
        />
      ) : null}

      {!hasSearchResults && !isLoading && query.length ? (
        <EmptyResult query={query} />
      ) : null}

      {businessUsersLoading || organizationsLoading ? (
        <SkeletonLoader />
      ) : (businessUsersSearchResults.length ||
          organizationsSearchResults.length) &&
        onFocus ? (
        <>
          <Divider />
          <ViewAll query={query} />
        </>
      ) : null}
    </Panel>
  );
};
