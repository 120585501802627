import React, { useEffect, useState } from 'react';
import {
  ColumnSort,
  DataTable,
  DataTableColumn,
} from '@allergan-data-labs/alle-elements';

import { LocationTableUserStatusIndicator } from '@allergan-data-labs/admin-business-component-library/src/components';
import { Location_User_Table_Status } from '@allergan-data-labs/admin-business-component-library/src/components/statusIndicator/locationTableUserStatusIndicator';
import {
  Flex,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
} from '@chakra-ui/react';
import { Maybe } from '@allergan-data-labs/alle-graphql-types';
import { useDataTable } from './use-data-table';
import { OptionsMenu, OptionsMenuItem } from '../../../optionsMenu';
import { SearchIcon } from '@allergan-data-labs/component-library/src/icons/searchIcon';

type UserData = {
  firstName: string;
  lastName: string;
  email: string;
};

export type LocationUserDataViewModel = {
  id: string;
  user: UserData;
  phone: string;
  role: string;
  status: string;
  loginDate: string;
};
export interface LocationUserTableProps {
  users: Array<LocationUserDataViewModel>;
  isLoading: boolean;
  getOptionsMenuItems?: (id: string, status: string) => OptionsMenuItem[];
}
const LocationUserTable: React.FC<LocationUserTableProps> = ({
  users,
  isLoading,
  getOptionsMenuItems,
}) => {
  const columns: Array<DataTableColumn<LocationUserDataViewModel>> = [
    {
      column: 'User',
      field: 'user',
      renderCell: (_, user) => {
        const userInfo = user as UserData;
        return (
          <Flex flexDirection="column">
            <Text
              textStyle="Body/Small/Regular"
              color="light.Text/Neutral/Support"
            >
              {userInfo.firstName} {userInfo.lastName}
            </Text>
            <Text
              textStyle="Body/Small/Regular"
              color="light.Text/Neutral/Support"
            >
              {userInfo.email}
            </Text>
          </Flex>
        );
      },
    },
    {
      column: 'Phone',
      field: 'phone',
      renderCell: (_, phone) => {
        return <Text textStyle="Body/Medium/Regular">{phone as string}</Text>;
      },
    },
    {
      column: 'Role',
      field: 'role',
      renderCell: (_, role) => {
        return <Text textStyle="Body/Medium/Regular">{role as string}</Text>;
      },
    },
    {
      column: 'Status',
      field: 'status',
      renderCell: (_, status) => {
        const statusType = status as Maybe<Location_User_Table_Status>;
        return <LocationTableUserStatusIndicator status={statusType} />;
      },
    },
    {
      column: 'Last Login Date',
      field: 'loginDate',
      renderCell: ({ id, status }, loginDate) => {
        return (
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Text textStyle="Body/Medium/Regular">{loginDate as string}</Text>
            {getOptionsMenuItems ? (
              <OptionsMenu items={getOptionsMenuItems(id, status)} size="xs" />
            ) : null}
          </Flex>
        );
      },
    },
  ];

  const [filteredItems, setFilteredItems] = useState(users);
  const [sortConfig, setSortConfig] = useState<ColumnSort>({
    id: null,
    direction: 'asc',
  });

  const filter = (
    obj: LocationUserDataViewModel,
    searchTerm: string
  ): boolean => {
    const { user, phone, status, role, loginDate } = obj;
    const userString = `${user.firstName} ${user.lastName} (${user.email})`;
    const record = `User: ${userString}, Phone: ${phone}, Status: ${status}, Role: ${role}, Login Date: ${loginDate}`;
    return record.toLowerCase().includes(searchTerm.toLowerCase());
  };

  const getSortedItems = (
    sortingState: ColumnSort,
    records: LocationUserDataViewModel[]
  ): LocationUserDataViewModel[] => {
    if (!sortingState.id || sortingState.direction === null) {
      return records;
    }
    const sortKey = sortingState.id as keyof LocationUserDataViewModel;
    return [...records].sort((a, b) => {
      const aValue = a[sortKey] || '';
      const bValue = b[sortKey] || '';
      if (sortingState.direction === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      }
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    });
  };

  const { getDataTableProps, getInputFilterProps, setData } =
    useDataTable<LocationUserDataViewModel>({
      records: users,
      columns,
      onFilter: (searchTerm: string, records: LocationUserDataViewModel[]) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = records.filter((item) =>
          filter(item, lowerCaseSearchTerm)
        );
        setFilteredItems(filtered);
        return getSortedItems(sortConfig, filtered);
      },
    });

  useEffect(() => {
    setData(users);
  }, [setData, users]);

  const handleSort = (sortingState: ColumnSort) => {
    setSortConfig(sortingState);
    setData(getSortedItems(sortingState, filteredItems));
  };

  return (
    <Flex width="100%" flexDirection="column" gap={24}>
      <Flex justifyContent="flex-end">
        <InputGroup maxWidth={300}>
          <InputLeftElement
            left="12px"
            marginRight="10px"
            height="100%"
            pointerEvents="none"
          >
            <SearchIcon />
          </InputLeftElement>
          <Input
            paddingLeft="44px"
            width={300}
            fontSize={4}
            maxHeight={'48px'}
            minHeight={'48px'}
            {...getInputFilterProps({
              placeholder: 'Search by name, email, or phone',
            })}
          />
        </InputGroup>
      </Flex>
      <Box
        sx={{
          borderRadius: '0.5rem',
          borderColor: 'light.Border/Neutral/Subtle 2',
          borderWidth: '1px',
          borderStyle: 'solid',
        }}
      >
        <DataTable
          isLoading={isLoading}
          {...getDataTableProps()}
          onSort={handleSort}
          sx={{
            'th:nth-of-type(1)': {
              borderLeftRadius: '0.5rem',
            },
            'th:nth-of-type(5)': {
              borderRightRadius: '0.5rem',
            },
          }}
        />
      </Box>
    </Flex>
  );
};

export { LocationUserTable };
