import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconContainer } from './iconContainer';

const Calendar: React.FunctionComponent = () => (
  <IconContainer>
    <SvgIcon width="22" height="22" viewBox="0 0 22 22">
      <g
        id="-Icons-/-Basic-/-Calendar"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <mask id="mask-2" fill="white">
          <path
            d="M9,2 C9.55225,2 10,2.447754 10,3 L12,3 C12,2.447754 12.4478,2 13,2 C13.5522,2 14,2.447754 14,3 L19,3 C19.5522,3 20,3.44775 20,4 L20,19 C20,19.5522 19.5522,20 19,20 L3,20 C2.447754,20 2,19.5522 2,19 L2,4 C2,3.44775 2.447754,3 3,3 L8,3 C8,2.447754 8.44775,2 9,2 Z M12,5 C12,5.55225 12.4478,6 13,6 C13.5522,6 14,5.55225 14,5 L18,5 L18,18 L4,18 L4,5 L8,5 C8,5.55225 8.44775,6 9,6 C9.55225,6 10,5.55225 10,5 L12,5 Z M8,14 C8,14.5522 7.55225,15 7,15 C6.44775,15 6,14.5522 6,14 C6,13.4478 6.44775,13 7,13 C7.55225,13 8,13.4478 8,14 Z M11,15 C11.55225,15 12,14.5522 12,14 C12,13.4478 11.55225,13 11,13 C10.44775,13 10,13.4478 10,14 C10,14.5522 10.44775,15 11,15 Z M16,14 C16,14.5522 15.5522,15 15,15 C14.4478,15 14,14.5522 14,14 C14,13.4478 14.4478,13 15,13 C15.5522,13 16,13.4478 16,14 Z M7,11 C7.55225,11 8,10.55225 8,10 C8,9.44775 7.55225,9 7,9 C6.44775,9 6,9.44775 6,10 C6,10.55225 6.44775,11 7,11 Z M12,10 C12,10.55225 11.55225,11 11,11 C10.44775,11 10,10.55225 10,10 C10,9.44775 10.44775,9 11,9 C11.55225,9 12,9.44775 12,10 Z M15,11 C15.5522,11 16,10.55225 16,10 C16,9.44775 15.5522,9 15,9 C14.4478,9 14,9.44775 14,10 C14,10.55225 14.4478,11 15,11 Z"
            id="path-1"
          ></path>
        </mask>
        <path
          fill="#111111"
          d="M9,2 C9.55225,2 10,2.447754 10,3 L12,3 C12,2.447754 12.4478,2 13,2 C13.5522,2 14,2.447754 14,3 L19,3 C19.5522,3 20,3.44775 20,4 L20,19 C20,19.5522 19.5522,20 19,20 L3,20 C2.447754,20 2,19.5522 2,19 L2,4 C2,3.44775 2.447754,3 3,3 L8,3 C8,2.447754 8.44775,2 9,2 Z M12,5 C12,5.55225 12.4478,6 13,6 C13.5522,6 14,5.55225 14,5 L18,5 L18,18 L4,18 L4,5 L8,5 C8,5.55225 8.44775,6 9,6 C9.55225,6 10,5.55225 10,5 L12,5 Z M8,14 C8,14.5522 7.55225,15 7,15 C6.44775,15 6,14.5522 6,14 C6,13.4478 6.44775,13 7,13 C7.55225,13 8,13.4478 8,14 Z M11,15 C11.55225,15 12,14.5522 12,14 C12,13.4478 11.55225,13 11,13 C10.44775,13 10,13.4478 10,14 C10,14.5522 10.44775,15 11,15 Z M16,14 C16,14.5522 15.5522,15 15,15 C14.4478,15 14,14.5522 14,14 C14,13.4478 14.4478,13 15,13 C15.5522,13 16,13.4478 16,14 Z M7,11 C7.55225,11 8,10.55225 8,10 C8,9.44775 7.55225,9 7,9 C6.44775,9 6,9.44775 6,10 C6,10.55225 6.44775,11 7,11 Z M12,10 C12,10.55225 11.55225,11 11,11 C10.44775,11 10,10.55225 10,10 C10,9.44775 10.44775,9 11,9 C11.55225,9 12,9.44775 12,10 Z M15,11 C15.5522,11 16,10.55225 16,10 C16,9.44775 15.5522,9 15,9 C14.4478,9 14,9.44775 14,10 C14,10.55225 14.4478,11 15,11 Z"
          id="path-1"
        ></path>
      </g>
    </SvgIcon>
  </IconContainer>
);

export { Calendar };
