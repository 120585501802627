import React from 'react';
import { Section } from '../../section';
import { SectionItem } from '../../sectionItem';
import { Label } from '../../label';

import { Indicator, IndicatorVariant } from '@allergan-data-labs/alle-elements';

export interface AlleBusinessProps {
  statusLabel: string;
  statusVariant: IndicatorVariant;
  migrationDate: string;
  isEligible: boolean;
  waveId?: string;
  enableCopyWave?: boolean;
  bottomDivider?: boolean;
}

const AlleBusiness: React.FC<AlleBusinessProps> = ({
  statusLabel,
  statusVariant,
  migrationDate,
  waveId,
  isEligible,
  enableCopyWave = true,
  bottomDivider = true,
}) => {
  return (
    <Section title="Allē for Business" bottomDivider={bottomDivider}>
      <SectionItem title="Migration Status">
        <Indicator size="md" variant={statusVariant} text={statusLabel} />
      </SectionItem>
      <SectionItem title="Migration Date">
        <Label text={migrationDate} />
      </SectionItem>
      {waveId ? (
        <SectionItem title="Wave ID">
          <Label text={waveId} isCopyEnabled={enableCopyWave} />
        </SectionItem>
      ) : null}
      <SectionItem title="Allē Eligibility">
        <Label text={isEligible ? 'Eligible' : 'Ineligible'} />
      </SectionItem>
    </Section>
  );
};
export { AlleBusiness };
