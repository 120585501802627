import React from 'react';
import { Box } from '@chakra-ui/react';

import {
  Maybe,
  Provider_User_Role_Assignment_State,
} from '@allergan-data-labs/alle-graphql-types';
import {
  IndicatorVariant,
  IndicatorProps,
  IndicatorSize,
  Indicator,
} from '@allergan-data-labs/alle-elements';

export enum Location_User_Table_Status {
  Active = 'ACTIVE',
  Suggested = 'SUGGESTED',
  Suspended = 'SUSPENDED',
  Pending = 'PENDING',
}
export const LocationUserTableStatusTextMap = {
  [Provider_User_Role_Assignment_State.Active]: 'Active',
  [Provider_User_Role_Assignment_State.Suggested]: 'Suggested',
  [Provider_User_Role_Assignment_State.Suspended]: 'Removed',
  [Location_User_Table_Status.Pending]: 'Invite Pending',
};

const StatusColorMap = {
  [Provider_User_Role_Assignment_State.Active]: 'success',
  [Provider_User_Role_Assignment_State.Suggested]: 'info',
  [Provider_User_Role_Assignment_State.Suspended]: 'warning',
  [Location_User_Table_Status.Pending]: 'warning',
} as unknown as IndicatorVariant;

export interface LocationTableUserStatusIndicatorProps extends IndicatorProps {
  status: Maybe<Location_User_Table_Status>;
  /** Status indicator size
   * @default md
   */
  size?: IndicatorSize;
  /** Provide custom data-testid
   * @default undefined
   */
  dataTestId?: string;
}

export const LocationTableUserStatusIndicator: React.FC<
  LocationTableUserStatusIndicatorProps
> = ({ size = 'md', dataTestId, status }) => {
  const isValidStatusIndicator =
    status && Object.hasOwn(LocationUserTableStatusTextMap, status);
  const testId =
    dataTestId || 'location-table-users-status-indicator-container';

  return isValidStatusIndicator ? (
    <Box data-testid={testId}>
      <Indicator
        size={size}
        variant={StatusColorMap[status as any] as IndicatorVariant}
        text={LocationUserTableStatusTextMap[status]}
      />
    </Box>
  ) : null;
};
