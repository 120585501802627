import React from 'react';
import { Flex } from '@chakra-ui/react';
import { CloseIcon } from '@allergan-data-labs/alle-elements-icons/16/closeIcon';

import {
  ActionMenuHeader,
  ActionMenuGroup,
  ActionMenuClose,
  IconButton,
} from '@allergan-data-labs/alle-elements';

interface ProfileMenuHeaderProps {
  children: React.ReactNode;
}

const ProfileMenuHeader: React.FC<ProfileMenuHeaderProps> = ({ children }) => {
  return (
    <ActionMenuGroup>
      <Flex
        justify="space-between"
        sx={{
          '.action-menu__header': { padding: '0 0 8px 0' },
          '.action-menu__header + div': {
            alignContent: 'flex-start',
            display: 'contents',
          },
        }}
      >
        <ActionMenuHeader>
          <Flex gap="8px">{children}</Flex>
        </ActionMenuHeader>
        <ActionMenuClose>
          <IconButton
            aria-label="Close Menu"
            margin="-8px -8px 0 0"
            variant="ghost"
            size="xs"
          >
            <CloseIcon />
          </IconButton>
        </ActionMenuClose>
      </Flex>
    </ActionMenuGroup>
  );
};

export { ProfileMenuHeader };
